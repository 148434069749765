import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { setNewMessage } from 'state/reducers/channelReducer';
import { removeEndedLiveStreamFromCreator } from 'state/reducers/creatorReducer';
import { endActiveLiveEvent, getDiscoverLiveEvents } from 'state/reducers/liveEventReducer';

import {
  setNewComment,
  resetComments,
  updateViewCount,
  setNewPoll,
  resetPoll,
  getPollResult,
  endLive,
  getLiveStreams,
  removeEndedLiveStreamFromLiveStream
} from 'state/reducers/liveStreamReducer';
import { setHasUnread } from 'state/reducers/messageReducer';
import { removeEndedLiveStreamFromPosts } from 'state/reducers/postReducer';
import { getVideoCallRequests, updateVideoCallRequest } from 'state/reducers/requestReducer';
import { triggerTwilioEndCall } from 'state/reducers/sessionReducer';
// import { fetchLiveStreams } from 'state/reducers/postReducer';
import { toggleSnackbarOpen } from 'state/reducers/snackbarReducer';
import { newLiveStreamNotification, toggleToastClose, toggleToastOpen } from 'state/reducers/toastReducer';
import { store } from 'state/store';
import { VideoCallRequest } from 'types';

let token: string;
let hubConnection: HubConnection;

const getToken = () => {
  const state = store.getState();
  token = state.session.authToken;
};

export const initializeWebSocket = () => {
  getToken();
  if (token) {
    hubConnection = new HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_WEB_SOCKET_URL || '', { accessTokenFactory: () => token })
      .configureLogging(LogLevel.Error)
      .build();

    hubConnection.on('UserMessage', (next) => {
      const message = JSON.parse(next).data;

      store.dispatch(setHasUnread(message));
    });

    hubConnection.on('LiveStreamPost', (next) => {
      const data = JSON.parse(next).data;

      store.dispatch(getLiveStreams());
      store.dispatch(newLiveStreamNotification(data.postGUID));

      // store.dispatch(fetchLiveStreams());
    });

    hubConnection.on('LiveStreamPostCompleted', (next) => {
      const data = JSON.parse(next).data;
      const guid = data.postGUID;

      // store.dispatch(fetchLiveStreams());
      store.dispatch(getLiveStreams());
      store.dispatch(removeEndedLiveStreamFromLiveStream(guid));
      store.dispatch(removeEndedLiveStreamFromCreator(guid));
      store.dispatch(removeEndedLiveStreamFromPosts(guid));
    });

    hubConnection.on('LiveStreamComment', (next) => {
      const comment = JSON.parse(next).data;
      store.dispatch(setNewComment(comment));
    });

    hubConnection.on('LiveStreamViewerCount', (next) => {
      const data = JSON.parse(next).data;
      store.dispatch(updateViewCount(data));
    });

    hubConnection.on('LiveStreamCompleted', () => {
      store.dispatch(endLive());
      store.dispatch(endActiveLiveEvent());
    });

    hubConnection.on('Gift', (next) => {
      const payload = JSON.parse(next).data;
      store.dispatch(
        toggleSnackbarOpen({
          message: `${payload.fromUserDisplayName} has sent you ${payload.amount} Fanbucks`,
          type: 'info',
          timeout: 10000
        })
      );
    });

    hubConnection.on('LiveStreamPoll', (next) => {
      const poll = JSON.parse(next).data;
      store.dispatch(setNewPoll(poll));
    });

    hubConnection.on('LiveStreamPollCompleted', () => {
      store.dispatch(resetPoll());
    });

    hubConnection.on('LiveStreamPollVote', (next) => {
      const poll = JSON.parse(next).data;
      store.dispatch(getPollResult(poll.pollGUID));
    });

    hubConnection.on('LiveEvent', (next) => {
      const liveEventData = JSON.parse(next).data;
      store.dispatch(
        toggleSnackbarOpen({
          message: `${liveEventData.merchantName}'s Live event has started.`,
          type: 'info',
          timeout: 10000
        })
      );
    });

    hubConnection.on('LiveEventPublished', (next) => {
      const liveEventData = JSON.parse(next).data;
      store.dispatch(getDiscoverLiveEvents());
      store.dispatch(
        toggleToastOpen({
          message: `${liveEventData.merchantName} published a new live event.`,
          type: 'info',
          timeout: 10000,
          data: liveEventData
        })
      );
    });

    hubConnection.on('LiveEventCancelled', (next) => {
      const liveEventData = JSON.parse(next).data;
      store.dispatch(getDiscoverLiveEvents());
      store.dispatch(
        toggleToastOpen({
          message: `${liveEventData.merchantName}'s Live event has cancelled.`,
          type: 'info',
          timeout: 10000,
          data: liveEventData
        })
      );
    });

    hubConnection.on('MerchantMeetAndGreetRequestCallStarted', (next) => {
      const payload = JSON.parse(next);
      const videoCall = payload.data as VideoCallRequest;
      videoCall.status = 'InProgress';
      store.dispatch(updateVideoCallRequest(videoCall));

      store.dispatch(
        toggleToastOpen({
          message: `${payload.data.merchantName} is calling you...`,
          timeout: 60000,
          messageType: payload.messageType,
          data: payload
        })
      );
    });

    hubConnection.on('MerchantMeetAndGreetRequestCallCompleted', (next) => {
      const payload = JSON.parse(next);
      store.dispatch(toggleToastClose());
      store.dispatch(
        toggleSnackbarOpen({
          message: `Call with ${payload.data.merchantName} has ended.`,
          type: 'info',
          timeout: 5000
        })
      );
      const videoCall = payload.data as VideoCallRequest;
      videoCall.status = 'Completed';
      setTimeout(() => {
        store.dispatch(updateVideoCallRequest(videoCall));
        store.dispatch(triggerTwilioEndCall());
        //store.dispatch(toggleToastClose());
        // history.go(0);
      }, 3000);
    });

    hubConnection.on('ChannelMessage', (next) => {
      const newMessage = JSON.parse(next).data;
      store.dispatch(setNewMessage(newMessage));
    });

    hubConnection.on('Notification', (next) => {
      const payload = JSON.parse(next);

      if (
        payload.data.notificationType !== 'MerchantMeetAndGreetRequestStarted' &&
        payload.data.notificationType !== 'MerchantMeetAndGreetRequestCompleted' &&
        payload.data.notificationType !== 'MerchantMeetAndGreetRequestCancelled'
      ) {
        store.dispatch(getVideoCallRequests({ userGUID: payload.data.toUserGuid }));
        store.dispatch(
          toggleToastOpen({
            message: `${payload.data.message} by ${payload.data.fromUserDisplayName}.`,
            type: 'info',
            timeout: 10000,
            data: payload.data
          })
        );
      }
    });

    hubConnection.start().catch(() => {
      console.error('Error while starting connection. Retrying...');
      setTimeout(() => {
        initializeWebSocket();
      }, 500);
    });
  }
};

export const initializeMessagingService = (recipientUserGuid: string) => {
  hubConnection.on('UserMessage', (next) => {
    const message = JSON.parse(next).data;
    if (recipientUserGuid === message.fromUserGUID) {
      store.dispatch(setNewMessage(message));
    }
  });
};

export const initializeChannelMessagingService = (channelGUID: string) => {
  if (hubConnection.state === HubConnectionState.Connected) {
    hubConnection.invoke('SubscribeToChannelUpdates', channelGUID).catch((error) => {
      console.error(error);
    });
  } else {
    setTimeout(() => {
      initializeChannelMessagingService(channelGUID);
    }, 250);
  }
};

export const unsubscribeToChannelMessaging = (channelGUID: string) => {
  //store.dispatch(resetComments());
  hubConnection.invoke('UnsubscribeFromChannelUpdates', channelGUID).catch((error) => {
    console.error(error.toString());
  });
};

// export const initializeLiveChatService = () => {

// };

export const subscribeToLiveStreamChat = (liveStreamGuid: string) => {
  if (hubConnection.state === HubConnectionState.Connected) {
    hubConnection.invoke('SubscribeToLiveStreamUpdates', liveStreamGuid).catch((error) => {
      console.error(error);
    });
  } else {
    setTimeout(() => {
      subscribeToLiveStreamChat(liveStreamGuid);
    }, 250);
  }
};

export const unsubscribeToLiveStreamChat = (liveStreamGuid: string) => {
  store.dispatch(resetComments());
  hubConnection.invoke('UnsubscribeFromLiveStreamUpdates', liveStreamGuid).catch((error) => {
    console.error(error.toString());
  });
};
