import React from 'react';
import classnames from 'classnames/bind';

import { SubscriptionPlan } from 'types';
import Button from 'components/Button';

import styles from './CancelSubscriptionModal.module.scss';

const cn = classnames.bind(styles);

interface PaymentModalProps {
  subscriptionPlan?: SubscriptionPlan;
  onSelect: (value: boolean) => void;
}

const CancelSubscriptionModal = (props: PaymentModalProps) => {
  return (
    <div className={cn('content')}>
      Are you sure you want to cancel your subscription with {props.subscriptionPlan?.merchantName}?
      <div className={cn('ft-mt-8')}>
        <Button color="secondary" className={cn('ft-mr-4', 'danger')} onClick={() => props.onSelect(true)}>
          Proceed
        </Button>
        <Button color="primary" onClick={() => props.onSelect(false)}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default CancelSubscriptionModal;
