import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { useSelector } from 'react-redux';

import { RootState } from 'state/store';

import PostFeed from 'components/PostFeed';
import Skeleton from 'components/Skeleton';
import { Feed } from 'types';

import BlankPage from 'components/BlankPage';
import { PostType } from 'enums';

import styles from './Activity.module.scss';

const cn = classnames.bind(styles);

const Activity = () => {
  const creator = useSelector((state: RootState) => state.creator);
  const [feed, setFeed] = useState<Feed[] | undefined>(undefined);

  useEffect(() => {
    if (creator.feed && creator.feed.length > 0) {
      setFeed(
        creator.feed.filter(
          (x) => x.postType !== PostType.LiveStream || (x.postType === PostType.LiveStream && x.contentUrl)
        )
      );
    }
  }, [creator.feed]);

  return (
    <div className={cn('activity')}>
      {creator.feedStatus === 'fulfilled' ? (
        <>
          {feed && feed.length ? (
            feed?.map((activity) => (
              <div className={cn('post')} key={activity.guid}>
                <PostFeed type="activity" isPromoted={activity.isPromoted} feed={activity} />
              </div>
            ))
          ) : (
            <div className={cn('ft-pt-4', 'ft-pb-4')}>
              <BlankPage
                text={(creator.creator?.name || creator.creator?.userDisplayName) + ' has yet to post any content.'}
                removeTopMargin
              />
            </div>
          )}
        </>
      ) : (
        <>
          <div className={cn('ft-mt-4')}>
            <Skeleton type="rect" width="640px" height="600px" />
          </div>
        </>
      )}
    </div>
  );
};

export default Activity;
