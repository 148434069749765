import localForage from 'localforage';
import { AnyAction, AsyncThunk, combineReducers, configureStore, Reducer } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';

import authReducer from 'state/reducers/authReducer';
import creatorReducer from 'state/reducers/creatorReducer';
import liveEventReducer from 'state/reducers/liveEventReducer';
import liveStreamReducer from 'state/reducers/liveStreamReducer';
import messageReducer from 'state/reducers/messageReducer';
import paymentReducer from 'state/reducers/paymentReducer';
import postReducer from 'state/reducers/postReducer';
import requestReducer from 'state/reducers/requestReducer';
import sessionReducer from 'state/reducers/sessionReducer';
import subscriptionReducer from 'state/reducers/subscriptionReducer';
import userReducer from 'state/reducers/userReducer';
import snackbarReducer from 'state/reducers/snackbarReducer';
import channelReducer from 'state/reducers/channelReducer';
import badgeReducer from 'state/reducers/badgeReducer';
import toastReducer from 'state/reducers/toastReducer';
import { ErrorPayload } from 'types/errorPayload';
import merchandiseReducer from 'state/reducers/merchandiseReducer';

type AsyncThunkConfig = {
  state?: unknown;
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
  pendingMeta?: unknown;
  fulfilledMeta?: unknown;
  rejectedMeta?: unknown;
};

export type GenericAsyncThunk = AsyncThunk<unknown, unknown | ErrorPayload, AsyncThunkConfig>;
export type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
export type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;

const sessionPersistConfig = {
  key: 'session',
  storage: localForage,
  whitelist: ['authToken']
};

const combinedReducer = combineReducers({
  auth: authReducer,
  creator: creatorReducer,
  liveEvent: liveEventReducer,
  liveStream: liveStreamReducer,
  merchandise: merchandiseReducer,
  message: messageReducer,
  payment: paymentReducer,
  post: postReducer,
  request: requestReducer,
  subscription: subscriptionReducer,
  user: userReducer,
  snackbar: snackbarReducer,
  session: persistReducer(sessionPersistConfig, sessionReducer),
  channel: channelReducer,
  badge: badgeReducer,
  toast: toastReducer
});

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'auth/logout') {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

const persistor = persistStore(store);

export { store, persistor };
