import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { deletePaymentProfiles, fetchPaymentProfiles, fetchTransaction } from 'state/reducers/userReducer';
import { RootState } from 'state/store';

import { STATES } from 'consts';
// import { COUNTRIES } from 'consts';

import { useScript } from 'hooks';
import { CreditCard, TokenBundle } from 'types';
import { formatNumber, serializeFormData } from 'utils';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Input from 'components/Input';
import Select from 'components/Select';

import americanExpressImg from 'assets/images/amex-purple.png';
import genericCard from 'assets/images/generic-blue.png';
import masterCardImg from 'assets/images/mastercard-gray.png';
import visaImg from 'assets/images/visa-blue.png';

import { toggleSnackbarOpen } from 'state/reducers/snackbarReducer';
import { resetPurchaseStatus } from 'state/reducers/paymentReducer';
import Modal from 'components/Modal';
import Icon from 'components/Icon';
import DeleteCreditCardModal from 'components/Modal/DeleteCreditCardModal';
import styles from './PaymentGateway.module.scss';

const cn = classnames.bind(styles);

declare class Payment {
  static formatCardNumber(element: Element | null): void;
  static formatCardExpiry(element: Element | null): void;
  static formatCardCVC(element: Element | null): void;
}

interface CheckboxProps {
  name?: string;
  onConfirm: (creditCard: CreditCard) => unknown;
  onSuccess: () => void;
  purchaseToken?: TokenBundle | undefined;
  isSubscription?: boolean;
}

const PaymentGateway = (props: CheckboxProps) => {
  useScript('/lib/payment.js');
  const { payment, user } = useSelector((state: RootState) => ({ payment: state.payment, user: state.user }));
  const [isNewPaymentProfile, setIsNewPaymentProfile] = useState(false);
  const [selectedCreditCard, setSelectedCreditCard] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isPurchased, setIsPurchased] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);
  const cardNumberRef = useRef<HTMLInputElement>(null);
  const cardExpiryRef = useRef<HTMLInputElement>(null);
  const cardCvvRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const usStates = STATES.map((x) => ({ name: x.name, value: x.key }));
  // const countries = COUNTRIES.map((x) => ({ name: x.name, value: x.code }));

  const formatCardEnding = (cardNumber: string) => {
    return cardNumber.substring(4, 8);
  };

  const confirmPurchase = () => {
    let newCard: CreditCard = {};
    let serializeForm;
    setIsPurchased(true);
    if (isNewPaymentProfile) {
      serializeForm = new FormData(formRef.current as HTMLFormElement);
      newCard = serializeFormData(serializeForm);
      newCard.creditCardNumber = newCard.creditCardNumber?.replace(/\s/g, '');
      newCard.expirationDate = newCard.expirationDate?.replace(/\s/g, '');
    } else {
      newCard.guid = selectedCreditCard;
    }
    if (newCard.saveCreditCard) {
      newCard.saveCreditCard = true;
    }
    newCard.isNewPaymentProfile = isNewPaymentProfile;

    if (isNewPaymentProfile && !formRef.current?.reportValidity()) {
      return false;
    }
    props.onConfirm(newCard);
  };

  const validateNewPayment = (e: FormEvent) => {
    formRef.current?.reportValidity();
    e.preventDefault();
    if (formRef.current?.checkValidity()) {
      setShowConfirmationModal(true);
    }
  };

  const deleteCreditCard = (creditCardGUID: string) => {
    dispatch(deletePaymentProfiles(creditCardGUID));
    dispatch(
      toggleSnackbarOpen({
        message: `Credit Card deleted.`,
        type: 'info',
        timeout: 4000
      })
    );

    setShowDeleteModal(false);
  };

  const confirmDeletion = (value: boolean) => {
    if (value) {
      deleteCreditCard(selectedCreditCard);
    } else {
      setShowDeleteModal(false);
    }
  };

  useEffect(() => {
    if (isNewPaymentProfile) {
      setTimeout(() => {
        Payment.formatCardNumber(cardNumberRef.current);
        Payment.formatCardExpiry(cardExpiryRef.current);
        Payment.formatCardCVC(cardCvvRef.current);
      }, 50);
    }
  }, [isNewPaymentProfile]);

  useEffect(() => {
    if (payment.isPurchaseSuccess === true) {
      setIsNewPaymentProfile(false);
      setSelectedCreditCard('');
      props.onSuccess();
      dispatch(
        toggleSnackbarOpen({
          message: `Congratulations, your purchase was successful.`,
          type: 'success',
          timeout: 3000
        })
      );
      dispatch(fetchTransaction());
    }
    if (payment.isPurchaseSuccess === false) {
      dispatch(
        toggleSnackbarOpen({
          message: `Unable to complete purchase. Please check your credit card details and try again.`,
          type: 'danger',
          timeout: 3000
        })
      );
    }
    dispatch(resetPurchaseStatus());
  }, [payment.isPurchaseSuccess]);

  useEffect(() => {
    dispatch(fetchPaymentProfiles(user.user?.guid as string));
  }, []);

  useEffect(() => {
    if (user.paymentProfiles && user.paymentProfiles?.length === 1) {
      setSelectedCreditCard(user.paymentProfiles[0].guid || '');
    } else {
      setSelectedCreditCard('');
    }
  }, [user.paymentProfiles]);

  return (
    <>
      <div>
        {isNewPaymentProfile ? (
          <>
            <div className={cn('is-size-6', 'has-text-weight-semibold')}>New payment details:</div>
            <div className={cn('new-card', 'ft-mt-4')}>
              <form ref={formRef}>
                <div className={cn('row')}>
                  <Input
                    inputRef={cardNumberRef}
                    className={cn('w-50')}
                    label="Card number:"
                    name="creditCardNumber"
                    layer={1}
                    required
                  />
                  <Input
                    inputRef={cardExpiryRef}
                    className={cn('w-25')}
                    label="Expiration:"
                    name="expirationDate"
                    placeholder="MM/YY"
                    layer={1}
                    maxLength={7}
                    required
                  />
                  <Input inputRef={cardCvvRef} className={cn('w-25')} label="CVV:" name="cardCode" layer={1} required />
                </div>
                <div className={cn('row')}>
                  <Input className={cn('w-100')} label="Name on card:" name="firstName" layer={1} required />
                </div>
                <div className={cn('row')}>
                  <Input className={cn('w-100')} label="Address:" name="address" layer={1} required />
                </div>
                <div className={cn('row')}>
                  <Select className={cn('w-33')} label="State/Province:" name="state" layer={1} options={usStates} />
                  <Input className={cn('w-33')} label="City:" name="city" layer={1} required />
                  <Input className={cn('w-33')} label="Zip/Postal code:" name="zipCode" layer={1} required />
                </div>
                {/* <div className={cn('row')}>
                  <Select className={cn('w-100')} label="Country" name="country" layer={1} options={countries} />
                </div> */}
                <div className={cn('row')}>
                  <Checkbox label="Save this card for future purchases" name="saveCreditCard" />
                </div>
              </form>
            </div>
          </>
        ) : (
          <>
            <div className={cn('is-size-6', 'has-text-weight-semibold')}>Select credit card</div>
            <div className={cn('fluid-wrapper', 'ft-mt-4')}>
              <div className={cn('flexbox', 'fluid', 'credit-cards')}>
                {user.paymentProfiles?.map((card) => {
                  let isGeneric = false;
                  let cardImgSrc = undefined;
                  switch (card.creditCardType) {
                    case 'AmericanExpress':
                      cardImgSrc = americanExpressImg;
                      break;
                    case 'Mastercard':
                      cardImgSrc = masterCardImg;
                      break;
                    case 'Visa':
                      cardImgSrc = visaImg;
                      break;
                    default:
                      cardImgSrc = genericCard;
                      isGeneric = true;
                      break;
                  }

                  return (
                    <div
                      key={card.guid}
                      className={cn('card', 'ft-mb-2', { selected: selectedCreditCard === card.guid })}
                      onClick={() => setSelectedCreditCard(card.guid || '')}>
                      <img className={cn('background')} src={cardImgSrc} />
                      {selectedCreditCard && selectedCreditCard === card.guid && !props.isSubscription && (
                        <Button type="button" className={cn('delete')} onClick={() => setShowDeleteModal(true)}>
                          <Icon name="trash" size="lg"></Icon>
                        </Button>
                      )}
                      <div className={cn('detail')}>Ending {formatCardEnding(card.maskedNumber || '')}</div>
                      {isGeneric && <div className={cn('card-type')}>{card.creditCardType}</div>}
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
        <div className={cn('confirm')}>
          {!isNewPaymentProfile && (
            <>
              <div>
                <Button
                  type="button"
                  onClick={() => setShowConfirmationModal(true)}
                  disabled={!selectedCreditCard ? true : false}>
                  Purchase
                </Button>
              </div>
              <div className={cn('ft-ml-4')}>
                <Button type="button" color="clear" onClick={() => setIsNewPaymentProfile(true)}>
                  Add new card
                </Button>
              </div>
            </>
          )}
          {isNewPaymentProfile && (
            <>
              <div>
                <Button type="button" onClick={validateNewPayment}>
                  Purchase
                </Button>
              </div>
              <div className={cn('ft-ml-4')}>
                <Button type="button" color="clear" onClick={() => setIsNewPaymentProfile(false)}>
                  Back
                </Button>
              </div>
            </>
          )}
        </div>
        {showConfirmationModal && props.purchaseToken && (
          <Modal width="70rem" onClose={() => setShowConfirmationModal(false)}>
            <div className={cn('payment-modal')}>
              <div className={cn('finalize', 'ft-mt-4')}>
                <div className={cn('is-size-5', 'has-text-weight-semibold')}>Confirm purchase</div>
                <div className={cn('flexbox')}>
                  <div className={cn('flex', 'purchase-text')}>
                    {props.isSubscription ? (
                      <div className={cn('ft-mt-4')}>You are about to subscribe to:</div>
                    ) : (
                      <div className={cn('ft-mt-4')}>You are about to purchase:</div>
                    )}
                    <div className={cn('ft-mt-2')}>for:</div>
                  </div>
                  <div className={cn('is-size-5', 'has-text-weight-bold', 'total', 'flex')}>
                    <div className={cn('flexbox', 'ft-mt-2')}>
                      <div className={cn('amount')}>
                        {props.isSubscription ? (
                          <div className={cn('ft-mb-2')}>{props.name}</div>
                        ) : (
                          <>
                            <div>{formatNumber(props.purchaseToken.amount || 0)}</div>
                            <div className={cn('ft-ml-1')}>
                              <Icon name="fanbucks" />
                            </div>
                            <div className={cn('ft-ml-2')}>FanBucks</div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className={cn('ft-mt-2')}>${props.purchaseToken?.priceUSD}</div>
                  </div>
                </div>
              </div>
              <div className={cn('confirm')}>
                <div>
                  <Button onClick={confirmPurchase} disabled={isPurchased}>
                    Confirm Purchase
                  </Button>
                </div>
                <div className={cn('ft-mr-8')}>
                  <Button type="button" color="clear" onClick={() => setShowConfirmationModal(false)}>
                    Back
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        )}
        {showDeleteModal && (
          <Modal onClose={() => setShowDeleteModal(false)}>
            <DeleteCreditCardModal onSelect={confirmDeletion} />
          </Modal>
        )}
      </div>
    </>
  );
};

export default PaymentGateway;
