import React, { ReactNode, useRef, useState } from 'react';
import classnames from 'classnames/bind';

import { useOutsideAlerter } from 'hooks';
import ContextMenuList from 'components/ContextMenu/ContextMenuList';

import styles from './ContextMenu.module.scss';

const cn = classnames.bind(styles);

interface ContextMenuProps {
  interface?: ReactNode;
  children?: ReactNode;
  alignment?: 'mobile' | 'channel';
  onClose?: () => void;
}

const ContextMenu = (props: ContextMenuProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, () => {
    setIsMenuOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  });

  return (
    <div className={cn('wrapper')} ref={wrapperRef}>
      <div
        className={cn('wrapper-inner')}
        onClick={(event) => {
          setIsMenuOpen(!isMenuOpen);
          event.stopPropagation();
        }}>
        {props.interface}
      </div>
      {isMenuOpen && <ContextMenuList alignment={props.alignment}>{props.children}</ContextMenuList>}
    </div>
  );
};

export default ContextMenu;
