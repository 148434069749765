import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import { cloneDeep } from 'lodash';
import moment from 'moment';

import { fetchTokenBundles, purchaseTokens } from 'state/reducers/paymentReducer';
import { fetchPaymentProfiles, fetchTokens, fetchTransaction } from 'state/reducers/userReducer';
import { RootState } from 'state/store';

import { CreditCard, PaymentProfile, TokenBalance, TokenBundle, UserTransaction } from 'types';
import { formatNumber } from 'utils';
import Button from 'components/Button';
import Icon from 'components/Icon';
import PaymentGateway from 'components/PaymentGateway';
import { useHistory } from 'react-router-dom';
import { resetCreator, searchCreators } from 'state/reducers/creatorReducer';

import tokenCardImg from 'assets/images/trubucks-balance-background.png';
import credit1Img from 'assets/images/trubucks/trubucks-credit-background-1.png';
import credit2Img from 'assets/images/trubucks/trubucks-credit-background-2.png';
import credit3Img from 'assets/images/trubucks/trubucks-credit-background-3.png';
import credit4Img from 'assets/images/trubucks/trubucks-credit-background-4.png';
import credit5Img from 'assets/images/trubucks/trubucks-credit-background-5.png';
import credit6Img from 'assets/images/trubucks/trubucks-credit-background-6.png';
import defaultAvatar from 'assets/images/default-avatar-1.png';

import styles from './Wallet.module.scss';

const cn = classnames.bind(styles);

const tokenBackgrounds = [credit1Img, credit2Img, credit3Img, credit4Img, credit5Img, credit6Img];

const Wallet = () => {
  const { payment, user, creator } = useSelector((state: RootState) => ({
    payment: state.payment,
    user: state.user,
    creator: state.creator
  }));
  const [tabValue, setTabValue] = useState('fanbucks');
  const [transactionTab, setTransactionTab] = useState<string | undefined>(undefined);

  const [fanbucksTransaction, setFanbucksTransaction] = useState<UserTransaction[] | undefined>([]);
  const [fanRewardsTransaction, setFanRewardsTransaction] = useState<UserTransaction[] | undefined>([]);

  const [fanbucksCurrentTransaction, setFanbucksCurrentTransaction] = useState<UserTransaction[] | undefined>([]);
  const [fanRewardsCurrentTransaction, setFanRewardsCurrentTransaction] = useState<UserTransaction[] | undefined>([]);

  const [fanbucksCurrentPage, setFanbucksCurrentPage] = useState(1);
  const [fanRewardCurrentPage, setFanRewardCurrentPage] = useState(1);

  const transactionPerPage = 20;

  const indexOfFRLastTransaction: number = fanRewardCurrentPage * transactionPerPage;
  const indexOfFRFirstTransaction: number = indexOfFRLastTransaction - transactionPerPage;

  const indexOfFBLastTransaction: number = fanbucksCurrentPage * transactionPerPage;
  const indexOfFBFirstTransaction: number = indexOfFBLastTransaction - transactionPerPage;

  const [truBucks, setTruBucks] = useState<TokenBalance | undefined>();
  const [purchaseToken, setPurchaseToken] = useState<TokenBundle | undefined>();
  const dispatch = useDispatch();
  const history = useHistory();

  const onSetPurchaseTier = (token: TokenBundle) => {
    setPurchaseToken(token);
  };

  const confirmPurchase = (creditCard: CreditCard) => {
    const purchaseRequest: PaymentProfile = {
      tokenPurchaseBatchGUID: purchaseToken?.guid
    };
    if (creditCard.isNewPaymentProfile) {
      purchaseRequest.creditCard = creditCard;
      purchaseRequest.saveCreditCard = creditCard.saveCreditCard;
    } else {
      purchaseRequest.paymentProfileGUID = creditCard.guid;
    }
    dispatch(purchaseTokens(purchaseRequest));
  };

  const purchaseSuccess = () => {
    const updatedTruBucks = cloneDeep(truBucks);
    if (updatedTruBucks?.balance) {
      updatedTruBucks.balance += purchaseToken?.amount || 0;
    }
    setTruBucks(updatedTruBucks);
    setPurchaseToken(undefined);
    dispatch(fetchPaymentProfiles(user.user?.guid as string));
  };

  const nextPage = (currentPage: number) => {
    if (tabValue === 'rewards' && transactionTab === 'Received') {
      if (
        fanRewardsTransaction &&
        fanRewardCurrentPage < Math.ceil(fanRewardsTransaction?.length / transactionPerPage)
      ) {
        setFanRewardCurrentPage(++currentPage);
      }
    }

    if (tabValue === 'fanbucks' && transactionTab === 'Received') {
      if (fanbucksTransaction && fanbucksCurrentPage < Math.ceil(fanbucksTransaction?.length / transactionPerPage)) {
        setFanbucksCurrentPage(++currentPage);
      }
    }
  };

  const prevPage = (currentPage: number) => {
    if (currentPage > 1 && tabValue === 'rewards' && transactionTab === 'Received') {
      setFanRewardCurrentPage(--currentPage);
    }

    if (currentPage > 1 && tabValue === 'fanbucks' && transactionTab === 'Received') {
      setFanbucksCurrentPage(--currentPage);
    }
  };

  const goToMerchant = (merchantName: string) => {
    dispatch(searchCreators(merchantName));

    if (creator.creators) {
      history.push(`/${creator.creators.find((x) => x.name === merchantName)?.userUsername}`);
      dispatch(resetCreator());
    }
  };

  useEffect(() => {
    dispatch(fetchTokens());
    dispatch(fetchTokenBundles());
    dispatch(fetchTransaction());
  }, []);

  useEffect(() => {
    const fanbucks = cloneDeep(user.transaction)?.filter(
      (t) =>
        t.type !== 'Received' && !t.tetheredMerchantAmount && !t.tetheredMerchantGUID && !t.tetheredMerchantUserUsername
    );
    setFanbucksTransaction(fanbucks);
    setFanbucksCurrentTransaction(fanbucks?.splice(indexOfFBFirstTransaction, indexOfFBLastTransaction));

    const fanrewards = cloneDeep(user.transaction)?.filter(
      (t) =>
        t.type === 'Received' ||
        (t.tetheredMerchantAmount &&
          t.tetheredMerchantGUID &&
          t.tetheredMerchantUserUsername &&
          t.fromUserGUID === user.user?.guid)
    );
    setFanRewardsTransaction(fanrewards);
    setFanRewardsCurrentTransaction(fanrewards?.splice(indexOfFRFirstTransaction, indexOfFRLastTransaction));
  }, [user.transaction]);

  useEffect(() => {
    if (tabValue === 'fanbucks' && transactionTab === 'Received') {
      const currentTransactionFB = fanbucksTransaction?.slice(indexOfFBFirstTransaction, indexOfFBLastTransaction);
      setFanbucksCurrentTransaction(currentTransactionFB);
    }

    if (tabValue === 'rewards' && transactionTab === 'Received') {
      const currentTransactionFR = fanRewardsTransaction?.slice(indexOfFRFirstTransaction, indexOfFRLastTransaction);
      setFanRewardsCurrentTransaction(currentTransactionFR);
    }
  }, [fanRewardCurrentPage, fanbucksCurrentPage]);

  useEffect(() => {
    if (user.balance) {
      setTruBucks(user.balance.find((x) => x.tetheredMerchantGUID === undefined));
    }
  }, [user.balance]);

  return (
    <div>
      <div className={cn('is-size-5', 'has-text-weight-semibold', 'flexbox', 'page-title')}>
        <div className={cn('mr-6')}>Wallet</div>
      </div>
      <div className={cn('ft-mt-4')}>
        <ul className={cn('tab')}>
          <li className={cn('tab-item', { active: tabValue === 'fanbucks' })} onClick={() => setTabValue('fanbucks')}>
            <Icon name="fanbucks" size="md" /> FanBucks
            {tabValue === 'fanbucks' && <div className={cn('border')} />}
          </li>
          <li className={cn('tab-item', { active: tabValue === 'rewards' })} onClick={() => setTabValue('rewards')}>
            <Icon name="fan-rewards" size="md" /> Fan Rewards
            {tabValue === 'rewards' && <div className={cn('border')} />}
          </li>
        </ul>
      </div>
      <div className={cn('ft-mt-4')}>
        <ul className={cn('tab')}>
          <li
            className={cn('tab-item', { active: transactionTab === undefined })}
            onClick={() => setTransactionTab(undefined)}>
            Balance
            {transactionTab === undefined && <div className={cn('border')} />}
          </li>
          <li
            className={cn('tab-item', { active: transactionTab === 'Received' })}
            onClick={() => setTransactionTab('Received')}>
            Transaction History
            {transactionTab === 'Received' && <div className={cn('border')} />}
          </li>
        </ul>
      </div>
      {tabValue === 'fanbucks' && transactionTab === undefined && (
        <div className={cn('main')}>
          <div className={cn('trubucks-balance')}>
            <img className={cn('background')} src={tokenCardImg} />
            <div className={cn('details', 'flexbox')}>
              <div className={cn('flex')}>Your FanBucks Balance</div>
              <div className={cn('balance')}>
                <div>{formatNumber(truBucks?.balance || 0)}</div>
                <div className={cn('ft-ml-2')}>
                  <Icon name="fanbucks" size="xl" />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>Purchase FanBucks</div>
            <ul className={cn('bundles', 'ft-mt-2', { 'has-selection': purchaseToken })}>
              {payment.tokens?.map((token, index) => (
                <li
                  key={token.guid}
                  className={cn(`tier-${index + 1}`, { active: token.guid === purchaseToken?.guid })}
                  onClick={() => onSetPurchaseTier(token)}>
                  <img className={cn('background')} src={tokenBackgrounds[index]} />
                  <div className={cn('detail')}>
                    <div className={cn('amount')}>
                      <div>{formatNumber(token.amount || 0)}</div>
                      <div className={cn('ft-ml-1')}>
                        <Icon name="fanbucks" />
                      </div>
                    </div>
                    <div className={cn('price')}>${token.priceUSD}</div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          {purchaseToken && (
            <div className={cn('finalize', 'ft-mt-4')}>
              <div className={cn('is-size-5', 'has-text-weight-semibold')}>Confirm purchase</div>
              <div className={cn('ft-mt-4')}>You are about to puchase:</div>
              <div className={cn('flexbox', 'ft-mt-2')}>
                <div className={cn('amount')}>
                  <div>{formatNumber(purchaseToken.amount || 0)}</div>
                  <div className={cn('ft-ml-1')}>
                    <Icon name="fanbucks" />
                  </div>
                  <div className={cn('ft-ml-2')}>FanBucks</div>
                </div>
              </div>
              <div className={cn('is-size-5', 'has-text-weight-bold', 'total')}>
                <span className={cn('ft-mr-4')}>Your total</span>${purchaseToken?.priceUSD}
              </div>
              <div className={cn('ft-mt-4')}>
                <PaymentGateway onConfirm={confirmPurchase} purchaseToken={purchaseToken} onSuccess={purchaseSuccess} />
              </div>
            </div>
          )}
        </div>
      )}
      {tabValue === 'fanbucks' && transactionTab === 'Received' && fanbucksTransaction ? (
        <div className={cn('table-wrapper', 'ft-mt-4')}>
          <table className={cn('table')}>
            <thead>
              <tr>
                <th>User</th>
                <th className={cn('w-50')}>Transaction</th>
                <th>Amount</th>
                <th>Type</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {fanbucksCurrentTransaction?.map((transaction: UserTransaction) => {
                return (
                  <tr key={transaction.guid}>
                    <td className={cn('flexbox')}>
                      <img
                        className={cn('avatar')}
                        src={
                          transaction.type === 'Payment'
                            ? transaction.fromUserProfileImageUrl || defaultAvatar
                            : transaction.fromUserProfileImageUrl || transaction.toUserProfileImageUrl || defaultAvatar
                        }
                      />
                      <div className={cn('ft-ml-2')}>
                        {transaction.type === 'Payment'
                          ? transaction.fromUserDisplayName
                          : transaction.fromUserDisplayName || transaction.toUserDisplayName}
                      </div>
                    </td>
                    <td>{transaction.details}</td>
                    {() => {
                      if (transaction.tokenProfileKey === 'KUDO') {
                        return transaction.type === 'Received' ? (
                          <td className={cn('has-text-legacy')}>{formatNumber(transaction.amount || 0)}</td>
                        ) : (
                          <td className={cn('has-text-danger')}>{formatNumber(transaction.amount || 0)}</td>
                        );
                      }

                      if (transaction.tokenProfileKey === 'Cash') {
                        return transaction.type === 'Received' ? (
                          <td className={cn('has-text-success')}>{formatNumber(transaction.amount || 0)}</td>
                        ) : (
                          <td className={cn('has-text-danger')}>{formatNumber(transaction.amount || 0)}</td>
                        );
                      }
                    }}
                    {transaction.type === 'Received' && transaction.tokenProfileKey === 'KUDO' ? (
                      <td className={cn('has-text-primary')}>{formatNumber(transaction.amount || 0)}</td>
                    ) : transaction.type === 'Payment' && transaction.tokenProfileKey === 'KUDO' ? (
                      <td className={cn('has-text-danger')}>{formatNumber(transaction.amount || 0)}</td>
                    ) : transaction.type === 'Received' && transaction.tokenProfileKey === 'Cash' ? (
                      <td className={cn('has-text-success')}>{formatNumber(transaction.amount || 0)}</td>
                    ) : (
                      <td className={cn('has-text-primary')}>{formatNumber(transaction.amount || 0)}</td>
                    )}
                    <td>{transaction.type}</td>
                    <td>{moment(transaction.dateTransferred).format('MM/DD/YYYY')}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className={cn('ft-mt-4', 'flexbox', 'table-footer')}>
            <div className={cn('flexbox')}>
              <Button className={cn('ft-mr-4 mr-5')} onClick={() => prevPage(fanbucksCurrentPage)}>
                Prev
              </Button>
              <Button onClick={() => nextPage(fanbucksCurrentPage)}>Next</Button>
            </div>
            {user.transaction ? (
              <div className={cn('flexbox', 'page', 'ft-ml-4')}>
                <div className={cn('is-size-7')}>
                  Page {fanbucksCurrentPage} of{' '}
                  {fanbucksTransaction.length !== 0
                    ? Math.ceil((fanbucksTransaction?.length as number) / transactionPerPage)
                    : 1}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      ) : (
        ''
      )}
      {tabValue === 'rewards' && transactionTab === undefined && user.balance && (
        <div className={cn('table-wrapper', 'ft-mt-4')}>
          <table className={cn('table')}>
            <thead>
              <tr>
                <th>Athlete</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              {user.balance
                ?.filter((x) => x.tetheredMerchantGUID && x.tetheredMerchantGUID !== user.user?.merchantGUID)
                .map((merchant: TokenBalance) => {
                  return (
                    <tr
                      className={cn('rewards-row', 'cursor')}
                      key={merchant.tetheredMerchantGUID}
                      onClick={() => goToMerchant(merchant.tetheredMerchantName as string)}>
                      <td className={cn('flexbox')}>
                        <img className={cn('avatar')} src={merchant.tetheredMerchantProfileImageUrl || defaultAvatar} />
                        <div className={cn('ft-ml-4')}>{merchant.tetheredMerchantName}</div>
                      </td>
                      <td>
                        <Icon name="fan-rewards" size="md" /> {formatNumber(merchant.balance || 0)}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className={cn('ft-mt-4', 'flexbox', 'table-footer')}>
            <div className={cn('flexbox')}>
              <Button className={cn('ft-mr-4')} onClick={() => prevPage(fanbucksCurrentPage)}>
                Prev
              </Button>
              <Button onClick={() => nextPage(fanbucksCurrentPage)}>Next</Button>
            </div>
            {user.balance ? (
              <div className={cn('flexbox', 'page', 'ft-ml-4')}>
                <div className={cn('is-size-7')}>
                  Page {fanbucksCurrentPage} of {Math.ceil(user.balance.length / transactionPerPage)}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      )}
      {tabValue === 'rewards' && transactionTab === 'Received' && fanRewardsTransaction ? (
        <div className={cn('table-wrapper', 'ft-mt-4')}>
          <table className={cn('table')}>
            <thead>
              <tr>
                <th>User</th>
                <th className={cn('w-50')}>Transaction</th>
                <th>Amount</th>
                <th>Type</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {fanRewardsCurrentTransaction?.map((transaction: UserTransaction) => {
                return (
                  <tr key={transaction.guid}>
                    <td className={cn('flexbox')}>
                      <img
                        className={cn('avatar')}
                        src={
                          transaction.type === 'Payment'
                            ? transaction.fromUserProfileImageUrl || defaultAvatar
                            : transaction.fromUserProfileImageUrl || transaction.toUserProfileImageUrl || defaultAvatar
                        }
                      />
                      <div className={cn('ft-ml-2')}>
                        {transaction.type === 'Payment'
                          ? transaction.fromUserDisplayName
                          : transaction.fromUserDisplayName || transaction.toUserDisplayName}
                      </div>
                    </td>
                    <td>{transaction.details}</td>
                    {() => {
                      if (transaction.tokenProfileKey === 'KUDO') {
                        return transaction.type === 'Received' ? (
                          <td className={cn('has-text-legacy')}>{formatNumber(transaction.amount || 0)}</td>
                        ) : (
                          <td className={cn('has-text-danger')}>{formatNumber(transaction.amount || 0)}</td>
                        );
                      }

                      if (transaction.tokenProfileKey === 'Cash') {
                        return transaction.type === 'Received' ? (
                          <td className={cn('has-text-success')}>{formatNumber(transaction.amount || 0)}</td>
                        ) : (
                          <td className={cn('has-text-danger')}>{formatNumber(transaction.amount || 0)}</td>
                        );
                      }
                    }}
                    {transaction.type === 'Received' && transaction.tokenProfileKey === 'KUDO' ? (
                      <td className={cn('has-text-primary')}>{formatNumber(transaction.amount || 0)}</td>
                    ) : transaction.type === 'Payment' && transaction.tokenProfileKey === 'KUDO' ? (
                      <td className={cn('has-text-danger')}>{formatNumber(transaction.amount || 0)}</td>
                    ) : transaction.type === 'Received' && transaction.tokenProfileKey === 'Cash' ? (
                      <td className={cn('has-text-success')}>{formatNumber(transaction.amount || 0)}</td>
                    ) : (
                      <td className={cn('has-text-primary')}>{formatNumber(transaction.amount || 0)}</td>
                    )}
                    <td>{transaction.type}</td>
                    <td>{moment(transaction.dateTransferred).format('MM/DD/YYYY')}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className={cn('ft-mt-4', 'flexbox', 'table-footer')}>
            <div className={cn('flexbox')}>
              <Button className={cn('ft-mr-4')} onClick={() => prevPage(fanRewardCurrentPage)}>
                Prev
              </Button>
              <Button onClick={() => nextPage(fanRewardCurrentPage)}>Next</Button>
            </div>
            {user.transaction ? (
              <div className={cn('flexbox', 'page', 'ft-ml-4')}>
                <div className={cn('is-size-7')}>
                  Page {fanRewardCurrentPage} of{' '}
                  {fanRewardsTransaction.length !== 0
                    ? Math.ceil((fanRewardsTransaction?.length as number) / transactionPerPage)
                    : 1}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Wallet;
