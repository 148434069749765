import { httpClient } from 'http/httpClient';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PendingAction, RejectedAction } from 'state/store';
import { Badge } from 'types/badge';

export const getBadges = createAsyncThunk('badge/getBadges', async (merchantGuid: string) => {
  const response = await httpClient.get(`/badges/search`, {
    params: { merchantGuid: merchantGuid }
  });
  return response.data;
});

export const getBadgeList = createAsyncThunk('badge/getBadgeList', async () => {
  const response = await httpClient.get(`/badges`);
  return response.data;
});

interface BadgeState {
  status: string;
  error?: string;
  badges?: Badge[];
  badgeList?: Badge[];
}

const initialState: BadgeState = {
  status: 'idle'
};

const badgeSlice = createSlice({
  name: 'badge',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBadges.fulfilled, (state, action) => {
        state.status = 'success';
        state.badges = action.payload;
      })
      .addCase(getBadgeList.fulfilled, (state, action) => {
        state.status = 'success';
        state.badgeList = action.payload.filter(
          (x: Badge) => x.type === 'LoyaltyBadge' && x.membershipDuration === 'OneMonth'
        );
      })
      .addMatcher(
        (action): action is PendingAction => action.type.endsWith('/pending'),
        (state) => {
          state.status = 'loading';
        }
      )
      .addMatcher(
        (action): action is RejectedAction => action.type.endsWith('/rejected'),
        (state, action) => {
          state.error = 'rejected';
          state.error = action.error.message;
        }
      );
  }
});

export default badgeSlice.reducer;
