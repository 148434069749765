import { httpClient } from 'http/httpClient';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

import { Message, User, UserMessages } from 'types';
import { PendingAction, RejectedAction } from 'state/store';
import { ErrorPayload } from 'types/errorPayload';

export const fetchMessages = createAsyncThunk('message/fetchMessages', async (offset: number) => {
  const response = await httpClient.get('/users/current/usermessagegroups', { params: { offetN: offset, topN: '20' } });
  return response.data;
});

export const fetchUserMessages = createAsyncThunk('message/fetchUserMessages', async (recipientUserGuid: string) => {
  const response = await httpClient.get('/usermessages/search', { params: { userGUID: recipientUserGuid } });
  return response.data;
});

export const addMessage = createAsyncThunk('message/addMessage', async (message: Message, { rejectWithValue }) => {
  try {
    const response = await httpClient.post('/usermessages', message);
    return { newMessage: message, data: response.data };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const markMessageAsRead = createAsyncThunk('message/markMessageAsRead', async (userGuid: string) => {
  await httpClient.put(`/users/current/usermessagegroups/${userGuid}/markasread`, {});
  return userGuid;
});

export const searchUsers = createAsyncThunk('message/searchUsers', async (query: string) => {
  const response = await httpClient.get('/users/search', { params: { searchTerm: query } });
  return response.data;
});

export const deleteMessageForCallingUser = createAsyncThunk(
  'message/deleteMessageForCallingUser',
  async (messageGUID: string) => {
    const response = await httpClient.delete(`/usermessages/${messageGUID}`);
    return { guid: messageGUID, data: response.data };
  }
);

export const unsendMessageForUser = createAsyncThunk('message/unsendMessageForUser', async (messageGUID: string) => {
  const response = await httpClient.post(`/usermessages/${messageGUID}/unsend`, {});
  if (response.status === 204) {
    const deleteResponse = await httpClient.delete(`/usermessages/${messageGUID}`);
    return { guid: messageGUID, data: deleteResponse.data };
  }
});

interface MessageState {
  status: string;
  error?: string;
  hasUnread?: Message;
  userMessages?: UserMessages[];
  messages?: Message[];
  newMessage?: Message;
  isMessageSent?: boolean;
  users?: User[];
  isDeleteMessage?: boolean;
  errorPayload?: ErrorPayload;
}

const initialState: MessageState = {
  status: 'idle'
};

const meesageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setHasUnread: (state, action) => {
      state.hasUnread = action.payload;
    },
    setNewMessage: (state, action) => {
      state.newMessage = action.payload;
    },
    reset: (state) => {
      state.messages = undefined;
    },
    resetIsDeleteMessage: (state) => {
      state.isDeleteMessage = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMessages.fulfilled, (state, action) => {
        state.status = 'success';
        state.userMessages = action.payload;
      })
      .addCase(fetchUserMessages.fulfilled, (state, action) => {
        state.status = 'success';
        state.messages = action.payload;
      })
      .addCase(addMessage.fulfilled, (state, action) => {
        state.isMessageSent = true;
        state.isDeleteMessage = false;
        action.payload.newMessage.guid = action.payload.data.guid;
        const messagesList = cloneDeep(state.messages);
        messagesList?.push(action.payload.newMessage);
        state.messages = messagesList;
      })
      .addCase(addMessage.rejected, (state, action) => {
        state.status = 'rejected';
        state.isMessageSent = false;
        state.errorPayload = action.payload as ErrorPayload;
      })
      .addCase(markMessageAsRead.fulfilled, (state, action) => {
        const userMessages = cloneDeep(state.userMessages);
        const userMessage = userMessages?.find((x) => x.userGUID === action.payload) as UserMessages;

        if (userMessage !== undefined) {
          userMessage.unreadMessageCount = 0;
          state.userMessages = userMessages;
        }
      })
      .addCase(searchUsers.fulfilled, (state, action) => {
        state.status = 'success';
        state.users = action.payload;
      })
      .addCase(deleteMessageForCallingUser.fulfilled, (state, action) => {
        state.status = 'success';
        state.isDeleteMessage = true;
        const messagesList = cloneDeep(state.messages);
        const index = messagesList?.findIndex((c) => c.guid === action.payload.guid) as number;
        if (index >= 0) {
          messagesList?.splice(index, 1);
          state.messages = messagesList;
        }
      })
      .addCase(unsendMessageForUser.fulfilled, (state, action) => {
        state.status = 'success';
        state.isDeleteMessage = true;
        const messagesList = cloneDeep(state.messages);
        const index = messagesList?.findIndex((c) => c.guid === action.payload?.guid) as number;
        if (index >= 0) {
          messagesList?.splice(index, 1);
          state.messages = messagesList;
        }
      })
      .addMatcher(
        (action): action is PendingAction => action.type.endsWith('/pending'),
        (state) => {
          state.status = 'loading';
        }
      )
      .addMatcher(
        (action): action is RejectedAction => action.type.endsWith('/rejected'),
        (state, action) => {
          state.status = 'rejected';
          if (action.payload) {
            const errorPayload = action.payload as ErrorPayload;
            const message = errorPayload.message ? errorPayload.message : '';
            state.error = message;
            state.errorPayload = action.payload as ErrorPayload;
          }
        }
      );
  }
});

export const { reset, setHasUnread, setNewMessage, resetIsDeleteMessage } = meesageSlice.actions;
export default meesageSlice.reducer;
