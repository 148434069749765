import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import moment from 'moment';

import { RootState } from 'state/store';
import { getLiveEvent } from 'state/reducers/liveEventReducer';
import { LiveEvent, UserNotification } from 'types';
import { playContent } from 'state/reducers/sessionReducer';
import { ContentType } from 'enums';
import { toggleSnackbarOpen } from 'state/reducers/snackbarReducer';

import Anchor from 'components/Anchor';
import { openAdmin } from 'services/miscellaneousService';
import defaultIcon from 'assets/images/default-avatar-1.png';

import BlankPage from 'components/BlankPage';
import { useHistory } from 'react-router-dom';
import {
  fetchAnnouncements,
  fetchNotifications,
  markAllNotificationsAsRead,
  markNotificationAsRead
} from 'state/reducers/userReducer';
import Skeleton from 'components/Skeleton';
import { Announcement } from 'types/announcement';
import Modal from 'components/Modal';

import styles from './Notifications.module.scss';

const cn = classnames.bind(styles);

const Notifications = () => {
  const { user, liveEvent } = useSelector((state: RootState) => ({
    request: state.request,
    user: state.user,
    liveEvent: state.liveEvent
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  const [showLiveEvent, setShowLiveEvent] = useState(false);
  const [tab, setTab] = useState<string | undefined>(undefined);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState<string | undefined>(undefined);
  const videoElement = useRef(null);

  const goToLiveEvent = (liveEventGUID?: string) => {
    const event: LiveEvent = { guid: liveEventGUID };
    dispatch(getLiveEvent(event));
    setShowLiveEvent(true);
  };

  const goToUserPage = (notif: UserNotification) => {
    if (notif.fromUserMerchantGUID) {
      history.push(`/${notif.fromUserUsername}`);
    } else {
      history.push(`/profile/${notif.fromUserUsername}`);
    }
  };

  const announcementGoToUserPage = (announcement: Announcement) => {
    if (announcement.merchantGUID) {
      history.push(`/${announcement.merchantName}`);
    }
  };

  const markAllAsRead = () => {
    dispatch(markAllNotificationsAsRead());
  };

  const markAsRead = (notificationGUID: string, isRead: boolean) => {
    if (!isRead) {
      dispatch(markNotificationAsRead(notificationGUID));
    }
  };

  useEffect(() => {
    if (showLiveEvent) {
      if (liveEvent.activeLiveEvent) {
        if (liveEvent.activeLiveEvent.status !== 'Completed') {
          dispatch(playContent({ contentType: ContentType.Post, post: liveEvent.activeLiveEvent }));
        } else {
          dispatch(
            toggleSnackbarOpen({
              message: `Live event has ended.`,
              type: 'info',
              timeout: 4000
            })
          );
        }
      }
    }
  }, [liveEvent.activeLiveEvent]);

  useEffect(() => {
    dispatch(fetchNotifications());
    dispatch(fetchAnnouncements());
  }, []);

  return (
    <>
      <div className={cn('row', 'ft-ml-4')}>
        <div className={cn('menu')}>
          <div className={cn('flexbox', 'tab')}>
            <div className={cn('tab-item', { active: tab === undefined })} onClick={() => setTab(undefined)}>
              <span className={cn('is-size-6', 'has-text-weight-bold')}>Notifications</span>

              {tab === undefined && <div className={cn('border')} />}
            </div>
            <div
              className={cn('tab-item', { active: tab === 'announcements' })}
              onClick={() => setTab('announcements')}>
              <span className={cn('is-size-6', 'has-text-weight-bold')}>Announcements</span>

              {tab === 'announcements' && <div className={cn('border')} />}
            </div>
          </div>
        </div>
        {tab === undefined ? (
          <div className={cn('ft-mt-4', 'ft-mb-2', 'is-size-7')}>
            <Anchor onClick={markAllAsRead}>Mark all as read</Anchor>
          </div>
        ) : (
          <div className={cn('ft-mt-6')} />
        )}
      </div>

      {tab === undefined && user.notifications && user.notifications.length > 0 ? (
        <div className={cn('notifications-wrapper')}>
          {user.notifications?.map((notification) => (
            <div
              key={notification.guid}
              onMouseLeave={() => markAsRead(notification.guid as string, notification.isRead as boolean)}>
              <div className={cn('flexbox', 'item', !notification.isRead ? 'unread-notif' : '')}>
                <img className={cn('avatar')} src={notification.fromUserNormalizedProfileImageUrl || defaultIcon} />
                <div className={cn('ft-ml-2')}>
                  {notification.notificationType &&
                  notification.notificationType === 'NewMerchantMeetAndGreetRequest' ? (
                    <>
                      <div>
                        <span className={cn('link')} onClick={() => goToUserPage(notification)}>
                          {notification.fromUserDisplayName}
                        </span>
                        <span> has a pending Video Call Request </span>
                      </div>
                      <div className={cn('cursor')} onClick={() => openAdmin()}>
                        <div className={cn('is-size-8', 'ft-mt-1')}>Launch Admin</div>
                      </div>
                    </>
                  ) : notification.notificationType &&
                    notification.notificationType === 'NewMerchantVideoMessageRequest' ? (
                    <>
                      <div>
                        <span className={cn('link')} onClick={() => goToUserPage(notification)}>
                          {notification.fromUserDisplayName}
                        </span>
                        <span> has a pending Video Message Request </span>
                      </div>
                      <div className={cn('cursor')} onClick={() => openAdmin()}>
                        <div className={cn('is-size-8', 'ft-mt-1')}>Launch Admin</div>
                      </div>
                    </>
                  ) : notification.notificationType && notification.notificationType === 'LiveEventStarted' ? (
                    <>
                      <div className={cn('cursor')} onClick={() => goToLiveEvent(notification.liveEventGUID)}>
                        <span className={cn('link')} onClick={() => goToUserPage(notification)}>
                          {notification.message?.split("'")[0] + "'"}
                        </span>
                        <span>{notification.message?.split("'")[1]}</span>
                      </div>
                    </>
                  ) : notification.notificationType &&
                    [
                      'PostReaction',
                      'Gift',
                      'Comment',
                      'CommentReaction',
                      'FollowRequest',
                      'PostUnlocked',
                      'LiveEventTicketPurchased',
                      'PostTip'
                    ].indexOf(notification.notificationType) !== -1 ? (
                    <>
                      <div>
                        <span className={cn('link')} onClick={() => goToUserPage(notification)}>
                          {notification.fromUserUsername}
                        </span>
                        <span className={cn('ft-ml-1')}>
                          {notification.message?.split(notification.fromUserDisplayName as string)[1] ||
                            notification.message?.split(notification.fromUserUsername as string)[1]}
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <span className={cn('link')} onClick={() => goToUserPage(notification)}>
                          {notification.fromUserUsername}
                        </span>
                        <span className={cn('ft-ml-1')}>{notification.message}</span>
                      </div>
                    </>
                  )}
                  <div className={cn('moment')}>{moment(notification.dateCreated).fromNow()}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : tab === 'announcements' && user.announcements && user.announcements?.length > 0 ? (
        <div className={cn('announcement-wrapper')}>
          {user.announcements?.map((announcement) => (
            <div key={announcement.guid}>
              <div className={cn('flexbox', 'item')}>
                <img className={cn('avatar')} src={announcement.normalizedProfileImageUrl || defaultIcon} />
                <div className={cn('ft-ml-2')}>
                  <>
                    <div>
                      <div className={cn('link')} onClick={() => announcementGoToUserPage(announcement)}>
                        {announcement.merchantName}
                      </div>
                      <div className={cn('has-text-weight-semibold')}>{announcement.title}</div>
                      <div className={cn('message')}>{announcement.message}</div>
                    </div>
                  </>
                  <div className={cn('row')}>
                    <span className={cn('moment', 'ft-mr-8')}>{moment(announcement.dateCreated).fromNow()}</span>
                    {announcement.contentUrl && (
                      <span
                        className={cn('is-size-8', 'link', 'has-text-weight-bold', 'ft-ml-8')}
                        onClick={() => setSelectedAnnouncement(announcement.contentUrl)}>
                        View Media
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : tab === 'announcements' && user.announcements?.length === 0 ? (
        <BlankPage text="There are no new announcements." />
      ) : tab === undefined && user.notifications?.length === 0 ? (
        <BlankPage text="There are no new notifications." />
      ) : (
        <div className={cn('notifications-wrapper')}>
          <div className={cn('flexbox', 'item')}>
            <Skeleton type="circ" radius={16} />
            <Skeleton className={cn('desktop', 'ft-ml-2')} type="rect" width="200px" height="32px" />
          </div>
          <div className={cn('flexbox', 'item')}>
            <Skeleton type="circ" radius={16} />
            <Skeleton className={cn('desktop', 'ft-ml-2')} type="rect" width="200px" height="32px" />
          </div>
          <div className={cn('flexbox', 'item')}>
            <Skeleton type="circ" radius={16} />
            <Skeleton className={cn('desktop', 'ft-ml-2')} type="rect" width="200px" height="32px" />
          </div>
        </div>
      )}

      {selectedAnnouncement && (
        <Modal width="25%" onClose={() => setSelectedAnnouncement(undefined)}>
          <div>
            {selectedAnnouncement.includes('png' || 'jpg' || 'jpeg' || 'bmp' || 'gif') ? (
              <img src={selectedAnnouncement} />
            ) : (
              <video
                controls
                controlsList="nodownload"
                className={cn('media')}
                src={selectedAnnouncement}
                ref={videoElement}
              />
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default Notifications;
