import React, { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, debounce } from 'lodash';
import { v4 as uuid4 } from 'uuid';
import classnames from 'classnames/bind';
import moment from 'moment';

import { webSocketService } from 'services';
import { Merchant, Message, MessageGroup, User, UserMessages } from 'types';
import { randomGenerator, propertySort } from 'utils';
import Button from 'components/Button';
import Input from 'components/Input';

import {
  addMessage,
  fetchMessages,
  fetchUserMessages,
  markMessageAsRead,
  reset,
  setNewMessage,
  searchUsers,
  deleteMessageForCallingUser,
  unsendMessageForUser,
  resetIsDeleteMessage
} from 'state/reducers/messageReducer';
import { RootState } from 'state/store';

import BlankPage from 'components/BlankPage';
import { useHistory } from 'react-router-dom';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import { toggleSnackbarOpen } from 'state/reducers/snackbarReducer';
import MessagesModal from 'components/Modal/MessagesModal';
import { resetMerchantDirectMessage } from 'state/reducers/sessionReducer';
import styles from './Messages.module.scss';

const cn = classnames.bind(styles);

const Messages = () => {
  const { message, user, session } = useSelector((state: RootState) => ({
    message: state.message,
    user: state.user,
    session: state.session
  }));
  const [selectedMessage, setSelectedMessage] = useState<UserMessages | undefined>();
  const [messageGroups, setMessageGroups] = useState<MessageGroup[] | undefined>();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResultIndex, setSearchResultIndex] = useState<number>(0);
  const [searchResults, setSearchResults] = useState<Merchant[]>();
  const [deleteMessage, setDeleteMessage] = useState<Message | undefined>();
  const [unsendMessage, setUnsendMessage] = useState<Message | undefined>();
  const [isMessageConfirmation, setIsMessageConfirmation] = useState<KeyboardEvent | undefined>();

  const lastSenderGuid = useRef<string>();
  const lastMessageGroup = useRef<MessageGroup>();
  const chatRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const confirmSendMessage = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      const value = (event.target as HTMLInputElement).value;
      if (value === '') {
        return;
      } else {
        if (!user.user?.merchantGUID) {
          //fan
          if (selectedMessage?.merchantGUID && selectedMessage?.isMerchantAcceptDirectMessages) {
            //fan to merchant DMs ON
            setIsMessageConfirmation(event);
          } else if (selectedMessage?.merchantGUID && !selectedMessage?.isMerchantAcceptDirectMessages) {
            //fan to merchant DMs OFF
            dispatch(
              toggleSnackbarOpen({
                message: `${selectedMessage?.username} doesn't accept direct messages at the moment.`,
                type: 'danger',
                timeout: 6000
              })
            );
          } else {
            //fan to fan
            sendMessage(value);
            (event.target as HTMLInputElement).value = '';
          }
        } else {
          //merchant
          if (user.user.isMerchantAcceptDirectMessages) {
            //sender merchant DMs ON
            if (selectedMessage?.merchantGUID && selectedMessage?.isMerchantAcceptDirectMessages) {
              //receiver merchant DMs ON
              setIsMessageConfirmation(event);
            } else if (selectedMessage?.merchantGUID && !selectedMessage.isMerchantAcceptDirectMessages) {
              //receiver merchant DMs OFF
              dispatch(
                toggleSnackbarOpen({
                  message: `${selectedMessage?.username} doesn't accept direct messages at the moment.`,
                  type: 'danger',
                  timeout: 6000
                })
              );
            } else {
              //to fan
              sendMessage(value);
              (event.target as HTMLInputElement).value = '';
            }
          } else {
            //sender merchant DMs OFF
            dispatch(
              toggleSnackbarOpen({
                message: `You currently have direct messaging turned off. To send a message or reply,
                  go to the Requests tab on your  Admin portal.`,
                type: 'danger',
                timeout: 10000
              })
            );
          }
        }
      }
    }
  };

  const sendMessage = (value: string) => {
    const message: Message = {
      fromUserGUID: user.user?.guid,
      toUserGUID: selectedMessage?.userGUID,
      toUserMerchantGUID: selectedMessage?.merchantGUID,
      message: value,
      amount: selectedMessage?.isMerchantAcceptDirectMessages ? selectedMessage.merchantDirectMessagePaymentAmount : 0,
      tokenProfileKey: selectedMessage?.isMerchantAcceptDirectMessages
        ? selectedMessage.merchantDirectMessagePaymentTokenProfileKey
        : ''
    };
    dispatch(addMessage(message));
    dispatch(
      setNewMessage({
        ...message,
        guid: uuid4()
      })
    );
    value = '';
  };

  const resetMessages = () => {
    setSelectedMessage(undefined);
    setMessageGroups(undefined);
    lastSenderGuid.current = undefined;
    lastMessageGroup.current = undefined;
    setSearchResults(undefined);
    dispatch(resetIsDeleteMessage());
  };

  const resetDeleteUnsendMessages = () => {
    setMessageGroups(undefined);
    lastSenderGuid.current = undefined;
    lastMessageGroup.current = undefined;
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      chatRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 50);
  };

  const goToUserPage = (message: UserMessages) => {
    if (message.merchantGUID) {
      history.push(`/${message.username}`);
    }
  };

  const trimUsername = (username: string) => {
    if (username && username.length > 14) {
      return username.substring(0, 14) + '...';
    } else {
      return username;
    }
  };

  const handleSearch = useCallback(
    debounce((text) => setSearchTerm(text), 200),
    []
  );

  const handleKeyDown = (event: KeyboardEvent) => {
    event.stopPropagation();
    if (searchResults && searchResults?.length > 0) {
      switch (event.code) {
        case 'ArrowDown': {
          if (searchResultIndex === searchResults.length - 1) {
            setSearchResultIndex(0);
          } else {
            setSearchResultIndex(searchResultIndex + 1);
          }
          break;
        }
        case 'ArrowUp': {
          if (searchResultIndex < 0) {
            setSearchResultIndex(searchResults.length - 1);
          } else {
            setSearchResultIndex(searchResultIndex - 1);
          }
          break;
        }
      }
    }
  };

  const confirmDeletion = (value: boolean) => {
    if (value) {
      dispatch(deleteMessageForCallingUser(deleteMessage?.guid as string));
      dispatch(
        toggleSnackbarOpen({
          message: `Message has been deleted.`,
          type: 'info',
          timeout: 4000
        })
      );
    }

    setDeleteMessage(undefined);
  };

  const confirmUnsend = (value: boolean) => {
    if (value) {
      dispatch(unsendMessageForUser(unsendMessage?.guid as string));
      dispatch(
        toggleSnackbarOpen({
          message: `Your message has been unsent.`,
          type: 'info',
          timeout: 4000
        })
      );
    }

    setUnsendMessage(undefined);
  };

  const confirmDirectMessage = (value: boolean) => {
    if (value) {
      sendMessage((isMessageConfirmation?.target as HTMLInputElement).value);
      (isMessageConfirmation?.target as HTMLInputElement).value = '';
    }

    setIsMessageConfirmation(undefined);
  };

  useEffect(() => {
    dispatch(fetchMessages(0));

    return () => {
      resetMessages();
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (selectedMessage && selectedMessage.userGUID) {
      dispatch(fetchUserMessages(selectedMessage.userGUID));
      webSocketService.initializeMessagingService(selectedMessage.userGUID);
      dispatch(markMessageAsRead(selectedMessage.userGUID));
    }
  }, [selectedMessage]);

  useEffect(() => {
    resetDeleteUnsendMessages();
    let sortedNext = cloneDeep(message.messages);
    sortedNext = sortedNext?.sort(propertySort('dateCreated'));
    if (sortedNext) {
      const messageGroups: MessageGroup[] | undefined = [];
      for (const message of sortedNext) {
        if (lastSenderGuid.current !== message.fromUserGUID) {
          if (lastMessageGroup.current) {
            messageGroups.push(lastMessageGroup.current);
          }
          lastMessageGroup.current = {
            guid: uuid4(),
            fromUserGuid: message.fromUserGUID,
            profileImageUrl: message.fromUserNormalizedProfileImageUrl,
            messages: []
          };
          lastSenderGuid.current = message.fromUserGUID;
        }
        lastMessageGroup.current?.messages?.push(message);
      }
      if (lastMessageGroup.current) {
        messageGroups.push(lastMessageGroup.current);
      }
      setMessageGroups(messageGroups);
      if (!message.isDeleteMessage) {
        scrollToBottom();
      }
    }
  }, [message.messages, message.isDeleteMessage]);

  useEffect(() => {
    resetDeleteUnsendMessages();
    const newMessageGroups = cloneDeep(messageGroups);
    if (message.newMessage) {
      if (lastSenderGuid.current !== message.newMessage.fromUserGUID) {
        lastMessageGroup.current = {
          guid: uuid4(),
          fromUserGuid: message.newMessage.fromUserGUID,
          profileImageUrl: message.newMessage.fromUserNormalizedProfileImageUrl,
          messages: []
        };
        lastMessageGroup.current?.messages?.push(message.newMessage);
        lastSenderGuid.current = message.newMessage.fromUserGUID;
        newMessageGroups?.push(lastMessageGroup.current);
      } else {
        lastMessageGroup.current?.messages?.push(message.newMessage);
        newMessageGroups?.splice(newMessageGroups.length - 1, 1, lastMessageGroup.current || {});
      }
      setMessageGroups(newMessageGroups);
      scrollToBottom();
    }
  }, [message.newMessage]);

  useEffect(() => {
    if (searchTerm !== '') {
      //dispatch(searchCreators(searchTerm));
      dispatch(searchUsers(searchTerm));
    } else {
      setSearchResultIndex(0);
      setSearchResults(undefined);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (searchTerm !== '' && message.users && message.users?.length > 0) {
      setSearchResults(message.users);
    }
  }, [message.users]);

  useEffect(() => {
    if (session.directMessageMerchant) {
      setSelectedMessage(session.directMessageMerchant);
      dispatch(resetMerchantDirectMessage());
    }
  }, [session.directMessageMerchant]);

  useEffect(() => {
    if (message.status === 'rejected' && !message.isMessageSent) {
      dispatch(
        toggleSnackbarOpen({
          message: message.errorPayload?.message,
          type: 'danger',
          timeout: 4000
        })
      );
    }
  }, [message.status, message.isMessageSent, message.errorPayload]);

  return (
    <>
      {!selectedMessage ? (
        <>
          <div className={cn('is-size-6', 'has-text-weight-bold', 'ft-mb-2', 'ft-ml-4')}>Messages</div>
          <Input
            type="text"
            placeholder="Search"
            className={cn('ft-pl-2', 'ft-pr-2', 'ft-mb-2')}
            layer={1}
            name="search"
            onChange={(event) => handleSearch((event?.target as HTMLInputElement).value)}
            onKeyUp={(event) => handleKeyDown(event)}
          />

          {searchResults && searchResults.length > 0 && (searchTerm !== '' || searchTerm !== undefined) ? (
            <>
              <span className={cn('ft-ml-2', 'ft-mb-2')}>Search result:</span>
              <div className={cn('messages')}>
                {searchResults?.map((user: User) => (
                  <div
                    className={cn('flexbox', 'item')}
                    key={user.guid}
                    onClick={() => {
                      setSelectedMessage({
                        userGUID: user.guid,
                        username: user.username,
                        normalizedProfileImageUrl: user.normalizedProfileImageUrl,
                        merchantGUID: user.merchantGUID ? user.merchantGUID : undefined,
                        unreadMessageCount: 0,
                        isMerchantAcceptDirectMessages: user.isMerchantAcceptDirectMessages,
                        merchantDirectMessagePaymentAmount: user.merchantDirectMessagePaymentAmount,
                        merchantDirectMessagePaymentTokenProfileKey: user.merchantDirectMessagePaymentTokenProfileKey
                      });
                    }}>
                    <img
                      className={cn('avatar')}
                      src={randomGenerator(user.username || '', user.normalizedProfileImageUrl)}
                    />
                    <div className={cn('message', 'ft-ml-2')}>
                      <div className={cn('user')}>
                        {() => {
                          const id = message.userMessages?.findIndex((u) => u.userGUID === user.guid);
                          if (id !== undefined && message.userMessages) {
                            return message.userMessages[id].lastMessage;
                          }
                        }}
                        {trimUsername(user.username as string)} <div className={cn('badge')} />
                        {/* <div className={cn('moment', 'ft-ml-auto')}>{moment(user.dateOfLastMessage).fromNow(true)}</div> */}
                      </div>
                      <div className={cn('wrap-text')}>
                        {/* {user.lastMessage !== '' ? user.lastMessage : '[blank message not allowed]'} */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            ''
          )}

          {message.userMessages && message.userMessages.length > 0 ? (
            <div className={cn('messages')}>
              {message.userMessages?.map((user) => (
                <div
                  className={cn('flexbox', 'item', { unread: user.unreadMessageCount })}
                  key={user.userGUID}
                  onClick={() => {
                    setSelectedMessage(user);
                  }}>
                  <img
                    className={cn('avatar')}
                    src={randomGenerator(user.username || '', user.normalizedProfileImageUrl)}
                  />
                  <div className={cn('message', 'ft-ml-2')}>
                    <div className={cn('user')}>
                      {trimUsername(user.username as string)} <div className={cn('badge')} />
                      <div className={cn('moment', 'ft-ml-auto')}>{moment(user.dateOfLastMessage).fromNow(true)}</div>
                    </div>
                    <div className={cn('wrap-text')}>
                      {user.lastMessage !== '' ? user.lastMessage : '[blank message not allowed]'}
                      {/*user.lastMessage === lastMessageGroup.current?.messages?.pop()?.message ? (
                        user.lastMessage
                      ) : (
                        lastMessageGroup.current?.messages?.pop()?.message
                      )*/}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <BlankPage text="You currently have no messages." />
          )}
        </>
      ) : (
        <>
          <div className={cn('flexbox', 'ft-ml-2', 'ft-mr-2')}>
            <Button size="sm" color="secondary" onClick={resetMessages}>
              Back to messages
            </Button>
            {selectedMessage.isMerchantAcceptDirectMessages && (
              <span className={cn('has-text-weight-bold', 'ft-ml-auto', 'is-size-5')}>
                COST: {selectedMessage.merchantDirectMessagePaymentAmount}
                <Icon name="fanbucks" size="md" className={cn('ft-mx-1')} />
              </span>
            )}
          </div>
          <div className={cn('flexbox', 'ft-my-2', 'ft-ml-2')}>
            <img
              className={cn('avatar')}
              src={randomGenerator(selectedMessage.username || '', selectedMessage.normalizedProfileImageUrl)}
            />
            <div
              className={cn('is-size-6', 'has-text-weight-semibold', 'ft-ml-2', 'cursor')}
              onClick={() => goToUserPage(selectedMessage)}>
              {selectedMessage.username}
            </div>
          </div>
          <div className={cn('chat')}>
            {messageGroups?.map((messageGroup) =>
              messageGroup.fromUserGuid === user.user?.guid ? (
                <div className={cn('me')} key={messageGroup.guid}>
                  {messageGroup.messages?.map((message) => (
                    <div className={cn('message')} key={message.guid}>
                      <div className={cn('message-options')}>
                        {new Date((message?.dateCreated as Date) || new Date()).getTime() >=
                        new Date().setMinutes(new Date().getMinutes() - 5) ? (
                          <Button
                            className={cn('unsend')}
                            color="clear"
                            size="sm"
                            onClick={() => setUnsendMessage(message)}>
                            Unsend
                          </Button>
                        ) : (
                          <Button color="clear" size="sm" onClick={() => setDeleteMessage(message)}>
                            <Icon name="trash" size="sm"></Icon>
                          </Button>
                        )}
                      </div>
                      <div className={cn('bubble')}>{message.message}</div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className={cn('them')} key={messageGroup.guid}>
                  {messageGroup.messages?.map((message) => (
                    <div className={cn('message')} key={message.guid}>
                      <div className={cn('bubble')}>{message.message}</div>
                      <div className={cn('message-options')}>
                        <Button color="clear" size="sm" onClick={() => setDeleteMessage(message)}>
                          <Icon name="trash" size="sm"></Icon>
                        </Button>
                      </div>
                      <div className={cn('moment')}>{moment(message.dateCreated).format('M/D h:mma')}</div>
                    </div>
                  ))}
                </div>
              )
            )}
            <div ref={chatRef} />
          </div>
          <div className={cn('input')}>
            <Input placeholder="Say something..." name="message" layer={1} onKeyUp={confirmSendMessage} />
          </div>
        </>
      )}

      {deleteMessage && (
        <Modal onClose={() => setDeleteMessage(undefined)}>
          <MessagesModal onSelect={confirmDeletion} type={1}></MessagesModal>
        </Modal>
      )}

      {unsendMessage && (
        <Modal onClose={() => setUnsendMessage(undefined)}>
          <MessagesModal onSelect={confirmUnsend} type={2}></MessagesModal>
        </Modal>
      )}

      {isMessageConfirmation && (
        <Modal onClose={() => setIsMessageConfirmation(undefined)}>
          <MessagesModal
            onSelect={confirmDirectMessage}
            type={3}
            messageAmount={selectedMessage?.merchantDirectMessagePaymentAmount}></MessagesModal>
        </Modal>
      )}
    </>
  );
};

export default Messages;
