import React from 'react';
import classnames from 'classnames/bind';

import Button from 'components/Button';

import styles from './DeleteCreditCardModal.module.scss';

const cn = classnames.bind(styles);

interface CreditCardModalProps {
  onSelect: (value: boolean) => void;
}

const DeleteCreditCardModal = (props: CreditCardModalProps) => {
  return (
    <div className={cn('card-content')}>
      Do you confirm deleting this credit card?
      <div className={cn('ft-mt-8')}>
        <Button color="danger" className={cn('ft-mr-4')} onClick={() => props.onSelect(true)}>
          Delete
        </Button>
        <Button color="white" onClick={() => props.onSelect(false)}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default DeleteCreditCardModal;
