export enum NotificationType {
  Gift = 'Gift',
  FollowRequest = 'FollowRequest',
  FollowRequestAccepted = 'FollowRequestAccepted',
  LiveEventStarted = 'LiveEventStarted',
  LiveEventTicketPurchased = 'LiveEventTicketPurchased',
  PostUnlocked = 'PostUnlocked',
  PostReaction = 'PostReaction',
  Comment = 'Comment',
  PostTip = 'PostTip',
  CommentReaction = 'CommentReaction',
  Donation = 'Donation',
  NewMerchantMeetAndGreetRequest = 'NewMerchantMeetAndGreetRequest',
  MerchantMeetAndGreetRequestCancelled = 'MerchantMeetAndGreetRequestCancelled',
  MerchantMeetAndGreetRequestMissed = 'MerchantMeetAndGreetRequestMissed',
  MerchantMeetAndGreetRequestCompleted = 'MerchantMeetAndGreetRequestCompleted',
  NewMerchantVideoMessageRequest = 'NewMerchantVideoMessageRequest',
  MerchantVideoMessageRequestCancelled = 'MerchantVideoMessageRequestCancelled',
  MerchantVideoMessageRequestRejected = 'MerchantVideoMessageRequestRejected',
  MerchantVideoMessageRequestAccepted = 'MerchantVideoMessageRequestAccepted',
  MerchantVideoMessageRequestCompleted = 'MerchantVideoMessageRequestCompleted',
  MerchantVideoMessageRequestRated = 'MerchantVideoMessageRequestRated'
}

export enum SidebarType {
  Messages,
  Notification
}
