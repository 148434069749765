import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';

import { useDispatch, useSelector } from 'react-redux';
import {
  fetchUserFollower,
  followUser,
  resetViewUser,
  searchBlockedUsers,
  unfollowUser
} from 'state/reducers/userReducer';
import { RootState } from 'state/store';
import defaultAvatar from 'assets/images/default-avatar-1.png';

import Button from 'components/Button';
import { UserFollower, UserFollowing } from 'types';
import { clone } from 'lodash';
import BlankPage from 'components/BlankPage';
import { useHistory } from 'react-router-dom';
import styles from './Follower.module.scss';

const cn = classnames.bind(styles);

const Follower = () => {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [blockList, setBlockList] = useState<string[]>([]);

  const viewProfile = (data: UserFollower) => {
    const username = data.userUsername;
    dispatch(resetViewUser());
    if (data.userMerchantGUID) {
      history.push(`/${username}`);
    } else {
      history.push(`/profile/${username}`);
    }
  };

  const onToggleFollow = (type: string, tempUser: UserFollower | UserFollowing, index: number) => {
    const userData = clone(tempUser);
    if (type === 'following') {
      userData.userGUID = tempUser.followedUserGUID;
    }
    dispatch(followUser({ type, user: tempUser, data: type === 'following' ? user.following : user.follower, index }));
  };

  const onToggleUnfollow = (type: string, tempUser: UserFollower | UserFollowing, index: number) => {
    const userData = clone(tempUser);
    if (type === 'following') {
      userData.userGUID = tempUser.followedUserGUID;
    }
    dispatch(
      unfollowUser({ type, user: tempUser, data: type === 'following' ? user.following : user.follower, index })
    );
  };

  useEffect(() => {
    if (user.user?.guid) {
      dispatch(fetchUserFollower(user.user?.guid));
      dispatch(searchBlockedUsers(user.user.guid));
    }
  }, [user.user]);

  useEffect(() => {
    if (user.blockedUsers && user.follower) {
      const blockList: string[] = [];
      user.follower?.forEach((follower) => {
        if (user.blockedUsers?.find((x) => x.blockedUserGUID === follower.userGUID)) {
          blockList.push(follower.userGUID as string);
        }
      });
      setBlockList(blockList);
    }
  }, [user.blockedUsers, user.follower]);

  return (
    <div className={cn('container')}>
      <div>
        <div className={cn('is-size-5 has-text-weight-semibold')}>Followers</div>
        {user.follower && user.follower.length > 0 ? (
          <>
            <div className={cn('list', 'ft-mt-4')}>
              {user.follower
                ?.filter((x) => !blockList.includes(x.userGUID as string))
                .map((follower, index) => (
                  <>
                    <div className={cn('item', 'flexbox')}>
                      <img
                        className={cn('avatar', 'pointer')}
                        src={follower.normalizedProfileImageUrl || defaultAvatar}
                        onClick={() => viewProfile(follower)}
                      />
                      <div className={cn('ft-ml-2', 'pointer')} onClick={() => viewProfile(follower)}>
                        <div>{follower.userDisplayName || `@${follower.userUsername}`}</div>
                        <div>@{follower?.userUsername}</div>
                      </div>
                      {/* <div className={cn('ft-ml-auto')}>
                    <Button color="secondary">Unfollow</Button>
                  </div> */}
                      {!follower.isFollowRequestedByCallingUser &&
                        !follower.isFollowedByCallingUser &&
                        user.user?.guid !== follower?.userGUID && (
                          <div className="ft-ml-auto">
                            <Button color="secondary" onClick={() => onToggleFollow('follower', follower, index)}>
                              Follow
                            </Button>
                          </div>
                        )}
                      {((follower?.isFollowRequestedByCallingUser && follower.userMerchantAutoAcceptFollowers) ||
                        follower?.isFollowedByCallingUser) &&
                        user.user?.guid !== follower?.userGUID && (
                          <div className={'ft-ml-auto'}>
                            <Button color="secondary" onClick={() => onToggleUnfollow('follower', follower, index)}>
                              Unfollow
                            </Button>
                          </div>
                        )}
                      {follower?.isFollowRequestedByCallingUser &&
                        !follower.userMerchantAutoAcceptFollowers &&
                        user.user?.guid !== follower?.userGUID && (
                          <div className={cn('ft-ml-auto')}>
                            {
                              <Button onClick={() => onToggleUnfollow('follower', follower, index)}>
                                Cancel Follow Request
                              </Button>
                            }
                          </div>
                        )}
                    </div>
                  </>
                ))}
            </div>
          </>
        ) : (
          <BlankPage text="You currently have no followers." tabText="Discover Athletes" tabLink="/discover" />
        )}
      </div>
    </div>
  );
};

export default Follower;
