import React, { ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames/bind';

import { ClubRoom, Channel, SubscriptionPlan } from 'types';
import { serializeFormData } from 'utils';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Input from 'components/Input';

//import planImg from 'assets/images/temp/plan-1.png';
import defaultIcon from 'assets/images/default-avatar-1.png';
import { addUsersToChannel, getMembersPerChannel, removeUserFromChannel } from 'state/reducers/channelReducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/store';
import { fetchSubscribers, searchSubscriptionsByMerchantGuid } from 'state/reducers/subscriptionReducer';
import Checkbox from 'components/Checkbox';
import { debounce } from 'lodash';
import { toggleSnackbarOpen } from 'state/reducers/snackbarReducer';
import { createChannel, resetNewChannel, resetUpdateChannelStatus, updateChannel } from 'state/reducers/userReducer';
import BlankPage from 'components/BlankPage';
import Skeleton from 'components/Skeleton';

import styles from './ClubModal.module.scss';

const cn = classnames.bind(styles);

const channelTypes = [
  { id: 'text', name: 'Channel' },
  { id: 'voice', name: 'Lobby' }
];

const accessTypes = [
  { id: 'Public', name: 'Public' },
  { id: 'Private', name: 'Private' }
];

interface ClubModalProps {
  channel?: Channel;
  onClose: (value: boolean) => void;
}

const ClubModal = (props: ClubModalProps) => {
  const { user, subscription, channel } = useSelector((state: RootState) => ({
    user: state.user,
    subscription: state.subscription,
    channel: state.channel
  }));
  const [step, setStep] = useState(1);
  const [channelType, setChannelType] = useState('text');
  const [accessType, setAccessType] = useState('');
  const [clubRoom, setClubRoom] = useState<ClubRoom>();
  const [subscribersList, setSubscribersList] = useState<SubscriptionPlan[]>([]);
  const [invitedList, setInvitedList] = useState<string[]>([]);
  const [deletedList, setDeletedList] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isRoomNameTaken, setIsRoomNameTaken] = useState(false);
  const dispatch = useDispatch();
  const formRef = useRef<HTMLFormElement>(null);

  const toggleChannelType = (type: string) => {
    setChannelType(type);
    setStep(1);
  };

  const toggleAccessType = (type: string) => {
    setAccessType(type);
    setStep(2);
  };

  const toggleAddMembers = () => {
    const newChannel: Channel = serializeFormData(new FormData(formRef.current as HTMLFormElement));
    if (newChannel.name?.trim() === '') {
      dispatch(
        toggleSnackbarOpen({
          message: `Room name is required.`,
          type: 'danger',
          timeout: 4000
        })
      );
      return false;
    } else {
      dispatch(resetNewChannel());
      dispatch(resetUpdateChannelStatus());
      setClubRoom(newChannel);
      setStep(3);
    }
  };

  const setupChannel = (event: FormEvent) => {
    event.preventDefault();
    if (props.channel) {
      const updatedChannel: Channel =
        accessType === 'Public'
          ? serializeFormData(new FormData(event.target as HTMLFormElement))
          : { name: clubRoom?.name, description: clubRoom?.description };
      updatedChannel.guid = props.channel.guid;
      updatedChannel.merchantGUID = props.channel.merchantGUID;
      updatedChannel.isChannelPinnedForCallingUser = props.channel.isChannelPinnedForCallingUser;
      updatedChannel.isPublished = props.channel.isPublished;

      dispatch(updateChannel(updatedChannel));
      //props.onClose(true);
      dispatch(
        toggleSnackbarOpen({
          message: 'Channel updated.',
          type: 'info'
        })
      );

      if (invitedList.length === 0 && deletedList.length === 0) {
        props.onClose(true);
      }
    } else {
      //setSubscribersList([]);

      const newChannel: Channel =
        accessType === 'Public'
          ? serializeFormData(new FormData(event.target as HTMLFormElement))
          : { name: clubRoom?.name, description: clubRoom?.description };
      newChannel.accessType = accessType;
      newChannel.merchantGUID = user.user?.merchantGUID;
      newChannel.isChannelPinnedForCallingUser = false;
      newChannel.isPublished = false;

      if (newChannel.name?.trim() === '') {
        dispatch(
          toggleSnackbarOpen({
            message: `Room name is required.`,
            type: 'danger',
            timeout: 4000
          })
        );
        return false;
      } else if (isRoomNameTaken) {
        dispatch(
          toggleSnackbarOpen({
            message: `Please change the room name to proceed.`,
            type: 'danger',
            timeout: 4000
          })
        );
        return false;
      } else {
        //dispatch(fetchUserChannels());
        dispatch(createChannel(newChannel));
      }

      if (accessType === 'Public' || invitedList.length === 0) {
        dispatch(resetNewChannel());
        props.onClose(true);
      }
    }
  };

  const addToInvited = (user: SubscriptionPlan, isMember: boolean) => {
    if (isMember) {
      if (deletedList?.length === 0) {
        setDeletedList([user.userGUID as string]);
      } else {
        if (deletedList.findIndex((x) => x.includes(user.userGUID as string)) >= 0) {
          deletedList.splice(
            deletedList.findIndex((x) => x.includes(user.userGUID as string)),
            1
          );
        } else {
          deletedList.push(user.userGUID as string);
        }
      }
    } else {
      if (invitedList?.length === 0) {
        setInvitedList([user.userGUID as string]);
      } else {
        if (invitedList.findIndex((x) => x.includes(user.userGUID as string)) >= 0) {
          invitedList.splice(
            invitedList.findIndex((x) => x.includes(user.userGUID as string)),
            1
          );
        } else {
          invitedList.push(user.userGUID as string);
        }
      }
    }
  };

  const handleSearch = useCallback(
    debounce((text) => setSearchTerm(text), 1000),
    []
  );

  const handleChange = (event?: ChangeEvent) => {
    const roomName = (event?.target as HTMLInputElement).value.trim();
    if (
      roomName !== null &&
      user.userChannels &&
      user.userChannels.find(
        (x) => x.merchantGUID === user.user?.merchantGUID && x.name?.toLowerCase() === roomName.toLowerCase()
      )
    ) {
      setIsRoomNameTaken(true);
    } else {
      setIsRoomNameTaken(false);
    }
  };

  const isMemberOfChannel = (subscriber: SubscriptionPlan) => {
    if (channel.channelMembers) {
      if (channel.channelMembers.find((x) => x.displayName === subscriber.userDisplayName)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    setSubscribersList([]);
    //dispatch(resetChannelMembers());

    if (props.channel) {
      dispatch(getMembersPerChannel(props.channel?.guid as string));

      if (props.channel.accessType === 'Public') {
        toggleAccessType('Public');
      } else {
        toggleAccessType('Private');
      }
    }
  }, [props.channel]);

  useEffect(() => {
    if (user.user?.merchantGUID) {
      dispatch(searchSubscriptionsByMerchantGuid(user.user?.merchantGUID));
    }
  }, [user.user?.merchantGUID, channel.channelMembers]);

  useEffect(() => {
    if (subscription.subscriptionsByCreator && subscription.subscriptionsByCreator.find((x) => x.isPublished)) {
      dispatch(fetchSubscribers(subscription.subscriptionsByCreator.filter((x) => x.isPublished)[0]?.guid as string));
      /*subscription.subscriptionsByCreator
        .filter((x) => x.isPublished && (x.activeSubscriptions as number) > 0)
        .map((plan: SubscriptionPlan) => {
          dispatch(fetchSubscribers(plan.guid as string));
        });*/
    }
  }, [subscription.subscriptionsByCreator]);

  useEffect(() => {
    if (subscription.subscribers) {
      setSubscribersList([...subscription.subscribers.filter((x) => x.status === 'Active')]);
      /*subscription.subscribers
        ?.filter((x) => x.status === 'Active')
        .map((user) => {
          if (subscribersList?.length === 0) {
            setSubscribersList([user]);
          } else {
            if (subscribersList.findIndex((x) => x.guid === user.guid) < 0) {
              subscribersList?.push(user);
            }
          }
        });*/
    }
  }, [subscription.subscribers]);

  useEffect(() => {
    if ((user.newChannel || (user.updateChannelStatus === 'success' && props.channel)) && invitedList.length > 0) {
      dispatch(
        addUsersToChannel({
          channelGUID: user.newChannel?.guid || (props.channel?.guid as string),
          userGUIDs: invitedList
        })
      );
      //setInvitedList([]);
      dispatch(resetNewChannel());
      dispatch(resetUpdateChannelStatus());
      props.onClose(true);
    }
  }, [user.newChannel, user.updateChannelStatus, invitedList]);

  useEffect(() => {
    if (user.updateChannelStatus === 'success' && deletedList.length > 0) {
      deletedList.forEach((user) => {
        dispatch(removeUserFromChannel({ channelGUID: props.channel?.guid as string, userGUID: user }));
      });

      dispatch(resetUpdateChannelStatus());
      props.onClose(true);
    }
  }, [user.updateChannelStatus, deletedList]);

  return (
    <div className={cn('content')}>
      {step > 0 && (
        <div className={cn('breadcrumb', 'ft-mb-4')}>
          <ul>
            {channelType !== '' && <li>Room: {channelTypes.find((x) => x.id === channelType)?.name}</li>}
            {accessType !== '' && <li>Access: {accessTypes.find((x) => x.id === accessType)?.name}</li>}
            {clubRoom && <li>Name: {clubRoom.name}</li>}
          </ul>
        </div>
      )}
      {
        {
          0: (
            <div>
              <div className={cn('header')}>Select a room type</div>
              <div className={cn('cards', 'ft-mt-4')}>
                <div className={cn('card')} onClick={() => toggleChannelType('text')}>
                  <div className={cn('is-size-6', 'has-text-weight-semibold')}>Channel</div>
                  <div className={cn('has-text-primary')}>
                    <Icon name="channel-text" sizePixel={48} />
                  </div>
                  <div className={cn('is-size-8')}>Members will be able to chat over text</div>
                </div>
                <div className={cn('card')} onClick={() => toggleChannelType('voice')}>
                  <div className={cn('is-size-6', 'has-text-weight-semibold')}>Lobby</div>
                  <div className={cn('has-text-primary')}>
                    <Icon name="channel-voice" sizePixel={48} />
                  </div>
                  <div className={cn('is-size-8')}>Members will be able to talk over audio</div>
                </div>
              </div>
            </div>
          ),
          1: (
            <div>
              <div className={cn('header')}>Select room type:</div>
              <div className={cn('is-size-7 mt-2')}>Note: Club rooms are exclusive to your subscribers.</div>
              <div className={cn('cards', 'ft-mt-4')}>
                <div className={cn('card')} onClick={() => toggleAccessType('Public')}>
                  <div className={cn('is-size-6', 'has-text-weight-semibold')}>General</div>
                  <div className={cn('has-text-primary')}>
                    <Icon name="channel-public" sizePixel={48} />
                  </div>
                  <div className={cn('is-size-8')}>All subscribers can view and participate</div>
                </div>
                <div className={cn('card')} onClick={() => toggleAccessType('Private')}>
                  <div className={cn('is-size-6', 'has-text-weight-semibold')}>Private</div>
                  <div className={cn('has-text-primary')}>
                    <Icon name="channel-private" sizePixel={48} />
                  </div>
                  <div className={cn('is-size-8')}>Only invited subscribers can view and participate</div>
                </div>
              </div>
            </div>
          ),
          2: (
            <div>
              <div className={cn('header')}>Enter room information:</div>
              <div className={cn('control-group', 'ft-mt-4')}>
                <form ref={formRef} onSubmit={setupChannel}>
                  <Input
                    label="Room name*"
                    sublabel="Only letters, numbers, and dashes (-) are allowed. Room name must also be unique."
                    name="name"
                    defaultValue={props.channel ? props.channel.name : ''}
                    onChange={handleChange}
                    className={cn('no-mb')}
                  />
                  {isRoomNameTaken && <label className={cn('danger')}>Name is already taken.</label>}
                  <Input
                    type="textarea"
                    label="Room description"
                    sublabel="A brief description of what this room is about to ensure that members stay on topic and
                  discuss similar ideas."
                    name="description"
                    defaultValue={props.channel ? props.channel.description : ''}
                    className={cn('ft-mt-4')}
                  />
                  <div className={cn('control')}>
                    {accessType === 'Public' ? (
                      <Button type="submit">Create Room</Button>
                    ) : (
                      <Button type="button" onClick={toggleAddMembers}>
                        Next
                      </Button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          ),
          3: (
            <div>
              <div className={cn('header')}>Invite subscribers to room:</div>
              <div className={cn('ft-mt-4')}>
                <Input
                  placeholder="Search users"
                  iconLeft={<Icon name="search" />}
                  name="search"
                  onChange={(event) => handleSearch((event?.target as HTMLInputElement).value)}
                />
              </div>
              <div className={cn('has-text-weight-semibold', 'ft-mt-4')}>Subscribers:</div>
              <div className={cn('invited', 'ft-mt-2')}>
                <ul className={cn('list')}>
                  {subscribersList.length > 0 ? (
                    subscribersList
                      ?.filter(
                        (x) =>
                          x.userDisplayName?.toLowerCase().includes(searchTerm ? searchTerm : '') ||
                          x.subscriptionPlanName?.toLocaleLowerCase().includes(searchTerm ? searchTerm : '')
                      )
                      ?.map((user) => (
                        <li key={user.guid}>
                          <Checkbox
                            name="addUser"
                            onClick={() => addToInvited(user, isMemberOfChannel(user))}
                            value={props.channel ? isMemberOfChannel(user) : false}></Checkbox>
                          <img className={cn('avatar')} src={user.userProfileImageUrl || defaultIcon} />
                          <div className={cn('ft-ml-2')}>{user.userDisplayName}</div>
                        </li>
                      ))
                  ) : subscribersList.length === 0 ? (
                    <BlankPage text="You currently have no subscribers."></BlankPage>
                  ) : (
                    <div className={cn('ft-ml-8', 'ft-mt-2')}>
                      <div className={cn('flexbox', 'ft-py-1')}>
                        <Skeleton type="circ" radius={16} />
                        <Skeleton className={cn('desktop', 'ft-ml-2')} type="rect" width="160px" height="20px" />
                      </div>
                      <div className={cn('flexbox', 'ft-py-1')}>
                        <Skeleton type="circ" radius={16} />
                        <Skeleton className={cn('desktop', 'ft-ml-2')} type="rect" width="160px" height="20px" />
                      </div>
                      <div className={cn('flexbox', 'ft-py-1')}>
                        <Skeleton type="circ" radius={16} />
                        <Skeleton className={cn('desktop', 'ft-ml-2')} type="rect" width="160px" height="20px" />
                      </div>
                    </div>
                  )}
                </ul>
              </div>
              <div className={cn('ft-mt-4')}>
                {subscribersList.length > 0 ? (
                  <Button onClick={setupChannel}>Create Room</Button>
                ) : (
                  <Button onClick={() => props.onClose(true)}>Cancel</Button>
                )}
              </div>
            </div>
          )
        }[step]
      }
    </div>
  );
};

export default ClubModal;
