import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import classnames from 'classnames/bind';

import { CreditCardUserInfo, PurchaseInformation, UserAddress } from 'types';
import { formatNumber } from 'utils';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';

import { purchaseMerchandise, resetPurchase } from 'state/reducers/merchandiseReducer';
import { initializePurchaseItem } from 'state/reducers/sessionReducer';
import { RootState } from 'state/store';

import styles from './PurchaseItemModal.module.scss';

const cn = classnames.bind(styles);

const PurchaseItemModal = () => {
  const { merchandise, session, user } = useSelector((state: RootState) => ({
    merchandise: state.merchandise,
    session: state.session,
    user: state.user
  }));
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [shippingInformation, setShippingInformation] = useState<UserAddress>();
  const [billingInformation, setBillingInformation] = useState<CreditCardUserInfo>();
  const [isPurchaseComplete, setIsPurchaseComplete] = useState(false);
  const [isSameAsShipping, setIsSameAsShipping] = useState(false);

  const {
    register: userAddressRegister,
    handleSubmit: userAddressHandleSubmit,
    reset: shippingInformationReset,
    formState: { errors: userAddressErrors, isValid: userAddressIsValid, isSubmitted: userAddressIsSubmitted }
  } = useForm<UserAddress>();

  const {
    register: billingInformationRegister,
    handleSubmit: billingInformationHandleSubmit,
    reset: billingInformationReset,
    formState: {
      errors: billingInformationErrors,
      isValid: billingInformationIsValid,
      isSubmitted: billingInformationIsSubmitted
    }
  } = useForm<CreditCardUserInfo>({ defaultValues: billingInformation });

  const completePurchase = () => {
    const finalShipping = shippingInformation || {};
    finalShipping.userGUID = user.user?.guid;

    const finalBilling = billingInformation || {};

    if (isSameAsShipping) {
      finalBilling.address = shippingInformation?.address;
      finalBilling.city = shippingInformation?.city;
      finalBilling.state = shippingInformation?.state;
      finalBilling.zipCode = shippingInformation?.zipCode;
    }

    const purchaseInformation: PurchaseInformation = {
      listingGuid: session.purchasePost?.marketplaceListingGUID,
      userAddress: finalShipping,
      creditCard: finalBilling
    };

    // eslint-disable-next-line no-console
    console.log(purchaseInformation);

    dispatch(purchaseMerchandise(purchaseInformation));
  };

  useEffect(() => {
    if (merchandise.isPurchaseComplete) {
      setIsPurchaseComplete(true);
    }
  }, [merchandise.isPurchaseComplete]);

  useEffect(() => {
    if (shippingInformation === undefined && step == 1) {
      shippingInformationReset();
    }
    if (billingInformation === undefined && step == 2) {
      billingInformationReset();
    }
  }, [step]);

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <div className={cn('is-size-6', 'has-text-weight-bold')}>Enter shipping information</div>
            <div className={cn('form-group', 'ft-mt-4')}>
              <form
                onSubmit={userAddressHandleSubmit((data: UserAddress) => {
                  setShippingInformation(data);
                  setStep(2);
                })}>
                <div className={cn('row')}>
                  <div className={cn('control', 'w-100')}>
                    <div className={cn('label')}>Address</div>
                    <div className={cn('field', { 'is-danger': userAddressErrors.address })}>
                      <input
                        className={cn('input')}
                        id="address"
                        {...userAddressRegister('address', { required: true })}
                      />
                    </div>
                  </div>
                </div>
                <div className={cn('row')}>
                  <div className={cn('control', 'w-33')}>
                    <div className={cn('label')}>State</div>
                    <div className={cn('field', { 'is-danger': userAddressErrors.state })}>
                      <input className={cn('input')} id="state" {...userAddressRegister('state', { required: true })} />
                    </div>
                  </div>
                  <div className={cn('control', 'w-33')}>
                    <div className={cn('label')}>City</div>
                    <div className={cn('field', { 'is-danger': userAddressErrors.city })}>
                      <input className={cn('input')} {...userAddressRegister('city', { required: true })} />
                    </div>
                  </div>
                  <div className={cn('control', 'w-33')}>
                    <div className={cn('label')}>Zip code</div>
                    <div className={cn('field', { 'is-danger': userAddressErrors.zipCode })}>
                      <input className={cn('input')} {...userAddressRegister('zipCode', { required: true })} />
                    </div>
                  </div>
                </div>
                <div className={cn('flexbox', 'ft-mt-4')}>
                  <Button color="secondary" type="submit">
                    Next
                  </Button>
                  {userAddressIsSubmitted && !userAddressIsValid && (
                    <div className={cn('has-text-danger', 'ft-ml-4')}>All fields are required</div>
                  )}
                </div>
              </form>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className={cn('is-size-6', 'has-text-weight-bold')}>Enter billing information</div>
            <div className={cn('form-group', 'ft-mt-4')}>
              <form
                onSubmit={billingInformationHandleSubmit((data: CreditCardUserInfo) => {
                  setBillingInformation(data);
                  setStep(3);
                })}>
                <div className={cn('row')}>
                  <div className={cn('control', 'w-50')}>
                    <div className={cn('label')}>Card number</div>
                    <div className={cn('field', { 'is-danger': billingInformationErrors.creditCardNumber })}>
                      <input
                        className={cn('input')}
                        id="creditCardNumber"
                        {...billingInformationRegister('creditCardNumber', { required: true })}
                      />
                    </div>
                  </div>
                  <div className={cn('control', 'w-25')}>
                    <div className={cn('label')}>Expiration</div>
                    <div className={cn('field', { 'is-danger': billingInformationErrors.expirationDate })}>
                      <input
                        className={cn('input')}
                        placeholder="MM/YY"
                        maxLength={7}
                        {...billingInformationRegister('expirationDate', { required: true })}
                      />
                    </div>
                  </div>
                  <div className={cn('control', 'w-25')}>
                    <div className={cn('label')}>CVV</div>
                    <div className={cn('field', { 'is-danger': billingInformationErrors.cardCode })}>
                      <input className={cn('input')} {...billingInformationRegister('cardCode', { required: true })} />
                    </div>
                  </div>
                </div>

                <div className={cn('row')}>
                  <div className={cn('control', 'w-100')}>
                    <div className={cn('label')}>Name on card</div>
                    <div className={cn('field', { 'is-danger': billingInformationErrors.firstName })}>
                      <input
                        className={cn('input')}
                        id="firstName"
                        {...billingInformationRegister('firstName', { required: true })}
                      />
                    </div>
                  </div>
                </div>

                <div className={cn('row')}>
                  <Checkbox
                    name="isSameAsShippingAddress"
                    label="Same as shipping"
                    level={1}
                    onChange={setIsSameAsShipping}
                  />
                </div>

                {!isSameAsShipping && (
                  <>
                    <div className={cn('row')}>
                      <div className={cn('control', 'w-100')}>
                        <div className={cn('label')}>Address</div>
                        <div className={cn('field', { 'is-danger': billingInformationErrors.address })}>
                          <input
                            className={cn('input')}
                            id="address"
                            {...billingInformationRegister('address', { required: true })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={cn('row')}>
                      <div className={cn('control', 'w-33')}>
                        <div className={cn('label')}>State</div>
                        <div className={cn('field', { 'is-danger': billingInformationErrors.state })}>
                          <input
                            className={cn('input')}
                            id="state"
                            {...billingInformationRegister('state', { required: true })}
                          />
                        </div>
                      </div>
                      <div className={cn('control', 'w-33')}>
                        <div className={cn('label')}>City</div>
                        <div className={cn('field', { 'is-danger': billingInformationErrors.city })}>
                          <input className={cn('input')} {...billingInformationRegister('city', { required: true })} />
                        </div>
                      </div>
                      <div className={cn('control', 'w-33')}>
                        <div className={cn('label')}>Zip code</div>
                        <div className={cn('field', { 'is-danger': billingInformationErrors.zipCode })}>
                          <input
                            className={cn('input')}
                            {...billingInformationRegister('zipCode', { required: true })}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className={cn('ft-mt-4', 'flexbox')}>
                  <Button color="secondary" onClick={() => setStep(1)}>
                    Back
                  </Button>
                  <div className={cn('ft-ml-4')}>
                    <Button color="secondary" type="submit">
                      Next
                    </Button>
                  </div>
                  {billingInformationIsSubmitted && !billingInformationIsValid && (
                    <div className={cn('has-text-danger', 'ft-ml-4')}>All fields are required</div>
                  )}
                </div>
              </form>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className={cn('is-size-6', 'has-text-weight-bold')}>Confirm purhase</div>
            <div className={cn('form-group', 'ft-mt-4')}>
              <div className={cn('moss')}>
                <div className={cn('flexbox')}>
                  <div>{session.purchasePost?.name}</div>
                  <div className={cn('ft-ml-auto')}>
                    ${formatNumber(session.purchasePost?.marketplaceListingUSDAmount || 0)}
                  </div>
                </div>

                <div className={cn('flexbox')}>
                  <div>Shipping fee</div>
                  <div className={cn('ft-ml-auto')}>$0</div>
                </div>

                <div className={cn('flexbox', 'has-text-weight-bold', 'subtotal')}>
                  <div>Subtotal</div>
                  <div className={cn('ft-ml-auto')}>
                    ${formatNumber(session.purchasePost?.marketplaceListingUSDAmount || 0)}
                  </div>
                </div>
              </div>
              <div className={cn('ft-mt-4', 'flexbox')}>
                <Button color="secondary" onClick={() => setStep(2)}>
                  Back
                </Button>
                <div className={cn('ft-ml-4')}>
                  <Button color="primary" onClick={() => completePurchase()} disabled={merchandise.isPurchaseRequest}>
                    Purchase
                  </Button>
                </div>
              </div>
            </div>
          </>
        );
    }
  };

  return (
    <>
      <div className={cn('header')}>
        <div className={cn('flexbox', 'flexbox-start')}>
          <img className={cn('item-thumbnail')} src={session.purchasePost?.contentUrl} />
          <div className={cn('ft-ml-4')}>
            <div className={cn('name', 'is-size-5', 'has-text-weight-semibold', 'ft-mt-2')}>
              {session.purchasePost?.name}
            </div>
            {/*<div>{session.purchasePost?.description}</div>*/}
            <div className={cn('is-size-5', 'has-text-weight-bold', 'ft-mt-4')}>
              ${formatNumber(session.purchasePost?.marketplaceListingUSDAmount || 0)}
            </div>
            <div className={cn('is-size-7', 'ft-mt-4')}>Stock: {session.purchasePost?.marketplaceListingQuantity}</div>
          </div>
        </div>
      </div>
      <div className={cn('ft-p-4')}>
        {isPurchaseComplete ? (
          <div className={cn('purchase-complete')}>
            <div>
              Thank you for your purchase. You will receive an email to track your order details.
              <div className={cn('ft-mt-4')}>
                <Button
                  color="secondary"
                  onClick={() => {
                    dispatch(resetPurchase());
                    dispatch(initializePurchaseItem(undefined));
                  }}>
                  Back to app
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className={cn('breadcrumb', 'ft-mb-4')}>
              <ul>
                {step >= 1 && <li>Shipping Information</li>}
                {step >= 2 && <li>Billing Information</li>}
                {step >= 3 && <li>Confirm Purchase</li>}
              </ul>
            </div>
            <div>{renderStep()}</div>
          </div>
        )}
      </div>
    </>
  );
};

export default PurchaseItemModal;
