import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import classnames from 'classnames/bind';

import { legalRouteConfig } from 'routes';
import RouteFromPath from 'components/Route/RouteFromPath';

import logoMark from 'assets/images/heyfans-logo.svg';
import logoType from 'assets/images/heyfans-logotype.svg';

import styles from './Legal.module.scss';

const cn = classnames.bind(styles);

const Legal = () => {
  const match = useRouteMatch();
  const preferencesRoute = useRouteMatch('/preferences');
  const history = useHistory();

  return (
    <div className={cn('container', { 'preferences-container': preferencesRoute })}>
      <div className={cn('wrapper')}>
        {!preferencesRoute && (
          <div className={cn('header')}>
            <img className={cn('mark')} src={logoMark} onClick={() => history.push('/login')} />
            <img className={cn('type')} src={logoType} />
          </div>
        )}
        <div className={cn('header-name', preferencesRoute ? 'preferences' : 'login')}>Legal</div>
        <div className={cn('is-size-7', 'ft-mt-4')}>
          Here you will find information about how we collect and use your data (Privacy), what our expectations are for
          you with regards to using the HeyFans platform (Terms) and how we might track your use of this site to better
          understand your interests as well as improve the HeyFans experience (Cookies).
        </div>

        <div className={cn('flexbox', 'tab', 'ft-mt-4')}>
          <div
            className={cn('tab-item', { active: location.pathname === `${preferencesRoute?.url || ''}/legal/terms` })}
            onClick={() => {
              history.push(`${preferencesRoute?.url || ''}/legal/terms`);
            }}>
            Terms
            {location.pathname === `${preferencesRoute?.url || ''}/legal/terms` && <div className={cn('border')} />}
          </div>
          <div
            className={cn('tab-item', { active: location.pathname === `${preferencesRoute?.url || ''}/legal/privacy` })}
            onClick={() => {
              history.push(`${preferencesRoute?.url || ''}/legal/privacy`);
            }}>
            Privacy
            {location.pathname === `${preferencesRoute?.url || ''}/legal/privacy` && <div className={cn('border')} />}
          </div>
          <div
            className={cn('tab-item', {
              active: location.pathname === `${preferencesRoute?.url || ''}/legal/copyright`
            })}
            onClick={() => {
              history.push(`${preferencesRoute?.url || ''}/legal/copyright`);
            }}>
            Copyright
            {location.pathname === `${preferencesRoute?.url || ''}/legal/copyright` && <div className={cn('border')} />}
          </div>
          <div
            className={cn('tab-item', { active: location.pathname === `${preferencesRoute?.url || ''}/legal/cookies` })}
            onClick={() => {
              history.push(`${preferencesRoute?.url || ''}/legal/cookies`);
            }}>
            Cookies
            {location.pathname === `${preferencesRoute?.url || ''}/legal/cookies` && <div className={cn('border')} />}
          </div>
        </div>

        <div className={cn('ft-mt-8')}>
          {legalRouteConfig.map((route) => (
            <RouteFromPath key={`route${route.path}`} base={match.url} {...route} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Legal;
