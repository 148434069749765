import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { RootState } from 'state/store';

import { answerPoll, fetchPoll, togglePoll } from 'state/reducers/liveStreamReducer';
import Button from 'components/Button';
import { PollOptionResults, PollOptions } from 'types/poll';
import Icon from 'components/Icon';
import styles from './Poll.module.scss';

const cn = classnames.bind(styles);
const Poll = () => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState<number>();

  const { user, togglePollControl, poll, liveStream, liveEvent } = useSelector((state: RootState) => ({
    user: state.user,
    togglePollControl: state.liveStream.togglePoll,
    poll: state.liveStream.poll,
    liveStream: state.liveStream,
    liveEvent: state.liveEvent
  }));

  function toggle() {
    dispatch(togglePoll(!togglePollControl));
  }

  const select = (option: PollOptions, index: number) => {
    setSelectedOption(index);
    dispatch(answerPoll(option));
  };

  useEffect(() => {
    setSelectedOption(undefined);
    dispatch(fetchPoll(liveStream.live?.guid || liveEvent.activeLiveEvent?.guid || ''));
  }, [liveStream.live, liveEvent.activeLiveEvent]);

  const checkHasAnswer = poll?.pollOptions.find((o: PollOptions) => o.isVotedForByCallingUser === true);
  return (
    <>
      {(liveStream.poll?.isActive || liveStream.pollResult?.isActive) && (
        <div className={cn('poll', togglePollControl ? 'show' : 'hide')}>
          <div className={cn('has-text-weight-bold', 'is-size-6', 'ft-mb-4')}>
            {liveStream.pollResult && checkHasAnswer ? 'Result' : 'Poll'}
          </div>
          <div className={cn('flexbox')}>
            <div className={cn('question', 'flex', 'ft-mb-2')}>{poll?.question}</div>
          </div>
          {liveStream.pollResult && checkHasAnswer && liveStream.poll?.userGUID !== user.user?.guid ? (
            <>
              <div className={cn('result')}>
                {liveStream.pollResult?.pollOptions.map((option: PollOptionResults) => (
                  <div className={cn('option')}>
                    <div>{option.name}</div>
                    {liveStream.pollResult?.voteAmount && (
                      <div
                        className={cn(option.isVotedForByCallingUser ? 'selected' : 'others')}
                        style={{
                          maxWidth: '100%',
                          width: `${(option.voteCount / liveStream.pollResult?.voteAmount) * 100}%`
                        }}>
                        {`${((option.voteCount / liveStream.pollResult?.voteAmount) * 100).toFixed(2)}%`}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <div className={cn('options', 'fluid')}>
                {poll?.pollOptions.map((option: PollOptions, index: number) => (
                  <Button
                    className={cn('option')}
                    type="button"
                    color={option.isVotedForByCallingUser || selectedOption === index ? 'primary' : 'secondary'}
                    onClick={() => select(option, index)}
                    disabled={!!selectedOption}>
                    {option.name}
                  </Button>
                ))}
              </div>
              {poll?.voteAmount && (poll?.voteAmount as number) > 0 ? (
                <div className={cn('monetize-info', 'ft-mt-2')}>
                  Answering this poll will cost{' '}
                  <span className={cn('poll-amount')}>
                    {poll.voteAmount.toFixed(0)}
                    <Icon name="fanbucks" size="sm" />
                  </span>
                </div>
              ) : (
                ''
              )}
            </>
          )}
          <div className={cn('poll-toggle', 'flexbox')} onClick={() => toggle()}>
            <div>
              <i className={`tf ${togglePollControl ? 'tf-close' : 'tf-arrow-left'}`} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Poll;
