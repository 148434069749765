import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import RouteFromPath from 'components/Route/RouteFromPath';
import { baseRouteConfig } from 'routes';

import './App.scss';

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          {baseRouteConfig.map((route) => (
            <RouteFromPath key={`route${route.path}`} base="" {...route} />
          ))}
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
