import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import classnames from 'classnames/bind';

import { discoverRouteConfig } from 'routes';
import RouteFromPath from 'components/Route/RouteFromPath';

// import discover from 'assets/images/discover-banner.png';

import styles from './Discover.module.scss';

const cn = classnames.bind(styles);

const Discover = () => {
  const history = useHistory();
  const match = useRouteMatch();

  const goToPage = (page: string) => {
    switch (page) {
      case '':
        history.push(`${match.url}`);
        break;
      case 'events':
        history.push(`${match.url}/events`);
        break;
    }
  };

  return (
    <div className={cn('container')}>
      <div className={cn('content')}>
        <div className={cn('main')}>
          <div className={cn('banner')}>
            {/* <img className={cn('background')} src={discover} /> */}
            <div className={cn('is-size-5 has-text-weight-semibold')}>
              <div>Athletes and Events on HeyFans</div>
            </div>
          </div>

          <div className={cn('menu-wrapper')}>
            <div className={cn('menu')}>
              <div className={cn('flexbox', 'tab')}>
                <div
                  className={cn('tab-item', { active: location.pathname === '/discover' })}
                  onClick={() => goToPage('')}>
                  Athletes
                  {location.pathname === '/discover' && <div className={cn('border')} />}
                </div>
                <div
                  className={cn('tab-item', { active: location.pathname === '/discover/events' })}
                  onClick={() => goToPage('events')}>
                  Events
                  {location.pathname === '/discover/events' && <div className={cn('border')} />}
                </div>
              </div>
            </div>
          </div>

          {discoverRouteConfig.map((route) => (
            <RouteFromPath key={`route${route.path}`} base={match.url} {...route} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Discover;
