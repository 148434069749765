import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames/bind';

import { PostType } from 'enums';
import { formatNumber } from 'utils';
import { fetchPostsByMerchatByType } from 'state/reducers/postReducer';
import { RootState } from 'state/store';

import styles from './Merchandise.module.scss';

const cn = classnames.bind(styles);

const Merchandise = () => {
  const { creator, merchandise } = useSelector((state: RootState) => ({
    creator: state.creator,
    merchandise: state.merchandise
  }));
  const dispatch = useDispatch();
  /*
  const purchase = (item: Post) => {
    dispatch(initializePurchaseItem(item));
  };*/

  useEffect(() => {
    dispatch(
      fetchPostsByMerchatByType({
        creatorGuid: creator.creator?.guid as string,
        postType: PostType.Image
      })
    );
  }, [creator.creator?.guid]);

  return (
    <div className={cn('merchandise-wrapper')}>
      <div className={cn('merchandise')}>
        <div className={cn('grid')}>
          {merchandise.merchandise?.map((post) => (
            <div className={cn('grid-inner')}>
              <Link to={`/merch/${post.guid}`}>
                <div className={cn('flexbox', 'flexbox-start')} key={post.guid}>
                  <div className={cn('thumbnail')}>
                    <img src={post.contentUrl} />
                  </div>
                  <div className={cn('ft-ml-4')}>
                    <div className={cn('name', 'is-size-5', 'has-text-weight-bold', 'ft-mt-2')}>{post.name}</div>
                    <div className={cn('ft-mt-2')}>{post.description}</div>
                    <div className={cn('is-size-5', 'has-text-weight-bold', 'ft-mt-4')}>
                      ${formatNumber(post.marketplaceListingUSDAmount || 0)}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Merchandise;
