import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/store';
import defaultAvatar from 'assets/images/default-avatar-1.png';

import Button from 'components/Button';
import BlankPage from 'components/BlankPage';
import { searchBlockedUsers, unblockUser } from 'state/reducers/userReducer';
import Modal from 'components/Modal';
import { UserBlock } from 'types';
import UnblockUserModal from 'components/Modal/UnblockUserModal';
import { toggleSnackbarOpen } from 'state/reducers/snackbarReducer';
import styles from './Block.module.scss';

const cn = classnames.bind(styles);

const Block = () => {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const [isUnblockUser, setIsUnblockUser] = useState<UserBlock | undefined>();

  const confirmUnblocking = (value: boolean) => {
    if (value) {
      dispatch(unblockUser(isUnblockUser?.guid as string));
      dispatch(
        toggleSnackbarOpen({
          message: `${isUnblockUser?.blockedUserDisplayName} is now unblocked.`,
          type: 'info',
          timeout: 4000
        })
      );
    }

    setIsUnblockUser(undefined);
  };

  useEffect(() => {
    if (user.user?.guid) {
      dispatch(searchBlockedUsers(user.user.guid));
    }
  }, [user.user]);

  return (
    <div className={cn('container')}>
      <div>
        <div className={cn('is-size-5 has-text-weight-semibold')}>Blocked Users</div>
        {user.blockedUsers && user.blockedUsers.length > 0 ? (
          <>
            <div className={cn('list', 'ft-mt-4')}>
              {user.blockedUsers?.map((user) => (
                <>
                  {
                    <div className={cn('item', 'flexbox')}>
                      <img className={cn('avatar')} src={user.blockedUserNormalizedProfileImageUrl || defaultAvatar} />
                      <div className={cn('ft-ml-2')}>
                        <div>{user.blockedUserDisplayName}</div>
                        <div>@{user?.blockedUserUsername}</div>
                      </div>

                      <div className="ft-ml-auto">
                        <Button color="secondary" onClick={() => setIsUnblockUser(user)}>
                          Unblock
                        </Button>
                      </div>
                    </div>
                  }
                </>
              ))}
            </div>
          </>
        ) : (
          <BlankPage text="You have no blocked users." />
        )}
      </div>

      {isUnblockUser && (
        <Modal onClose={() => setIsUnblockUser(undefined)}>
          <UnblockUserModal onSelect={confirmUnblocking} unBlockedUser={isUnblockUser as UserBlock} />
        </Modal>
      )}
    </div>
  );
};

export default Block;
