/* eslint-disable no-console */
import { httpClient } from 'http/httpClient';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  CalendarQuery,
  Channel,
  LiveEvent,
  Schedules,
  SubscriptionPlan,
  TokenBalance,
  User,
  UserBlock,
  UserFollower,
  UserFollowing,
  UserMerchantApplication,
  UserNotification,
  UserPaymentProfile,
  UserReferralCodes,
  UserReferralStats,
  UserTransaction,
  VideoCallRequest
} from 'types';
import { PendingAction, RejectedAction } from 'state/store';
import { clone, cloneDeep } from 'lodash';
import moment from 'moment';
import _ from 'lodash';
import { ErrorPayload } from 'types/errorPayload';
import { Announcement } from 'types/announcement';

export const getCurrentUser = createAsyncThunk('user/getCurrentUser', async () => {
  const response = await httpClient.get('/users/current');
  return response.data;
});

export const updateUser = createAsyncThunk('user/updateUser', async (user: User, { rejectWithValue }) => {
  try {
    const response = await httpClient.patch(`/users/${user.guid}`, user);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updateUserProfileImage = createAsyncThunk(
  'user/updateUserProfileImage',
  async ({ userGuid, file }: { userGuid: string; file: FormData }) => {
    const response = await httpClient.post(`/users/${userGuid}/profileimage/upload`, file);
    return response.data;
  }
);

export const fetchUserFollowing = createAsyncThunk('user/fetchUserFollowing', async (request: string) => {
  const response = await httpClient.get('/userfollowers/search', {
    params: { userGUID: request }
  });
  return response.data;
});

export const fetchMerchantFollowing = createAsyncThunk('user/fetchMerchantFollowing', async (request: string) => {
  const response = await httpClient.get('/userfollowers/search', {
    params: { userGUID: request }
  });
  return response.data;
});

export const fetchUserFollower = createAsyncThunk('user/fetchUserFollower', async (request: string) => {
  const response = await httpClient.get('/userfollowers/search', {
    params: { followedUserGUID: request, status: 'accepted' }
  });
  return response.data;
});

export const fetchNotifications = createAsyncThunk('user/fetchNotifications', async () => {
  const response = await httpClient.get('/users/current/notifications');
  return response.data;
});

export const fetchTokens = createAsyncThunk('user/fetchTokens', async () => {
  const response = await httpClient.get('/users/current/tokenbalances');
  return response.data;
});

export const fetchPaymentProfiles = createAsyncThunk('user/fetchPaymentProfiles', async (request: string) => {
  const response = await httpClient.get(`/users/${request}/userpaymentprofiles`);
  return response.data;
});

export const deletePaymentProfiles = createAsyncThunk(
  'user/deletePaymentProfiles',
  async (paymentProfileGUID: string) => {
    const response = await httpClient.delete(`/userpaymentprofiles/${paymentProfileGUID}`);
    return { guid: paymentProfileGUID, data: response.data };
  }
);

export const fetchTransaction = createAsyncThunk('user/fetchTransaction', async () => {
  const response = await httpClient.get(`/users/current/tokentransfers`);
  return response.data;
});

export const fetchSubscriptions = createAsyncThunk('user/fetchSubscriptions', async () => {
  const response = await httpClient.get(`/users/current/subscriptions`);
  return response.data;
});

export const fetchUser = createAsyncThunk('user/fetchUser', async (username: string) => {
  const response = await httpClient.get('/users/search', { params: { username } });
  return response.data[0];
});

export const followUser = createAsyncThunk(
  'user/followUser',
  async (data: {
    type: string;
    user: UserFollower | UserFollowing;
    data: UserFollower[] | UserFollowing[] | undefined;
    index: number;
  }) => {
    let userGUID;
    if (data.type === 'following') {
      userGUID = data.user?.followedUserGUID;
    }
    if (data.type === 'follower') {
      userGUID = data.user?.userGUID;
    }
    const response = await httpClient.post(`/users/${userGUID}/follow`);
    const user = clone(data.user);
    user.isFollowRequestedByCallingUser = true;
    const tempData = clone(data.data);

    if (tempData !== undefined) {
      if (data.type === 'following') {
        user.isFollowedUserFollowRequestedByCallingUser = true;
        user.isFollowedUserFollowedByCallingUser = true;
      }
      if (data.type === 'follower') {
        user.isFollowRequestedByCallingUser = true;
        user.isFollowedByCallingUser = false;
      }

      tempData[data.index] = user;
    }
    return { response, user, data: tempData, type: data.type };
  }
);

export const unfollowUser = createAsyncThunk(
  'user/unfollowUser',
  async (data: {
    type: string;
    user: UserFollower | UserFollowing;
    data: UserFollower[] | UserFollowing[] | undefined;
    index: number;
  }) => {
    let userGUID;
    if (data.type === 'following') {
      userGUID = data.user?.followedUserGUID;
    }
    if (data.type === 'follower') {
      userGUID = data.user?.userGUID;
    }
    const response = await httpClient.post(`/users/${userGUID}/unfollow`);
    const user = clone(data.user);
    if (data.type === 'following') {
      user.isFollowRequestedByCallingUser = false;
      user.isFollowedUserFollowedByCallingUser = false;
    }
    if (data.type === 'follower') {
      user.isFollowedByCallingUser = false;
      user.isFollowRequestedByCallingUser = false;
    }
    const tempData = clone(data.data);
    if (tempData !== undefined) {
      tempData[data.index] = user;
    }
    return { response, user, data: tempData, type: data.type };
  }
);

export const searchUsers = createAsyncThunk('user/searchUsers', async (params: { [p: string]: string }) => {
  const response = await httpClient.get('/users/search', { params });
  return response.data;
});

export const fetchCalendarContent = createAsyncThunk('user/fetchCalendarContent', async (data: CalendarQuery) => {
  const dates: string[] = [];
  const schedules: Schedules[] = [];
  //get merchant events
  if (data.merchantGuid) {
    const merchantEvent = await httpClient.get(`/liveevents/search`, {
      params: {
        merchantGUID: data.merchantGuid,
        afterDateInclusive: data.dateStart.toISOString(),
        beforeDateInclusive: data.dateEnd.toISOString(),
        isPublish: true
      }
    });
    merchantEvent.data.map((event: LiveEvent) => {
      if (event.dateOfEvent) {
        const tempDate = moment(event.dateOfEvent).format('MM/DD/YY');
        schedules.push({
          guid: event.guid,
          date: tempDate,
          timeStamp: moment(event.dateOfEvent).unix(),
          liveEventData: event,
          type: 'LiveEvent'
        });
        dates.push(tempDate);
      }
    });
  }

  //get bought events
  const boughtEvent = await httpClient.get(`/liveevents/search`, {
    params: {
      afterDateInclusive: data.dateStart.toISOString(),
      beforeDateInclusive: data.dateEnd.toISOString(),
      isPublish: true,
      hasTicketForCallingUser: true
    }
  });
  boughtEvent.data.map((event: LiveEvent) => {
    if (event.dateOfEvent) {
      const tempDate = moment(event.dateOfEvent).format('MM/DD/YY');
      schedules.push({
        guid: event.guid,
        date: tempDate,
        timeStamp: moment(event.dateOfEvent).unix(),
        liveEventData: event,
        type: 'LiveEvent'
      });
      dates.push(tempDate);
    }
  });

  //get merchant video call request
  if (data.merchantGuid) {
    const merchantVideoCallRequest = await httpClient.get('/merchantmeetandgreetrequests/search', {
      params: {
        merchantGUID: data.merchantGuid,
        afterDateInclusive: data.dateStart.toISOString(),
        beforeDateInclusive: data.dateEnd.toISOString()
      }
    });

    merchantVideoCallRequest.data.map((request: VideoCallRequest) => {
      if (request.dateScheduled) {
        const tempDate = moment(request.dateScheduled).format('MM/DD/YY');
        schedules.push({
          guid: request.guid,
          date: tempDate,
          timeStamp: moment(request.dateScheduled).unix(),
          videoMessageData: request,
          type: 'VideoCall'
        });
        dates.push(tempDate);
      }
    });
  }

  //get video call request
  const videoCallRequest = await httpClient.get('/merchantmeetandgreetrequests/search', {
    params: {
      userGUID: data.userGuid,
      afterDateInclusive: data.dateStart.toISOString(),
      beforeDateInclusive: data.dateEnd.toISOString()
    }
  });

  videoCallRequest.data.map((request: VideoCallRequest) => {
    if (request.dateScheduled) {
      const tempDate = moment(request.dateScheduled).format('MM/DD/YY');
      schedules.push({
        guid: request.guid,
        date: tempDate,
        timeStamp: moment(request.dateScheduled).unix(),
        videoMessageData: request,
        type: 'VideoCall'
      });
      dates.push(tempDate);
    }
  });

  const sortedSchedules = _.sortBy(schedules, 'timeStamp');
  const response = { dates, schedules: sortedSchedules };

  return response;
});

export const unsubscribe = createAsyncThunk('subscription/unsubscribe', async (subscriptionPlan: SubscriptionPlan) => {
  const response = await httpClient.post(`/subscriptionplans/${subscriptionPlan.subscriptionPlanGUID}/unsubscribe`, {});
  const unsubscribedPlan = cloneDeep(subscriptionPlan);
  unsubscribedPlan.status = 'Cancelled';
  return { response: response.data, data: unsubscribedPlan };
});

export const markAllNotificationsAsRead = createAsyncThunk('user/markNotificationsAsRead', async () => {
  const response = await httpClient.put(`/users/current/notifications/markasread`, {});
  return response;
});

export const markNotificationAsRead = createAsyncThunk(
  'user/markNotificationAsRead',
  async (notificationGUID: string) => {
    const response = await httpClient.patch(`/notifications/${notificationGUID}`, {
      isRead: true
    });
    return { response: response.data, guid: notificationGUID };
  }
);

export const fetchAnnouncements = createAsyncThunk('user/fetchAnnouncements', async () => {
  const response = await httpClient.get(`/users/current/followerannouncements`, {});
  return response.data;
});

export const searchAnnouncements = createAsyncThunk(
  'user/searchAnnouncements',
  async (params: { [p: string]: string }) => {
    const response = await httpClient.get('/announcements/search', { params });
    return response.data;
  }
);

export const blockUser = createAsyncThunk('user/blockUser', async (request: UserBlock) => {
  const response = await httpClient.post('/userblocks', {
    userGUID: request.userGUID,
    blockedUserGUID: request.blockedUserGUID,
    blockedUserUsername: request.blockedUserUsername,
    blockedUserDisplayName: request.blockedUserDisplayName,
    blockedUserFirstName: request.blockedUserFirstName,
    blockedUserLastName: request.blockedUserLastName,
    blockedUserMerchantGUID: request.blockedUserMerchantGUID,
    blockedUserProfileImageUrl: request.blockedUserProfileImageUrl,
    blockedUserNormalizedProfileImageUrl: request.blockedUserNormalizedProfileImageUrl
  });
  return { response: response.data, data: request };
});

export const searchBlockedUsers = createAsyncThunk('user/searchBlockedUsers', async (userGUID: string) => {
  const response = await httpClient.get('/userblocks/search', {
    params: {
      userGUID: userGUID
    }
  });
  return response.data;
});

export const searchBlockers = createAsyncThunk('user/searchBlockers', async (blockedUserGUID: string) => {
  const response = await httpClient.get('/userblocks/search', {
    params: {
      blockedUserGUID: blockedUserGUID
    }
  });
  return response.data;
});

export const unblockUser = createAsyncThunk('user/unblockUser', async (blockedUserGUID: string) => {
  const response = await httpClient.delete(`/userblocks/${blockedUserGUID}`);
  return { guid: blockedUserGUID, data: response.data };
});

export const platformRefreshToken = createAsyncThunk('user/platformRefreshToken', async (platform: string) => {
  const response = await httpClient.post('/users/login/token', {
    platform: platform
  });
  return response.data;
});

export const fetchUserChannels = createAsyncThunk('user/fetchUserChannels', async () => {
  const response = await httpClient.get(`/users/current/channels`);
  return response.data;
});

export const createChannel = createAsyncThunk('user/createChannel', async (channel: Channel) => {
  const response = await httpClient.post('/channels', channel);
  return { channel, data: response.data };
});

export const updateChannel = createAsyncThunk('user/updateChannel', async (channel: Channel) => {
  const response = await httpClient.patch(`/channels/${channel.guid}`, channel);
  return { channel, data: response.data };
});

export const deleteChannel = createAsyncThunk('user/deleteChannel', async (channelGUID: string) => {
  const response = await httpClient.delete(`/channels/${channelGUID}`);
  return { guid: channelGUID, data: response.data };
});

export const pinChannel = createAsyncThunk('user/pinChannel', async (channelGUID: string) => {
  const response = await httpClient.post(`/channels/${channelGUID}/pin`, {});
  return { channelGUID: channelGUID, data: response.data };
});

export const unpinChannel = createAsyncThunk('user/unpinChannel', async (channelGUID: string) => {
  const response = await httpClient.post(`/channels/${channelGUID}/unpin`, {});
  return { channelGUID: channelGUID, data: response.data };
});

export const fetchUserReferralStats = createAsyncThunk('user/fetchUserReferralStats', async () => {
  const response = await httpClient.get(`/users/current/referralstats`);
  return response.data;
});

export const fetchUserReferralCodes = createAsyncThunk('user/fetchUserReferralCodes', async () => {
  const response = await httpClient.get(`/users/current/referralcodes`);
  return response.data;
});

export const saveReferralCode = createAsyncThunk(
  'user/saveReferralCode',
  async (request: { newCode: string; userGUID: string }) => {
    const response = await httpClient.post('/referralcodes', {
      code: request.newCode,
      userGUID: request.userGUID
    });
    return { newCode: request, data: response.data };
  }
);

export const submitMerchantApplication = createAsyncThunk(
  'user/submitMerchantApplication',
  async (request: UserMerchantApplication, { rejectWithValue }) => {
    try {
      const response = await httpClient.post('/public/merchantapplications', {
        platform: 'heyfans',
        userGUID: request.userGUID,
        emailAddress: request.emailAddress,
        name: request.name,
        description: request.description,
        performanceSampleUrl: request.performanceSampleUrl,
        twitterUrl: request.twitterUrl,
        facebookUrl: request.facebookUrl,
        youTubeUrl: request.youTubeUrl,
        instagramUrl: request.instagramUrl,
        tikTokUrl: request.tikTokUrl,
        phoneNumber: request.phoneNumber,
        referralCode: request.referralCode
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const validateReferralCode = createAsyncThunk('user/validateReferralCode', async (referralCode: string) => {
  const response = await httpClient.get(`/users/existence/referralcode/${referralCode}`);
  return response.data;
});

interface UserState {
  status: string;
  error?: string;
  updateStatus?: string;
  isUserUpdated?: boolean;
  isUserProfileImageUpdated?: boolean;
  user?: User;
  following?: UserFollowing[];
  merchantFollowing?: UserFollowing[];
  follower?: UserFollower[];
  notifications?: UserNotification[];
  balance?: TokenBalance[];
  paymentProfiles?: UserPaymentProfile[];
  transaction?: UserTransaction[];
  subscriptions?: SubscriptionPlan[];
  users?: User[];
  viewUser?: User;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  announcements?: Announcement[];
  announcementsSearch?: Announcement[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  calendar?: any;
  errorPayload?: ErrorPayload;
  blockedUsers?: UserBlock[];
  blockers?: UserBlock[];
  authToken?: string;
  userChannels?: Channel[];
  changeUser?: boolean;
  updateChannelStatus?: string;
  updatedChannel?: Channel;
  newChannel?: Channel;
  referralStats?: UserReferralStats;
  defaultReferralCode?: UserReferralCodes;
  isReferralCodeCreated?: boolean;
  isApplicationSubmitted?: boolean;
  isReferralCode?: boolean;
}

const initialState: UserState = {
  status: 'idle'
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: (state) => {
      state = initialState;
      return state;
    },
    setUser: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload
      };
    },
    setMerchant: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
        merchantDescription: action.payload.description
      };
    },
    setPendingUpdate: (state) => {
      state.updateStatus = 'pending';
    },
    setViewUser: (state, action) => {
      state.viewUser = action.payload;
    },
    resetViewUser: (state) => {
      state.viewUser = undefined;
      state.following = undefined;
      state.follower = undefined;
      return state;
    },
    resetNotification: (state) => {
      state.notifications = undefined;
      return state;
    },
    resetSubscriptions: (state) => {
      state.subscriptions = undefined;
      return state;
    },
    resetUserUpdate: (state) => {
      state.isUserUpdated = false;
      state.updateStatus = undefined;
    },
    resetUserErrorPayload: (state) => {
      state.error = undefined;
      state.errorPayload = undefined;
    },
    resetChangeUser: (state) => {
      state.changeUser = false;
    },
    resetIsReferralCodeCreated: (state) => {
      state.isReferralCodeCreated = undefined;
      state.error = undefined;
    },
    resetUpdateChannelStatus: (state) => {
      state.updateChannelStatus = undefined;
    },
    resetNewChannel: (state) => {
      state.newChannel = undefined;
    },
    resetIsApplicationSubmitted: (state) => {
      state.isApplicationSubmitted = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.status = 'success';
        state.user = action.payload;
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.status = 'success';
        state.isUserUpdated = true;
        state.updateStatus = 'success';
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.status = 'rejected';
        state.isUserUpdated = false;
        state.errorPayload = action.payload as ErrorPayload;
      })
      .addCase(updateUserProfileImage.fulfilled, (state) => {
        state.status = 'success';
        state.isUserProfileImageUpdated = true;
      })
      .addCase(fetchUserFollowing.fulfilled, (state, action) => {
        state.status = 'success';
        state.following = action.payload;
      })
      .addCase(fetchMerchantFollowing.fulfilled, (state, action) => {
        state.status = 'success';
        state.merchantFollowing = action.payload.filter((x: UserFollowing) => x.followedUserMerchantGUID);
      })
      .addCase(fetchUserFollower.fulfilled, (state, action) => {
        state.status = 'success';
        state.follower = action.payload;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.status = 'success';
        state.notifications = action.payload;
      })
      .addCase(fetchTokens.fulfilled, (state, action) => {
        state.status = 'success';
        state.balance = action.payload;
      })
      .addCase(fetchPaymentProfiles.fulfilled, (state, action) => {
        state.status = 'success';
        state.paymentProfiles = action.payload;
      })
      .addCase(deletePaymentProfiles.fulfilled, (state, action) => {
        state.status = 'success';
        const paymentProfiles = cloneDeep(state.paymentProfiles);
        const deletedGUID = action.payload.guid;
        paymentProfiles?.splice(
          paymentProfiles.findIndex((card) => card.guid === deletedGUID),
          1
        );
        state.paymentProfiles = paymentProfiles;
      })
      .addCase(fetchTransaction.fulfilled, (state, action) => {
        state.status = 'success';
        state.transaction = action.payload;
      })
      .addCase(fetchSubscriptions.fulfilled, (state, action) => {
        state.status = 'success';
        state.subscriptions = action.payload;
      })
      .addCase(followUser.fulfilled, (state, action) => {
        state.status = 'success';
        if (action.payload.type === 'following') {
          state.following = action.payload.data;
        }
        if (action.payload.type === 'follower') {
          state.follower = action.payload.data;
        }
      })
      .addCase(fetchCalendarContent.fulfilled, (state, action) => {
        state.status = 'success';
        state.calendar = action.payload;
      })

      .addCase(unfollowUser.fulfilled, (state, action) => {
        state.status = 'success';
        if (action.payload.type === 'following') {
          state.following = action.payload.data;
        }
        if (action.payload.type === 'follower') {
          state.follower = action.payload.data;
        }
      })
      .addCase(searchUsers.fulfilled, (state, action) => {
        state.status = 'success';
        state.users = action.payload;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = 'success';
        state.viewUser = action.payload;
      })
      .addCase(unsubscribe.fulfilled, (state, action) => {
        state.status = 'success';
        const subscriptionList = cloneDeep(state.subscriptions);
        const unsubscribedIndex = subscriptionList?.findIndex(
          (plan) => plan.subscriptionPlanGUID === action.payload.data.subscriptionPlanGUID
        ) as number;
        if (subscriptionList) {
          subscriptionList[unsubscribedIndex] = action.payload.data;
        }
        state.subscriptions = subscriptionList;
      })
      .addCase(markAllNotificationsAsRead.fulfilled, (state) => {
        const notificationsList = cloneDeep(state.notifications);
        notificationsList?.forEach((notif) => {
          if (notif.isRead === false) {
            notif.isRead = true;
          }
        });
        state.notifications = notificationsList;
      })
      .addCase(markNotificationAsRead.fulfilled, (state, action) => {
        const notificationsList = cloneDeep(state.notifications);
        const updatedIndex = notificationsList?.findIndex((x) => x.guid === action.payload.guid) as number;
        if (notificationsList && updatedIndex >= 0) {
          notificationsList[updatedIndex].isRead = true;
        }
        state.notifications = notificationsList;
      })
      .addCase(fetchAnnouncements.fulfilled, (state, action) => {
        state.status = 'success';
        state.announcements = action.payload;
      })
      .addCase(searchAnnouncements.fulfilled, (state, action) => {
        state.status = 'success';
        state.announcementsSearch = action.payload;
      })
      .addCase(blockUser.fulfilled, (state, action) => {
        state.status = 'success';
        action.payload.data.guid = action.payload.response;
        const blockList = cloneDeep(state.blockedUsers);
        blockList?.push(action.payload.data);
        state.blockedUsers = blockList;

        if (action.payload.data.blockedUserMerchantGUID) {
          const merchantFollowingList = cloneDeep(state.merchantFollowing);
          const blockedMerchantIndex = merchantFollowingList?.findIndex(
            (merchant) => merchant.followedUserMerchantGUID === action.payload.data.blockedUserMerchantGUID
          ) as number;
          if (blockedMerchantIndex >= 0) {
            merchantFollowingList?.splice(blockedMerchantIndex, 1);
          }
          state.merchantFollowing = merchantFollowingList;
        }
      })
      .addCase(searchBlockedUsers.fulfilled, (state, action) => {
        state.status = 'success';
        state.blockedUsers = action.payload;
      })
      .addCase(searchBlockers.fulfilled, (state, action) => {
        state.status = 'success';
        state.blockers = action.payload;
      })
      .addCase(unblockUser.fulfilled, (state, action) => {
        state.status = 'success';
        const blockList = cloneDeep(state.blockedUsers);
        const deletedGUID = action.payload.guid;
        blockList?.splice(
          blockList.findIndex((user) => user.guid === deletedGUID),
          1
        );
        state.blockedUsers = blockList;
      })
      .addCase(platformRefreshToken.fulfilled, (state, action) => {
        state.status = 'success';
        state.user = action.payload.user;
        state.authToken = action.payload.token;
      })
      .addCase(fetchUserChannels.fulfilled, (state, action) => {
        state.status = 'success';
        state.userChannels = action.payload;
      })
      .addCase(createChannel.fulfilled, (state, action) => {
        state.status = 'success';
        const channelList = cloneDeep(state.userChannels);
        const updatedChannel = action.payload.channel;
        updatedChannel.guid = action.payload.data.guid;
        channelList?.unshift(updatedChannel);
        state.newChannel = updatedChannel;
        state.userChannels = channelList;
        state.changeUser = true;
      })
      .addCase(updateChannel.fulfilled, (state, action) => {
        state.status = 'success';
        state.updateChannelStatus = 'success';
        const channelList = cloneDeep(state.userChannels);
        const editedChannel = channelList?.find((ch) => ch.guid === action.payload.channel.guid);
        const editedChannelIndex = channelList?.findIndex((ch) => ch.guid === action.payload.channel.guid) as number;

        if (editedChannel && channelList) {
          editedChannel.name = action.payload.channel.name;
          editedChannel.description = action.payload.channel.description;
          channelList[editedChannelIndex] = editedChannel;
          state.updatedChannel = editedChannel;
        }
        state.userChannels = channelList;
      })
      .addCase(deleteChannel.fulfilled, (state, action) => {
        state.status = 'success';
        const channelsList = cloneDeep(state.userChannels);
        const deletedGUID = action.payload.guid;
        channelsList?.splice(
          channelsList.findIndex((ch) => ch.guid === deletedGUID),
          1
        );
        state.userChannels = channelsList;
      })
      .addCase(pinChannel.fulfilled, (state, action) => {
        state.status = 'success';
        const channelsList = cloneDeep(state.userChannels);
        const channelIndex = channelsList?.findIndex((channel) => channel.guid === action.payload.channelGUID);
        if (channelsList && channelIndex && channelIndex >= 0) {
          channelsList[channelIndex].isChannelPinnedForCallingUser = true;
        }
        state.userChannels = channelsList;
      })
      .addCase(unpinChannel.fulfilled, (state, action) => {
        state.status = 'success';
        const channelsList = cloneDeep(state.userChannels);
        const channelIndex = channelsList?.findIndex((channel) => channel.guid === action.payload.channelGUID);
        if (channelsList && channelIndex && channelIndex >= 0) {
          channelsList[channelIndex].isChannelPinnedForCallingUser = false;
        }
        state.userChannels = channelsList;
      })
      .addCase(fetchUserReferralStats.fulfilled, (state, action) => {
        state.status = 'success';
        state.referralStats = action.payload;
      })
      .addCase(fetchUserReferralCodes.fulfilled, (state, action) => {
        state.status = 'success';
        state.defaultReferralCode = action.payload.filter((x: UserReferralCodes) => x.isActive)[0];
      })
      .addCase(validateReferralCode.fulfilled, (state, action) => {
        state.status = 'success';
        state.isReferralCode = action.payload.exists;
      })
      .addCase(saveReferralCode.fulfilled, (state) => {
        state.status = 'success';
        state.isReferralCodeCreated = true;
      })
      .addCase(saveReferralCode.rejected, (state) => {
        state.status = 'rejected';
        state.isReferralCodeCreated = false;
        state.error = 'The referral code you entered has already been taken.';
      })
      .addCase(submitMerchantApplication.fulfilled, (state) => {
        state.status = 'success';
        state.isApplicationSubmitted = true;
      })
      .addCase(submitMerchantApplication.rejected, (state, action) => {
        state.status = 'rejected';
        state.isApplicationSubmitted = false;
        state.errorPayload = action.payload as ErrorPayload;
      })
      .addMatcher(
        (action): action is PendingAction => action.type.endsWith('/pending'),
        (state) => {
          state.status = 'loading';
        }
      )
      .addMatcher(
        (action): action is RejectedAction => action.type.endsWith('/rejected'),
        (state, action) => {
          state.status = 'rejected';
          if (action.payload) {
            const errorPayload = action.payload as ErrorPayload;
            const message = errorPayload.message ? errorPayload.message : '';
            state.error = message;
            state.errorPayload = action.payload as ErrorPayload;
          }
        }
      );
  }
});

export const {
  reset,
  setUser,
  setPendingUpdate,
  setViewUser,
  resetViewUser,
  resetNotification,
  resetSubscriptions,
  resetUserUpdate,
  setMerchant,
  resetUserErrorPayload,
  resetChangeUser,
  resetIsReferralCodeCreated,
  resetUpdateChannelStatus,
  resetNewChannel,
  resetIsApplicationSubmitted
} = userSlice.actions;
export default userSlice.reducer;
