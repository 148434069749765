import React from 'react';
import classnames from 'classnames/bind';
import Button from 'components/Button';

import styles from './BlockUserModal.module.scss';

const cn = classnames.bind(styles);

interface BlockUserProps {
  onSelect: (value: boolean) => void;
  blockedUsername: string;
}

const BlockUserModal = (props: BlockUserProps) => {
  return (
    <div className={cn('modal-content')}>
      <strong>{props.blockedUsername}</strong> will no longer be able to send requests or message you. Are you sure you
      want to block <strong>{props.blockedUsername}</strong>?
      <div className={cn('ft-mt-8')}>
        <Button color="danger" className={cn('ft-mr-4')} onClick={() => props.onSelect(true)}>
          Block
        </Button>
        <Button color="white" onClick={() => props.onSelect(false)}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default BlockUserModal;
