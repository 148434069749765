import React from 'react';
import classnames from 'classnames/bind';
import Button from 'components/Button';
import Icon from 'components/Icon';

import styles from './MessagesModal.module.scss';

const cn = classnames.bind(styles);

interface MessageProps {
  onSelect: (value: boolean) => void;
  type: number;
  messageAmount?: number;
}

const MessagesModal = (props: MessageProps) => {
  return (
    <>
      {props.type === 1 ? (
        <div className={cn('modal-content')}>
          This message will only be deleted on your side. The recipient of the message can still read it. Are you sure
          you want to delete this message?
          <div className={cn('ft-mt-8')}>
            <Button color="danger" className={cn('ft-mr-4')} onClick={() => props.onSelect(true)}>
              Delete
            </Button>
            <Button color="white" onClick={() => props.onSelect(false)}>
              Cancel
            </Button>
          </div>
        </div>
      ) : props.type === 2 ? (
        <div className={cn('modal-content')}>
          Unsending this means that you and the recipient won't be able to read this message anymore. Are you sure you
          want to unsend this message?
          <div className={cn('ft-mt-8')}>
            <Button color="primary" className={cn('ft-mr-4')} onClick={() => props.onSelect(true)}>
              Unsend
            </Button>
            <Button color="white" onClick={() => props.onSelect(false)}>
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <div className={cn('modal-content')}>
          Sending this message will cost you <strong className={cn('is-size-5')}>{props.messageAmount}</strong>{' '}
          <Icon name="fanbucks" size="md" />. Are you sure you want to send this message?
          <div className={cn('ft-mt-8')}>
            <Button color="primary" className={cn('ft-mr-4')} onClick={() => props.onSelect(true)}>
              Send
            </Button>
            <Button color="white" onClick={() => props.onSelect(false)}>
              Cancel
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default MessagesModal;
