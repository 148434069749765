import { ContentType, PostType, ResponseStatus } from 'enums';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import { Feed, Post } from 'types';

import { fetchPostsByMerchatByType, reset } from 'state/reducers/postReducer';
import { playContent } from 'state/reducers/sessionReducer';
import { RootState } from 'state/store';

import defaultVideo from 'assets/images/default-video.png';

import Icon from 'components/Icon';
import Modal from 'components/Modal';
import UnlockModal from 'components/Modal/UnlockModal';
import styles from './Video.module.scss';

const cn = classnames.bind(styles);

interface VideoGroup {
  type?: string;
  name?: string;
  videos?: Post[];
}

const videoTypes = [{ type: 'video', name: '' }];

const Videos = () => {
  const { creator, post, user } = useSelector((state: RootState) => ({
    creator: state.creator,
    post: state.post,
    user: state.user
  }));
  const [unlockPost, setUnlockPost] = useState<Feed | undefined>(undefined);
  const dispatch = useDispatch();

  const [videoGroup, setVideoGroup] = useState<VideoGroup[]>([]);

  const onViewContent = (post: Post) => {
    dispatch(playContent({ contentType: ContentType.Post, post }));
  };

  useEffect(() => {
    dispatch(
      fetchPostsByMerchatByType({
        creatorGuid: creator.creator?.guid as string,
        postType: PostType.Video
      })
    );

    return () => {
      dispatch(reset());
    };
  }, [creator.creator?.guid]);

  useEffect(() => {
    if (post.status === ResponseStatus.Success && post.postType === PostType.Video) {
      setVideoGroup([
        {
          type: post.postType?.toLowerCase(),
          name: videoTypes.find((x) => x.type === post.postType?.toLowerCase())?.name,
          videos: post.posts
        }
      ]);
    }
  }, [post.status]);

  return (
    <div className={cn('videos')}>
      {videoGroup.map((group) => (
        <div className={cn('group-wrapper')} key={group.type}>
          <div className={cn('group')}>
            <div className={cn('section')}>{group.name}</div>
            <div className={cn('video-group')}>
              {group.videos?.map((video) => (
                <div className={cn('video', 'image-wrapper')} key={video.guid}>
                  <div className={cn('thumbnail')} onClick={() => onViewContent(video)}>
                    <img
                      className={cn({
                        blur: video.isLockedForCallingUser && user.user?.guid !== creator.creator?.userGUID
                      })}
                      src={video.featuredImageUrl || defaultVideo}
                    />
                  </div>
                  {video.isLockedForCallingUser && user.user?.guid !== creator.creator?.userGUID && (
                    <div className={cn('frosted')} onClick={() => setUnlockPost(video)}>
                      <Icon name="lock" />
                    </div>
                  )}
                  <div className={cn('ft-mt-1')}>
                    <div className={cn('name')}>{video.name}</div>
                    <div className={cn('views')}>{video.viewCount} views</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
      {unlockPost && (
        <Modal onClose={() => setUnlockPost(undefined)}>
          <UnlockModal feed={unlockPost} />
        </Modal>
      )}
    </div>
  );
};

export default Videos;
