import defaultAvatar1 from 'assets/images/default-avatar-1.png';
import defaultAvatar2 from 'assets/images/default-avatar-2.png';

const avatars = [defaultAvatar1, defaultAvatar2];

export const randomGenerator = (username: string, avatar?: string) => {
  if (avatar) {
    return avatar;
  } else {
    return avatars[generateNumberFromUsername(username, 2)];
  }
};

export const randomNumberGenerator = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const generateNumberFromUsername = (username: string, range: number) => {
  let hash = 0;
  const length = username.length;
  for (let i = 0; i < length; i++) {
    hash = (hash << 5) - hash + username.charCodeAt(i);
    hash |= 0;
  }
  return Math.abs(hash) % range;
};
