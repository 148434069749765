import { httpClient } from 'http/httpClient';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { User, UserResetPassword } from 'types';
import { PendingAction, RejectedAction } from 'state/store';
import { ErrorPayload } from 'types/errorPayload';

export const loginUser = createAsyncThunk('auth/login', async (payload: User, { rejectWithValue }) => {
  payload.platform = 'heyfans';
  try {
    const response = await httpClient.post('/users/login/password', payload);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const passwordReset = createAsyncThunk('auth/passwordReset', async (payload: UserResetPassword) => {
  payload.platform = 'heyfans';
  payload.method = 'email';
  const response = await httpClient.post('/users/password/reset/init', payload);
  return response.data;
});

export const verifyPasswordReset = createAsyncThunk('auth/verifyPasswordReset', async (payload: UserResetPassword) => {
  const response = await httpClient.post('/users/password/reset', payload);
  return response.data;
});

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async (payload: UserResetPassword, { rejectWithValue }) => {
    try {
      const response = await httpClient.post('/users/password/change', payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const registerUser = createAsyncThunk('auth/register', async (payload: User, { rejectWithValue }) => {
  payload.primaryPlatform = 'heyfans';
  try {
    const response = await httpClient.post('/users/register', payload);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const verifyByPhoneNumber = createAsyncThunk(
  'auth/verifyPhoneNumber',
  async (data: { guid: string; code: number | string }, { rejectWithValue }) => {
    try {
      const response = await httpClient.post(`/users/${data.guid}/phonenumber/verify`, {
        verificationCode: data.code
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const resendPhoneVerification = createAsyncThunk('auth/resendVerifyPhoneNumber', async (guid: string) => {
  const response = await httpClient.post(`/users/${guid}/phonenumber/verify/resend`, {});
  return response.data;
});

export const verifyByEmail = createAsyncThunk(
  'auth/verifyEmail',
  async (data: { guid: string; code: number | string }, { rejectWithValue }) => {
    try {
      const response = await httpClient.post(`/users/${data.guid}/email/verify`, {
        verificationCode: data.code
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const resendEmailVerification = createAsyncThunk('auth/resendVerifyEmail', async (guid: string) => {
  const response = await httpClient.post(`/users/${guid}/email/verify/resend`, {});
  return response.data;
});

interface AuthState {
  status: string;
  error?: string;
  authToken?: string;
  isLoggedIn?: boolean;
  isPasswordTokenSent?: boolean;
  isPasswordResetVerified?: boolean;
  isPasswordChanged?: boolean;
  isRegistered?: boolean;
  isVerified?: boolean;
  user?: User;
  errorPayload?: ErrorPayload;
  guid?: string;
  verificationCode?: string;
}

const initialState: AuthState = {
  status: 'idle',
  errorPayload: undefined,
  verificationCode: undefined,
  guid: undefined
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => initialState,
    setUnverifiedAccount: (state, action) => {
      state.guid = action.payload.guid;
      state.isRegistered = action.payload.isRegistered;
      state.isVerified = false;
    },
    resetError: (state) => {
      state.error = undefined;
    },
    resetErrorPayload: (state) => {
      state.error = undefined;
      state.errorPayload = undefined;
    },
    resetPasswordChange: (state) => {
      state.isPasswordChanged = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = 'success';
        state.authToken = action.payload.token;
        state.isLoggedIn = true;
      })
      .addCase(passwordReset.fulfilled, (state) => {
        state.status = 'success';
        state.isPasswordTokenSent = true;
      })
      .addCase(verifyPasswordReset.fulfilled, (state) => {
        state.status = 'success';
        state.isPasswordResetVerified = true;
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.status = 'success';
        state.isPasswordChanged = true;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.status = 'rejected';
        state.isPasswordChanged = false;
        state.errorPayload = action.payload as ErrorPayload;
      })
      .addCase(loginUser.rejected, (state, action) => {
        const errorPayload = action.payload as ErrorPayload;
        state.status = 'rejected';
        state.errorPayload = errorPayload;
        const message = errorPayload.message ? errorPayload.message : 'The username/password you entered is invalid.';
        state.error = message;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.status = 'success';
        state.isRegistered = true;
        state.isVerified = false;
        state.guid = action.payload.guid as string;
      })
      .addCase(verifyByPhoneNumber.fulfilled, (state, action) => {
        state.status = 'success';
        state.isRegistered = true;
        state.isVerified = true;
        state.verificationCode = action.payload.verificationCode;
      })
      .addCase(verifyByEmail.fulfilled, (state, action) => {
        state.status = 'success';
        state.isRegistered = true;
        state.isVerified = true;
        state.verificationCode = action.payload.verificationCode;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.status = 'rejected';
        state.isRegistered = false;
        state.errorPayload = action.payload as ErrorPayload;
      })
      .addMatcher(
        (action): action is PendingAction => action.type.endsWith('/pending'),
        (state) => {
          state.status = 'loading';
        }
      )
      .addMatcher(
        (action): action is RejectedAction => action.type.endsWith('/rejected'),
        (state, action) => {
          state.status = 'rejected';
          if (action.payload) {
            const errorPayload = action.payload as ErrorPayload;
            const message = errorPayload.message
              ? errorPayload.message
              : 'The username/password you entered is invalid.';
            state.error = message;
            state.errorPayload = action.payload as ErrorPayload;
          }
        }
      );
  }
});

export const { logout, setUnverifiedAccount, resetError, resetErrorPayload, resetPasswordChange } = authSlice.actions;
export default authSlice.reducer;
