import React, { useState } from 'react';
import classnames from 'classnames/bind';

import Icon from 'components/Icon';
import styles from './FloatingReactions.module.scss';

const cn = classnames.bind(styles);

interface FloatingReactionsProps {
  right?: number;
  onAnimationEnd?: (value: boolean) => unknown;
}

const FloatingReactions = (props: FloatingReactionsProps) => {
  const [isAnimationFinished, setIsAnimationFinished] = useState<boolean>(false);

  const Reaction = () => (
    <div className={cn('reaction')}>
      <Icon name="heart" size="md" />
    </div>
  );

  const reachedTop = () => {
    setIsAnimationFinished(true);
    if (props.onAnimationEnd) {
      props.onAnimationEnd(true);
    }
  };

  return (
    <React.Fragment>
      {!isAnimationFinished && (
        <div className={cn('reaction-container')} style={{ right: `${props.right}px` }} onAnimationEnd={reachedTop}>
          <Reaction />
        </div>
      )}
    </React.Fragment>
  );
};

export default FloatingReactions;
