import React from 'react';
import classnames from 'classnames/bind';

import styles from './Anchor.module.scss';

const cn = classnames.bind(styles);

interface AnchorProps {
  type?: 'primary' | 'foreground';
  size?: 'sm' | 'md';
  children?: string | string[];
  className?: string;
  onClick?: () => void;
}

const Anchor = (props: AnchorProps) => {
  const handleClick = () => {
    if (props.onClick) props.onClick();
  };

  return (
    <a className={cn('anchor', props.type, { sm: props.size === 'sm' }, props.className)} onClick={handleClick}>
      {props.children}
    </a>
  );
};

export default Anchor;
