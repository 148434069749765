import { httpClient } from 'http/httpClient';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PostType } from 'enums';
import { Post, VideoCallRequest } from 'types';

interface ToastState {
  status: string | undefined;
  toggle: boolean;
  timeout: number;
  type?: string;
  message?: string;
  url?: string;
  liveStream?: Post;
  messageType?: string;
  data?: VideoCallRequest;
}
const initialState: ToastState = {
  status: undefined,
  toggle: false,
  message: '',
  timeout: 4000,
  url: '',
  messageType: undefined
};

export const newLiveStreamNotification = createAsyncThunk(
  'post/newLiveStreamNotification',
  async (postGUID: string) => {
    const response = await httpClient.get('/posts/search', {
      params: {
        postType: PostType.LiveStream,
        guid: postGUID
      }
    });
    return response.data[0];
  }
);

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    toggleToastOpen: (state, action) => {
      state.toggle = true;
      state.url = action.payload.url || undefined;
      state.timeout = action.payload.timeout || 3000;
      state.message = action.payload.message || undefined;
      if (action.payload.messageType) {
        state.messageType = action.payload.messageType;
      }
      if (action.payload.data) {
        state.data = action.payload.data.data || undefined;
      }
    },
    toggleToastClose: (state) => {
      state = initialState;
      return state;
    },
    setToastData: (state, action) => {
      if (action.payload.data) {
        state.data = action.payload.data.data || undefined;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(newLiveStreamNotification.fulfilled, (state, action) => {
      const live = action.payload as Post;
      state.status = 'success';
      state.type = 'NewLiveStream';
      state.toggle = true;
      state.url = action.payload.url || undefined;
      state.timeout = action.payload.timeout || 10000;
      state.message = `${live.merchantName} started new live stream. `;
      state.liveStream = action.payload;
    });
  }
});

export const { toggleToastOpen, toggleToastClose, setToastData } = toastSlice.actions;
export default toastSlice.reducer;
