import React from 'react';
import classnames from 'classnames/bind';

import kudo from 'assets/images/kudo.svg';

import styles from './Icon.module.scss';

const cn = classnames.bind(styles);

interface IconProps {
  name: string;
  type?: 'svg' | 'sym';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  sizePixel?: number;
  className?: string;
}

const iconLocations = [
  {
    name: 'kudo',
    path: kudo
  }
];

const renderIcon = (props: IconProps) => {
  if (props.type === 'svg') {
    return (
      <div className={cn('svg')}>
        <img src={iconLocations.find((x) => x.name === props.name)?.path || ''} />
      </div>
    );
  } else {
    return (
      <span className={cn('icon', props.size, props.className)} style={{ fontSize: props.sizePixel }}>
        <i className={cn('tf', `tf-${props.name}`)} />
      </span>
    );
  }
};

const Icon = (props: IconProps) => {
  return renderIcon(props);
};

export default Icon;
