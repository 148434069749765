import React, { Fragment, FormEvent, KeyboardEvent, useEffect, useRef, useState, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TinyColor } from '@ctrl/tinycolor';
import { useColor } from 'color-thief-react';
import classnames from 'classnames/bind';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import Bowser from 'bowser';

import { resetActiveLiveEvent } from 'state/reducers/liveEventReducer';
import { addChat, fetchLiveStream, fetchPoll, getPollResult, resetComment } from 'state/reducers/liveStreamReducer';
import {
  fetchComments,
  fetchLiveStreamComments,
  likeComment,
  likePost,
  postComment,
  resetPost,
  resetPostComment,
  unlikeComment,
  unlikePost
} from 'state/reducers/postReducer';
import { getPostsFromPostGroup, stopContent, updateContent } from 'state/reducers/sessionReducer';
import { fetchTokens } from 'state/reducers/userReducer';
import { createView } from 'state/reducers/viewReducer';
import { RootState } from 'state/store';

import useVideoPlayer from 'hooks/useVideoPlayer';
import { ContentType, PostType } from 'enums';
import { webSocketService } from 'services';
import { Post, PostComment, TokenBalance } from 'types';
import Button from 'components/Button';
import Comment from 'components/Comment';
import Icon from 'components/Icon';
import Input from 'components/Input';
import Sound, { SoundStatus } from 'components/Sound';
import FloatingReactions from 'components/FloatingReactions';
import LiveStreamPlayer from 'components/LiveStreamPlayer';
import Poll from 'components/Poll';
import { fetchSubscribers, searchSubscriptionsByMerchantGuid } from 'state/reducers/subscriptionReducer';
import { getBadgeList } from 'state/reducers/badgeReducer';
import { Badge } from 'types/badge';
import { toggleSnackbarOpen } from 'state/reducers/snackbarReducer';

import defaultAvatar from 'assets/images/default-avatar-1.png';
import styles from './Player.module.scss';

const cn = classnames.bind(styles);

const googleImgProxyUrl =
  'https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&refresh=2592000&url=';

interface PostCommentReaction extends PostComment {
  right?: number;
}

function getRandomNumber(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

const Player = () => {
  const { liveStream, liveEvent, post, session, user, subscription, badge } = useSelector((state: RootState) => ({
    liveStream: state.liveStream,
    liveEvent: state.liveEvent,
    post: state.post,
    session: state.session,
    user: state.user,
    subscription: state.subscription,
    badge: state.badge
  }));
  const [truBucks, setTruBucks] = useState<TokenBalance | undefined>();

  const [togglePlayback, setTogglePlayback] = useState(SoundStatus.Play);
  const [currentTime, setCurrentTime] = useState(0);
  const [seekerPosition, setSeekerPosition] = useState(0);
  const [isTrubucksPanelOpen, setIsTrubucksPanelOpen] = useState(false);
  const [tipJar, setTipJar] = useState<number | undefined>();
  const [endTipJar, setEndTipJar] = useState<number | undefined>();
  const [isTipSent, setIsTipSent] = useState<boolean>(false);
  const [oneMonthBadge, setOneMonthBadge] = useState<Badge | undefined>();

  const [parentComment, setParentComment] = useState<PostComment | undefined>();
  const [highlightComment, setHighlightComment] = useState<string>();
  const [isSupported, setIsSupported] = useState(true);

  const isScrubbing = useRef(false);
  const [comments, setComments] = useState<PostComment[]>([]);
  const [reactions, setReactions] = useState<PostCommentReaction[]>([]);
  const audioPlayerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const [inputComment, setInputComment] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const color = useColor(session.postGroup?.thumbnailImageUrl || session.post?.thumbnailImageUrl || '', 'hex', {
    crossOrigin: 'anonymous'
  });

  if (session.postGroup?.thumbnailImageUrl) {
    const color = useColor(googleImgProxyUrl + session.postGroup?.thumbnailImageUrl, 'hex', {
      crossOrigin: 'anonymous'
    });

    useEffect(() => {
      if (session.contentType === ContentType.PostGroup) {
        if (color.data) {
          setTimeout(() => {
            const style = audioPlayerRef.current?.style;
            if (style) {
              style.background = `linear-gradient(${color.data}, ${new TinyColor(color.data)
                .brighten(40)
                .toHexString()})`;
            }
          }, 1000);
        }
      }
    }, [color]);
  } else {
    const color = useColor(googleImgProxyUrl + session.post?.normalizedProfileImageUrl, 'hex', {
      crossOrigin: 'anonymous'
    });
    const style = audioPlayerRef.current?.style;
    if (style) {
      style.background = `linear-gradient(${color.data}, ${new TinyColor(color.data).brighten(40).toHexString()})`;
    }
  }

  const theaterRef = useRef<HTMLDivElement>(null);
  const liveChatRef = useRef<HTMLDivElement>(null);
  const [isAutoScroll, setIsAutoScroll] = useState(true);
  const videoElement = useRef(null);
  const playerSeeker = useRef(null);

  const {
    playerState,
    handleOnTimeUpdate,
    handleVideoProgress,
    togglePlay,
    toggleFullscreen,
    handleVolume,
    toggleMute,
    closeFullscreen
  } = useVideoPlayer(videoElement);

  const onClose = () => {
    setTogglePlayback(SoundStatus.Stop);
    setTimeout(() => {
      dispatch(resetPostComment());
      dispatch(stopContent());
      if (session.contentType === ContentType.Post) {
        if (session.post?.postType === PostType.LiveStream) {
          if (!session.post?.contentUrl || session.post.contentUrl === '') {
            dispatch(resetComment());
            setComments([]);
            setReactions([]);
            webSocketService.unsubscribeToLiveStreamChat(session.post.liveStreamGUID || '');
          }
        }
      }
      if (liveEvent.activeLiveEvent) {
        dispatch(resetActiveLiveEvent());
        webSocketService.unsubscribeToLiveStreamChat(liveEvent.activeLiveEvent.guid);
        dispatch(resetComment());
        setComments([]);
        setReactions([]);
      }
    }, 50);
    dispatch(resetPost());
  };

  const noEffectFullscreen = () => {
    if (!playerState.isFullscreen) {
      const theater: HTMLElement | null = document.getElementById('theater');
      if (theater?.requestFullscreen) {
        theater?.requestFullscreen().then((r) => r);
      } else if (theater?.webkitRequestFullscreen) {
        theater?.webkitRequestFullscreen();
      } else if (theater?.webkitEnterFullscreen) {
        theater?.webkitEnterFullscreen();
      } else if (theater?.msRequestFullscreen) {
        theater?.msRequestFullscreen();
      } else if (theater?.mozRequestFullScreen) {
        theater?.mozRequestFullScreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen().catch((r) => r);
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }

    toggleFullscreen();
  };

  useEffect(() => {
    document.addEventListener(
      'fullscreenchange',
      () => {
        if (document.fullscreenElement === null) {
          closeFullscreen();
        }
      },
      false
    );

    return () => {
      setIsSupported(true);
    };
  }, []);

  const onTogglePlayback = () => {
    setTogglePlayback(togglePlayback === SoundStatus.Play ? SoundStatus.Pause : SoundStatus.Play);
  };

  const onCurrentTime = (currentTime: number) => {
    if (!isScrubbing.current) {
      setCurrentTime(currentTime);
      setSeekerPosition(currentTime);
    }
  };

  const onScrub = (event: FormEvent) => {
    setCurrentTime(parseInt((event.target as HTMLInputElement).value));
  };

  const onToggleScrub = (event: FormEvent) => {
    if (event.type === 'mousedown') {
      isScrubbing.current = true;
    } else if (event.type === 'mouseup') {
      isScrubbing.current = false;
      setSeekerPosition(parseInt((event.target as HTMLInputElement).value));
    }
  };

  const onTrackChange = (track: Post) => {
    dispatch(updateContent(track));
    setTogglePlayback(SoundStatus.Play);
  };

  const onTrackSkip = (type: 'back' | 'next') => {
    const index = session.postsInPostGroup?.findIndex((x) => x.guid === session.post?.guid) as number;
    switch (type) {
      case 'back':
        if (index >= 1) {
          return onTrackChange(session.postsInPostGroup?.[index - 1] as Post);
        }
        break;
      case 'next':
        if (index < (session.postsInPostGroup?.length as number) - 1) {
          return onTrackChange(session.postsInPostGroup?.[index + 1] as Post);
        }
        break;
    }
    return setTogglePlayback(SoundStatus.Stop);
  };

  const togglePostLike = (post: Post) => {
    const updatedPost = { ...post };
    if (post.reactionTypeForCallingUser) {
      dispatch(unlikePost(post.guid as string));
      (updatedPost.reactionCount as number) -= 1;
      updatedPost.reactionTypeForCallingUser = undefined;
    } else {
      dispatch(likePost(post.guid as string));
      (updatedPost.reactionCount as number) += 1;
      updatedPost.reactionTypeForCallingUser = 'like';
    }
    dispatch(updateContent(updatedPost));
  };

  const toggleCommentLike = (comment: PostComment) => {
    const newComments = cloneDeep(comments);
    const index = comments.findIndex((x: PostComment) => x.guid === comment.guid);
    if (comments && index !== undefined) {
      const updatedComment = newComments[index];

      if (comment.reactionTypeForCallingUser) {
        dispatch(unlikeComment(comment.guid as string));
        updatedComment.reactionTypeForCallingUser = undefined;
      } else {
        dispatch(likeComment(comment.guid as string));
        updatedComment.reactionTypeForCallingUser = 'like';
      }

      setComments(newComments);
    }
  };

  const onHighlightComment = (comment: PostComment) => {
    setHighlightComment(comment.parentCommentGUID);
    setTimeout(() => {
      setHighlightComment(undefined);
    }, 2000);
  };

  const deleteAnimation = (reactionId: string | undefined) => {
    if (reactions.length > 0) {
      setReactions(reactions.filter((reaction: PostCommentReaction) => reaction.guid !== reactionId));
    }
  };

  const handleChange = (event?: ChangeEvent) => {
    setInputComment((event?.target as HTMLInputElement).value);
  };

  const handleComment = (isClicked: boolean, event?: KeyboardEvent) => {
    if (isClicked || event?.key === 'Enter') {
      let comment: PostComment = {
        userGUID: user.user?.guid,
        postGUID: session.post?.guid,
        message: isClicked ? inputComment : (event?.target as HTMLInputElement).value,
        dateCreated: moment().toDate(),
        normalizedProfileImageUrl: user.user?.normalizedProfileImageUrl,
        userUsername: user.user?.username,
        userDisplayName: user.user?.displayName
      };

      if (parentComment) {
        comment.parentCommentGUID = parentComment.guid;
      }

      if (tipJar) {
        comment = {
          ...comment,
          ...{
            tipTokenProfileKey: 'kudo',
            tipAmount: tipJar
          }
        };
      }

      if ((tipJar as number) > (truBucks?.balance as number)) {
        dispatch(
          toggleSnackbarOpen({
            message: 'Insufficient balance for transfer.',
            type: 'danger',
            timeout: 4000
          })
        );
      } else {
        dispatch(postComment(comment));
      }

      (inputRef.current as HTMLInputElement).value = '';
      setInputComment('');
      if (!isClicked) {
        (event?.target as HTMLInputElement).value = '';
      }
      setParentComment(undefined);
      toggleOpenTipJar(false);
    }
  };

  const toggleStreamLike = (type: string) => {
    const comment: PostComment = {
      userGUID: user.user?.guid,
      liveStreamGUID: liveEvent.activeLiveEvent?.guid ? liveEvent.activeLiveEvent.guid : session.post?.liveStreamGUID,
      reactionType: type
    };
    dispatch(addChat(comment));
  };

  const handleChat = (isClicked: boolean, event?: KeyboardEvent) => {
    event?.stopPropagation();
    let value = !isClicked ? (event?.target as HTMLInputElement).value : '';
    value = value.replace(/^\s+/, '').replace(/\s+$/, '');

    if (isClicked || (event?.key === 'Enter' && value !== '')) {
      let comment: PostComment = {
        userGUID: user.user?.guid,
        liveStreamGUID: liveEvent.activeLiveEvent?.guid ? liveEvent.activeLiveEvent.guid : session.post?.liveStreamGUID,
        message: isClicked ? inputComment : (event?.target as HTMLInputElement).value
      };
      if (tipJar) {
        comment = {
          ...comment,
          ...{
            tipTokenProfileKey: 'kudo',
            tipAmount: tipJar
          }
        };
      }
      dispatch(addChat(comment));
      (inputRef.current as HTMLInputElement).value = '';
      setInputComment('');
      if (!isClicked) {
        (event?.target as HTMLInputElement).value = '';
      }
      toggleOpenTipJar(false);
    }
  };

  const toggleOpenTipJar = (isOpen?: boolean) => {
    const value = isOpen !== undefined ? isOpen : !isTrubucksPanelOpen;
    setIsTrubucksPanelOpen(value);
    dispatch(fetchTokens());
    if (!value) {
      setTimeout(() => {
        setTipJar(undefined);
      }, 500);
    }
  };

  const tipMerchant = (tip: number) => {
    if (tip !== tipJar && hasBalance) {
      setTipJar(tip);
    } else {
      setTipJar(undefined);
    }
  };

  const tipLiveEventEnd = (tip: number) => {
    if (tip !== tipJar && hasBalance) {
      setEndTipJar(tip);
    } else {
      setEndTipJar(undefined);
    }
  };

  const sendTip = () => {
    let liveStreamGUID = '';
    if (liveEvent.activeLiveEvent) {
      liveStreamGUID = liveEvent.activeLiveEvent.guid;
    }
    if (liveStream.live) {
      liveStreamGUID = liveStream.live.guid || '';
    }
    let comment: PostComment = {
      userGUID: user.user?.guid,
      postGUID: session.post?.guid,
      liveStreamGUID,
      message: `${user.user?.displayName} tipped you ${endTipJar} FanBucks.`,
      dateCreated: moment().toDate(),
      normalizedProfileImageUrl: user.user?.normalizedProfileImageUrl,
      userUsername: user.user?.username
    };
    comment = {
      ...comment,
      ...{
        tipTokenProfileKey: 'kudo',
        tipAmount: endTipJar
      }
    };
    dispatch(addChat(comment));
    setIsTipSent(true);
  };

  const incrementViews = (post?: Post) => {
    const view = {
      merchantGUID: post?.merchantGUID,
      postGUID: post?.guid,
      dateViewed: moment().format('MM/DD/YY hh:mm:ss')
    };

    dispatch(createView(view));
  };

  const renderTipPanel = () => {
    return (
      <div className={cn('trubucks-panel', { open: isTrubucksPanelOpen })}>
        <div className={cn('header')}>
          <div>Tip FanBucks</div>
          <Button
            size="sm"
            shape="circle"
            color="clear"
            className={cn('close')}
            onClick={() => toggleOpenTipJar(false)}
            hasIcon>
            <Icon name="close" size="xs" />
          </Button>
        </div>
        <div className={cn('ft-mt-2')}>
          <ul className={cn('predefined')}>
            <li>
              <Button
                color={tipJar === 100 ? 'primary' : 'secondary'}
                className={cn('cost')}
                onClick={() => tipMerchant(100)}>
                100 <Icon name="fanbucks" size="sm" />
              </Button>
            </li>
            <li>
              <Button
                color={tipJar === 200 ? 'primary' : 'secondary'}
                className={cn('cost')}
                onClick={() => tipMerchant(200)}>
                200 <Icon name="fanbucks" size="sm" />
              </Button>
            </li>
            <li>
              <Button
                color={tipJar === 500 ? 'primary' : 'secondary'}
                className={cn('cost')}
                onClick={() => tipMerchant(500)}>
                500 <Icon name="fanbucks" size="sm" />
              </Button>
            </li>
            <li>
              <Button
                color={tipJar === 1000 ? 'primary' : 'secondary'}
                className={cn('cost')}
                onClick={() => tipMerchant(1000)}>
                1000 <Icon name="fanbucks" size="sm" />
              </Button>
            </li>
            <li>
              <Button
                color={tipJar === 2000 ? 'primary' : 'secondary'}
                className={cn('cost')}
                onClick={() => tipMerchant(2000)}>
                2000 <Icon name="fanbucks" size="sm" />
              </Button>
            </li>
            <li>
              <Button
                color={tipJar === 5000 ? 'primary' : 'secondary'}
                className={cn('cost')}
                onClick={() => tipMerchant(5000)}>
                5000 <Icon name="fanbucks" size="sm" />
              </Button>
            </li>
          </ul>
        </div>
        <div className={cn('confirmation')}>
          {tipJar && hasBalance ? (
            <div className={cn('post')}>
              <div className={cn('is-size-8')}>You are about to tip</div>
              <div className={cn('has-text-weight-semibold', 'is-size-2', 'ft-mb-2')}>
                {tipJar} <Icon name="fanbucks" size="lg" />
              </div>
              <div className={cn('is-size-8')}>Your tip will be sent with your message</div>
            </div>
          ) : (
            <div>
              {hasBalance
                ? 'Select an amount above to tip'
                : 'Insufficient balance. Please add FanBucks to your wallet.'}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderPlayer = () => {
    return (
      <>
        {session.post && (
          <div className={cn('media', 'audio')} ref={audioPlayerRef}>
            <div className={cn('center-media')}>
              <div className={cn('cover-art')}>
                <img
                  src={
                    session.postGroup?.thumbnailImageUrl ||
                    session.post?.thumbnailImageUrl ||
                    session.post?.normalizedProfileImageUrl
                  }
                />
              </div>
              <div className={cn('playback')}>
                <div className={cn('title')}>
                  <div className={cn('song')}>{session.post?.name}</div>
                  <div className={cn('album')}>{session.postGroup?.name}</div>
                </div>
                <div>
                  <div className={cn('seeker')}>
                    <progress className={cn('progress')} value={currentTime} max={session.post?.durationInSeconds} />
                    <input
                      type="range"
                      className={cn('playhead')}
                      min={0}
                      max={session.post?.durationInSeconds}
                      value={currentTime}
                      onInput={onScrub}
                      onMouseDown={onToggleScrub}
                      onMouseUp={onToggleScrub}
                    />
                    <Sound
                      url={session.post?.contentUrl}
                      playStatus={togglePlayback}
                      playFromPosition={seekerPosition}
                      onPlaying={(e) => onCurrentTime(e)}
                      onEnded={() => {
                        onTrackSkip('next');
                        incrementViews(session.post);
                      }}
                    />
                  </div>
                  <div className={cn('flexbox', 'timers', 'ft-mt-1')}>
                    <div>{moment(currentTime * 1000).format('m:ss')}</div>
                    <div className={cn('ft-ml-auto')}>
                      {moment((session.post?.durationInSeconds || 0) * 1000).format('m:ss')}
                    </div>
                  </div>
                  <div className={cn('flexbox', 'controls', 'ft-mt-2')}>
                    <div className={cn('button', 'ft-ml-auto')} onClick={() => onTrackSkip('back')}>
                      <Icon name="backward" />
                    </div>
                    <div className={cn('button', 'play-pause')} onClick={onTogglePlayback}>
                      <Icon name={togglePlayback === SoundStatus.Play ? 'pause' : 'play'} size="lg" />
                    </div>
                    <div className={cn('button', 'ft-mr-auto')} onClick={() => onTrackSkip('next')}>
                      <Icon name="forward" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    if (post.comments?.length) {
      setComments([...post.comments].reverse());
    }
  }, [post.comments]);

  useEffect(() => {
    if (session.post?.postType === PostType.Audio) {
      if (color.data) {
        const style = audioPlayerRef.current?.style;
        if (style) {
          style.background = `linear-gradient(${color.data}, ${new TinyColor(color.data).brighten(40).toHexString()})`;
        }
      }
    }
  }, [color]);

  useEffect(() => {
    if (session.postsInPostGroup) {
      if (!session.post) {
        dispatch(updateContent(session.postsInPostGroup[0]));
      }
    }
  }, [session.postsInPostGroup]);

  useEffect(() => {
    if (liveStream && liveStream.newComment) {
      const newComment = liveStream.newComment;
      if (newComment.reactionType) {
        setReactions([
          ...reactions,
          {
            ...newComment,
            right: getRandomNumber(40, 100)
          }
        ]);
      } else {
        setComments([
          ...comments,
          {
            ...newComment
          }
        ]);
      }
    }
  }, [liveStream.newComment]);

  useEffect(() => {
    scrollToBottom();
  }, [comments]);

  useEffect(() => {
    dispatch(getPollResult(liveStream.poll?.guid || ''));
  }, [liveStream.poll]);

  useEffect(() => {
    dispatch(resetPostComment());
    dispatch(getBadgeList());

    if (session.post?.merchantGUID) {
      dispatch(searchSubscriptionsByMerchantGuid(session.post?.merchantGUID as string));
    }

    if (session.contentType === ContentType.Post) {
      if (session.post?.postType === PostType.LiveStream) {
        dispatch(fetchTokens());
        if (session.post.liveStreamStatus === 'Active') {
          dispatch(fetchLiveStream(session.post.guid || ''));
          webSocketService.subscribeToLiveStreamChat(session.post.liveStreamGUID || '');
          dispatch(fetchLiveStreamComments(session.post?.liveStreamGUID || ''));
          dispatch(fetchPoll(session.post?.liveStreamGUID || ''));
        } else {
          if (session.post) {
            dispatch(fetchLiveStreamComments(session.post?.liveStreamGUID || ''));
            dispatch(fetchPoll(session.post?.liveStreamGUID || liveEvent.activeLiveEvent?.guid || ''));
          }
        }
      } else if (session.post?.postType === PostType.LiveEvent) {
        dispatch(fetchLiveStreamComments(session.post?.liveStreamGUID || ''));
        dispatch(fetchPoll(session.post?.liveStreamGUID || ''));
      } else if (session.post?.postType) {
        dispatch(fetchComments(session.post?.guid || ''));
        dispatch(fetchPoll(session.post?.liveStreamGUID || ''));
      }
    } else if (session.contentType === ContentType.PostGroup) {
      dispatch(getPostsFromPostGroup(session.postGroup?.guid || ''));
    }
  }, [session.post]);

  useEffect(() => {
    if (liveEvent.activeLiveEvent) {
      dispatch(fetchTokens());
      webSocketService.subscribeToLiveStreamChat(liveEvent.activeLiveEvent.guid || '');
      dispatch(fetchLiveStreamComments(liveEvent.activeLiveEvent?.guid || ''));
      dispatch(fetchPoll(liveEvent.activeLiveEvent?.guid || ''));
    }
  }, [liveEvent.activeLiveEvent]);

  useEffect(() => {
    if (user.balance) {
      setTruBucks(user.balance.find((x) => x.tetheredMerchantGUID === undefined));
    }
  }, [user.balance]);

  useEffect(() => {
    if (subscription.subscriptionsByCreator && subscription.subscriptionsByCreator.find((x) => x.isPublished)) {
      dispatch(fetchSubscribers(subscription.subscriptionsByCreator.find((x) => x.isPublished)?.guid as string));
    }
  }, [subscription.subscriptionsByCreator]);

  useEffect(() => {
    if (badge.badgeList) {
      setOneMonthBadge(badge.badgeList.find((x) => x.merchantGUID === session.post?.merchantGUID));
    }
  }, [badge.badgeList]);

  const scrollToBottom = () => {
    if (isAutoScroll) {
      if (liveChatRef.current) {
        liveChatRef.current.scroll({
          top: liveChatRef.current.scrollHeight,
          left: 0,
          behavior: 'smooth'
        });
      }
    }
  };

  const isUserNearBottom = () => {
    if (liveChatRef.current) {
      const threshold = 150;
      const position = liveChatRef.current.scrollTop + liveChatRef.current.offsetHeight;
      const height = liveChatRef.current.scrollHeight;
      return position > height - threshold;
    } else {
      return false;
    }
  };

  const onChatScroll = () => {
    setIsAutoScroll(isUserNearBottom());
  };
  const hasBalance = (truBucks?.balance || 0) > 10;

  useEffect(() => {
    if (session.post?.contentUrl) {
      if (
        Bowser.getParser(window.navigator.userAgent).getBrowserName(true) !== 'safari' &&
        session.post?.contentUrl.split('.').pop()?.toLowerCase() === 'mov'
      ) {
        setIsSupported(false);
      }
    }
  }, [session.post?.contentUrl]);

  return (
    <div className={cn('layout')}>
      <div
        id="theater"
        className={cn('theater', { 'mobile-video-message': session.contentType === ContentType.VideoMessage })}
        ref={theaterRef}>
        {session.contentType === ContentType.Post && (
          <>
            {session.post?.postType === PostType.Audio && renderPlayer()}
            {session.post?.postType === PostType.Video &&
              (session.post?.videoUrl === undefined || session.post.videoUrl === '') && (
                <>
                  {isSupported ? (
                    <video
                      autoPlay
                      // controls
                      controlsList="nodownload"
                      className={cn('media')}
                      src={session.post?.contentUrl}
                      onEnded={() => incrementViews(session.post)}
                      ref={videoElement}
                      onTimeUpdate={handleOnTimeUpdate}
                      onClick={togglePlay}
                    />
                  ) : (
                    <div className={cn('center-content')}>
                      <div>Your browser does not support playback of this video format.</div>
                    </div>
                  )}

                  <div className={cn('player-controls')}>
                    {/* <div className={cn('track')} style={{width:`${playerState.progress}%`}}></div> */}
                    <div className={cn('player-seeker')} ref={playerSeeker}>
                      <div className={cn('track-progress')} style={{ width: `${playerState.progress}%` }} />
                    </div>
                    <div className={cn('actions', 'flexbox')}>
                      <button className={cn('play-pause')} onClick={togglePlay}>
                        {!playerState.isPlaying ? (
                          <i className={cn('tf tf-play')} />
                        ) : (
                          <i className={cn('tf tf-pause')} />
                        )}
                      </button>
                      <div className={cn('volume-wrapper')}>
                        <button className={cn('volume-toggle')} onClick={toggleMute}>
                          {!playerState.isMuted && playerState.volume >= 50 ? (
                            <i className={cn('tf tf-volume-high')} />
                          ) : !playerState.isMuted && playerState.volume < 50 ? (
                            <i className={cn('tf tf-volume-low')} />
                          ) : (
                            <i className={cn('tf tf-volume-mute')} />
                          )}
                        </button>
                        <div className={cn('volume-container')}>
                          <div
                            className={cn('volume-level')}
                            style={{
                              width: !playerState.isMuted
                                ? `calc(${130 * (playerState.volume / 100)}px -  ${(10 * playerState.volume) / 100}px)`
                                : 0
                            }}
                          />
                          <input
                            className={cn('volume-control')}
                            type="range"
                            min="0"
                            max="100"
                            value={!playerState.isMuted ? playerState.volume : 0}
                            onChange={(e) => handleVolume(e)}
                          />
                        </div>
                      </div>
                      <span className={cn('duration')}>{`${moment(playerState.currentTime * 1000).format(
                        'm:ss'
                      )} / ${moment((playerState.duration || 0) * 1000).format('m:ss')}`}</span>
                    </div>
                    <div className={cn('flexbox', 'fullscreen')}>
                      <button className={cn('full-screen', 'flex-end')} onClick={noEffectFullscreen}>
                        {!playerState.isFullscreen ? <Icon name="expand" /> : <Icon name="contract" />}
                      </button>
                    </div>
                    <input
                      className={cn('input-progress')}
                      type="range"
                      min="0"
                      max="100"
                      value={playerState.progress}
                      style={{ width: 'calc(100% - 28px)' }}
                      onChange={(e) => handleVideoProgress(e)}
                    />
                  </div>
                </>
              )}
            {session.post?.postType === PostType.Video && session.post?.videoUrl && (
              <>
                <iframe className={cn('youtubeIframe')} width="100vw" height="100vh" src={session.post.videoUrl}>
                  {' '}
                </iframe>
              </>
            )}
            {(session.post?.postType === PostType.LiveStream || session.post?.postType === PostType.LiveEvent) && (
              <Fragment>
                {session.post?.contentUrl && session.post?.liveStreamStatus === 'Completed' ? (
                  <video controls autoPlay muted className={cn('media')} src={session?.post.contentUrl} />
                ) : (
                  <>
                    {liveStream.poll && <Poll />}
                    {liveStream.live?.status && liveStream.live.status === 'Active' ? (
                      <LiveStreamPlayer src={liveStream.live?.playbackUrl || 'End?'} />
                    ) : session.post && liveStream.live === undefined ? (
                      <div className={cn('stream-status')}>
                        <div className={cn('has-text-weight-bold is-size-5')}>{session.post?.merchantName}</div>
                        <img
                          className={cn('avatar', 'large', 'ft-mt-2', 'ft-mb-2')}
                          src={session.post?.normalizedProfileImageUrl}
                        />
                        <div className={cn('has-text-weight-bold is-size-5')}>will be live shortly...</div>
                      </div>
                    ) : liveStream.live?.status === 'Finished' || liveStream.live?.status === undefined ? (
                      <div className={cn('stream-status')}>
                        <div className={cn('has-text-weight-bold is-size-5')}>This live stream has ended.</div>
                        {isTipSent ? (
                          <>
                            <div className={cn('mt-5')}>
                              <div className={cn('has-text-weight-bold is-size-6')}>Thank you for tipping!</div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className={cn('has-text-weight-bold is-size-6')}>
                              If you enjoyed the show, please tip {session.post?.merchantName} some FanBucks to show
                              your support.
                            </div>
                            <div className={cn('ft-mt-2')}>
                              <ul className={cn('predefined', 'tipbox')}>
                                <li>
                                  <Button
                                    color={endTipJar === 100 ? 'primary' : 'secondary'}
                                    className={cn('cost')}
                                    onClick={() => tipLiveEventEnd(100)}>
                                    100 <Icon name="fanbucks" size="sm" />
                                  </Button>
                                </li>
                                <li>
                                  <Button
                                    color={endTipJar === 200 ? 'primary' : 'secondary'}
                                    className={cn('cost')}
                                    onClick={() => tipLiveEventEnd(200)}>
                                    200 <Icon name="fanbucks" size="sm" />
                                  </Button>
                                </li>
                                <li>
                                  <Button
                                    color={endTipJar === 500 ? 'primary' : 'secondary'}
                                    className={cn('cost')}
                                    onClick={() => tipLiveEventEnd(500)}>
                                    500 <Icon name="fanbucks" size="sm" />
                                  </Button>
                                </li>
                                <li>
                                  <Button
                                    color={endTipJar === 1000 ? 'primary' : 'secondary'}
                                    className={cn('cost')}
                                    onClick={() => tipLiveEventEnd(1000)}>
                                    1000 <Icon name="fanbucks" size="sm" />
                                  </Button>
                                </li>
                                <li>
                                  <Button
                                    color={endTipJar === 2000 ? 'primary' : 'secondary'}
                                    className={cn('cost')}
                                    onClick={() => tipLiveEventEnd(2000)}>
                                    2000 <Icon name="fanbucks" size="sm" />
                                  </Button>
                                </li>
                                <li>
                                  <Button
                                    color={endTipJar === 5000 ? 'primary' : 'secondary'}
                                    className={cn('cost')}
                                    onClick={() => tipLiveEventEnd(5000)}>
                                    5000 <Icon name="fanbucks" size="sm" />
                                  </Button>
                                </li>
                              </ul>
                            </div>
                            {endTipJar && hasBalance ? (
                              <>
                                <div className={cn('ft-mt-2')}>
                                  <Button onClick={() => sendTip()}>Send Tip</Button>
                                </div>
                              </>
                            ) : (
                              <div>
                                {hasBalance
                                  ? 'Select an amount above to tip'
                                  : 'Insufficient balance. Please add FanBucks to your wallet.'}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                )}

                {reactions.length > 0 &&
                  reactions.map((rea: PostCommentReaction) => (
                    <FloatingReactions
                      key={rea.guid}
                      right={rea.right}
                      onAnimationEnd={() => deleteAnimation(rea.guid)}
                    />
                  ))}
              </Fragment>
            )}
            {liveEvent.activeLiveEvent && (
              <Fragment>
                <>
                  {liveStream.poll && <Poll />}
                  {liveEvent.activeLiveEvent.status === 'Active' ? (
                    <LiveStreamPlayer src={liveEvent.activeLiveEvent?.playbackUrl || ''} />
                  ) : liveEvent.activeLiveEvent.status === 'Finished' ||
                    liveEvent.activeLiveEvent.status === undefined ? (
                    <div className={cn('stream-status')}>
                      <div className={cn('has-text-weight-bold is-size-5')}>This live stream has ended.</div>

                      {isTipSent ? (
                        <>
                          <div className={cn('mt-5')}>
                            <div className={cn('has-text-weight-bold is-size-6')}>Thank you for tipping!</div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={cn('has-text-weight-bold is-size-6')}>
                            If you enjoyed the show, please tip {session.post?.merchantName} some FanBucks to show your
                            support.
                          </div>
                          <div className={cn('ft-mt-2')}>
                            <ul className={cn('predefined', 'tipbox')}>
                              <li>
                                <Button
                                  color={endTipJar === 100 ? 'primary' : 'secondary'}
                                  className={cn('cost')}
                                  onClick={() => tipLiveEventEnd(100)}>
                                  100 <Icon name="fanbucks" size="sm" />
                                </Button>
                              </li>
                              <li>
                                <Button
                                  color={endTipJar === 200 ? 'primary' : 'secondary'}
                                  className={cn('cost')}
                                  onClick={() => tipLiveEventEnd(200)}>
                                  200 <Icon name="fanbucks" size="sm" />
                                </Button>
                              </li>
                              <li>
                                <Button
                                  color={endTipJar === 500 ? 'primary' : 'secondary'}
                                  className={cn('cost')}
                                  onClick={() => tipLiveEventEnd(500)}>
                                  500 <Icon name="fanbucks" size="sm" />
                                </Button>
                              </li>
                              <li>
                                <Button
                                  color={endTipJar === 1000 ? 'primary' : 'secondary'}
                                  className={cn('cost')}
                                  onClick={() => tipLiveEventEnd(1000)}>
                                  1000 <Icon name="fanbucks" size="sm" />
                                </Button>
                              </li>
                              <li>
                                <Button
                                  color={endTipJar === 2000 ? 'primary' : 'secondary'}
                                  className={cn('cost')}
                                  onClick={() => tipLiveEventEnd(2000)}>
                                  2000 <Icon name="fanbucks" size="sm" />
                                </Button>
                              </li>
                              <li>
                                <Button
                                  color={endTipJar === 5000 ? 'primary' : 'secondary'}
                                  className={cn('cost')}
                                  onClick={() => tipLiveEventEnd(5000)}>
                                  5000 <Icon name="fanbucks" size="sm" />
                                </Button>
                              </li>
                            </ul>
                          </div>
                          {endTipJar && hasBalance ? (
                            <div className={cn('ft-mt-2')}>
                              <Button onClick={() => sendTip()}>Send Tip</Button>
                            </div>
                          ) : (
                            <div>
                              {hasBalance
                                ? 'Select an amount above to tip'
                                : 'Insufficient balance. Please add FanBucks to your wallet.'}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  ) : (
                    // <div className={cn('stream-status')}>
                    //   <div className={cn('has-text-weight-bold is-size-5')}>
                    //     Live stream starting soon. Please wait...
                    //   </div>
                    // </div>
                    <div className={cn('stream-status')}>
                      <div className={cn('has-text-weight-bold is-size-5')}>{session.post?.merchantName}</div>
                      <img
                        className={cn('avatar', 'large', 'ft-mt-2', 'ft-mb-2')}
                        src={session.post?.normalizedProfileImageUrl}
                      />
                      <div className={cn('has-text-weight-bold is-size-5')}>will be live shortly...</div>
                    </div>
                  )}
                </>

                {reactions.length > 0 &&
                  reactions.map((rea: PostCommentReaction) => (
                    <FloatingReactions
                      key={rea.guid}
                      right={rea.right}
                      onAnimationEnd={() => deleteAnimation(rea.guid)}
                    />
                  ))}
              </Fragment>
            )}
            {session.post?.postType === PostType.Image && (
              <img className={cn('media', 'image')} src={session.post?.contentUrl} />
            )}
          </>
        )}
        {session.contentType === ContentType.PostGroup && renderPlayer()}
        {session.contentType === ContentType.VideoMessage && (
          <video autoPlay controls className={cn('media', 'request')} src={session.videoMessage?.contentUrl} />
        )}
      </div>
      {session.contentType !== ContentType.VideoMessage &&
      liveEvent.activeLiveEvent === undefined &&
      post.liveStream === undefined ? (
        <div className={cn('siderail')}>
          <>
            <div className={cn('controls', 'ft-mb-2')}>
              <div>
                {/*session.post?.postType === PostType.Video && (
                  <Button size="sm" className={cn('dark')}>
                    See related videos
                  </Button>
                )*/}
                {session.post?.postType === PostType.LiveStream && (
                  <div>
                    <div>{session.post.merchantName} live stream</div>
                    <div className={cn('moment')}>
                      Started {moment(session.post?.dateCreated).fromNow()} ● {liveStream.activeViewCount || 0} viewers
                    </div>
                  </div>
                )}
                {session.post?.postType === PostType.LiveEvent && (
                  <div>
                    <div>{session.post.merchantName} live event</div>
                    <div className={cn('moment')}>
                      Started {moment(session.post?.dateCreated).fromNow()} ● {liveStream.activeViewCount || 0} viewers
                    </div>
                  </div>
                )}
              </div>
            </div>
            {(session.post?.postType === PostType.Image || session.post?.postType === PostType.Video) && (
              <>
                <div>{session.post.postType === PostType.Video ? session.post?.name : session.post?.description}</div>
                <div className={cn('moment')}>Posted {moment(session.post?.dateCreated).fromNow()}</div>
              </>
            )}
            {session.post?.postType === PostType.Audio && (
              <div className={cn('audio')}>
                <div className={cn('album')}>
                  {session.contentType === ContentType.PostGroup ? session.postGroup?.name : session.post.name}
                </div>
                {session.postsInPostGroup && (
                  <div className={cn('tracks')}>
                    <table>
                      <tbody>
                        {session.postsInPostGroup?.map((track, index) => (
                          <tr
                            className={cn('track', { selected: track.guid === session.post?.guid })}
                            key={track.guid}
                            onClick={() => onTrackChange(track)}>
                            <td>
                              {track.guid === session.post?.guid && (
                                <div className={cn('is-track-playing')}>
                                  <Icon name="play" />
                                </div>
                              )}
                              {track.guid !== session.post?.guid && index + 1}
                            </td>
                            <td className={cn('track-name')}>{track.name}</td>
                            <td>{moment((track.durationInSeconds || 0) * 1000).format('m:ss')}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            )}
            {session.post?.postType &&
              session.post?.postType !== PostType.LiveStream &&
              session.post?.postType !== PostType.LiveEvent && (
                <div
                  className={cn('flexbox', 'engagement', 'ft-mt-2', {
                    highlight: session.post?.reactionTypeForCallingUser
                  })}>
                  <div className={cn('flexbox')} onClick={() => togglePostLike(session.post as Post)}>
                    <span className={cn('icon')}>
                      <Icon name="heart" size="sm" />
                    </span>
                    <span className={cn('count')}>{session.post?.reactionCount}</span>
                  </div>
                </div>
              )}
            {session.post?.postType === PostType.LiveStream || session.post?.postType === PostType.LiveEvent ? (
              <div className={cn('live-chat')}>
                <div className={cn('section', 'ft-mb-2')}>Live Chat</div>
                <div className={cn('chat-list-wrapper')} ref={liveChatRef}>
                  <div className={cn('chat-list')} onScroll={onChatScroll}>
                    {!session.post?.contentUrl &&
                      comments
                        ?.filter((x) => !x.reactionType)
                        .map((comment) => (
                          <Comment
                            key={comment.guid}
                            comment={comment}
                            type={comment.tipAmount ? 'tip' : 'chat'}
                            badge={
                              subscription.subscribers?.find(
                                (x) => x.userDisplayName === comment.userDisplayName && x.status === 'Active'
                              )
                                ? oneMonthBadge
                                : undefined
                            }
                          />
                        ))}

                    {session.post?.contentUrl &&
                      comments
                        ?.filter((x) => !x.reactionType)
                        .map((comment) => (
                          <Comment
                            key={comment.guid}
                            comment={comment}
                            type={comment.tipAmount ? 'tip' : 'chat'}
                            badge={
                              subscription.subscribers?.find(
                                (x) => x.userDisplayName === comment.userDisplayName && x.status === 'Active'
                              )
                                ? oneMonthBadge
                                : undefined
                            }
                          />
                        ))}
                  </div>
                </div>
                <Input
                  inputRef={inputRef}
                  name="comment"
                  className={cn('comment')}
                  iconLeft={
                    <img className={cn('avatar')} src={user.user?.normalizedProfileImageUrl || defaultAvatar} />
                  }
                  iconRightSecondary={
                    <div className={cn('flexbox')}>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => handleChat(true)}
                        disabled={inputComment !== '' ? false : true}>
                        Send
                      </Button>
                      <div onClick={() => toggleStreamLike('Love')}>
                        <Icon name="heart" className={cn('trubucks', 'ft-ml-2')} />
                      </div>
                    </div>
                  }
                  iconRight={
                    <div onClick={() => toggleOpenTipJar()}>
                      <Icon name="fanbucks" className={cn('trubucks')} />
                    </div>
                  }
                  placeholder="Add your message"
                  onKeyUp={(event) => handleChat(false, event)}
                  autoComplete="off"
                  onChange={handleChange}
                />
                {renderTipPanel()}
              </div>
            ) : (
              <div className={cn('comments', { 'comments-audio': session.post?.postType === PostType.Audio })}>
                <div className={cn('section', 'ft-mb-2')}>Comments • {session.post?.commentCount}</div>
                <div className={cn('comment-list')}>
                  {comments.map((comment) => (
                    <Comment
                      key={comment.guid}
                      comment={comment}
                      parentComment={comments.find((x) => x.guid === comment.parentCommentGUID)}
                      highlightComment={highlightComment}
                      type={comment.tipAmount ? 'tip' : 'comment'}
                      onToggleCommentLike={() => toggleCommentLike(comment)}
                      onReply={() => setParentComment(comment)}
                      onHighlight={() => onHighlightComment(comment)}
                      badge={
                        subscription.subscribers?.find(
                          (x) => x.userDisplayName === comment.userDisplayName && x.status === 'Active'
                        )
                          ? oneMonthBadge
                          : undefined
                      }
                    />
                  ))}
                </div>
                <div className={cn('comment-input', 'ft-mr-4')}>
                  {parentComment && <div className={cn('replying')}>Replying to {parentComment?.userUsername}...</div>}
                  <Input
                    inputRef={inputRef}
                    name="comment"
                    className={cn('comment')}
                    iconLeft={
                      <img className={cn('avatar')} src={user.user?.normalizedProfileImageUrl || defaultAvatar} />
                    }
                    iconRight={
                      <div className={cn('flexbox')}>
                        <Button
                          color="primary"
                          size="sm"
                          onClick={() => handleComment(true)}
                          disabled={inputComment !== '' ? false : true}>
                          Send
                        </Button>
                        <div onClick={() => toggleOpenTipJar()}>
                          <Icon name="fanbucks" className={cn('trubucks', 'ft-ml-2')} />
                        </div>
                      </div>
                    }
                    placeholder="Add your comment"
                    autoComplete="off"
                    onKeyUp={(event) => handleComment(false, event)}
                    onChange={handleChange}
                  />
                  {renderTipPanel()}
                </div>
              </div>
            )}
          </>
        </div>
      ) : (
        (liveEvent.activeLiveEvent || post.liveStream) &&
        session.post && (
          <div className={cn('siderail')}>
            <>
              <div className={cn('controls', 'ft-mb-2')}>
                <div>
                  {/*session.post?.postType === PostType.Video && (
                    <Button size="sm" className={cn('dark')}>
                      See related videos
                    </Button>
                  )*/}
                  {session.post?.postType === PostType.LiveStream && (
                    <div>
                      <div>{session.post.merchantName} live stream</div>
                      <div className={cn('moment')}>
                        Started {moment(session.post?.dateCreated).fromNow()} ● {liveStream.activeViewCount || 0}{' '}
                        viewers
                      </div>
                    </div>
                  )}
                  {session.post?.postType === PostType.LiveEvent && (
                    <div>
                      <div>{session.post.merchantName} live event</div>
                      <div className={cn('moment')}>
                        Started {moment(session.post?.dateCreated).fromNow()} ● {liveStream.activeViewCount || 0}{' '}
                        viewers
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {(session.post?.postType === PostType.Image || session.post?.postType === PostType.Video) && (
                <>
                  <div>{session.post.postType === PostType.Video ? session.post?.name : session.post?.description}</div>
                  <div className={cn('moment')}>Posted {moment(session.post?.dateCreated).fromNow()}</div>
                </>
              )}
              {session.post?.postType === PostType.Audio && (
                <div className={cn('audio')}>
                  <div className={cn('album')}>
                    {session.contentType === ContentType.PostGroup ? session.postGroup?.name : session.post.name}
                  </div>
                  {session.postsInPostGroup && (
                    <div className={cn('tracks')}>
                      <table>
                        <tbody>
                          {session.postsInPostGroup?.map((track, index) => (
                            <tr
                              className={cn('track', { selected: track.guid === session.post?.guid })}
                              key={track.guid}
                              onClick={() => onTrackChange(track)}>
                              <td>
                                {track.guid === session.post?.guid && (
                                  <div className={cn('is-track-playing')}>
                                    <Icon name="play" />
                                  </div>
                                )}
                                {track.guid !== session.post?.guid && index + 1}
                              </td>
                              <td className={cn('track-name')}>{track.name}</td>
                              <td>{moment((track.durationInSeconds || 0) * 1000).format('m:ss')}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {session.post?.postType &&
                session.post?.postType !== PostType.LiveStream &&
                session.post.postType !== PostType.LiveEvent && (
                  <div
                    className={cn('flexbox', 'engagement', 'ft-mt-2', {
                      highlight: session.post?.reactionTypeForCallingUser
                    })}>
                    <div className={cn('flexbox')} onClick={() => togglePostLike(session.post as Post)}>
                      <span className={cn('icon')}>
                        <Icon name="heart" size="sm" />
                      </span>
                      <span className={cn('count')}>{session.post?.reactionCount}</span>
                    </div>
                  </div>
                )}
              {/* // Live Event */}
              <div className={cn('live-chat')}>
                <div className={cn('section', 'ft-mb-2')}>Live Chat</div>
                <div className={cn('chat-list-wrapper')} ref={liveChatRef}>
                  <div className={cn('chat-list')} onScroll={onChatScroll}>
                    {!session.post?.contentUrl &&
                      comments
                        ?.filter((x) => !x.reactionType)
                        .map((comment) => (
                          <Comment
                            key={comment.guid}
                            comment={comment}
                            type={comment.tipAmount ? 'tip' : 'chat'}
                            badge={
                              subscription.subscribers?.find(
                                (x) => x.userDisplayName === comment.userDisplayName && x.status === 'Active'
                              )
                                ? oneMonthBadge
                                : undefined
                            }
                          />
                        ))}

                    {session.post?.contentUrl &&
                      comments
                        ?.filter((x) => !x.reactionType)
                        .map((comment) => (
                          <Comment
                            key={comment.guid}
                            comment={comment}
                            type={comment.tipAmount ? 'tip' : 'chat'}
                            badge={
                              subscription.subscribers?.find(
                                (x) => x.userDisplayName === comment.userDisplayName && x.status === 'Active'
                              )
                                ? oneMonthBadge
                                : undefined
                            }
                          />
                        ))}
                  </div>
                </div>
                <Input
                  inputRef={inputRef}
                  name="comment"
                  className={cn('comment')}
                  iconLeft={
                    <img className={cn('avatar')} src={user.user?.normalizedProfileImageUrl || defaultAvatar} />
                  }
                  iconRightSecondary={
                    <div className={cn('flexbox')}>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => handleChat(true)}
                        disabled={inputComment !== '' ? false : true}>
                        Send
                      </Button>
                      <div onClick={() => toggleStreamLike('Love')}>
                        <Icon name="heart" className={cn('trubucks', 'ft-ml-2')} />
                      </div>
                    </div>
                  }
                  iconRight={
                    <div onClick={() => toggleOpenTipJar()}>
                      <Icon name="fanbucks" className={cn('trubucks')} />
                    </div>
                  }
                  placeholder="Add your message"
                  onKeyUp={(event) => handleChat(false, event)}
                  onChange={handleChange}
                />
                {renderTipPanel()}
              </div>
            </>
          </div>
        )
      )}
      <div className={cn('close-player')}>
        <Button size="sm" shape="circle" className={cn('dark')} onClick={onClose} hasIcon>
          <Icon name="close" size="xs" />
        </Button>
      </div>
    </div>
  );
};

export default Player;
