import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';

import { useDispatch, useSelector } from 'react-redux';
import {
  fetchUserFollowing,
  followUser,
  resetViewUser,
  searchBlockers,
  unfollowUser
} from 'state/reducers/userReducer';
import { RootState } from 'state/store';
import defaultAvatar from 'assets/images/default-avatar-1.png';

import Button from 'components/Button';
import { UserFollower, UserFollowing } from 'types';
import { clone } from 'lodash';
import BlankPage from 'components/BlankPage';
import { useHistory } from 'react-router-dom';
import styles from './Following.module.scss';

const cn = classnames.bind(styles);

const Following = () => {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const viewProfile = (data: UserFollowing) => {
    const username = data.followedUserUsername;
    dispatch(resetViewUser());
    if (data.followedUserMerchantGUID) {
      history.push(`/${username}`);
    } else {
      history.push(`/profile/${username}`);
    }
  };
  const [blockedBy, setBlockedBy] = useState<string[]>([]);

  const onToggleFollow = (type: string, tempUser: UserFollower | UserFollowing, index: number) => {
    const userData = clone(tempUser);
    if (type === 'following') {
      userData.userGUID = tempUser.followedUserGUID;
    }
    dispatch(followUser({ type, user: tempUser, data: type === 'following' ? user.following : user.follower, index }));
  };

  const onToggleUnfollow = (type: string, tempUser: UserFollower | UserFollowing, index: number) => {
    const userData = clone(tempUser);
    if (type === 'following') {
      userData.userGUID = tempUser.followedUserGUID;
    }
    dispatch(
      unfollowUser({ type, user: tempUser, data: type === 'following' ? user.following : user.follower, index })
    );
  };

  useEffect(() => {
    if (user.user?.guid) {
      dispatch(fetchUserFollowing(user.user?.guid));
      dispatch(searchBlockers(user.user.guid));
    }
  }, [user.user]);

  useEffect(() => {
    if (user.blockers && user.following) {
      const merchantBlockers: string[] = [];
      user.following?.forEach((following) => {
        if (user.blockers?.find((x) => x.userGUID === following.followedUserGUID)) {
          merchantBlockers.push(following.followedUserGUID as string);
        }
      });
      setBlockedBy(merchantBlockers);
    }
  }, [user.blockers, user.following]);

  return (
    <div className={cn('container')}>
      <div>
        <div className={cn('is-size-5 has-text-weight-semibold')}>Following</div>
        {user.following && user.following.length > 0 ? (
          <>
            <div className={cn('list', 'ft-mt-4')}>
              {user.following
                ?.filter((x) => !blockedBy.includes(x.followedUserGUID as string))
                .map((following, index) => (
                  <>
                    {
                      <div className={cn('item', 'flexbox')}>
                        <img
                          className={cn('avatar', 'pointer')}
                          src={following.followedUserNormalizedProfileImageUrl || defaultAvatar}
                          onClick={() => viewProfile(following)}
                        />
                        <div className={cn('ft-ml-2', 'pointer')} onClick={() => viewProfile(following)}>
                          <div>{following.followedUserDisplayName || `@${following.followedUserUsername}`}</div>
                          <div>@{following?.followedUserUsername}</div>
                        </div>

                        {!following.isFollowedUserFollowRequestedByCallingUser &&
                          !following.isFollowedUserFollowedByCallingUser &&
                          user.user?.guid !== following?.followedUserGUID && (
                            <div className="ft-ml-auto">
                              <Button color="secondary" onClick={() => onToggleFollow('following', following, index)}>
                                Follow
                              </Button>
                            </div>
                          )}
                        {((following?.isFollowedUserFollowRequestedByCallingUser &&
                          following.userMerchantAutoAcceptFollowers) ||
                          following?.isFollowedUserFollowedByCallingUser) &&
                          user.user?.guid !== following?.followedUserGUID && (
                            <div className={'ft-ml-auto'}>
                              <Button color="secondary" onClick={() => onToggleUnfollow('following', following, index)}>
                                Unfollow
                              </Button>
                            </div>
                          )}
                        {following?.isFollowedUserFollowRequestedByCallingUser &&
                          !following.userMerchantAutoAcceptFollowers &&
                          user.user?.guid !== following?.followedUserGUID && (
                            <div className={cn('ft-ml-auto')}>
                              {
                                <Button onClick={() => onToggleUnfollow('following', following, index)}>
                                  Cancel Follow Request
                                </Button>
                              }
                            </div>
                          )}
                      </div>
                    }
                  </>
                ))}
            </div>
          </>
        ) : (
          <BlankPage text="You are currently not following anyone." tabText="Discover Athletes" tabLink="/discover" />
        )}
      </div>
    </div>
  );
};

export default Following;
