import React, { useRef, useState } from 'react';
import { default as cn } from 'classnames';
import { default as ReactDatePicker } from 'react-date-picker';
import moment from 'moment';

import Icon from 'components/Icon';
import Input from 'components/Input';

import './DatePicker.scss';

interface DatePickerProps {
  className?: string;
  inputClassName?: string;
  label?: string;
  availableDates?: Date[];
  required?: boolean;
  onChange: (value: Date) => unknown;
}

const DatePicker = (props: DatePickerProps) => {
  const [value, setValue] = useState<Date>();
  const inputRef = useRef(null);

  const checkDateAvailability = ({ date }: { date: Date }) => {
    if (props.availableDates) {
      const available = props.availableDates.find(
        (x) => moment(x).format('MM/DD/YY') === moment(date).format('MM/DD/YY')
      );
      return !available;
    }
    return false;
  };

  const onChange = (value: Date) => {
    setValue(value);
    props.onChange(value);
  };

  return (
    <div className={cn('control', 'date-picker', props.className)}>
      {props.label && <label className={cn('label')}>{props.label}</label>}
      <ReactDatePicker
        clearIcon={null}
        inputRef={inputRef}
        value={value}
        onChange={onChange}
        calendarIcon={<Icon name="calendar" />}
        navigationLabel={({ date }) => `${moment(date).format('MMM YYYY')}`}
        formatMonth={(_, date) => moment(date).format('MMM')}
        tileDisabled={checkDateAvailability}
        calendarType="US"
        minDetail="year"
        className={props.inputClassName}
        required={props.required}
      />
      <Input
        className={cn('custom-input')}
        inputRef={inputRef}
        placeholder="Select available date"
        defaultValue={value ? moment(value).format('MM/DD/YYYY') : ''}
        layer={0}
        readOnly
      />
    </div>
  );
};

export default DatePicker;
