import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { ContentType } from 'enums';
import { LiveEvent, RemainingTime } from 'types';
import {
  cancelTicket,
  getLiveEvent,
  getMerchantLiveEvents,
  getUserLiveEvents,
  getUserPastEvents,
  resetActiveLiveEvent
} from 'state/reducers/liveEventReducer';
import { fetchLiveStreamPostById, fetchPastLiveStreamPostById, resetLiveStream } from 'state/reducers/postReducer';
import { playContent } from 'state/reducers/sessionReducer';
import { RootState } from 'state/store';

import Ticket from 'components/Ticket';
import Modal from 'components/Modal';
import Button from 'components/Button';

import BlankPage from 'components/BlankPage';
import styles from './Event.module.scss';

const cn = classnames.bind(styles);

const Event = () => {
  const { liveEvent, post, user } = useSelector((state: RootState) => ({
    liveEvent: state.liveEvent,
    post: state.post,
    user: state.user
  }));
  const [showCancelTicket, setShowCancelTicket] = useState(false);
  const [eventModal, setEventModal] = useState<LiveEvent | undefined>(undefined);
  const [isPastEvent, setIsPastEvent] = useState<boolean>(false);
  const [tab, setTab] = useState<number>(0);

  const dispatch = useDispatch();

  const viewTicket = (pastEvent: boolean, event?: LiveEvent) => {
    dispatch(resetActiveLiveEvent());
    setEventModal(event);
    setTimeLeft(calculateTimeLeft(event));

    if (event?.liveStreamGUID && pastEvent) {
      setIsPastEvent(pastEvent);
      dispatch(fetchPastLiveStreamPostById(event?.liveStreamGUID));
    }
    if (event && !pastEvent) {
      dispatch(getLiveEvent(event));
    }
  };

  const viewInAdmin = () => {
    const win = window.open(process.env.REACT_APP_ADMIN_URL, '_blank');
    if (win != null) {
      win.focus();
    }
  };

  const closeTicket = () => {
    dispatch(resetLiveStream());
    setIsPastEvent(false);
    setEventModal(undefined);
    setTimeLeft(calculateTimeLeft(undefined));
  };

  const watchLiveEvent = (event: LiveEvent) => {
    // dispatch(playContent({ contentType: ContentType.Post, post: event }));
    dispatch(playContent({ contentType: ContentType.Post, post: post.liveStream }));
    dispatch(fetchLiveStreamPostById(event.liveStreamGUID || ''));
  };

  const watchPastLiveEvent = () => {
    dispatch(playContent({ contentType: ContentType.Post, post: post.liveStream }));
  };

  const confirmCancelTicket = (event: LiveEvent) => {
    dispatch(cancelTicket({ liveEvents: event, bookedLiveEvents: liveEvent.bookedLiveEvents }));
    dispatch(getUserLiveEvents());

    setEventModal(undefined);
    setShowCancelTicket(false);
  };

  const calculateTimeLeft = (purchaseEvent: LiveEvent | undefined) => {
    let difference = 0;
    if (purchaseEvent) {
      difference = +new Date(purchaseEvent.dateOfEvent as Date) - +new Date();
    }
    let timeLeft: RemainingTime = {
      eventStart: false,
      raw: 0
    };

    if (difference > 0) {
      timeLeft = {
        raw: difference,
        eventStart: false,
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    } else {
      timeLeft.eventStart = true;
      timeLeft.raw = 0;
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(eventModal));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(eventModal));
    }, 1000);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    dispatch(getUserLiveEvents());
    dispatch(getUserPastEvents());
    if (user.user?.merchantGUID) {
      dispatch(getMerchantLiveEvents(user.user.merchantGUID));
    } else {
      setTab(1);
    }
  }, []);

  return (
    <div>
      <div className={cn('is-size-5', 'has-text-weight-semibold')}>Events</div>
      <div className={cn('menu-wrapper')}>
        {user.user?.merchantGUID && (
          <div className={cn('menu')}>
            <div className={cn('flexbox', 'tab')}>
              <div className={cn('tab-item', { active: tab === 0 })} onClick={() => setTab(0)}>
                My Live Events
                {tab === 0 && <div className={cn('border')} />}
              </div>
              <div className={cn('tab-item', { active: tab === 1 })} onClick={() => setTab(1)}>
                Booked Live Events
                {tab === 1 && <div className={cn('border')} />}
              </div>
              {liveEvent.bookedPastEvents && (
                <div className={cn('tab-item', { active: tab === 2 })} onClick={() => setTab(2)}>
                  Past Live Events
                  {tab === 2 && <div className={cn('border')} />}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {/*   <div className={cn('is-size-6', 'ft-mt-4')}>Upcoming events</div>
      <div className={cn('event-wrapper')}>
        <div className={cn('events', 'flexbox', 'ft-mt-2')}>
          <div className={cn('ticket')}>
            <Ticket
              backgroundUrl={stubFull}
              passType="full"
              name="SOUR Live Event"
              artist="Olivia Rodrigo"
              primaryColor="#dfe456"
            />
          </div>
          <div className={cn('ticket', 'ft-ml-8')}>
            <Ticket
              backgroundUrl={stubHalf}
              passType="half"
              name="SOUR Live"
              artist="Olivia Rodrigo"
              primaryColor="#dfe456"
              secondaryColor="#39afcd"
            />
          </div>
        </div>
      </div>*/}

      {tab === 0 &&
        (liveEvent.merchantEvents && liveEvent.merchantEvents.length > 0 ? (
          <div className={cn('ft-mt-4')}>
            <div className={cn('is-size-6')}>My Live Events</div>
            <div className={cn('ticket', 'ft-mt-2', 'fluid-wrapper')}>
              <div className={cn('flexbox', 'fluid')}>
                {liveEvent.merchantEvents?.map((event) => (
                  <div
                    className={cn('tile', 'ft-mr-4', 'ft-mb-4')}
                    key={event.guid}
                    onClick={() => viewTicket(false, event)}>
                    <>
                      <Ticket
                        artist={event.merchantName}
                        size="xs"
                        passType={event.ticketType ? event.ticketType : 'FullTicket'}
                        backgroundUrl={event.thumbnailImageUrl}
                        name={event.name}
                        date={moment(event.dateOfEvent).format('MMM DD / h a')}
                        primaryColor={event.colorHex ? '#' + event.colorHex : event.primaryColor}
                        secondaryColor={event.secondaryColorHex ? '#' + event.secondaryColorHex : event.secondaryColor}
                      />
                    </>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className={cn('ft-mt-8', 'text-center')}>You have no purchased Event tickets.</div>
        ))}

      {tab === 1 &&
        (liveEvent.bookedLiveEvents && liveEvent.bookedLiveEvents.length > 0 ? (
          <div className={cn('ft-mt-4')}>
            <div className={cn('is-size-6')}>Booked Live Events</div>
            <div className={cn('ticket', 'ft-mt-2', 'fluid-wrapper')}>
              <div className={cn('flexbox', 'fluid')}>
                {liveEvent.bookedLiveEvents?.map((event) => (
                  <div
                    className={cn('tile', 'ft-mr-4', 'ft-mb-4')}
                    key={event.guid}
                    onClick={() => viewTicket(false, event)}>
                    <>
                      <Ticket
                        artist={event.merchantName}
                        size="xs"
                        passType={event.ticketType ? event.ticketType : 'FullTicket'}
                        backgroundUrl={event.thumbnailImageUrl}
                        name={event.name}
                        date={moment(event.dateOfEvent).format('MMM DD / h a')}
                        primaryColor={event.colorHex ? '#' + event.colorHex : event.primaryColor}
                        secondaryColor={event.secondaryColorHex ? '#' + event.secondaryColorHex : event.secondaryColor}
                      />
                    </>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <BlankPage text="You have no upcoming Events." tabText="Discover Events" tabLink="/discover/events" />
        ))}

      {tab === 2 &&
        (liveEvent.bookedPastEvents && liveEvent.bookedPastEvents.length ? (
          <div className={cn('past-events', 'ft-mt-4')}>
            <div className={cn('is-size-6')}>Past events</div>
            <div className={cn('ticket', 'ft-mt-2', 'fluid-wrapper')}>
              <div className={cn('flexbox', 'fluid')}>
                {liveEvent.bookedPastEvents?.map((event) => (
                  <div
                    className={cn('tile', 'ft-mr-4', 'ft-mb-4')}
                    key={event.guid}
                    onClick={() => viewTicket(true, event)}>
                    <>
                      <Ticket
                        artist={event.merchantName}
                        size="xs"
                        passType={event.ticketType ? event.ticketType : 'FullTicket'}
                        backgroundUrl={event.thumbnailImageUrl}
                        name={event.name}
                        date={moment(event.dateOfEvent).format('MMM DD/ h a')}
                        primaryColor={event.colorHex ? '#' + event.colorHex : event.primaryColor}
                        secondaryColor={event.secondaryColorHex ? '#' + event.secondaryColorHex : event.secondaryColor}
                      />
                    </>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className={cn('ft-mt-8', 'text-center')}>You have no past Event tickets.</div>
        ))}

      {eventModal && (
        <Modal width="90rem" onClose={() => closeTicket()}>
          <div className={cn('event-modal')}>
            {!showCancelTicket ? (
              <>
                <div>
                  <Ticket
                    size="sm"
                    passType={eventModal.ticketType ? eventModal.ticketType : 'FullTicket'}
                    backgroundUrl={eventModal.thumbnailImageUrl}
                    name={eventModal.name}
                    artist={eventModal.merchantName}
                    primaryColor={eventModal.colorHex ? '#' + eventModal.colorHex : eventModal.primaryColor}
                    secondaryColor={
                      eventModal.secondaryColorHex ? '#' + eventModal.secondaryColorHex : eventModal.secondaryColor
                    }
                  />
                </div>
                <div className={cn('details')}>
                  <div className={cn('title')}>{eventModal.name}</div>
                  <div className={cn('has-text-weight-semibold')}>
                    {moment(eventModal.dateOfEvent).format('MMM DD')} / {moment(eventModal.dateOfEvent).format('h:mm')}-
                    {moment(eventModal.dateOfEvent).add(eventModal.durationInMinutes, 'm').format('h:mm A')}
                  </div>
                  <div className={cn('ft-mt-4')}>{eventModal.description}</div>
                  <div className={cn('purchase')}>
                    {isPastEvent && post.liveStream !== undefined && eventModal.hasTicketForCallingUser ? (
                      <Button onClick={() => watchPastLiveEvent()}>Watch</Button>
                    ) : liveEvent.activeLiveEvent?.playbackUrl &&
                      (liveEvent.activeLiveEvent?.status === 'Active' || eventModal.liveStreamStatus === 'Active') &&
                      !isPastEvent ? (
                      <Button onClick={() => watchLiveEvent(eventModal)}>Watch</Button>
                    ) : eventModal.merchantGUID === user.user?.merchantGUID ? (
                      <Button onClick={() => viewInAdmin()}>Manage in Admin</Button>
                    ) : eventModal.status === 'Pending' &&
                      eventModal.liveStreamStatus === undefined &&
                      !timeLeft.eventStart ? (
                      <>
                        <div className={cn('flexbox')}>
                          {`Event will start in 
                        ${timeLeft.raw > 86400000 ? `${timeLeft.days} ${timeLeft.days !== 1 ? 'days' : 'day'}` : ''}
                        ${timeLeft.raw > 3600000 ? `${timeLeft.hours} ${timeLeft.hours !== 1 ? 'hours' : 'hour'}` : ''} 
                        ${
                          timeLeft.raw > 60000
                            ? `${timeLeft.minutes} ${timeLeft.minutes !== 1 ? 'minutes' : 'minute'}`
                            : ''
                        } 
                        ${
                          timeLeft.seconds ? `${timeLeft.seconds} ${timeLeft.seconds !== 1 ? 'seconds' : 'second'}` : ''
                        }`}
                        </div>
                        {timeLeft.days && timeLeft.days >= 1 && eventModal.liveEventTicketGUID ? (
                          <div className={cn('flexbox', 'ft-mt-4')}>
                            <Button onClick={() => setShowCancelTicket(true)}>Cancel Ticket</Button>
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className={cn('is-size-8', 'ft-mt-4')}>
                    <div>
                      Cancelation Policy: You may cancel your ticket up to one day before the event and receive a full
                      refund (where applicable). No refunds are given on the day of the event.
                    </div>
                    <div className={cn('ft-mt-1')}>
                      When you purchase this event, you will receive the event pass which you can add on your profile to
                      show other fans.
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div>
                  <div className={cn('flexbox', 'ft-mb-4')}>
                    Are you sure you want to cancel your ticket for this event?
                  </div>
                  <Button color="primary" onClick={() => confirmCancelTicket(eventModal)}>
                    Cancel Ticket
                  </Button>
                  <Button
                    type="button"
                    className={cn('ft-ml-4')}
                    color="clear"
                    onClick={() => setShowCancelTicket(false)}>
                    Cancel
                  </Button>
                </div>
              </>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Event;
