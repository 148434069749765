import React, { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import classnames from 'classnames/bind';
import { debounce } from 'lodash';

import { closeRightSidebar, toggleMessages, toggleNotifications } from 'state/reducers/sessionReducer';
import { fetchTokens, resetViewUser, searchUsers, setViewUser } from 'state/reducers/userReducer';
import { store, RootState } from 'state/store';
import { logout } from 'state/reducers/authReducer';
import { clearToken } from 'state/reducers/sessionReducer';

import { useOutsideAlerter } from 'hooks';
import { Merchant, TokenBalance, User } from 'types';
import { formatNumber } from 'utils';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Input from 'components/Input';

import logo from 'assets/images/heyfans-logo.svg';
import defaultIcon from 'assets/images/default-avatar-1.png';
import { toggleSnackbarOpen } from 'state/reducers/snackbarReducer';

import styles from './NavBar.module.scss';

const cn = classnames.bind(styles);

const NavBar = () => {
  const { user, session, message } = useSelector((state: RootState) => ({
    user: state.user,
    session: state.session,
    message: state.message
  }));
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showNotificationBadge, setShowNotificationBadge] = useState(false);
  const [searchResults, setSearchResults] = useState<Merchant[]>();
  const [truBucks, setTruBucks] = useState<TokenBalance | undefined>();
  const [searchResultIndex, setSearchResultIndex] = useState<number>(0);
  const wrapperRef = useRef(null);

  const history = useHistory();
  const location = useLocation();

  const viewInAdmin = () => {
    const win = window.open(process.env.REACT_APP_ADMIN_URL, '_blank');
    if (win != null) {
      win.focus();
    }
  };

  const handleSearch = useCallback(
    debounce((text) => setSearchTerm(text), 1000),
    []
  );

  const toggleMenu = () => {
    const isUpdate = !isMenuOpen;
    setIsMenuOpen(isUpdate);
    if (isUpdate) {
      dispatch(fetchTokens());
    }
  };

  const toggleRightSidebar = (type: string) => {
    switch (type) {
      case 'message':
        if (session.isNotificationsOpen) {
          dispatch(toggleNotifications());
          setTimeout(() => {
            dispatch(toggleMessages());
          }, 500);
        } else {
          dispatch(toggleMessages());
        }
        break;
      case 'notification':
        if (session.isMessagesOpen) {
          dispatch(toggleMessages());
          setTimeout(() => {
            dispatch(toggleNotifications());
          }, 500);
        } else {
          dispatch(toggleNotifications());
        }
        break;
    }
    setIsMenuOpen(false);
  };

  const closeFloat = () => {
    dispatch(closeRightSidebar());
  };

  const onSelectItem = (location: string) => {
    history.push(`/${location}`);
    setIsMenuOpen(false);
    dispatch(resetViewUser());
  };

  const onSignOut = () => {
    store.dispatch(clearToken());
    store.dispatch(logout());
    window.location.replace('/login');
  };

  const viewProfile = (creator: User) => {
    dispatch(setViewUser(creator));
    history.push(`/profile/${creator.username}`);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    event.stopPropagation();
    if (searchResults && searchResults?.length > 0) {
      switch (event.code) {
        case 'ArrowDown': {
          if (searchResultIndex === searchResults.length - 1) {
            setSearchResultIndex(0);
          } else {
            setSearchResultIndex(searchResultIndex + 1);
          }
          break;
        }
        case 'ArrowUp': {
          if (searchResultIndex < 0) {
            setSearchResultIndex(searchResults.length - 1);
          } else {
            setSearchResultIndex(searchResultIndex - 1);
          }
          break;
        }
        case 'Enter': {
          setSearchTerm('');
          const creator: User = searchResults[searchResultIndex];
          if (creator.merchantGUID) {
            history.push(`/${creator.username}`);
          } else {
            viewProfile(creator);
          }
          break;
        }
      }
    }
  };

  useEffect(() => {
    if (searchTerm !== '') {
      //dispatch(searchCreators(searchTerm));
      dispatch(searchUsers({ searchTerm: searchTerm }));
    } else {
      setSearchResultIndex(0);
      setSearchResults(undefined);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (user.balance) {
      setTruBucks(user.balance.find((x) => x.tetheredMerchantGUID === undefined));
    }
  }, [user.balance]);

  useEffect(() => {
    setSearchResults(user.users?.filter((x) => (x.merchantGUID ? x.isMerchantActive : true)));
  }, [user.users]);

  useEffect(() => {
    if (user.notifications) {
      if (user.notifications.find((x) => x.isRead === false)) {
        setShowNotificationBadge(true);
      } else {
        setShowNotificationBadge(false);
      }
    }
  }, [user.notifications]);

  useEffect(() => {
    if (message.hasUnread) {
      dispatch(
        toggleSnackbarOpen({
          message: `${message.hasUnread.fromUserDisplayName} has sent you a message.`,
          type: 'info',
          timeout: 5000
        })
      );
    }
  }, [message.hasUnread]);

  useOutsideAlerter(wrapperRef, () => setIsMenuOpen(false));

  return (
    <div className={cn('navbar')}>
      <div className={cn('menu')}>
        <div className={cn('menu-item', 'home')} onClick={() => history.push('/')}>
          <img className={cn('logo')} src={logo} />
        </div>
        <Link className={cn('menu-item', 'item', { active: location.pathname.includes('/discover') })} to="/discover">
          <div className={cn('desktop')}>
            Discover
            {location.pathname.includes('/discover') && <div className={cn('border')} />}
          </div>
        </Link>
        <Link className={cn('menu-item', 'item', { active: location.pathname === '/club' })} to="/club">
          <div className={cn('desktop')}>
            Club
            {location.pathname === '/club' && <div className={cn('border')} />}
          </div>
        </Link>
        <div className={cn('search', 'ft-mx-auto')}>
          <div className={cn('searchbox')}>
            <Input
              placeholder="Search"
              layer={1}
              iconLeft={<Icon name="search" />}
              name="search"
              onChange={(event) => handleSearch((event?.target as HTMLInputElement).value)}
              onKeyUp={(event) => handleKeyDown(event)}
            />
            {searchResults && (
              <div className={cn('search-results')}>
                <ul>
                  {searchResults.map(
                    (creator: User, index) =>
                      creator.primaryPlatform === 'HeyFans' && (
                        <li
                          className={cn('item', 'flexbox', { 'key-background': index === searchResultIndex })}
                          key={creator.guid}
                          onMouseDown={() =>
                            creator.merchantGUID !== undefined
                              ? history.push(`/${creator.username}`)
                              : viewProfile(creator)
                          }>
                          <img className={cn('avatar')} src={creator.normalizedProfileImageUrl || defaultIcon} />
                          <span className={cn('ft-ml-2')}>{creator.displayName}</span>
                        </li>
                      )
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div
          className={cn('menu-item', 'item', { active: session.isMessagesOpen })}
          onClick={() => toggleRightSidebar('message')}>
          <div className={cn('desktop')}>
            <Icon name="messages" />
          </div>
        </div>
        <div
          className={cn('menu-item', 'item', { active: session.isNotificationsOpen })}
          onClick={() => toggleRightSidebar('notification')}>
          <div className={cn('desktop')}>
            <Icon name="notification" />
            {showNotificationBadge && <span className={cn('notification-badge')}></span>}
          </div>
        </div>
        <div className={cn('menu-item', 'profile')} ref={wrapperRef}>
          <div
            className={cn('avatar-button', { hide: session.isMessagesOpen || session.isNotificationsOpen })}
            onClick={toggleMenu}>
            <img className={cn('avatar')} src={user.user?.normalizedProfileImageUrl || defaultIcon} />
          </div>
          {(session.isMessagesOpen || session.isNotificationsOpen) && (
            <div className={cn('close-button')}>
              <Button shape="circle" color="clear" className={cn('close')} hasIcon onClick={closeFloat}>
                <Icon name="close" size="sm" />
              </Button>
            </div>
          )}
          {isMenuOpen && (
            <div className={cn('preferences')}>
              <div className={cn('arrow')} />
              <div>
                <ul className={cn('submenu')}>
                  <li onClick={() => onSelectItem('profile')}>
                    <div className={cn('flexbox')}>
                      <div>
                        <img className={cn('avatar')} src={user.user?.normalizedProfileImageUrl || defaultIcon} />
                      </div>
                      <div className={cn('ft-ml-2')}>
                        <div>{user.user?.displayName}</div>
                        <div className={cn('is-size-8')}>View Profile</div>
                      </div>
                    </div>
                  </li>
                  <li onClick={() => onSelectItem('preferences/wallet')}>
                    <div>
                      <div className={cn('is-size-8')}>FanBucks</div>
                      <div className={cn('has-text-weight-bold', 'fanbucks')}>
                        {formatNumber(truBucks?.balance || 0)}
                        <span className={cn('has-text-secondary', 'ft-ml-1')}>
                          <Icon name="fanbucks" size="lg" />
                        </span>
                      </div>
                    </div>
                  </li>
                  {user.user?.merchantGUID && (
                    <li onClick={() => onSelectItem(user.user?.username || '')}>View My Page</li>
                  )}
                  <li className={cn('mobile')} onClick={() => onSelectItem('discover')}>
                    Discover
                  </li>
                  <li className={cn('mobile')} onClick={() => onSelectItem('club')}>
                    Club
                  </li>
                  <li className={cn('mobile')} onClick={() => toggleRightSidebar('message')}>
                    Messages
                  </li>
                  <li className={cn('mobile')} onClick={() => toggleRightSidebar('notification')}>
                    Notifications
                  </li>
                  <li onClick={() => onSelectItem('preferences')}>Preferences</li>
                  {user.user?.merchantGUID && <li onClick={() => viewInAdmin()}>Admin</li>}
                  <li onClick={() => onSignOut()} className={cn('has-text-danger')}>
                    Sign Out
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
