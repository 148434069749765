import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import classnames from 'classnames/bind';

import { RootState } from 'state/store';

import Button from 'components/Button';
import Ticket from 'components/Ticket';

import defaultAvatar from 'assets/images/default-avatar-1.png';

import defaultIcon from 'assets/images/default-avatar-1.png';
import {
  blockUser,
  fetchSubscriptions,
  fetchUser,
  fetchUserFollower,
  fetchUserFollowing,
  followUser,
  resetViewUser,
  searchBlockedUsers,
  searchBlockers,
  unfollowUser
} from 'state/reducers/userReducer';

import { LiveEvent, User, UserFollower, UserFollowing } from 'types';
import { clone } from 'lodash';
import Skeleton from 'components/Skeleton';
import moment from 'moment';
import { playContent } from 'state/reducers/sessionReducer';
import { ContentType } from 'enums';
import { getLiveEvent, getUserPastEvents, resetActiveLiveEvent } from 'state/reducers/liveEventReducer';
import { fetchPastLiveStreamPostById, resetLiveStream } from 'state/reducers/postReducer';
import Modal from 'components/Modal';
import BlankPage from 'components/BlankPage';
import BlockUserModal from 'components/Modal/BlockUserModal';
import { toggleSnackbarOpen } from 'state/reducers/snackbarReducer';

import styles from './Profile.module.scss';

const cn = classnames.bind(styles);
type GenericStringObject = { [key: string]: string };
const Profile = () => {
  const { userId } = useParams<GenericStringObject>();
  const { user, liveEvent, post } = useSelector((state: RootState) => ({
    user: state.user,
    liveEvent: state.liveEvent,
    post: state.post
  }));
  const [showUser, setShowUser] = useState<User | undefined>(undefined);

  const [tabValue, setTabValue] = useState('');
  const [eventModal, setEventModal] = useState<LiveEvent | undefined>(undefined);
  const [isPastEvent, setIsPastEvent] = useState<boolean>(false);
  const [clubCount, setClubCount] = useState<number>(0);
  const [isBlockUser, setIsBlockUser] = useState<boolean>(false);
  const [blockList, setBlockList] = useState<string[]>([]);
  const [blockedBy, setBlockedBy] = useState<string[]>([]);
  const history = useHistory();
  const dispatch = useDispatch();

  const onToggleFollow = (type: string, tempUser: UserFollower | UserFollowing, index: number) => {
    const userData = clone(tempUser);
    if (type === 'following') {
      userData.userGUID = tempUser.followedUserGUID;
    }
    dispatch(followUser({ type, user: tempUser, data: type === 'following' ? user.following : user.follower, index }));
  };

  const onToggleUnfollow = (type: string, tempUser: UserFollower | UserFollowing, index: number) => {
    const userData = clone(tempUser);
    if (type === 'following') {
      userData.userGUID = tempUser.followedUserGUID;
    }
    dispatch(
      unfollowUser({ type, user: tempUser, data: type === 'following' ? user.following : user.follower, index })
    );
  };

  const viewTicket = (pastEvent: boolean, event?: LiveEvent) => {
    dispatch(resetActiveLiveEvent());
    setEventModal(event);

    if (event?.liveStreamGUID && pastEvent) {
      setIsPastEvent(pastEvent);
      dispatch(fetchPastLiveStreamPostById(event?.liveStreamGUID));
    }
    if (event && !pastEvent) {
      dispatch(getLiveEvent(event));
    }
  };

  const watchPastLiveEvent = () => {
    dispatch(playContent({ contentType: ContentType.Post, post: post.liveStream }));
  };

  const closeTicket = () => {
    dispatch(resetLiveStream());
    setIsPastEvent(false);
    setEventModal(undefined);
  };

  const confirmBlocking = (value: boolean) => {
    if (value) {
      dispatch(
        blockUser({
          userGUID: user.user?.guid,
          blockedUserGUID: showUser?.guid,
          blockedUserUsername: showUser?.username,
          blockedUserDisplayName: showUser?.displayName,
          blockedUserMerchantGUID: showUser?.merchantGUID,
          blockedUserProfileImageUrl: showUser?.profileImageUrl,
          blockedUserNormalizedProfileImageUrl: showUser?.normalizedProfileImageUrl
        })
      );
      dispatch(
        toggleSnackbarOpen({
          message: `${showUser?.displayName} blocked.`,
          type: 'info',
          timeout: 4000
        })
      );
    }

    setIsBlockUser(false);
  };

  const goToFollowingProfile = (following: UserFollowing) => {
    if (following.followedUserMerchantGUID) {
      history.push(`/${following.followedUserUsername}`);
    } else {
      history.push(`/profile/${following.followedUserUsername}`);
    }
  };

  const goToFollowerProfile = (follower: UserFollower) => {
    if (follower.userMerchantGUID) {
      history.push(`/${follower.userUsername}`);
    } else {
      history.push(`/profile/${follower.userUsername}`);
    }
  };

  useEffect(() => {
    dispatch(resetViewUser());
    setShowUser(undefined);
    setTabValue('');
    dispatch(getUserPastEvents());
    dispatch(fetchSubscriptions());
    dispatch(searchBlockedUsers(user.user?.guid as string));
    dispatch(searchBlockers(user.user?.guid as string));

    if (userId !== undefined || userId) {
      dispatch(fetchUser(userId));
    }
    if (user.user?.guid && userId === undefined) {
      setShowUser(user.user);
      dispatch(fetchUserFollowing(user.user?.guid));
      dispatch(fetchUserFollower(user.user?.guid));
    }
  }, [userId]);

  useEffect(() => {
    //setTabValue('events');
    if (user.viewUser) {
      setShowUser(user.viewUser);
      dispatch(fetchUserFollowing(user.viewUser?.guid as string));
      dispatch(fetchUserFollower(user.viewUser?.guid as string));
    }
  }, [user.viewUser]);

  useEffect(() => {
    if (user.subscriptions) {
      setClubCount(user.subscriptions.filter((x) => x.status === 'Active').length);
    }
  }, [user.subscriptions]);

  useEffect(() => {
    if (user.blockedUsers && user.follower) {
      const blockList: string[] = [];
      user.follower?.forEach((follower) => {
        if (user.blockedUsers?.find((x) => x.blockedUserGUID === follower.userGUID)) {
          blockList.push(follower.userGUID as string);
        }
      });
      setBlockList(blockList);
    }
  }, [user.blockedUsers, user.follower]);

  useEffect(() => {
    if (user.blockers && user.following) {
      const merchantBlockers: string[] = [];
      user.following?.forEach((following) => {
        const u = showUser || {};
        if (user.blockers?.find((x) => x.userGUID === following.followedUserGUID)) {
          merchantBlockers.push(following.followedUserGUID as string);
          u.followingUserCount = u?.followingUserCount || 0 - 1;
          setShowUser(u);
        }
      });
      setBlockedBy(merchantBlockers);
    }
  }, [user.blockers, user.following]);

  return (
    <div className={cn('container')}>
      {user.blockedUsers?.find((user) => user.blockedUserUsername === userId) ? (
        <BlankPage
          className={cn('is-size-5')}
          text={'You have blocked ' + userId}
          tabText="You can unblock the user here"
          tabLink="/preferences/block"></BlankPage>
      ) : user.blockers?.find((blocker) => blocker.userUsername === userId) ? (
        <BlankPage className={cn('is-size-5')} text={'You have been blocked by ' + userId}></BlankPage>
      ) : showUser !== undefined ? (
        <div className={cn('main', 'flexbox')}>
          <div>
            <img className={cn('avatar')} src={showUser?.normalizedProfileImageUrl || defaultIcon} />
          </div>
          <div className={cn('ft-ml-4', 'flex')}>
            <div className={cn('is-size-6')}>{showUser?.displayName}</div>
            <div>@{showUser?.username}</div>
            <div className={cn('counters', 'flexbox', 'ft-mt-2')}>
              {userId === undefined && (
                <div className={cn('count', 'ft-mr-4')} onClick={() => setTabValue('club')}>
                  <div className={cn('is-size-5')}>{clubCount || 0}</div>
                  <div className={cn('is-size-8')}>Clubs</div>
                </div>
              )}
              <div className={cn('count')} onClick={() => setTabValue('followers')}>
                <div className={cn('is-size-5')}>{user.follower?.length || 0}</div>
                <div className={cn('is-size-8')}>Followers</div>
              </div>
              <div className={cn('count', 'ft-ml-4')} onClick={() => setTabValue('following')}>
                <div className={cn('is-size-5')}>{user.following?.length || 0}</div>
                <div className={cn('is-size-8')}>Following</div>
              </div>
              <div className={cn('count', 'ft-ml-4')} onClick={() => setTabValue('events')}>
                <div className={cn('is-size-5')}>{liveEvent.bookedPastEvents?.length || 0}</div>
                <div className={cn('is-size-8')}>Past events</div>
              </div>
            </div>
          </div>
          {userId === undefined ? (
            <div className={cn('edit')}>
              <Button color="secondary" onClick={() => history.push('/preferences')}>
                Edit profile
              </Button>
            </div>
          ) : (
            <div className={cn('edit')}>
              <Button color="danger" onClick={() => setIsBlockUser(true)}>
                Block
              </Button>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className={cn('main', 'flexbox')}>
            <div>
              <Skeleton type="circ" radius={50} />
            </div>
            <div className={cn('ft-ml-4', 'flex')}>
              <Skeleton type="rect" width="100px" height="20px" />
              <Skeleton className={cn('ft-mt-2')} type="rect" width="150px" height="20px" />
            </div>
          </div>
        </>
      )}

      {!user.blockedUsers?.find((user) => user.blockedUserUsername === userId) &&
        !user.blockers?.find((blocker) => blocker.userUsername === userId) &&
        showUser &&
        {
          club: <BlankPage text={null} tabText="Discover Clubs" tabLink="/club" />,
          events: (
            <div className={cn('ft-mt-6')}>
              {/*<div className={cn('is-size-6')}>Badge collection</div>
              <div className={cn('trophy', 'ft-mt-4')}>
                <Trophy
                  badgeUrl={oliviaBadge}
                  name="olivia"
                  title="14 months"
                  primaryColor="#006d77"
                  secondaryColor="#e29578"
                />
                <Trophy
                  badgeUrl={shroudBadge}
                  name="shroud"
                  title="Moderator"
                  primaryColor="#9bb7d4"
                  secondaryColor="#97cc04"
                />
                <Trophy
                  badgeUrl={alinityBadge}
                  name="alinity"
                  title="Big Spender"
                  primaryColor="#754668"
                  secondaryColor="#587d71"
                />
                <Trophy
                  badgeUrl={forsenBadge}
                  name="forsen"
                  title="3 months"
                  primaryColor="#e9897e"
                  secondaryColor="#f4e4ba"
                />
              </div>*/}
              {liveEvent.bookedPastEvents && liveEvent.bookedPastEvents.length ? (
                <div className={cn('past-events', 'ft-mt-6')}>
                  <div className={cn('is-size-6', 'ft-mb-2')}>Past events</div>
                  <div className={cn('ft-mt-2', 'fluid-wrapper')}>
                    <div className={cn('flexbox', 'fluid', 'events-scroll')}>
                      {liveEvent.bookedPastEvents?.map((event) => (
                        <div
                          className={cn('ticket', 'ft-mt-4', 'tile')}
                          key={event.guid}
                          onClick={() => viewTicket(true, event)}>
                          <>
                            <Ticket
                              artist={event.merchantName}
                              size="xs"
                              passType={event.ticketType ? event.ticketType : 'FullTicket'}
                              backgroundUrl={event.thumbnailImageUrl}
                              name={event.name}
                              date={moment(event.dateOfEvent).format('MMM DD/ h a')}
                              primaryColor={event.colorHex ? '#' + event.colorHex : event.primaryColor}
                              secondaryColor={
                                event.secondaryColorHex ? '#' + event.secondaryColorHex : event.secondaryColor
                              }
                            />
                          </>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : liveEvent.bookedPastEvents && liveEvent.bookedPastEvents.length === 0 ? (
                <BlankPage text="You have no past Event tickets." />
              ) : (
                <div className={cn('past-events', 'ft-mt-6')}>
                  <br />
                  <div className={cn('ft-mt-2', 'fluid-wrapper')}>
                    <div className={cn('flexbox', 'fluid')}>
                      <div className={cn('ticket', 'ft-mt-6', 'tile-skeleton')}>
                        <Skeleton type="rect" width="140px" height="210px" />
                      </div>
                      <div className={cn('ticket', 'ft-mt-6', 'tile-skeleton')}>
                        <Skeleton type="rect" width="140px" height="210px" />
                      </div>
                      <div className={cn('ticket', 'ft-mt-6', 'tile-skeleton')}>
                        <Skeleton type="rect" width="140px" height="210px" />
                      </div>
                      <div className={cn('ticket', 'ft-mt-6', 'tile-skeleton')}>
                        <Skeleton type="rect" width="140px" height="210px" />
                      </div>
                      <div className={cn('ticket', 'ft-mt-6', 'tile-skeleton')}>
                        <Skeleton type="rect" width="140px" height="210px" />
                      </div>
                      <div className={cn('ticket', 'ft-mt-6', 'tile-skeleton')}>
                        <Skeleton type="rect" width="140px" height="210px" />
                      </div>
                      <div className={cn('ticket', 'ft-mt-6', 'tile-skeleton')}>
                        <Skeleton type="rect" width="140px" height="210px" />
                      </div>
                      <div className={cn('ticket', 'ft-mt-6', 'tile-skeleton')}>
                        <Skeleton type="rect" width="140px" height="210px" />
                      </div>
                      <div className={cn('ticket', 'ft-mt-6', 'tile-skeleton')}>
                        <Skeleton type="rect" width="140px" height="210px" />
                      </div>
                      <div className={cn('ticket', 'ft-mt-6', 'tile-skeleton')}>
                        <Skeleton type="rect" width="140px" height="210px" />
                      </div>
                      <div className={cn('ticket', 'ft-mt-6', 'tile-skeleton')}>
                        <Skeleton type="rect" width="140px" height="210px" />
                      </div>
                      <div className={cn('ticket', 'ft-mt-6', 'tile-skeleton')}>
                        <Skeleton type="rect" width="140px" height="210px" />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ),
          followers: (
            <div className={cn('ft-mt-6')}>
              <div className={cn('is-size-6')}>Followers</div>
              <div className={cn('list', 'ft-mt-2')}>
                {user.follower ? (
                  <>
                    {user.follower
                      ?.filter((x) => !blockList.includes(x.userGUID as string))
                      .map((follower, index) => (
                        <>
                          <div className={cn('item', 'flexbox')} key={follower.userGUID}>
                            <img
                              className={cn('avatar', 'pointer')}
                              src={follower.normalizedProfileImageUrl || defaultAvatar}
                              onClick={() => goToFollowerProfile(follower)}
                            />
                            <div className={cn('ft-ml-2', 'pointer')} onClick={() => goToFollowerProfile(follower)}>
                              <div>{follower.userDisplayName || `@${follower.userUsername}`}</div>
                              <div>@{follower?.userUsername}</div>
                            </div>
                            {/* <div className={cn('ft-ml-auto')}>
                        <Button color="secondary">Unfollow</Button>
                      </div> */}
                            {!follower.isFollowRequestedByCallingUser &&
                              !follower.isFollowedByCallingUser &&
                              showUser?.guid !== follower?.userGUID &&
                              showUser?.guid === user.user?.guid && (
                                <div className="ft-ml-auto">
                                  <Button color="secondary" onClick={() => onToggleFollow('follower', follower, index)}>
                                    Follow
                                  </Button>
                                </div>
                              )}
                            {((follower?.isFollowRequestedByCallingUser && follower.userMerchantAutoAcceptFollowers) ||
                              follower?.isFollowedByCallingUser) &&
                              showUser?.guid !== follower?.userGUID &&
                              showUser?.guid === user.user?.guid && (
                                <div className={'ft-ml-auto'}>
                                  <Button
                                    color="secondary"
                                    onClick={() => onToggleUnfollow('follower', follower, index)}>
                                    Unfollow
                                  </Button>
                                </div>
                              )}
                            {follower?.isFollowRequestedByCallingUser &&
                              !follower.userMerchantAutoAcceptFollowers &&
                              showUser?.guid !== follower?.userGUID &&
                              showUser?.guid === user.user?.guid && (
                                <div className={cn('ft-ml-auto')}>
                                  {
                                    <Button onClick={() => onToggleUnfollow('follower', follower, index)}>
                                      Cancel Follow Request
                                    </Button>
                                  }
                                </div>
                              )}
                          </div>
                        </>
                      ))}
                  </>
                ) : (
                  <>
                    <div className={cn('feed-skeleton')}>
                      <Skeleton type="rect" width="100%" height="100px" />
                      <Skeleton type="rect" width="100%" height="100px" />
                      <Skeleton type="rect" width="100%" height="100px" />
                      <Skeleton type="rect" width="100%" height="100px" />
                      <Skeleton type="rect" width="100%" height="100px" />
                    </div>
                  </>
                )}
              </div>
            </div>
          ),
          following: (
            <div className={cn('ft-mt-6')}>
              <div className={cn('is-size-6')}>Following</div>
              <div className={cn('list', 'ft-mt-2')}>
                {user.following ? (
                  <>
                    {user.following
                      ?.filter((x) => !blockedBy.includes(x.followedUserGUID as string))
                      .map((following, index) => (
                        <>
                          {
                            <div className={cn('item', 'flexbox')} key={following.userGUID}>
                              <img
                                className={cn('avatar', 'pointer')}
                                src={following.followedUserNormalizedProfileImageUrl || defaultAvatar}
                                onClick={() => goToFollowingProfile(following)}
                              />
                              <div className={cn('ft-ml-2', 'pointer')} onClick={() => goToFollowingProfile(following)}>
                                <div>{following.followedUserDisplayName || `@${following.followedUserUsername}`}</div>
                                <div>@{following?.followedUserUsername}</div>
                              </div>

                              {!following.isFollowedUserFollowRequestedByCallingUser &&
                                !following.isFollowedUserFollowedByCallingUser &&
                                user.user?.guid !== following?.followedUserGUID &&
                                showUser?.guid !== following?.followedUserGUID &&
                                showUser?.guid === user.user?.guid && (
                                  <div className="ft-ml-auto">
                                    <Button
                                      color="secondary"
                                      onClick={() => onToggleFollow('following', following, index)}>
                                      Follow
                                    </Button>
                                  </div>
                                )}
                              {((following?.isFollowedUserFollowRequestedByCallingUser &&
                                following.userMerchantAutoAcceptFollowers) ||
                                following?.isFollowedUserFollowedByCallingUser) &&
                                user.user?.guid !== following?.followedUserGUID &&
                                showUser?.guid !== following?.followedUserGUID &&
                                showUser?.guid === user.user?.guid && (
                                  <div className={'ft-ml-auto'}>
                                    <Button
                                      color="secondary"
                                      onClick={() => onToggleUnfollow('following', following, index)}>
                                      Unfollow
                                    </Button>
                                  </div>
                                )}
                              {following?.isFollowedUserFollowRequestedByCallingUser &&
                                !following.userMerchantAutoAcceptFollowers &&
                                user.user?.guid !== following?.followedUserGUID &&
                                showUser?.guid !== following?.followedUserGUID &&
                                showUser?.guid === user.user?.guid && (
                                  <div className={cn('ft-ml-auto')}>
                                    {
                                      <Button onClick={() => onToggleUnfollow('following', following, index)}>
                                        Cancel Follow Request
                                      </Button>
                                    }
                                  </div>
                                )}
                            </div>
                          }
                        </>
                      ))}
                  </>
                ) : (
                  <>
                    <div className={cn('feed-skeleton')}>
                      <Skeleton type="rect" width="100%" height="100px" />
                      <Skeleton type="rect" width="100%" height="100px" />
                      <Skeleton type="rect" width="100%" height="100px" />
                      <Skeleton type="rect" width="100%" height="100px" />
                      <Skeleton type="rect" width="100%" height="100px" />
                    </div>
                  </>
                )}
              </div>
            </div>
          ),
          '': <BlankPage text="Click any option above to see details." />
        }[tabValue]}

      {eventModal && (
        <Modal width="90rem" onClose={() => closeTicket()}>
          <div className={cn('event-modal')}>
            {
              <>
                <div>
                  <Ticket
                    size="sm"
                    passType={eventModal.ticketType ? eventModal.ticketType : 'FullTicket'}
                    backgroundUrl={eventModal.thumbnailImageUrl}
                    name={eventModal.name}
                    artist={eventModal.merchantName}
                    primaryColor={eventModal.colorHex ? '#' + eventModal.colorHex : eventModal.primaryColor}
                    secondaryColor={
                      eventModal.secondaryColorHex ? '#' + eventModal.secondaryColorHex : eventModal.secondaryColor
                    }
                  />
                </div>
                <div className={cn('details')}>
                  <div className={cn('title')}>{eventModal.name}</div>
                  <div className={cn('has-text-weight-semibold')}>
                    {moment(eventModal.dateOfEvent).format('MMM DD')} / {moment(eventModal.dateOfEvent).format('h:mm')}-
                    {moment(eventModal.dateOfEvent).add(eventModal.durationInMinutes, 'm').format('h:mm A')}
                  </div>
                  <div className={cn('ft-mt-4')}>{eventModal.description}</div>
                  <div className={cn('purchase')}>
                    {isPastEvent && post.liveStream !== undefined && eventModal.hasTicketForCallingUser && (
                      <Button onClick={() => watchPastLiveEvent()}>Watch</Button>
                    )}
                  </div>
                  <div className={cn('is-size-8', 'ft-mt-4')}>
                    <div>
                      Cancelation Policy: You may cancel your ticket up to one day before the event and receive a full
                      refund (where applicable). No refunds are given on the day of the event.
                    </div>
                    <div className={cn('ft-mt-1')}>
                      When you purchase this event, you will receive the event pass which you can add on your profile to
                      show other fans.
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </Modal>
      )}

      {isBlockUser && (
        <Modal onClose={() => setIsBlockUser(false)}>
          <BlockUserModal onSelect={confirmBlocking} blockedUsername={showUser?.displayName as string} />
        </Modal>
      )}
    </div>
  );
};

export default Profile;
