import React from 'react';
import classnames from 'classnames/bind';

import styles from './Copyright.module.scss';

const cn = classnames.bind(styles);

const Copyright = () => {
  return (
    <div className={cn('legal')}>
      <div className={cn('header')}>Copyright Policy</div>
      <div className={cn('is-size-7')}>
        <p>Last Updated August 20, 2021</p>

        <p>
          HeyFans is a platform that allows people to upload and share music, photos, videos and other content through
          our website and mobile application (the "HeyFans Platform”). We take the rights of intellectual property
          owners very seriously and comply as a service provider with all applicable provisions of the Digital
          Millennium Copyright Act. For any questions or concerns about potential copyright infringements, you can reach
          out to HeyFans at the address below. Please note that you should consult with a lawyer to understand your
          rights and obligations when submitting a notification of claimed infringement.
        </p>

        <p>
          HeyFans will respond accordingly for any takedown requests that comply with the requirements of the Digital
          Millennium Copyright Act (DMCA), and other applicable intellectual property laws. Under the HeyFans Terms of
          Use, we expressly bar the upload of infringing material onto the HeyFans Platform. We respect the rights of
          copyright owners and we ask that you do the same.
        </p>

        <p>
          <b>Understanding Copyright:</b>
        </p>

        <p>
          Copyright is the right of an author of a creative work to prevent others from using that work, including
          performing or displaying the work publicly, reproducing a work, distributing a work, or using it to create new
          works (a derivative work). We strongly encourage users of the HeyFans Platform to familiarize themselves with
          copyright law before uploading content on the musHeyFansolive Platform.
        </p>

        <p>
          <b>What Happens If I Upload Content That Might Be Copyrighted:</b>
        </p>

        <p>
          If you upload an image, video, audio file or other derived version, then you may be subject to a claim by the
          copyright owner. Typically copyright owners will contact HeyFans and, if the complaint meets the requirements
          of the Digital Millennium Copyright Act (DMCA), and other applicable intellectual property laws, we will take
          the content down. This is required by law. If you continue to upload unauthorized content, then you will be
          banned from using the HeyFans Platform, as outlined in our Terms of Use.
        </p>

        <p>
          <b>
            IMPORTANT DISCLAIMER: WE ARE NOT YOUR ATTORNEYS AND THE INFORMATION CONTAINED HEREIN IS FOR YOUR GENERAL
            INFORMATION ONLY. THE INFORMATION CONTAINED HEREIN IS NOT LEGAL ADVICE OF ANY KIND. THIS INFORMATION MAY NOT
            COVER IMPORTANT ISSUES THAT AFFECT YOUR USE OF CONTENT. YOU SHOULD CONSULT WITH AN ATTORNEY IF YOU HAVE ANY
            QUESTIONS ABOUT COPYRIGHT LAW OR YOUR USE OF PARTICULAR CONTENT.
          </b>
        </p>

        <p>If you have questions about this policy, you can contact us as described below:</p>

        <p>
          Email: <a href="mailto:legal@heyfans.com">legal@heyfans.com</a>
        </p>

        <p>
          Mail:
          <br />
          HeyFans
          <br />
          ATTN: Privacy Operations
          <br />
          400 Spectrum Center Dr.,
          <br />
          Suite 2180
          <br />
          Irvine, CA 92618
        </p>
      </div>
    </div>
  );
};

export default Copyright;
