import React, { useEffect, useRef, useState } from 'react';
import { default as cn } from 'classnames';

import moment from 'moment';

import './CalendarFeed.scss';
import Calendar, { CalendarTileProperties } from 'react-calendar';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/store';
import { LiveEvent, RemainingTime, Schedules, VideoCallRequest } from 'types';
import { cloneDeep } from 'lodash';
import defaultIcon from 'assets/images/default-avatar-1.png';
import Button from 'components/Button';
import { cancelTicket, getLiveEvent, getUserLiveEvents, resetActiveLiveEvent } from 'state/reducers/liveEventReducer';
import { playContent } from 'state/reducers/sessionReducer';
import { fetchLiveStreamPostById } from 'state/reducers/postReducer';
import { ContentType } from 'enums';
import Modal from 'components/Modal';
import Ticket from 'components/Ticket';
import ViewVideoCallModal from 'components/Modal/ViewVideoCallModal';

interface CalendarProps {
  className?: string;
  inputClassName?: string;
  label?: string;
  availableDates?: Date[];
}

const CalendarFeed = (props: CalendarProps) => {
  const { user, liveEvent } = useSelector((state: RootState) => ({
    user: state.user,
    liveEvent: state.liveEvent
  }));
  const [availableDate, setAvailableDate] = useState([]);
  const [daySchedule, setDaySchedule] = useState([]);
  const [selectedDay, setSelectedDay] = useState(moment(new Date()).format('dddd, MMMM D'));
  const [purchaseEvent, setPurchaseEvent] = useState<LiveEvent | undefined>();
  const [showCancelTicket, setShowCancelTicket] = useState<boolean>();
  const [viewRequest, setViewRequest] = useState<VideoCallRequest>();
  const [value, setValue] = useState<Date>();
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const currentDate = new Date();
  const minDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const maxDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0, 23, 59, 59);

  const onChange = (value: Date) => {
    setValue(value);
  };

  const viewVideoCall = (data: VideoCallRequest | undefined) => {
    setViewRequest(data);
  };

  const viewTicket = (event: LiveEvent | undefined) => {
    setTimeLeft(calculateTimeLeft(event));
    dispatch(resetActiveLiveEvent());
    if (event) {
      dispatch(getLiveEvent(event));
    }

    setPurchaseEvent(event);
  };

  const watchLiveEvent = (event: LiveEvent) => {
    setPurchaseEvent(undefined);
    dispatch(playContent({ contentType: ContentType.Post, post: liveEvent.activeLiveEvent }));
    dispatch(fetchLiveStreamPostById(event.liveStreamGUID || ''));
  };

  const confirmCancelTicket = (event: LiveEvent) => {
    dispatch(cancelTicket({ liveEvents: event, bookedLiveEvents: liveEvent.bookedLiveEvents }));
    dispatch(getUserLiveEvents());

    setPurchaseEvent(undefined);
    setShowCancelTicket(undefined);
  };

  const calculateTimeLeft = (purchaseEvent: LiveEvent | undefined) => {
    let difference = 0;
    if (purchaseEvent) {
      difference = +new Date(purchaseEvent.dateOfEvent as Date) - +new Date();
    }
    let timeLeft: RemainingTime = {
      eventStart: false,
      raw: 0
    };

    if (difference > 0) {
      timeLeft = {
        raw: difference,
        eventStart: false,
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    } else {
      timeLeft.eventStart = true;
      timeLeft.raw = 0;
    }
    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(purchaseEvent));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(purchaseEvent));
    }, 1000);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    if (user.calendar !== undefined) {
      setAvailableDate(user.calendar.dates);
    }
  }, [user.calendar]);

  const checkDate = (date: CalendarTileProperties) => {
    const tileDate = moment(date.date).format('MM/DD/YY');
    const checkDate = availableDate.filter((d: string) => d === tileDate);

    if (checkDate !== undefined && checkDate.length > 0) {
      return cn('has-content');
    }
    return '';
  };

  return (
    <>
      <div className={cn('control', 'date-picker', props.className)}>
        {props.label && <label className={cn('label')}>{props.label}</label>}
        <Calendar
          className={props.inputClassName}
          navigationLabel={({ date }) => `${moment(date).format('MMM YYYY')}`}
          formatMonth={(_, date) => moment(date).format('MMM')}
          calendarType="US"
          minDate={minDate}
          maxDate={maxDate}
          inputRef={inputRef}
          value={value ? value : currentDate}
          onChange={onChange}
          onClickDay={(value) => {
            setSelectedDay(moment(value).format('dddd, MMMM D'));
            if (user.calendar.schedules) {
              const userSchedules = cloneDeep(user.calendar.schedules);
              const selectedDate = moment(value).format('MM/DD/YY');
              const filter = userSchedules.filter((s: Schedules) => s.date === selectedDate);
              setDaySchedule(filter);
            }
          }}
          tileClassName={(date: CalendarTileProperties) => checkDate(date)}
        />
      </div>
      {selectedDay && <p>{selectedDay}</p>}
      {daySchedule.map((row: Schedules) => {
        if (row.type === 'LiveEvent') {
          const event = row.liveEventData;
          return (
            <div className={cn('flexbox', 'row')} onClick={() => viewTicket(event)}>
              <div className={cn('time', 'ft-ml-2')}>{moment(event?.dateOfEvent).format('hh:mm A')}</div>
              <div className={cn('thumbnail', 'ft-ml-2')}>
                <img className={cn('avatar')} src={event?.thumbnailImageUrl || defaultIcon} />
              </div>
              <div className={cn('name', 'ft-ml-2')}>{event?.name}</div>
            </div>
          );
        }
        if (row.type === 'VideoCall') {
          const request = row.videoMessageData;
          return (
            <div className={cn('flexbox', 'row')} onClick={() => viewVideoCall(request)}>
              <div className={cn('time', 'ft-ml-2')}>{moment(request?.dateScheduled).format('hh:mm A')}</div>
              <div className={cn('thumbnail', 'ft-ml-2')}>
                <img className={cn('avatar')} src={request?.merchantUserNormalizedProfileImageUrl || defaultIcon} />
              </div>
              <div className={cn('name', 'ft-ml-2')}>Video Call with {request?.recipientName}</div>
            </div>
          );
        }
        return '';
      })}

      {purchaseEvent && (
        <Modal width="90rem" onClose={() => setPurchaseEvent(undefined)}>
          <div className={cn('event-modal')}>
            {!showCancelTicket ? (
              <>
                <div>
                  <Ticket
                    size="sm"
                    passType={purchaseEvent.ticketType ? purchaseEvent.ticketType : 'FullTicket'}
                    backgroundUrl={purchaseEvent.thumbnailImageUrl}
                    name={purchaseEvent.name}
                    artist={purchaseEvent.merchantName}
                    primaryColor={purchaseEvent.colorHex ? '#' + purchaseEvent.colorHex : purchaseEvent.primaryColor}
                    secondaryColor={
                      purchaseEvent.secondaryColorHex
                        ? '#' + purchaseEvent.secondaryColorHex
                        : purchaseEvent.secondaryColor
                    }
                  />
                </div>
                <div className={cn('details')}>
                  <div className={cn('title')}>{purchaseEvent.name}</div>
                  <div className={cn('has-text-weight-semibold')}>
                    {moment(purchaseEvent.dateOfEvent).format('MMM DD')} /{' '}
                    {moment(purchaseEvent.dateOfEvent).format('h:mm')}-
                    {moment(purchaseEvent.dateOfEvent).add(purchaseEvent.durationInMinutes, 'm').format('h:mm A')}
                  </div>
                  <div className={cn('ft-mt-4')}>{purchaseEvent.description}</div>
                  <div className={cn('purchase')}>
                    {!purchaseEvent.hasTicketForCallingUser ? (
                      ''
                    ) : liveEvent.activeLiveEvent?.playbackUrl ||
                      liveEvent.activeLiveEvent?.status === 'Active' ||
                      purchaseEvent.liveStreamStatus === 'Active' ? (
                      <Button onClick={() => watchLiveEvent(purchaseEvent)}>Watch</Button>
                    ) : purchaseEvent.status === 'Pending' && timeLeft.eventStart ? (
                      'Event will start soon.'
                    ) : purchaseEvent.liveStreamStatus !== 'Active' || !timeLeft.eventStart ? (
                      <>
                        <div className={cn('flexbox')}>
                          {`Event will start in 
                        ${timeLeft.raw > 86400000 ? `${timeLeft.days} ${timeLeft.days !== 1 ? 'days' : 'day'}` : ''}
                        ${timeLeft.raw > 3600000 ? `${timeLeft.hours} ${timeLeft.hours !== 1 ? 'hours' : 'hour'}` : ''} 
                        ${
                          timeLeft.raw > 60000
                            ? `${timeLeft.minutes} ${timeLeft.minutes !== 1 ? 'minutes' : 'minute'}`
                            : ''
                        } 
                        ${
                          timeLeft.seconds ? `${timeLeft.seconds} ${timeLeft.seconds !== 1 ? 'seconds' : 'second'}` : ''
                        }`}
                        </div>
                        {timeLeft.days && timeLeft.days >= 1 && purchaseEvent.liveEventTicketGUID ? (
                          <div className={cn('flexbox', 'ft-mt-4')}>
                            <Button onClick={() => setShowCancelTicket(true)}>Cancel Ticket</Button>
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      'Event will start soon.'
                    )}
                  </div>
                  <div className={cn('is-size-8', 'ft-mt-4')}>
                    <div>
                      Cancelation Policy: You may cancel your ticket up to one day before the event and receive a full
                      refund (where applicable). No refunds are given on the day of the event.
                    </div>
                    <div className={cn('ft-mt-1')}>
                      When you purchase this event, you will receive the event pass which you can add on your profile to
                      show other fans.
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div>
                  <div className={cn('flexbox', 'ft-mb-4')}>
                    Are you sure you want to cancel your ticket for this event?
                  </div>
                  <Button color="primary" onClick={() => confirmCancelTicket(purchaseEvent)}>
                    Cancel Ticket
                  </Button>
                  <Button
                    type="button"
                    className={cn('ft-ml-4')}
                    color="clear"
                    onClick={() => setShowCancelTicket(undefined)}>
                    Cancel
                  </Button>
                </div>
              </>
            )}
          </div>
        </Modal>
      )}

      {viewRequest && (
        <Modal width="90rem" onClose={() => setViewRequest(undefined)}>
          <ViewVideoCallModal data={viewRequest} />
        </Modal>
      )}
    </>
  );
};

export default CalendarFeed;
