import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import moment from 'moment';

import { toggleSnackbarOpen } from 'state/reducers/snackbarReducer';
import { resetSubscriptions, unsubscribe } from 'state/reducers/userReducer';
import { fetchSubscriptions } from 'state/reducers/userReducer';
import { RootState } from 'state/store';

import { SubscriptionPlan } from 'types';
import Button from 'components/Button';
import Modal from 'components/Modal';
import CancelSubscriptionModal from 'components/Modal/CancelSubscriptionModal';

import BlankPage from 'components/BlankPage';
import { cloneDeep } from 'lodash';
import { useHistory } from 'react-router-dom';
import defaultAvatar from 'assets/images/default-avatar-1.png';
import { getBadgeList } from 'state/reducers/badgeReducer';
import { Badge } from 'types/badge';

import styles from './Subscription.module.scss';

const cn = classnames.bind(styles);

const Subscription = () => {
  const { user, badge } = useSelector((state: RootState) => ({
    user: state.user,
    badge: state.badge
  }));
  const [cancelCreator, setCancelCreator] = useState<SubscriptionPlan | undefined>(undefined);

  const [activeSubscriptionList, setActiveSubscriptionList] = useState<SubscriptionPlan[]>([]);
  const [activeCurrentSubscription, setActiveCurrentSubscription] = useState<SubscriptionPlan[] | undefined>([]);
  const [activeSubsCurrentPage, setActiveSubsCurrentPage] = useState(1);

  const [expiredSubscriptionList, setExpiredSubscriptionList] = useState<SubscriptionPlan[]>([]);
  const [expiredCurrentSubscription, setExpiredCurrentSubscription] = useState<SubscriptionPlan[] | undefined>([]);
  const [expiredSubsCurrentPage, setExpiredSubsCurrentPage] = useState(1);

  const [tabValue, setTabValue] = useState('active');
  const dispatch = useDispatch();
  const history = useHistory();

  const subscriptionsPerPage = 10;
  const indexOfACLastTransaction: number = activeSubsCurrentPage * subscriptionsPerPage;
  const indexOfACFirstTransaction: number = indexOfACLastTransaction - subscriptionsPerPage;
  const indexOfEXLastTransaction: number = expiredSubsCurrentPage * subscriptionsPerPage;
  const indexOfEXFirstTransaction: number = indexOfEXLastTransaction - subscriptionsPerPage;

  const cancelSubscription = (subscription: SubscriptionPlan) => {
    dispatch(unsubscribe(subscription));
    dispatch(
      toggleSnackbarOpen({
        message: `You have unsubscribed to ${subscription?.merchantUserDisplayName}.`,
        type: 'info',
        timeout: 4000
      })
    );

    setCancelCreator(undefined);
  };

  const confirmCancellation = (value: boolean) => {
    if (value) {
      cancelSubscription(cancelCreator as SubscriptionPlan);
    } else {
      setCancelCreator(undefined);
    }
  };

  const getMonthsSubscribed = (dateSubscribed: Date) => {
    let months = null;
    months = (new Date().getFullYear() - new Date(dateSubscribed).getFullYear()) * 12;
    months -= new Date(dateSubscribed).getMonth();
    months += new Date().getMonth();
    return months + 1 + (months <= 0 ? ' Month' : ' Months');
  };

  const nextPage = (currentPage: number) => {
    if (tabValue === 'active') {
      if (
        activeSubscriptionList &&
        activeSubsCurrentPage < Math.ceil(activeSubscriptionList.length / subscriptionsPerPage)
      ) {
        setActiveSubsCurrentPage(++currentPage);
      }
    } else if (tabValue === 'expired') {
      if (
        expiredSubscriptionList &&
        expiredSubsCurrentPage < Math.ceil(expiredSubscriptionList.length / subscriptionsPerPage)
      ) {
        setExpiredSubsCurrentPage(++currentPage);
      }
    }
  };

  const prevPage = (currentPage: number) => {
    if (currentPage > 1 && tabValue === 'active') {
      setActiveSubsCurrentPage(--currentPage);
    } else if (currentPage > 1 && tabValue === 'expired') {
      setExpiredSubsCurrentPage(--currentPage);
    }
  };

  const goToMerchant = (subscription: SubscriptionPlan) => {
    if (subscription.merchantUsername) {
      history.push(`/${subscription.merchantUsername}`);
    } else {
      history.push(`/${subscription.merchantUserDisplayName}`);
    }
  };

  useEffect(() => {
    dispatch(resetSubscriptions());
    dispatch(fetchSubscriptions());
    dispatch(getBadgeList());
  }, []);

  useEffect(() => {
    if (user.subscriptions) {
      const activeSubs = cloneDeep(
        user.subscriptions?.filter(
          (x) => x.status === 'Active' || new Date(x.periodEndDate as Date).getTime() >= new Date().getTime()
        )
      );
      setActiveSubscriptionList(activeSubs);
      setActiveCurrentSubscription(activeSubs.slice(indexOfACFirstTransaction, indexOfACLastTransaction));

      const expiredSubs = cloneDeep(
        user.subscriptions?.filter(
          (x) => x.status === 'Cancelled' && new Date(x.periodEndDate as Date).getTime() < new Date().getTime()
        )
      );
      setExpiredSubscriptionList(expiredSubs);
      setExpiredCurrentSubscription(expiredSubs?.slice(indexOfEXFirstTransaction, indexOfEXLastTransaction));
    }
  }, [user.subscriptions]);

  useEffect(() => {
    if (tabValue === 'active') {
      const activeSubscription = activeSubscriptionList?.slice(indexOfACFirstTransaction, indexOfACLastTransaction);
      setActiveCurrentSubscription(activeSubscription);
    }
    if (tabValue === 'expired') {
      const expiredSubscription = expiredSubscriptionList?.slice(indexOfEXFirstTransaction, indexOfEXLastTransaction);
      setExpiredCurrentSubscription(expiredSubscription);
    }
  }, [activeSubsCurrentPage, expiredSubsCurrentPage]);

  return (
    <div>
      <div className={cn('is-size-5', 'has-text-weight-semibold')}>Subscriptions</div>
      {/*<div className={cn('ft-mt-4')}>Badge collection</div>
      <div className={cn('trophy-wrapper')}>
        <div className={cn('trophy', 'ft-mt-4')}>
          <div className={cn('temp-trophy')}>
            <div className={cn('placeholder')}>
              <Icon name="plus" sizePixel={32} />
            </div>
            <div className={cn('instruction')}>
              <div>Tap + to</div>
              <div>select a badge</div>
            </div>
          </div>
          <div className={cn('temp-trophy')}>
            <div className={cn('placeholder')}>
              <Icon name="plus" sizePixel={32} />
            </div>
            <div className={cn('instruction')}>
              <div>Tap + to</div>
              <div>select a badge</div>
            </div>
          </div>
          <div className={cn('temp-trophy')}>
            <div className={cn('placeholder')}>
              <Icon name="plus" sizePixel={32} />
            </div>
            <div className={cn('instruction')}>
              <div>Tap + to</div>
              <div>select a badge</div>
            </div>
          </div>
          <div className={cn('temp-trophy')}>
            <div className={cn('placeholder')}>
              <Icon name="plus" sizePixel={32} />
            </div>
            <div className={cn('instruction')}>
              <div>Tap + to</div>
              <div>select a badge</div>
            </div>
          </div>
        </div>
      </div>*/}
      <div className={cn('ft-mt-4')}>
        <ul className={cn('tab')}>
          <li className={cn('tab-item', { active: tabValue === 'active' })} onClick={() => setTabValue('active')}>
            Active subscriptions
            {tabValue === 'active' && <div className={cn('border')} />}
          </li>
          <li className={cn('tab-item', { active: tabValue === 'expired' })} onClick={() => setTabValue('expired')}>
            Expired subscriptions
            {tabValue === 'expired' && <div className={cn('border')} />}
          </li>
        </ul>
      </div>
      {user.subscriptions && tabValue === 'active' ? (
        <div className={cn('table-wrapper')}>
          {activeSubscriptionList?.length !== 0 ? (
            <>
              <ul className={cn('active-subs', 'ft-mt-4')}>
                {activeCurrentSubscription?.map((subscription) => (
                  <li key={subscription.guid} className={cn('mb-4')}>
                    <div>
                      <div className={cn('avatar-pill')} onClick={() => goToMerchant(subscription)}>
                        <img className={cn('avatar')} src={subscription.merchantUserProfileImageUrl} />
                      </div>
                    </div>
                    <div className={cn('fixed', 'ft-ml-4')} onClick={() => goToMerchant(subscription)}>
                      <div>{subscription.merchantUserDisplayName}</div>
                      <div className={cn('small')}>{getMonthsSubscribed(subscription.startDate as Date)}</div>
                    </div>
                    <div className={cn('ft-ml-4')}>
                      {subscription.status === 'Active' ? (
                        <>
                          <div className={cn('ft-mr-4')}>Your subscription will</div>
                          <div>renew on {moment(subscription.periodEndDate).format('MM/DD/YYYY')}</div>
                        </>
                      ) : (
                        <>
                          <div>Cancelled subscription</div>
                          <div>will end on {moment(subscription.periodEndDate).format('MM/DD/YYYY')}</div>
                        </>
                      )}
                    </div>
                    <div className={cn('ft-ml-4')}>
                      <div>{subscription.subscriptionPlanName}</div>
                    </div>
                    <div className={cn('ft-ml-auto', subscription.status !== 'Active' ? 'ft-mr-32' : '')}>
                      {(badge.badgeList?.filter((x: Badge) => x.merchantGUID === subscription.merchantGUID)
                        .length as number) > 0 && (
                        <img
                          className={cn('badge-img')}
                          src={
                            badge.badgeList?.filter((x: Badge) => x.merchantGUID === subscription.merchantGUID)[0]
                              ?.badgeImageUrl
                          }
                        />
                      )}
                    </div>
                    {subscription.status === 'Active' && (
                      <div className={cn('cancel', 'ft-ml-4')}>
                        <Button color="secondary" onClick={() => setCancelCreator(subscription)}>
                          Cancel Sub
                        </Button>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
              <div className={cn('ft-mt-4', 'flexbox', 'table-footer')}>
                <div className={cn('flexbox')}>
                  <Button className={cn('ft-mr-4')} onClick={() => prevPage(activeSubsCurrentPage)}>
                    Prev
                  </Button>
                  <Button onClick={() => nextPage(activeSubsCurrentPage)}>Next</Button>
                </div>
                {user.subscriptions ? (
                  <div className={cn('flexbox', 'page', 'ft-ml-4')}>
                    <div className={cn('is-size-7')}>
                      Page {activeSubsCurrentPage} of {Math.ceil(activeSubscriptionList?.length / subscriptionsPerPage)}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </>
          ) : (
            <BlankPage text="You have no active subscriptions." tabText="Discover Athletes" tabLink="/discover" />
          )}
        </div>
      ) : (
        user.subscriptions &&
        tabValue === 'expired' && (
          <div className={cn('table-wrapper')}>
            {expiredSubscriptionList?.length !== 0 ? (
              <>
                <ul className={cn('active-subs', 'ft-mt-4')}>
                  {expiredCurrentSubscription?.map((subscription) => (
                    <li key={subscription.guid} className={cn('mb-4')}>
                      <div>
                        <div className={cn('avatar-pill')} onClick={() => goToMerchant(subscription)}>
                          <img
                            className={cn('avatar')}
                            src={subscription.merchantUserProfileImageUrl || defaultAvatar}
                          />
                        </div>
                      </div>
                      <div className={cn('fixed', 'ft-ml-4', 'ft-mr-4')} onClick={() => goToMerchant(subscription)}>
                        <div>{subscription.merchantUserDisplayName}</div>
                        <div className={cn('small')}>{getMonthsSubscribed(subscription.startDate as Date)}</div>
                      </div>
                      <div className={cn('ft-ml-8', 'ft-mr-8')}>
                        <div>Subscription expired</div>
                        <div className={cn('align-center')}>
                          on {moment(subscription.periodEndDate).format('MM/DD/YYYY')}
                        </div>
                      </div>
                      <div className={cn('ft-ml-8')}>
                        <div>{subscription.subscriptionPlanName}</div>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className={cn('ft-mt-4', 'flexbox', 'table-footer')}>
                  <div className={cn('flexbox')}>
                    <Button className={cn('ft-mr-4')} onClick={() => prevPage(expiredSubsCurrentPage)}>
                      Prev
                    </Button>
                    <Button onClick={() => nextPage(expiredSubsCurrentPage)}>Next</Button>
                  </div>
                  {user.subscriptions ? (
                    <div className={cn('flexbox', 'page', 'ft-ml-4')}>
                      <div className={cn('is-size-7')}>
                        Page {expiredSubsCurrentPage} of{' '}
                        {Math.ceil(expiredSubscriptionList?.length / subscriptionsPerPage)}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </>
            ) : (
              <BlankPage text="You have no expired plans." />
            )}
          </div>
        )
      )}

      {cancelCreator && (
        <Modal onClose={() => setCancelCreator(undefined)}>
          <CancelSubscriptionModal subscriptionPlan={cancelCreator} onSelect={confirmCancellation} />
        </Modal>
      )}
    </div>
  );
};

export default Subscription;
