import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import moment from 'moment';

import {
  purchaseEventTicket,
  getLiveEvent,
  cancelTicket,
  resetActiveLiveEvent,
  getDiscoverLiveEvents
} from 'state/reducers/liveEventReducer';
import { RootState } from 'state/store';

import { LiveEvent, RemainingTime } from 'types';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import Ticket from 'components/Ticket';

import { ContentType } from 'enums';
import { playContent } from 'state/reducers/sessionReducer';
import { fetchPastLiveStreamPostById } from 'state/reducers/postReducer';
import BlankPage from 'components/BlankPage';
import { toggleSnackbarOpen } from 'state/reducers/snackbarReducer';
import { formatNumber } from 'utils';
import styles from './Events.module.scss';

const cn = classnames.bind(styles);

const Events = () => {
  const { user, liveEvent } = useSelector((state: RootState) => ({
    user: state.user,
    creator: state.creator,
    liveEvent: state.liveEvent
  }));
  const [purchaseEvent, setPurchaseEvent] = useState<LiveEvent | undefined>();
  const [showCancelTicket, setShowCancelTicket] = useState<boolean>();
  const [purchaseDisabled, setPurchaseDisabled] = useState<boolean>(false);

  const dispatch = useDispatch();

  const purchaseTicket = (event: LiveEvent) => {
    setPurchaseDisabled(true);
    dispatch(purchaseEventTicket({ event, userGuid: user.user?.guid, liveEvents: liveEvent.liveEvents }));
  };

  const viewTicket = (event: LiveEvent) => {
    setTimeLeft(calculateTimeLeft(event));
    dispatch(resetActiveLiveEvent());
    if (event?.liveStreamGUID) {
      dispatch(getLiveEvent(event));
    }

    setPurchaseEvent(event);
  };

  const watchLiveEvent = (event: LiveEvent) => {
    setPurchaseEvent(undefined);
    dispatch(playContent({ contentType: ContentType.Post, post: liveEvent.activeLiveEvent }));
    dispatch(fetchPastLiveStreamPostById(event.liveStreamGUID || ''));
  };

  const confirmCancelTicket = (event: LiveEvent) => {
    dispatch(cancelTicket({ liveEvents: event, bookedLiveEvents: liveEvent.bookedLiveEvents }));
    setPurchaseEvent(undefined);
    setShowCancelTicket(undefined);
  };

  const calculateTimeLeft = (purchaseEvent: LiveEvent | undefined) => {
    let difference = 0;
    if (purchaseEvent) {
      difference = +new Date(purchaseEvent.dateOfEvent as Date) - +new Date();
    }
    let timeLeft: RemainingTime = {
      eventStart: false,
      raw: 0
    };

    if (difference > 0) {
      timeLeft = {
        raw: difference,
        eventStart: false,
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    } else {
      timeLeft.eventStart = true;
      timeLeft.raw = 0;
    }
    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(purchaseEvent));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(purchaseEvent));
    }, 1000);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    dispatch(getDiscoverLiveEvents());
  }, []);

  useEffect(() => {
    setPurchaseEvent(undefined);
  }, [liveEvent.bookedLiveEvents]);

  useEffect(() => {
    if (liveEvent.status === 'rejected' && liveEvent.errorPayload) {
      dispatch(
        toggleSnackbarOpen({
          message: liveEvent.errorPayload.message,
          type: 'danger',
          timeout: 3000
        })
      );
    }
  }, [liveEvent.errorPayload]);

  useEffect(() => {
    if (liveEvent.status === 'success' && liveEvent.isUnlockSuccess) {
      dispatch(
        toggleSnackbarOpen({
          message: 'Ticket has been purchased.',
          type: 'info',
          timeout: 3000
        })
      );
      setPurchaseDisabled(false);
    }
  }, [liveEvent.isUnlockSuccess]);

  return (
    <div className={cn('container')}>
      <div className={cn('content')}>
        <div className={cn('main')}>
          {liveEvent.ongoingLiveEvents &&
            liveEvent.ongoingLiveEvents.filter((x) => x.hasTicketForCallingUser).length > 0 && (
              <div className={cn('ft-mt-8', 'big-section')}>
                <div className={cn('section')}>Ongoing Live Events</div>
                <div className={cn('fluid-wrapper')}>
                  <div className={cn('flexbox', 'fluid')}>
                    {liveEvent.ongoingLiveEvents?.map((event) => (
                      <div key={event.guid}>
                        {event.hasTicketForCallingUser && (
                          <div className={cn('tile-sm')} onClick={() => viewTicket(event)}>
                            <>
                              <Ticket
                                artist={event.merchantName}
                                size="xs"
                                passType={event.ticketType ? event.ticketType : 'FullTicket'}
                                backgroundUrl={event.thumbnailImageUrl}
                                name={event.name}
                                date={moment(event.dateOfEvent).format('MMM DD / hh:mm a')}
                                primaryColor={event.colorHex ? '#' + event.colorHex : event.primaryColor}
                                secondaryColor={
                                  event.secondaryColorHex ? '#' + event.secondaryColorHex : event.secondaryColor
                                }
                              />
                            </>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

          {liveEvent.ongoingLiveEvents &&
            liveEvent.ongoingLiveEvents.filter((x) => !x.hasTicketForCallingUser).length > 0 && (
              <div className={cn('ft-mt-8', 'big-section')}>
                <div className={cn('section')}>Other Live Events</div>
                <div className={cn('fluid-wrapper')}>
                  <div className={cn('flexbox', 'fluid')}>
                    {liveEvent.ongoingLiveEvents?.map((event) => (
                      <>
                        {!event.hasTicketForCallingUser && (
                          <div className={cn('tile-sm')} key={event.guid} onClick={() => setPurchaseEvent(event)}>
                            <>
                              <Ticket
                                artist={event.merchantName}
                                size="xs"
                                passType={event.ticketType ? event.ticketType : 'FullTicket'}
                                backgroundUrl={event.thumbnailImageUrl}
                                name={event.name}
                                date={moment(event.dateOfEvent).format('MMM DD / hh:mm a')}
                                primaryColor={event.colorHex ? '#' + event.colorHex : event.primaryColor}
                                secondaryColor={
                                  event.secondaryColorHex ? '#' + event.secondaryColorHex : event.secondaryColor
                                }
                              />
                            </>
                          </div>
                        )}
                      </>
                    ))}
                  </div>
                </div>
              </div>
            )}

          {liveEvent.liveEvents && liveEvent.liveEvents.length > 0 && (
            <div className={cn('ft-mt-8', 'big-section')}>
              <div className={cn('section')}>Upcoming Live Events</div>
              <div className={cn('fluid-wrapper')}>
                <div className={cn('flexbox', 'fluid')}>
                  {liveEvent.liveEvents?.map(
                    (event) =>
                      !event.hasTicketForCallingUser &&
                      event.isPublished && (
                        <div className={cn('tile-sm')} key={event.guid} onClick={() => viewTicket(event)}>
                          <>
                            <Ticket
                              artist={event.merchantName}
                              size="xs"
                              passType={event.ticketType ? event.ticketType : 'FullTicket'}
                              backgroundUrl={event.thumbnailImageUrl}
                              name={event.name}
                              date={moment(event.dateOfEvent).format('MMM DD / hh:mm a')}
                              primaryColor={event.colorHex ? '#' + event.colorHex : event.primaryColor}
                              secondaryColor={
                                event.secondaryColorHex ? '#' + event.secondaryColorHex : event.secondaryColor
                              }
                            />
                          </>
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
          )}

          {liveEvent.bookedLiveEvents && liveEvent.bookedLiveEvents?.length > 0 && (
            <div className={cn('ft-mt-8')}>
              <div className={cn('section')}>Booked Live Events</div>
              <div className={cn('fluid-wrapper')}>
                <div className={cn('flexbox', 'fluid')}>
                  {liveEvent.bookedLiveEvents?.map(
                    (event) =>
                      event.isPublished && (
                        <div className={cn('tile-sm')} key={event.guid} onClick={() => viewTicket(event)}>
                          <>
                            <Ticket
                              artist={event.merchantName}
                              size="xs"
                              passType={event.ticketType ? event.ticketType : 'FullTicket'}
                              backgroundUrl={event.thumbnailImageUrl}
                              name={event.name}
                              date={moment(event.dateOfEvent).format('MMM DD / hh:mm a')}
                              primaryColor={event.colorHex ? '#' + event.colorHex : event.primaryColor}
                              secondaryColor={
                                event.secondaryColorHex ? '#' + event.secondaryColorHex : event.secondaryColor
                              }
                            />
                          </>
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
          )}

          {(liveEvent.liveEvents === undefined || liveEvent.liveEvents?.length === 0) &&
          (liveEvent.ongoingLiveEvents === undefined || liveEvent.ongoingLiveEvents?.length === 0) &&
          (liveEvent.bookedLiveEvents === undefined || liveEvent.bookedLiveEvents?.length === 0) ? (
            <BlankPage text="You have no upcoming Event tickets." />
          ) : (
            ''
          )}
        </div>
      </div>

      {purchaseEvent && (
        <Modal width="90rem" onClose={() => setPurchaseEvent(undefined)}>
          <div className={cn('event-modal')}>
            {!showCancelTicket ? (
              <>
                <div>
                  <Ticket
                    size="sm"
                    passType={purchaseEvent.ticketType ? purchaseEvent.ticketType : 'FullTicket'}
                    backgroundUrl={purchaseEvent.thumbnailImageUrl}
                    name={purchaseEvent.name}
                    artist={purchaseEvent.merchantName}
                    primaryColor={purchaseEvent.colorHex ? '#' + purchaseEvent.colorHex : purchaseEvent.primaryColor}
                    secondaryColor={
                      purchaseEvent.secondaryColorHex
                        ? '#' + purchaseEvent.secondaryColorHex
                        : purchaseEvent.secondaryColor
                    }
                  />
                </div>
                <div className={cn('details')}>
                  <div className={cn('title')}>{purchaseEvent.name}</div>
                  <div className={cn('has-text-weight-semibold')}>
                    {moment(purchaseEvent.dateOfEvent).format('MMM DD')} /{' '}
                    {moment(purchaseEvent.dateOfEvent).format('h:mm')}-
                    {moment(purchaseEvent.dateOfEvent).add(purchaseEvent.durationInMinutes, 'm').format('h:mm A')}
                  </div>
                  <div className={cn('ft-mt-4')}>{purchaseEvent.description}</div>
                  <div className={cn('purchase')}>
                    {!purchaseEvent.hasTicketForCallingUser ? (
                      <Button
                        hasIconOnText
                        onClick={() => {
                          purchaseTicket(purchaseEvent);
                        }}
                        disabled={purchaseDisabled}>
                        Purchase ticket for ${formatNumber((purchaseEvent.amount as number) / 100)} or{' '}
                        {formatNumber(purchaseEvent.amount as number)}
                        <Icon name="fanbucks" className={'ft-ml-1'} />
                      </Button>
                    ) : liveEvent.activeLiveEvent?.playbackUrl ||
                      liveEvent.activeLiveEvent?.status === 'Active' ||
                      purchaseEvent.liveStreamStatus === 'Active' ? (
                      <Button onClick={() => watchLiveEvent(purchaseEvent)}>Watch</Button>
                    ) : purchaseEvent.status === 'Pending' && timeLeft.eventStart ? (
                      'Event will start soon.'
                    ) : purchaseEvent.liveStreamStatus !== 'Active' || !timeLeft.eventStart ? (
                      <>
                        <div className={cn('flexbox')}>
                          {`Event will start in 
                        ${timeLeft.raw > 86400000 ? `${timeLeft.days} ${timeLeft.days !== 1 ? 'days' : 'day'}` : ''}
                        ${timeLeft.raw > 3600000 ? `${timeLeft.hours} ${timeLeft.hours !== 1 ? 'hours' : 'hour'}` : ''} 
                        ${
                          timeLeft.raw > 60000
                            ? `${timeLeft.minutes} ${timeLeft.minutes !== 1 ? 'minutes' : 'minute'}`
                            : ''
                        } 
                        ${
                          timeLeft.seconds ? `${timeLeft.seconds} ${timeLeft.seconds !== 1 ? 'seconds' : 'second'}` : ''
                        }`}
                        </div>
                        {timeLeft.days && timeLeft.days >= 1 && purchaseEvent.liveEventTicketGUID ? (
                          <div className={cn('flexbox', 'ft-mt-4')}>
                            <Button onClick={() => setShowCancelTicket(true)}>Cancel Ticket</Button>
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      'Event will start soon.'
                    )}
                  </div>
                  <div className={cn('is-size-8', 'ft-mt-4')}>
                    <div>
                      Cancelation Policy: You may cancel your ticket up to one day before the event and receive a full
                      refund (where applicable). No refunds are given on the day of the event.
                    </div>
                    <div className={cn('ft-mt-1')}>
                      When you purchase this event, you will receive the event pass which you can add on your profile to
                      show other fans.
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div>
                  <div className={cn('flexbox', 'ft-mb-4')}>
                    Are you sure you want to cancel your ticket for this event?
                  </div>
                  <Button color="primary" onClick={() => confirmCancelTicket(purchaseEvent)}>
                    Cancel Ticket
                  </Button>
                  <Button
                    type="button"
                    className={cn('ft-ml-4')}
                    color="clear"
                    onClick={() => setShowCancelTicket(undefined)}>
                    Cancel
                  </Button>
                </div>
              </>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Events;
