import { httpClient } from 'http/httpClient';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RequestCallAvailability, VideoCallRequest, VideoMessageRequest } from 'types';
import { PendingAction, RejectedAction } from 'state/store';
import { cloneDeep } from 'lodash';
import { ErrorPayload } from 'types/errorPayload';

export const videoMessageRequest = createAsyncThunk(
  'request/videoMessageRequest',
  async (request: VideoMessageRequest) => {
    const response = await httpClient.post('/merchantvideomessagerequests', request);
    return response.data;
  }
);

export const videoCallRequest = createAsyncThunk(
  'request/videoCallRequest',
  async (request: VideoCallRequest, { rejectWithValue }) => {
    try {
      const response = await httpClient.post('/merchantmeetandgreetrequests', request);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getVideoMessageRequests = createAsyncThunk(
  'request/getVideoMessageRequests',
  async (request: VideoMessageRequest) => {
    request.platform = 'HeyFans';
    const response = await httpClient.get('/merchantvideomessagerequests/search', { params: request });
    return response.data;
  }
);

export const getFanVideoMessageRequests = createAsyncThunk(
  'request/getFanVideoMessageRequests',
  async (request: VideoMessageRequest) => {
    request.platform = 'HeyFans';
    const response = await httpClient.get('/merchantvideomessagerequests/search', { params: request });
    return response.data;
  }
);

export const getVideoCallRequests = createAsyncThunk(
  'request/getVideoCallRequests',
  async (request: VideoMessageRequest) => {
    request.platform = 'HeyFans';
    const response = await httpClient.get('/merchantmeetandgreetrequests/search', { params: request });
    return response.data;
  }
);

export const getFanVideoCallRequests = createAsyncThunk(
  'request/getFanVideoCallRequests',
  async (request: VideoMessageRequest) => {
    request.platform = 'HeyFans';
    const response = await httpClient.get('/merchantmeetandgreetrequests/search', { params: request });
    return response.data;
  }
);

export const getVideoCallsAvailability = createAsyncThunk(
  'request/getVideoCallsAvailability',
  async (request: VideoCallRequest) => {
    const response = await httpClient.get('/merchantcalendarevents/search', { params: request });
    return response.data;
  }
);

export const callResponse = createAsyncThunk(
  'request/callResponse',
  async (request: { guid: string; isAnswer: boolean }, { rejectWithValue }) => {
    try {
      const response = await httpClient.post(
        `/merchantmeetandgreetrequests/${request.guid}/videomeeting/${request.isAnswer ? 'accept' : 'reject'}`,
        {
          params: { guid: request.guid }
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

interface RequestState {
  status: string;
  error?: string;
  videoMessageRequests?: VideoMessageRequest[];
  fanVideoMessageRequests?: VideoMessageRequest[];
  videoCallRequests?: VideoCallRequest[];
  fanVideoCallRequests?: VideoCallRequest[];
  isVideoMessageRequestSuccess?: boolean;
  isVideoCallRequestSuccess?: boolean;
  callAvailability?: RequestCallAvailability[];
  reserveSlots?: RequestCallAvailability[];
  errorPayload?: ErrorPayload;
}

const initialState: RequestState = {
  status: 'idle'
};

const requestSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    reset: (state) => {
      state = initialState;
      return state;
    },
    updateVideoCallRequest: (state, action) => {
      const videoCalls = cloneDeep(state.videoCallRequests);
      const videoCall = action.payload as VideoCallRequest;
      const index = videoCalls?.findIndex((v) => v.guid === videoCall.guid);
      if (index !== undefined && videoCalls !== undefined) {
        videoCalls[index] = videoCall;
      }
      state.videoCallRequests = videoCalls;
    },
    resetRequest: (state) => {
      state.isVideoCallRequestSuccess = undefined;
      state.isVideoMessageRequestSuccess = undefined;
      state.errorPayload = undefined;
      state.error = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(videoMessageRequest.fulfilled, (state) => {
        state.status = 'success';
        state.isVideoMessageRequestSuccess = true;
      })
      .addCase(videoCallRequest.fulfilled, (state) => {
        state.status = 'success';
        state.isVideoCallRequestSuccess = true;
      })
      .addCase(getVideoMessageRequests.fulfilled, (state, action) => {
        state.status = 'success';
        state.videoMessageRequests = action.payload;
      })
      .addCase(getFanVideoMessageRequests.fulfilled, (state, action) => {
        state.status = 'success';
        state.fanVideoMessageRequests = action.payload;
      })
      .addCase(getVideoCallRequests.fulfilled, (state, action) => {
        state.status = 'success';
        state.videoCallRequests = action.payload;
      })
      .addCase(getFanVideoCallRequests.fulfilled, (state, action) => {
        state.status = 'success';
        state.fanVideoCallRequests = action.payload;
      })
      .addCase(getVideoCallsAvailability.fulfilled, (state, action) => {
        state.status = 'success';
        if (action.meta.arg.type === 'MeetAndGreet') {
          state.reserveSlots = action.payload;
        }
        if (action.meta.arg.type === 'Available') {
          state.callAvailability = action.payload;
        }
      })
      .addCase(videoMessageRequest.rejected, (state) => {
        state.isVideoMessageRequestSuccess = false;
        state.status = 'rejected';
      })
      .addCase(videoCallRequest.rejected, (state, action) => {
        state.isVideoCallRequestSuccess = false;
        state.status = 'rejected';
        const errorPayload = action.payload as ErrorPayload;
        state.errorPayload = errorPayload;
        const message = errorPayload.message ? errorPayload.message : '';
        state.error = message;
      })
      .addMatcher(
        (action): action is PendingAction => action.type.endsWith('/pending'),
        (state) => {
          state.status = 'loading';
        }
      )
      .addMatcher(
        (action): action is RejectedAction => action.type.endsWith('/rejected'),
        (state, action) => {
          state.status = 'rejected';
          if (action.payload) {
            const errorPayload = action.payload as ErrorPayload;
            const message = errorPayload.message ? errorPayload.message : '';
            state.error = message;
            state.errorPayload = action.payload as ErrorPayload;
          }
        }
      );
  }
});

export const { reset, updateVideoCallRequest, resetRequest } = requestSlice.actions;
export default requestSlice.reducer;
