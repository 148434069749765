import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { CreditCard, Merchant, SubscriptionPlan, TokenBundle, User, UserPaymentProfile } from 'types';
import Button from 'components/Button';
import Collapse from 'components/Collapse';
import PaymentGateway from 'components/PaymentGateway';

import { subscribe } from 'state/reducers/subscriptionReducer';
import { Badge } from 'types/badge';

import useVideoPlayer from 'hooks/useVideoPlayer';
import { fetchCreatorActivity } from 'state/reducers/creatorReducer';
import { RootState } from 'state/store';

import styles from './SubscriptionModal.module.scss';

const cn = classnames.bind(styles);

interface SubscriptionModalProps {
  creator: Merchant;
  plans: SubscriptionPlan[];
  badges: Badge[];
  user: User;
  onSelect: (value: boolean) => void;
}

const SubscriptionModal = (props: SubscriptionModalProps) => {
  const user = useSelector((state: RootState) => state.user);
  const [step, setStep] = useState(0);
  const [openedPlan, setOpenedPlan] = useState<SubscriptionPlan>();
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan>();
  const [purchaseToken, setPurchaseToken] = useState<TokenBundle | undefined>();
  const dispatch = useDispatch();
  const videoElement = useRef(null);

  const { handleOnTimeUpdate, togglePlay } = useVideoPlayer(videoElement);

  const onOpenPlan = (plan: SubscriptionPlan) => {
    setOpenedPlan(plan);
  };

  const onSelectPlan = (plan: SubscriptionPlan) => {
    setSelectedPlan(plan);
    setPurchaseToken({
      guid: plan.guid,
      amount: (plan.amount as number) * 100,
      priceUSD: plan.amount
    });
    setStep(1);
  };

  const confirmPurchase = (creditCard?: CreditCard) => {
    const card = creditCard?.guid
      ? user.paymentProfiles?.filter((x: UserPaymentProfile) => x.guid === creditCard.guid)[0]
      : creditCard;
    if (creditCard) {
      dispatch(
        subscribe({
          subscriptionPlanGuid: selectedPlan?.guid as string,
          paymentProfileGUID: creditCard?.guid as string,
          tokenProfileKey: 'USD',
          creditCard: {
            ...card
          },
          amount: purchaseToken?.priceUSD as number,
          saveCreditCard: creditCard?.saveCreditCard as boolean
        })
      );
    } else {
      dispatch(
        subscribe({
          subscriptionPlanGuid: selectedPlan?.guid as string,
          tokenProfileKey: 'USD',
          amount: purchaseToken?.priceUSD as number
        })
      );
    }
    dispatch(fetchCreatorActivity(props.creator.guid as string));
    setStep(2);
    props.onSelect(true);
  };

  const purchaseSuccess = () => {
    alert('purchase success not yet implemented');
  };

  return (
    <div className={cn('content')}>
      {
        {
          0: (
            <>
              <div className={cn('banner')}>
                <img src={props.creator.normalizedBackgroundImageUrl} />
              </div>
              <div className={cn('stub')}>
                <div className={cn('header')}>
                  Subscribe to <br /> {props.creator.name}
                </div>
                <div className={cn('ft-mt-4')}>
                  Show your support and get members-only access to the club and exclusive content.
                </div>
                <div className={cn('has-text-weight-semibold', 'is-size-6', 'ft-mt-4')}>
                  Select a plan to get started
                </div>

                {props.plans.length > 0 && (
                  <div className={cn('subscription-plan-list', 'ft-mt-2')}>
                    {props.plans.map((plan) => (
                      <div key={plan.guid} className={cn('subscription-plan')} onClick={() => onOpenPlan(plan)}>
                        <div className={cn('subscription-plan-header')}>
                          <div className={cn('image')}>
                            <img src={plan.thumbnailUrl} />
                          </div>
                          <div className={cn('is-size-5', 'has-text-weight-semibold', 'ft-ml-4')}>{plan.name}</div>
                        </div>
                        <div className={cn('subscription-plan-content')}>
                          <Collapse isOpen={plan.guid === openedPlan?.guid}>
                            <div
                              className={cn('subscription-plan-benefits', {
                                hidden: plan.guid !== openedPlan?.guid
                              })}>
                              <div>What you get:</div>
                              <ul className={cn('details')}>
                                {plan.functions?.map((benefit, index) => (
                                  <li key={index}>{benefit.description?.replace('___', benefit.value as string)}</li>
                                ))}
                              </ul>

                              <div className={cn('flexbox', 'ft-mt-4')}>
                                <div>
                                  <Button className={cn('button-subscribe')} onClick={() => onSelectPlan(plan)}>
                                    {`Subscribe Now $${plan.amount}/month`}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Collapse>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </>
          ),
          1: (
            <>
              <div className={cn('banner')}>
                <img src={props.creator.normalizedBackgroundImageUrl} />
              </div>
              <div className={cn('payment-form')}>
                <div className={cn('is-size-5', 'has-text-weight-semibold')}>Confirm purchase</div>
                <div className={cn('ft-mt-4')}>You are about to subscribe to:</div>
                <div className={cn('subscription-plan')}>
                  <div className={cn('subscription-plan-header')}>
                    <div className={cn('image')}>
                      <img src={selectedPlan?.thumbnailUrl} />
                    </div>
                    <div className={cn('is-size-5', 'has-text-weight-semibold', 'ft-ml-4')}>{selectedPlan?.name}</div>
                  </div>
                  <div className={cn('subscription-plan-content')}>
                    <div className={cn('subscription-plan-benefits')}>
                      <div>This plan includes:</div>
                      <ul className={cn('details')}>
                        {selectedPlan?.functions?.map((benefit, index) => (
                          <li key={index}>{benefit.description?.replace('___', benefit.value as string)}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={cn('is-size-6', 'has-text-weight-bold', 'total')}>
                  <span className={cn('ft-mr-4')}>Your total:</span>${selectedPlan?.amount}/month
                </div>
                <div className={cn('payment-gateway')}>
                  <PaymentGateway
                    onConfirm={confirmPurchase}
                    purchaseToken={purchaseToken}
                    onSuccess={purchaseSuccess}
                    isSubscription={true}
                    name={props.creator.name}
                  />
                </div>
                <div className={cn('ft-mt-4')}>
                  <Button color="clear" onClick={() => confirmPurchase()}>
                    Debug: Skip
                  </Button>
                </div>
              </div>
            </>
          ),
          2: (
            <>
              <div className={cn('banner')}>
                <img src={props.creator.normalizedBackgroundImageUrl} />
              </div>
              <div className={cn('stub')}>
                <div>Thank you for subscribing! You have unlocked a badge to show off on your profile.</div>
                {props.badges.find(
                  (badge) => badge.type === 'LoyaltyBadge' && badge.membershipDuration === 'OneMonth'
                ) && (
                  <>
                    <div className={cn('badge-header', 'ft-mt-4')}>
                      <div className={cn('badge-container')}>
                        <div className={cn('badge')}>
                          <img
                            src={
                              props.badges.find(
                                (badge) => badge.type === 'LoyaltyBadge' && badge.membershipDuration === 'OneMonth'
                              )?.badgeImageUrl
                            }
                          />
                        </div>
                        <div className={cn('is-size-8', 'ft-mt-2')}>1 month Badge</div>
                      </div>
                    </div>
                  </>
                )}

                {selectedPlan?.note && (
                  <>
                    <div className={cn('ft-mt-4')}>{selectedPlan?.note}</div>
                  </>
                )}

                {selectedPlan?.videoMessageUrl && (
                  <>
                    <div className={cn('ft-mt-4')}>Here’s a thank you video from {props.creator?.name}:</div>
                    <div className={cn('video-box', 'ft-mt-4')}>
                      <video
                        controls
                        controlsList="nodownload"
                        className={cn('media', 'video-message')}
                        src={selectedPlan?.videoMessageUrl}
                        ref={videoElement}
                        onTimeUpdate={handleOnTimeUpdate}
                        onClick={togglePlay}
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          )
        }[step]
      }
    </div>
  );
};

export default SubscriptionModal;
