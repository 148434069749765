import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import moment from 'moment';

import { fetchCreatorActivity, resetCreator, resetFeed } from 'state/reducers/creatorReducer';
import { fetchFeed, resetPosts } from 'state/reducers/postReducer';
import { playContent, toggleMessages, toggleNotifications } from 'state/reducers/sessionReducer';
import {
  fetchCalendarContent,
  fetchMerchantFollowing,
  fetchNotifications,
  resetNotification,
  searchBlockers
} from 'state/reducers/userReducer';
import { RootState } from 'state/store';
import { useHistory } from 'react-router-dom';

import { ContentType, PostType } from 'enums';
import { Feed } from 'types';
import Icon from 'components/Icon';
import PostFeed from 'components/PostFeed';
import Skeleton from 'components/Skeleton';

import defaultAvatar from 'assets/images/default-avatar-1.png';
import myFeed from 'assets/images/my-feed.png';

import CalendarFeed from 'components/CalendarFeed';
import { getMerchantLiveEvents, getUserLiveEvents } from 'state/reducers/liveEventReducer';
import { getLiveStreams } from 'state/reducers/liveStreamReducer';
import styles from './Home.module.scss';

const cn = classnames.bind(styles);

const Home = () => {
  const { creator, post, user, liveEvent, liveStream, session } = useSelector((state: RootState) => ({
    creator: state.creator,
    post: state.post,
    user: state.user,
    liveEvent: state.liveEvent,
    liveStream: state.liveStream,
    session: state.session
  }));
  const [selectedUser, setSelectedUser] = useState('');
  const [offset, setOffset] = useState(0);
  const [isShowSidebar, setIsShowSidebar] = useState(true);
  const [feed, setFeed] = useState<Feed[] | undefined>(undefined);
  const [showCalendar, setShowCalendar] = useState(false);
  const [blockedBy, setBlockedBy] = useState<string[]>([]);

  const dispatch = useDispatch();
  const history = useHistory();

  const feedRef = useRef<HTMLDivElement>(null);

  const onSwitchCreator = (guid: string) => {
    // const isNewUser = user.user?.followingUserCount !== undefined && user.user?.followingUserCount > 0 ? true : false;
    setSelectedUser(guid);
    setOffset(0);
    dispatch(resetPosts());
    if (guid !== '' && guid !== selectedUser) {
      dispatch(resetCreator());
      dispatch(resetFeed());
      dispatch(fetchCreatorActivity(guid));
    } else {
      dispatch(fetchFeed({ offset: 0 }));
    }
  };

  const handleFeedScroll = () => {
    if (feedRef.current) {
      if (feedRef.current?.scrollTop === feedRef.current?.scrollHeight - feedRef.current?.offsetHeight) {
        const newOffset = offset + 10;
        dispatch(fetchFeed({ offset: newOffset }));
        setOffset(newOffset);
      }
    }
  };

  const checkLiveSticky = () => {
    const live = liveStream.liveStreams?.find((x) => x.merchantGUID === selectedUser);
    if (live) {
      return (
        <div
          className={cn('sticky')}
          onClick={() => dispatch(playContent({ contentType: ContentType.Post, post: live }))}>
          <img className={cn('avatar')} src={live.normalizedProfileImageUrl || defaultAvatar} />
          <div className={cn('ft-p-2')}>
            <div className={cn('has-text-danger', 'is-size-7')}>Click to watch {live.merchantName}</div>
            <div className={cn('has-text-weight-semibold', 'is-size-6')}>
              {moment(live.dateCreated).format('MM/DD/YYYY')} live stream
            </div>
            <div className={cn('views', 'ft-mt-2')}>{live?.activeViewerCount} viewers</div>
          </div>
        </div>
      );
    }
  };

  const toggleRightSidebar = () => {
    if (session.isNotificationsOpen) {
      dispatch(toggleNotifications());
      setTimeout(() => {
        dispatch(toggleMessages());
      }, 500);
    } else {
      dispatch(toggleMessages());
    }
  };

  const currentDate = new Date();
  const minDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const maxDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0, 23, 59, 59);

  useEffect(() => {
    // const userType = user.user?.merchantGUID ? 1 : 0;
    // const isNewUser =
    //   user.user?.followingUserCount !== undefined && user.user?.followingUserCount > userType ? true : false;

    dispatch(fetchFeed({ offset }));
    dispatch(getLiveStreams());
    dispatch(resetNotification());
    dispatch(fetchNotifications());
  }, []);

  useEffect(() => {
    if (user.user?.guid) {
      dispatch(fetchMerchantFollowing(user.user?.guid));
      dispatch(searchBlockers(user.user.guid));
      dispatch(
        fetchCalendarContent({
          userGuid: user.user.guid,
          merchantGuid: user.user.merchantGUID || undefined,
          dateStart: minDate,
          dateEnd: maxDate
        })
      );
    }
    dispatch(getUserLiveEvents());
    if (user.user?.merchantGUID) {
      dispatch(getMerchantLiveEvents(user.user.merchantGUID));
    }
  }, [user.user]);

  useEffect(() => {
    if (selectedUser) {
      setFeed(creator.feed);
    } else {
      setFeed(post.posts);
    }
  }, [selectedUser, post.posts, creator.feed]);

  useEffect(() => {
    if (user.blockers && user.merchantFollowing) {
      const merchantBlockers: string[] = [];
      user.merchantFollowing?.forEach((following) => {
        if (user.blockers?.find((x) => x.userGUID === following.followedUserGUID)) {
          merchantBlockers.push(following.followedUserGUID as string);
        }
      });
      setBlockedBy(merchantBlockers);
    }
  }, [user.blockers, user.merchantFollowing]);

  return (
    <div className={cn('container')}>
      <div className={cn('sidebar', { hide: !isShowSidebar })}>
        <div className={cn('creator', { highlight: selectedUser === '' })} onClick={() => onSwitchCreator('')}>
          <div className={cn('avatar')}>
            <img src={myFeed} />
          </div>
          <div className={cn('name')}>My Feed</div>
        </div>
        <div>
          <div className={cn('section', 'is-size-8')}>
            <span>FOLLOWING</span>
          </div>
          {
            <>
              {user.merchantFollowing ? (
                <>
                  {user.merchantFollowing
                    ?.filter((x) => !blockedBy.includes(x.followedUserGUID as string) && x.isFollowedUserMerchantActive)
                    .map((following) => {
                      const isLive = !!liveStream.liveStreams?.find(
                        (x) => x.merchantGUID === following.followedUserMerchantGUID && x.liveStreamStatus === 'Active'
                      );
                      return (
                        <div
                          key={following.guid}
                          className={cn('creator', { highlight: selectedUser === following.followedUserMerchantGUID })}
                          onClick={() => {
                            history.push(`/${following?.followedUserUsername}${isLive ? '/streams' : ''}`);
                          }}>
                          <div className={cn('avatar', { live: isLive })}>
                            <img src={following.followedUserNormalizedProfileImageUrl || defaultAvatar} />
                          </div>
                          <div className={cn('name')}>
                            {following.followedUserDisplayName || `@${following.followedUserUsername}`}
                            {isLive && <div className={cn('has-text-danger', 'is-size-8')}>LIVE</div>}
                          </div>
                        </div>
                      );
                    })}
                </>
              ) : (
                <div className={cn('sidebar-skeleton')}>
                  <div className={cn('flexbox', 'ft-py-1')}>
                    <Skeleton type="circ" radius={16} />
                    <Skeleton className={cn('desktop', 'ft-ml-2')} type="rect" width="160px" height="16px" />
                  </div>
                  <div className={cn('flexbox', 'ft-py-1')}>
                    <Skeleton type="circ" radius={16} />
                    <Skeleton className={cn('desktop', 'ft-ml-2')} type="rect" width="160px" height="16px" />
                  </div>
                  <div className={cn('flexbox', 'ft-py-1')}>
                    <Skeleton type="circ" radius={16} />
                    <Skeleton className={cn('desktop', 'ft-ml-2')} type="rect" width="160px" height="16px" />
                  </div>
                </div>
              )}
            </>
          }
        </div>
        <div className={cn('hide-sidebar')} onClick={() => setIsShowSidebar(false)}>
          <div className={cn('arrow', 'flexbox')}>
            <Icon name="arrow-left" size="xs" />
          </div>
        </div>
      </div>
      {!isShowSidebar && <div className={cn('sidebar-open')} onClick={() => setIsShowSidebar(true)} />}
      <div className={cn('feed', { hide: !isShowSidebar })} ref={feedRef} onScroll={handleFeedScroll}>
        {selectedUser && checkLiveSticky()}
        <div className={cn('feed-wrapper')}>
          <div className={cn('ft-mt-4', 'feed-options', 'flexbox')}>
            <div className={cn('calendar-option-container')} onClick={() => setShowCalendar(!showCalendar)}>
              {/* <div className={cn('calendar-badge')} /> */}
              <Icon className={cn('calendar-icon')} name="calendar" />
            </div>
            <div className={cn('events', 'flexbox')} onClick={() => history.push('/preferences/events')}>
              <Icon name="ticket" />
              <div className={cn('hide-on-mobile', 'ml-4')}>
                My Events{' '}
                {liveEvent.bookedLiveEvents &&
                  `(${
                    liveEvent.bookedLiveEvents?.length +
                    (liveEvent.merchantEvents ? liveEvent.merchantEvents.length : 0)
                  })`}
              </div>
            </div>
            <div className={cn('request', 'flexbox')} onClick={() => history.push('/preferences/video-calls')}>
              <Icon name="video-call" />
              <div className={cn('hide-on-mobile', 'ml-4')}>
                Video Calls {user.user && `(${user.user.pendingMeetAndGreetRequestCount})`}
              </div>
            </div>
            <div className={cn('messages', 'flexbox')} onClick={() => history.push('/preferences/video-messages')}>
              <Icon name="video-message" />
              <div className={cn('hide-on-mobile', 'ml-4')}>
                My Video Messages {user.user && `(${user.user.pendingVideoMessageRequestCount})`}
              </div>
            </div>
            <div className={cn('messages', 'flexbox')} onClick={toggleRightSidebar}>
              <Icon name="messages" />
              <div className={cn('hide-on-mobile', 'ml-4')}>
                My DMs {user.user && `(${user.user.unreadMessageCount})`}
              </div>
            </div>
          </div>
          {user.calendar && showCalendar && (
            <div className={cn('calendar')}>
              <CalendarFeed />
            </div>
          )}

          {feed ? (
            <>
              {!showCalendar
                ? feed
                    ?.filter((x) => x.postType !== PostType.LiveEvent)
                    .map(
                      (feedPost) =>
                        (liveStream.liveStreams?.length === 0 ||
                          liveStream.liveStreams?.find(
                            (x) =>
                              feedPost.guid !== x.guid &&
                              (feedPost.postType !== PostType.LiveStream || feedPost.contentUrl)
                          )) && (
                          <div className={cn('post')} key={feedPost.guid}>
                            <PostFeed type="feed" isPromoted={feedPost.isPromoted} feed={feedPost} />
                          </div>
                        )
                    )
                : ''}
            </>
          ) : (
            <>
              <div className={cn('feed-skeleton')}>
                <Skeleton type="rect" width="100%" height="320px" />
                <Skeleton type="rect" width="100%" height="320px" />
                <Skeleton type="rect" width="100%" height="320px" />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
