import React from 'react';
import classnames from 'classnames/bind';
import Button from 'components/Button';
import { UserBlock } from 'types';

import styles from './UnblockUserModal.module.scss';

const cn = classnames.bind(styles);

interface UnlockUserProps {
  onSelect: (value: boolean) => void;
  unBlockedUser: UserBlock;
}

const UnblockUserModal = (props: UnlockUserProps) => {
  return (
    <div className={cn('modal-content')}>
      <strong>{props.unBlockedUser.blockedUserDisplayName}</strong> will be able to send requests or message you. Are
      you sure you want to unblock <strong>{props.unBlockedUser.blockedUserDisplayName}</strong>?
      <div className={cn('ft-mt-8')}>
        <Button color="danger" className={cn('ft-mr-4')} onClick={() => props.onSelect(true)}>
          Unblock
        </Button>
        <Button color="white" onClick={() => props.onSelect(false)}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default UnblockUserModal;
