import { ComponentType } from 'react';

import Login from 'routes/Login';
import Legal from 'routes/Legal';
import Contact from 'routes/Company/Contact';
import Base from 'routes/Base';
import Home from 'routes/Home';
import Discover from 'routes/Discover';
import CreatorMain from 'routes/Creator';
import Creators from 'routes/Discover/Creators';
import Events from 'routes/Discover/Events';
import Activity from 'routes/Creator/Activity';
import Music from 'routes/Creator/Music';
import Videos from 'routes/Creator/Videos';
import Gallery from 'routes/Creator/Gallery';
import Club from 'routes/Creator/Club';
import Livestream from 'routes/Creator/Livestream';
import Merchandise from 'routes/Creator/Merchandise';
import CreatorEvent from 'routes/Creator/CreatorEvent';
import Preferences from 'routes/Preferences';
import Account from 'routes/Preferences/Account';
import Profile from 'routes/Profile';
import Subscription from 'routes/Preferences/Subscription';
import Wallet from 'routes/Preferences/Wallet';
import Event from 'routes/Preferences/Event';
import VideoCall from 'routes/Preferences/VideoCall';
import VideoMessage from 'routes/Preferences/VideoMessage';
import About from 'routes/Preferences/About';
import ClubPage from 'routes/Club/Club';
import Terms from 'routes/Legal/Terms';
import Privacy from 'routes/Legal/Privacy';
import Copyright from 'routes/Legal/Copyright';
import Cookies from 'routes/Legal/Cookies';
import Follower from 'routes/Preferences/Follower';
import Following from 'routes/Preferences/Following';
import Block from 'routes/Preferences/Block';
import HowDoI from 'routes/Company/HowDoI';
import Faq from 'routes/Company/Faq';
import Company from 'routes/Company';
import Apply from 'routes/Apply';
import Item from 'routes/Item';

export interface RouteConfig {
  path: string | string[];
  component: ComponentType;
  authenticated?: boolean;
  base?: string;
  exact?: boolean;
  private?: boolean;
}

export const baseRouteConfig: RouteConfig[] = [
  {
    path: '/login',
    component: Login
  },
  {
    path: '/verify',
    component: Login
  },
  {
    path: '/legal',
    component: Legal
  },
  {
    path: '/company',
    component: Company
  },
  {
    path: '/apply',
    component: Apply
  },
  {
    path: '/',
    component: Base
  }
];

export const mainRouteConfig: RouteConfig[] = [
  {
    path: '/discover',
    component: Discover
  },
  {
    path: '/profile/:userId?',
    component: Profile
  },
  {
    path: '/preferences',
    component: Preferences
  },
  {
    path: '/club',
    component: ClubPage
  },
  {
    path: '/merch/:merchId',
    component: Item
  },
  {
    path: '/:creatorId',
    component: CreatorMain
  },
  {
    path: '/',
    exact: true,
    component: Home
  }
];

export const discoverRouteConfig: RouteConfig[] = [
  {
    path: '/creators',
    component: Creators
  },
  {
    path: '/events',
    component: Events
  },
  {
    path: '/',
    exact: true,
    component: Creators
  }
];

export const creatorRouteConfig: RouteConfig[] = [
  {
    path: '/music',
    component: Music
  },
  {
    path: '/videos',
    component: Videos
  },
  {
    path: '/gallery',
    component: Gallery
  },
  {
    path: '/events',
    component: CreatorEvent
  },
  {
    path: '/club',
    component: Club
  },
  {
    path: '/streams',
    component: Livestream
  },
  {
    path: '/merchandise',
    component: Merchandise
  },
  {
    path: '/',
    exact: true,
    component: Activity
  }
];

export const preferencesRouteConfig: RouteConfig[] = [
  {
    path: '/subscriptions',
    component: Subscription
  },
  {
    path: '/wallet',
    component: Wallet
  },
  {
    path: '/events',
    component: Event
  },
  {
    path: '/video-messages',
    component: VideoMessage
  },
  {
    path: '/video-calls',
    component: VideoCall
  },
  {
    path: '/about',
    component: About
  },
  {
    path: '/legal',
    component: Legal
  },
  {
    path: '/follower',
    component: Follower
  },
  {
    path: '/following',
    component: Following
  },
  {
    path: '/block',
    component: Block
  },
  /*{
    path: '/referrals',
    component: Referrals
  },*/
  {
    path: '/apply',
    component: Apply
  },
  {
    path: '/',
    exact: true,
    component: Account
  }
];

export const legalRouteConfig: RouteConfig[] = [
  {
    path: '/terms',
    component: Terms
  },
  {
    path: '/privacy',
    component: Privacy
  },
  {
    path: '/copyright',
    component: Copyright
  },
  {
    path: '/cookies',
    component: Cookies
  }
];

export const companyRouteConfig: RouteConfig[] = [
  {
    path: '/contact',
    component: Contact
  },
  {
    path: '/how-do-i',
    component: HowDoI
  },
  {
    path: '/faq',
    component: Faq
  }
];
