import React, { MouseEventHandler, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { RootState } from 'state/store';
import { toggleSnackbarClose } from 'state/reducers/snackbarReducer';

import styles from './Snackbar.module.scss';

const cn = classnames.bind(styles);
interface SnakckBarProps {
  onClick?: MouseEventHandler;
}

const Snackbar = (props: SnakckBarProps) => {
  const dispatch = useDispatch();
  const { toggle, message, timeout, type } = useSelector((state: RootState) => ({
    toggle: state.snackbar.toggle,
    message: state.snackbar.message,
    timeout: 3000,
    type: state.snackbar.type
  }));

  function handleTimeout() {
    setTimeout(() => {
      dispatch(toggleSnackbarClose());
    }, timeout);
  }

  useEffect(() => {
    if (toggle) {
      handleTimeout();
    }
  }, [toggle]);

  return (
    <>
      {toggle && (
        <div className={cn('snackbar', type ? type : 'default')} onClick={props.onClick}>
          <div>{message}</div>
        </div>
      )}
    </>
  );
};

export default Snackbar;
