import React from 'react';

import { Feed } from 'types';

import PostFeed from 'components/PostFeed';

interface UnlockModalProps {
  feed?: Feed;
}

const UnlockModal = (props: UnlockModalProps) => {
  return (
    <>
      <PostFeed type="activity" feed={props.feed} />
    </>
  );
};

export default UnlockModal;
