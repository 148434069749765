import React, { useEffect, useRef } from 'react';
import Hls from 'hls.js';
import classnames from 'classnames/bind';

import useVideoPlayer from 'hooks/useVideoPlayer';

import Button from 'components/Button';
import Icon from 'components/Icon';

import styles from './LiveStreamPlayer.module.scss';

const cn = classnames.bind(styles);

interface LiveStreamPlayerProps {
  src?: string;
}

const LiveStreamPlayer = (props: LiveStreamPlayerProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { playerState, handleVolume, toggleFullscreen, toggleMute, closeFullscreen } = useVideoPlayer(videoRef);

  useEffect(() => {
    if (props.src) {
      const video = videoRef.current;
      if (!video) return;

      video.controls = false;
      let hls: Hls;

      if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = props.src;
      } else if (Hls.isSupported()) {
        hls = new Hls();
        hls.loadSource(props.src);
        hls.attachMedia(video);
      } else {
        console.error(
          'This is an old browser that does not support MSE: ' +
            'https://developer.mozilla.org/en-US/docs/Web/API/Media_Source_Extensions_API'
        );
      }

      return () => {
        if (hls) {
          hls.destroy();
        }
      };
    }
  }, [props.src, videoRef]);

  const noEffectFullscreen = () => {
    if (!playerState.isFullscreen) {
      const theater: HTMLElement | null = document.getElementById('theater');
      if (theater?.requestFullscreen) {
        theater?.requestFullscreen().then((r) => r);
      } else if (theater?.webkitRequestFullscreen) {
        theater?.webkitRequestFullscreen();
      } else if (theater?.webkitEnterFullscreen) {
        theater?.webkitEnterFullscreen();
      } else if (theater?.msRequestFullscreen) {
        theater?.msRequestFullscreen();
      } else if (theater?.mozRequestFullScreen) {
        theater?.mozRequestFullScreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen().catch((r) => r);
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }

    toggleFullscreen();
  };

  useEffect(() => {
    document.addEventListener(
      'fullscreenchange',
      () => {
        if (document.fullscreenElement === null) {
          closeFullscreen();
        }
      },
      false
    );
  }, []);

  return (
    <>
      <video id="video-only" ref={videoRef} autoPlay className={cn('media')} />
      <div className={cn('livestream-player-controls')}>
        <div className={cn('flexbox', 'live')}>
          <div className={cn('flexbox')}>
            <span className={cn('live-indicator')} />
            <span className={cn('live-text')}>Live</span>
          </div>
          <div className={cn('volume-wrapper')}>
            <Button color="clear" className={cn('volume')} onClick={toggleMute}>
              {!playerState.isMuted && playerState.volume >= 50 ? (
                <Icon name="volume-high" />
              ) : !playerState.isMuted && playerState.volume < 50 ? (
                <Icon name="volume-low" />
              ) : (
                <Icon name="volume-mute" />
              )}
            </Button>

            <div className={cn('volume-container')}>
              <div className={cn('volume-container-inner-wrapper')}>
                <div
                  className={cn('volume-level')}
                  style={{
                    width: !playerState.isMuted
                      ? `calc(${120 * (playerState.volume / 100)}px -  ${(10 * playerState.volume) / 100}px)`
                      : 0
                  }}
                />
                <input
                  className={cn('volume-control')}
                  type="range"
                  min="0"
                  max="100"
                  value={!playerState.isMuted ? playerState.volume : 0}
                  onChange={(e) => handleVolume(e)}
                />
              </div>
            </div>
          </div>
          <Button color="clear" className={cn('ft-ml-auto')} onClick={noEffectFullscreen}>
            {!playerState.isFullscreen ? <Icon name="expand" /> : <Icon name="contract" />}
          </Button>
        </div>
      </div>
    </>
  );
};

export default LiveStreamPlayer;
