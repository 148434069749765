import { httpClient } from 'http/httpClient';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

import { PostGroupType, PostType } from 'enums';
import { LiveStream, Post, PostComment, PostGroup, UserFlaggedContent } from 'types';
import { PendingAction, RejectedAction } from 'state/store';
import { ErrorPayload } from 'types/errorPayload';

export const fetchFeed = createAsyncThunk(
  'post/fetchFeed',
  async (request: { offset: number; followedByCallingUser?: boolean }) => {
    const response = await httpClient.get('/posts/search', {
      params: {
        isFollowedByCallingUser: request.followedByCallingUser,
        hasPostGroup: false,
        platform: 'heyfans',
        topN: 10,
        offsetN: request.offset,
        isPublished: true
      }
    });
    return response.data;
  }
);

export const fetchPostById = createAsyncThunk('post/fetchPostById', async (guid: string) => {
  const response = await httpClient.get(`/posts/${guid}`);
  return response.data;
});

export const fetchPostsByMerchatByType = createAsyncThunk(
  'post/fetchPostsByMerchatByType',
  async (request: { creatorGuid: string; postType: PostType; isPublished?: boolean }) => {
    const response = await httpClient.get('/posts/search', {
      params: { merchantGUID: request.creatorGuid, postType: request.postType, isPublished: request.isPublished }
    });
    return { postType: request.postType, posts: response.data };
  }
);

export const fetchPostGroupsThenPosts = createAsyncThunk(
  'post/fetchPostGroupsThenPosts',
  async (request: { creatorGuid: string; postGroupType: PostGroupType }) => {
    return await httpClient
      .get('/posts/search', {
        params: { userGUID: request.creatorGuid, postGroupType: request.postGroupType }
      })
      .then((response) => {
        const postGroups: PostGroup[] = response.data;
        return new Promise<PostGroup[]>((resolve) => {
          Promise.allSettled(
            postGroups.map((postGroup: PostGroup) =>
              httpClient.get('/posts/search', {
                params: { postGroupGUID: postGroup.guid }
              })
            )
          ).then((promise) => {
            const newPostGroups = cloneDeep(postGroups);

            promise.forEach((posts, index) => {
              if (posts.status === 'fulfilled') {
                newPostGroups[index].posts = posts.value.data;
                resolve(newPostGroups);
              }
            });
          });
        });
      });
  }
);

export const fetchPostGroup = createAsyncThunk(
  'post/fetchPostGroup',
  async (request: { creatorGuid: string; postGroupType: PostGroupType }) => {
    const response = await httpClient.get('/posts/search', {
      params: { userGUID: request.creatorGuid, postGroupType: request.postGroupType }
    });
    return response.data;
  }
);

export const fetchPostsFromPostGroup = createAsyncThunk(
  'post/fetchPostsFromPostGroup',
  async (postGroup: PostGroup) => {
    const response = await httpClient.get('/posts/search', {
      params: { postGroupGUID: postGroup.guid }
    });
    return { postGroup: postGroup, posts: response.data };
  }
);

export const patchPosts = createAsyncThunk('post/patchPosts', (posts: Post[]) => {
  return posts;
});

export const fetchLiveStreams = createAsyncThunk('post/fetchLiveStreams', async () => {
  const response = await httpClient.get('/posts/search', {
    params: {
      postType: PostType.LiveStream,
      liveStreamStatus: 'Active',
      isPublished: true,
      isFollowedByCallingUser: true
    }
  });
  return response.data;
});

export const fetchLiveStreamPost = createAsyncThunk('post/fetchLiveStreamPost', async (streamGuid: string) => {
  const response = await httpClient.get(`/posts/${streamGuid}/livestream`);
  return response.data;
});

export const fetchLiveStreamPostById = createAsyncThunk('post/fetchLiveStreamPostById', async (streamGuid: string) => {
  const response = await httpClient.get('/posts/search', {
    params: {
      postType: PostType.LiveStream,
      liveStreamGUID: streamGuid
    }
  });
  return response.data;
});

export const fetchPastLiveStreamPostById = createAsyncThunk(
  'post/fetchPastLiveStreamPostById',
  async (streamGuid: string) => {
    const response = await httpClient.get('/posts/search', {
      params: {
        postType: PostType.LiveEvent,
        liveStreamGUID: streamGuid
      }
    });
    return response.data;
  }
);

export const fetchLiveStreamComments = createAsyncThunk('post/fetchLiveStreamComments', async (streamGuid: string) => {
  const response = await httpClient.get(`/livestreams/${streamGuid}/comments`);
  return response.data;
});

export const likePost = createAsyncThunk('post/likePost', async (postGuid: string, { rejectWithValue }) => {
  try {
    const response = await httpClient.post(`/posts/${postGuid}/reactions`, { reactionType: 'like' });
    return { guid: postGuid, data: response.data };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const unlikePost = createAsyncThunk('post/unlikePost', async (postGuid: string, { rejectWithValue }) => {
  try {
    const response = await httpClient.delete(`/posts/${postGuid}/reactions`);
    return { guid: postGuid, data: response.data };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const unlockPost = createAsyncThunk('post/unlockPost', async (post: Post, { rejectWithValue }) => {
  try {
    const response = await httpClient.post(`/posts/${post.guid}/unlock`, { type: 'Token' });
    return { guid: post.guid, data: response.data, post };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchComments = createAsyncThunk('post/fetchComments', async (postGuid: string) => {
  const response = await httpClient.get(`/comments/search`, { params: { postGUID: postGuid } });
  return response.data;
});

export const postComment = createAsyncThunk('post/postComment', async (comment: PostComment) => {
  const response = await httpClient.post(`/comments`, comment);
  return { comment, data: response.data };
});

export const likeComment = createAsyncThunk('post/likeComment', async (commentGuid: string) => {
  const response = await httpClient.post(`/comments/${commentGuid}/reactions`, { reactionType: 'like' });
  return { guid: commentGuid, data: response.data };
});

export const unlikeComment = createAsyncThunk('post/unlikeComment', async (commentGuid: string) => {
  const response = await httpClient.delete(`/comments/${commentGuid}/reactions`);
  return { guid: commentGuid, data: response.data };
});

export const deleteComment = createAsyncThunk('post/deleteComment', async (commentGuid: string) => {
  const response = await httpClient.delete(`/comments/${commentGuid}`);
  return { guid: commentGuid, data: response.data };
});

export const reportPost = createAsyncThunk('post/reportPost', async (request: UserFlaggedContent) => {
  const response = await httpClient.post(`/posts/${request.postGUID}/userflaggedcontent`, {
    userGUID: request.userGUID,
    flaggedContentType: request.flaggedContentType,
    flaggedUserGUID: request.flaggedUserGUID,
    merchantGUID: request.merchantGUID,
    postGUID: request.postGUID
  });
  return response.data;
});

interface PostState {
  status: string;
  error?: string;
  comments?: PostComment[];
  liveStream?: LiveStream;
  liveStreams?: Post[];
  posts?: Post[];
  post?: Post;
  postGroup?: PostGroup;
  postType?: PostType;
  postGroups?: PostGroup[];
  postUnlock?: string;
  isUnlockSuccess?: boolean;
  errorPayload?: ErrorPayload;
  isCommentDeleted?: boolean;
}

const initialState: PostState = {
  status: 'idle'
};

const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    reset: (state) => {
      state = initialState;
      return state;
    },
    resetPosts: (state) => {
      state.isUnlockSuccess = undefined;
      state.posts = undefined;
      return state;
    },
    resetPostComment: (state) => {
      state.comments = undefined;
      return state;
    },
    resetLiveStream: (state) => {
      state.liveStream = undefined;
      return state;
    },
    AddLiveStreamToPosts: (state, action) => {
      if (state.posts) {
        const posts = cloneDeep(state.posts);
        posts.push(action.payload);
        state.liveStreams = posts;
      }
    },
    removeEndedLiveStreamFromPosts: (state, action) => {
      if (state.posts) {
        const guid = action.payload;
        const posts = cloneDeep(state.posts);
        const index = posts?.findIndex((post) => post.guid === guid);

        if (index !== undefined) {
          const newPosts = posts?.splice(index, 1);
          state.liveStreams = newPosts;
        }
      }
    },
    resetPost: (state) => {
      state.postUnlock = undefined;
      state.post = undefined;
      state.postType = undefined;
      state.isUnlockSuccess = undefined;
      state.postGroup = undefined;
      return state;
    },
    resetDeletedComment: (state) => {
      state.isCommentDeleted = false;
    },
    setPostComments: (state, action) => {
      state.comments = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeed.fulfilled, (state, action) => {
        state.status = 'success';
        const oldPosts: Post[] = cloneDeep(state.posts || []);
        state.posts = [...oldPosts, ...action.payload];
      })
      .addCase(fetchPostById.fulfilled, (state, action) => {
        state.status = 'success';
        state.post = action.payload;
      })
      .addCase(fetchPostsByMerchatByType.fulfilled, (state, action) => {
        state.status = 'success';
        state.postType = action.payload.postType;
        state.posts = action.payload.posts;
      })
      .addCase(fetchPostGroupsThenPosts.fulfilled, (state, action) => {
        state.status = 'success';
        state.postGroups = action.payload as PostGroup[];
      })
      .addCase(fetchPostGroup.fulfilled, (state, action) => {
        state.status = 'success';
        state.postGroups = action.payload as PostGroup[];
      })
      .addCase(patchPosts.fulfilled, (state, action) => {
        state.status = 'success';
        state.posts = action.payload;
      })
      .addCase(fetchLiveStreams.fulfilled, (state, action) => {
        state.status = 'success';
        state.liveStreams = action.payload as Post[];
      })
      .addCase(fetchLiveStreamPost.fulfilled, (state, action) => {
        state.status = 'success';
        state.liveStream = action.payload;
      })
      .addCase(fetchLiveStreamPostById.fulfilled, (state, action) => {
        state.status = 'success';
        state.liveStream = action.payload;
      })
      .addCase(fetchPastLiveStreamPostById.fulfilled, (state, action) => {
        state.status = 'success';
        state.liveStream = action.payload[0];
      })
      .addCase(fetchLiveStreamComments.fulfilled, (state, action) => {
        state.status = 'success';
        state.comments = action.payload;
      })
      .addCase(likePost.fulfilled, (state, action) => {
        state.status = 'success';
        state.posts = countLikes('like', action.payload.guid, state.posts);
      })
      .addCase(unlikePost.fulfilled, (state, action) => {
        state.status = 'success';
        state.posts = countLikes('unlike', action.payload.guid, state.posts);
      })
      .addCase(fetchComments.fulfilled, (state, action) => {
        state.status = 'success';
        state.comments = action.payload;
      })
      .addCase(postComment.fulfilled, (state, action) => {
        state.status = 'success';
        const comments = cloneDeep(state.comments);
        const updatedComment = action.payload.comment;
        updatedComment.guid = action.payload.data.guid;
        comments?.unshift(updatedComment);
        state.comments = comments;
      })
      .addCase(likeComment.fulfilled, (state) => {
        state.status = 'success';
        // state.comments = updateComments('like', action.payload.guid, state.comments);
      })
      .addCase(unlikeComment.fulfilled, (state) => {
        state.status = 'success';
        // state.comments = updateComments('unlike', action.payload.guid, state.comments);
      })
      .addCase(unlockPost.fulfilled, (state, action) => {
        state.status = 'success';
        state.isUnlockSuccess = true;
        state.postUnlock = undefined;

        const posts = cloneDeep(state.posts);
        const postIndex = posts?.findIndex((p) => p.guid === action.payload.post.guid);
        if (postIndex !== undefined && posts) {
          posts[postIndex].isLockedForCallingUser = false;
          state.posts = posts;
        }
      })
      .addCase(deleteComment.fulfilled, (state, action) => {
        state.isCommentDeleted = true;
        const comments = cloneDeep(state.comments);
        const index = comments?.findIndex((c) => c.guid === action.payload.guid);
        if (index !== undefined) {
          comments?.splice(index, 1);
          state.comments = comments;
        }
      })
      .addCase(fetchPostsFromPostGroup.fulfilled, (state, action) => {
        state.status = 'success';
        const postGroup = cloneDeep(action.payload.postGroup);
        postGroup.posts = action.payload.posts;
        state.postGroup = postGroup;
      })
      .addCase(unlockPost.pending, (state, action) => {
        state.status = 'loading';
        state.post = action.meta.arg;
        state.postUnlock = action.meta.arg.guid;
      })
      .addCase(unlockPost.rejected, (state, action) => {
        state.postUnlock = undefined;
        state.status = 'rejected';
        state.error = action.error.message;
      })
      .addMatcher(
        (action): action is PendingAction => action.type.endsWith('/pending'),
        (state) => {
          state.status = 'loading';
        }
      )
      .addMatcher(
        (action): action is RejectedAction => action.type.endsWith('/rejected'),
        (state, action) => {
          state.error = 'rejected';
          if (action.payload) {
            const errorPayload = action.payload as ErrorPayload;
            const message = errorPayload.message ? errorPayload.message : '';
            state.error = message;
            state.errorPayload = action.payload as ErrorPayload;
          }
        }
      );
  }
});

const countLikes = (type: string, guid: string, state?: Post[]) => {
  const index = state?.findIndex((x: Post) => x.guid === guid);
  if (state && index !== undefined) {
    const updatedPost = state[index];
    if (updatedPost && updatedPost.reactionCount !== undefined) {
      switch (type) {
        case 'like':
          updatedPost.reactionCount += 1;
          updatedPost.reactionTypeForCallingUser = 'like';
          break;
        case 'unlike':
          updatedPost.reactionCount -= 1;
          updatedPost.reactionTypeForCallingUser = undefined;
          break;
      }
    }
    state[index] = updatedPost;
    return state;
  }
};

/*const updateComments = (type: string, guid: string, state?: PostComment[]) => {
  const index = state?.findIndex((x: PostComment) => x.guid === guid);
  if (state && index !== undefined) {
    const updatedComment = state[index];
    if (updatedComment) {
      switch (type) {
        case 'like':
          updatedComment.reactionTypeForCallingUser = 'like';
          break;
        case 'unlike':
          updatedComment.reactionTypeForCallingUser = undefined;
          break;
      }
    }
    state[index] = updatedComment;
    return state;
  }
};*/

export const {
  reset,
  resetPosts,
  resetPostComment,
  resetLiveStream,
  AddLiveStreamToPosts,
  removeEndedLiveStreamFromPosts,
  resetPost,
  resetDeletedComment,
  setPostComments
} = postSlice.actions;
export default postSlice.reducer;
