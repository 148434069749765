import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';

import styles from './Checkbox.module.scss';

const cn = classnames.bind(styles);

interface CheckboxProps {
  label?: string;
  name: string;
  onChange?: (value: boolean) => unknown;
  onClick?: () => void;
  value?: boolean;
  level?: number;
}

const Checkbox = (props: CheckboxProps) => {
  const [isSelected, setIsSelected] = useState(props.value as boolean);

  const toggleIsSelected = () => {
    setIsSelected(!isSelected);

    if (props.name === 'addUser' && props.onClick) {
      props.onClick();
    }
  };

  useEffect(() => {
    if (props.onChange) {
      props.onChange(isSelected);
    }
  }, [isSelected]);

  return (
    <div className={cn('control')} onClick={toggleIsSelected}>
      <div className={cn('checkbox', { selected: isSelected, 'level-100': props.level === 1 })} />
      <label className={cn('label')}>{props.label}</label>
      <input className={cn('hidden')} type="checkbox" name={props.name} checked={isSelected} readOnly />
    </div>
  );
};

export default Checkbox;
