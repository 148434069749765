import React, { MouseEventHandler } from 'react';
import classnames from 'classnames/bind';

import { VideoCallRequest } from 'types';

import defaultIcon from 'assets/images/default-avatar-1.png';
import moment from 'moment';
import styles from './ViewVideoCallModal.module.scss';

const cn = classnames.bind(styles);

interface ViewVideoCallModalProps {
  onClose?: MouseEventHandler<HTMLDivElement>;
  width?: string;
  data?: VideoCallRequest | undefined;
}

const ViewVideoCallModal = (props: ViewVideoCallModalProps) => {
  return (
    <div className={cn('content')}>
      <div className={cn('flexbox')}>
        <img className={cn('avatar')} src={props.data?.merchantUserNormalizedProfileImageUrl || defaultIcon} />
        <div className={cn('ft-ml-4')}>{props.data?.merchantName}</div>
      </div>
      <div>
        <p>Recipient: {props.data?.recipientName}</p>
        <p>
          Description: <br />
          {props.data?.description}
        </p>
        <p>
          Date Scheduled: <br />
          {moment(props.data?.dateScheduled).format('MMM DD, YYYY - hh:mm A')}
        </p>
      </div>
    </div>
  );
};

export default ViewVideoCallModal;
