import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import moment from 'moment';

import { playContent } from 'state/reducers/sessionReducer';
import { getFanVideoMessageRequests, getVideoMessageRequests } from 'state/reducers/requestReducer';
import { RootState } from 'state/store';

import { ContentType } from 'enums';
import { VideoMessageRequest } from 'types';
import Button from 'components/Button';
import ContextMenu from 'components/ContextMenu';
import Icon from 'components/Icon';
import Label from 'components/Label';
import defaultIcon from 'assets/images/default-avatar-1.png';

import { useHistory } from 'react-router-dom';
import { openAdmin } from 'services/miscellaneousService';
import BlankPage from 'components/BlankPage';
import { resetViewUser } from 'state/reducers/userReducer';
import styles from './VideoMessage.module.scss';

const cn = classnames.bind(styles);

const labels = [
  { name: 'Pending', color: 'yellow' },
  { name: 'Completed', color: 'green' },
  { name: 'Rejected', color: 'red' }
];

const VideoMessage = () => {
  const { request, user } = useSelector((state: RootState) => ({ request: state.request, user: state.user }));
  const [tab, setTab] = useState<string | undefined>(undefined);

  const dispatch = useDispatch();
  const history = useHistory();
  const viewProfile = (data: VideoMessageRequest, type?: string) => {
    const username = type === 'fan' ? data.userUsername : data.merchantUsername;
    dispatch(resetViewUser());

    if (!data.isUserMerchant && type === 'fan') {
      history.push(`/profile/${username}`);
    } else {
      history.push(`/${username}`);
    }
  };

  const viewMerchant = (data: VideoMessageRequest) => {
    dispatch(resetViewUser());
    history.push(`/${data.merchantUsername}`);
  };

  const startVideo = (request: VideoMessageRequest) => {
    dispatch(playContent({ contentType: ContentType.VideoMessage, videoMessage: request }));
  };

  useEffect(() => {
    if (user.user) {
      if (user.user.merchantGUID) {
        setTab('fan');
        dispatch(getFanVideoMessageRequests({ merchantGUID: user.user?.merchantGUID }));
      } else {
        setTab(undefined);
      }
      dispatch(getVideoMessageRequests({ userGUID: user.user?.guid }));
    }
  }, [user.user?.guid]);
  const hasRequest =
    (request.videoMessageRequests && request.videoMessageRequests.length > 0) ||
    (request.fanVideoMessageRequests && request.fanVideoMessageRequests.length > 0 && user.user?.merchantGUID);
  return (
    <div className={cn(!hasRequest ? 'container' : '')}>
      <div className={cn('is-size-5', 'has-text-weight-semibold')}>Video Messages</div>
      <div className={cn('menu-wrapper')}>
        {user.user?.merchantGUID && (
          <div className={cn('menu')}>
            <div className={cn('flexbox', 'tab')}>
              <div className={cn('tab-item', { active: tab === 'fan' })} onClick={() => setTab('fan')}>
                Fan Requests
                {tab === 'fan' && <div className={cn('border')} />}
              </div>
              <div className={cn('tab-item', { active: tab === undefined })} onClick={() => setTab(undefined)}>
                Your Requests
                {tab === undefined && <div className={cn('border')} />}
              </div>
            </div>
          </div>
        )}
      </div>
      {hasRequest ? (
        <div className={cn('table-wrapper', 'ft-mt-4')}>
          <table className={cn('table')}>
            <thead>
              <tr>
                <th>{tab === 'fan' ? 'Fan' : 'User'}</th>
                <th className={cn('w-50')}>Description</th>
                <th>Updated</th>
                <th>Status</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {tab === undefined &&
                request.videoMessageRequests?.map((request) => (
                  <tr key={request.guid}>
                    <td className={cn('flexbox', 'user')} onClick={() => viewMerchant(request)}>
                      <img
                        className={cn('avatar')}
                        src={request.merchantUserNormalizedProfileImageUrl || defaultIcon}
                      />
                      <div className={cn('ft-ml-2')}>{request.merchantName}</div>
                    </td>
                    <td>{request.description}</td>
                    <td>{moment(request.dateRequested).format('MM/DD/YYYY')}</td>
                    <td>
                      <Label name={request.status || ''} color={labels.find((x) => x.name === request.status)?.color} />
                    </td>
                    <td>
                      {request.status === 'Completed' && request.contentUrl ? (
                        <div>
                          <ContextMenu
                            alignment="mobile"
                            interface={
                              <Button shape="circle" color="white" hasIcon>
                                <Icon name="ellipsis" size="sm" />
                              </Button>
                            }>
                            <ul>
                              <li onClick={() => startVideo(request)}>View video</li>
                            </ul>
                          </ContextMenu>
                        </div>
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>
                ))}
              {tab === 'fan' &&
                request.fanVideoMessageRequests?.map((request) => (
                  <tr key={request.guid}>
                    <td className={cn('flexbox', 'user')} onClick={() => viewProfile(request, 'fan')}>
                      <img className={cn('avatar')} src={request.userProfileImageUrl || defaultIcon} />
                      <div className={cn('ft-ml-2')}>{request.userDisplayName}</div>
                    </td>
                    <td>{request.description}</td>
                    <td>{moment(request.dateRequested).format('MM/DD/YYYY')}</td>
                    <td>
                      <Label name={request.status || ''} color={labels.find((x) => x.name === request.status)?.color} />
                    </td>
                    <td>
                      <div>
                        <ContextMenu
                          alignment="mobile"
                          interface={
                            <Button shape="circle" color="white" hasIcon>
                              <Icon name="ellipsis" size="sm" />
                            </Button>
                          }>
                          <ul>
                            {user.user?.merchantGUID && <li onClick={() => openAdmin()}>Manage</li>}
                            {request.status === 'Completed' && request.contentUrl ? (
                              <li onClick={() => startVideo(request)}>View video</li>
                            ) : (
                              ''
                            )}
                          </ul>
                        </ContextMenu>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <BlankPage text="You have no video messages yet." tabText="Discover Creators" tabLink="/discover" />
      )}
    </div>
  );
};

export default VideoMessage;
