import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import classnames from 'classnames/bind';

import {
  loginUser,
  passwordReset,
  registerUser,
  resendPhoneVerification,
  resetError,
  resetErrorPayload,
  setUnverifiedAccount,
  verifyPasswordReset
} from 'state/reducers/authReducer';
import { saveToken } from 'state/reducers/sessionReducer';
import { RootState } from 'state/store';

import { User, UserResetPassword } from 'types';
import { randomNumberGenerator, serializeFormData } from 'utils';
import Button from 'components/Button';
import Anchor from 'components/Anchor';
import Input from 'components/Input';

import background1 from 'assets/images/login-1.jpg';
import background2 from 'assets/images/login-2.jpg';
import background3 from 'assets/images/login-3.jpg';
import background4 from 'assets/images/login-4.jpg';
import background5 from 'assets/images/login-5.jpg';
import background6 from 'assets/images/login-6.jpg';
import background7 from 'assets/images/login-7.jpg';
import background8 from 'assets/images/login-8.jpg';
import logo from 'assets/images/heyfans-logo.svg';
import logotype from 'assets/images/heyfans-logotype.svg';
import breaking from 'assets/images/breaking.png';
import masl from 'assets/images/strykers-logo.jpg';
// import jones from 'assets/images/juliobreakingshot.jpg';
import beta from 'assets/images/beta.png';
import appStore from 'assets/images/app-store.svg';

import Select from 'components/Select';
import { COUNTRIES } from 'consts';
import Verification from 'components/Verification';
import moment from 'moment';
import styles from './Login.module.scss';

const cx = classnames.bind(styles);

const backgrounds = [
  background1,
  background2,
  background3,
  background4,
  background5,
  background6,
  background7,
  background8
];
const countries = COUNTRIES.map((x) => ({ name: `${x.code} (${x.dialCode})`, value: x.dialCode, code: x.code }));

const Login = () => {
  const auth = useSelector((state: RootState) => state.auth);
  const [isLogin, setIsLogin] = useState(true);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false);
  const [isRegisterVerification, setIsRegisterVerification] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [background, setBackground] = useState('');
  const [verifyStatus, setVerifyStatus] = useState(0);
  const [cacheUser, setCacheUser] = useState<User>();
  const [showPassword, setIsShowPassword] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    const user: User = serializeFormData(new FormData(event.target as HTMLFormElement));
    setCacheUser(user);
    dispatch(loginUser(user));
  };

  const onPasswordReset = (event: FormEvent) => {
    event.preventDefault();
    const reset = serializeFormData(new FormData(event.target as HTMLFormElement));
    dispatch(passwordReset(reset));
  };

  const onVerifyPasswordReset = (event: FormEvent) => {
    event.preventDefault();
    const reset: UserResetPassword = serializeFormData(new FormData(event.target as HTMLFormElement));
    if (reset.newPassword !== reset.confirmPassword) {
      setVerifyStatus(1);
    } else {
      reset.emailVerificationCode = verificationCode;
      dispatch(verifyPasswordReset(reset));
    }
  };

  const register = (event: FormEvent) => {
    event.preventDefault();
    dispatch(resetErrorPayload());
    const user: User = serializeFormData(new FormData(event.target as HTMLFormElement));
    user.phoneNumber = `${user.country}${user.phoneNumber}`;
    user.isEmailAddressVerified = false;
    user.isPhoneNumberVerified = false;
    delete user.country;

    dispatch(registerUser(user));
    setCacheUser(user);
  };

  const cacheLogin = () => {
    dispatch(loginUser(cacheUser as User));
  };

  useEffect(() => {
    if (auth.isPasswordTokenSent) {
      setIsPasswordReset(false);
      setIsLogin(true);
    }
  }, [auth.isPasswordTokenSent]);

  useEffect(() => {
    if (auth.isPasswordResetVerified) {
      setVerificationCode('');
      setIsLogin(true);
      history.push('/login');
    }
  }, [auth.isPasswordResetVerified]);

  useEffect(() => {
    if (auth.isRegistered) {
      setIsRegisterVerification(true);
    }
    if (auth.isVerified) {
      setIsRegisterSuccess(true);
    }
  }, [auth.isRegistered, auth.isVerified]);

  useEffect(() => {
    if (auth.isLoggedIn) {
      dispatch(saveToken(auth.authToken));
      history.push('/');
    }
  }, [auth.isLoggedIn]);

  useEffect(() => {
    if (match.url === '/verify') {
      setVerificationCode(new URLSearchParams(location.search).get('verificationCode') || '');
    }

    setBackground(backgrounds[randomNumberGenerator(0, backgrounds.length - 1)]);
  }, []);

  useEffect(() => {
    if (
      auth.errorPayload?.errorCode &&
      auth.errorPayload?.errorCode === 'UnverifiedPhoneNumber' &&
      auth.errorPayload?.userGUID
    ) {
      dispatch(
        setUnverifiedAccount({
          guid: auth.errorPayload.userGUID,
          isRegistered: true,
          isVerified: false
        })
      );
      dispatch(resendPhoneVerification(auth.errorPayload.userGUID));
      dispatch(resetError());
      setIsRegisterVerification(true);
    }
  }, [auth.errorPayload]);

  return (
    <div className={cx('wrapper')}>
      <div className={cx('background')}>
        <img className={cx('background-img')} src={background} />
      </div>
      <div className={cx('layout', 'form')}>
        <div className={cx('layout-wrapper')}>
          {isLogin && (
            <div className={cx('breaking', 'ft-mb-4')}>
              <div className={cx('breaking-banner-wrapper')}>
                <img className={cx('breaking-banner')} src={breaking} />
              </div>
              <div className={cx('flexbox', 'ft-py-2', 'ft-px-4')}>
                <img className={cx('breaking-icon')} src={masl} />
                <div className={cx('ft-ml-6', 'is-size-7')}>
                  HeyFans announces Major Arena Soccer League sponsorship deal with the Empire Strykers!
                </div>
                {/* <img className={cx('breaking-icon')} src={jones} />
                <div className={cx('ft-ml-6', 'is-size-6')}>Julio Jones joins Tampa Bay Buccaneers and HeyFans!</div> */}
              </div>
            </div>
          )}

          <div className={cx('box')}>
            {verificationCode !== '' ? (
              <>
                <div>
                  <div className={cx('is-size-6')}>Reset your password</div>
                </div>
                <form onSubmit={onVerifyPasswordReset}>
                  <div className={cx('ft-mt-4')}>
                    <Input label="New password" type="password" name="newPassword" fieldClassName={cx('special')} />
                  </div>{' '}
                  <div className={cx('ft-mt-4')}>
                    <Input
                      label="Confirm new password"
                      type="password"
                      name="confirmPassword"
                      fieldClassName={cx('special')}
                    />
                  </div>
                  <div className={cx('ft-mt-4', 'flexbox')}>
                    <Button type="submit" className={cx('sign-in')}>
                      Submit
                    </Button>
                  </div>
                </form>
                {verifyStatus === 1 && (
                  <div className={cx('has-text-danger', 'has-text-centered', 'is-size-7', 'ft-mt-6')}>
                    Passwords do not match.
                  </div>
                )}
              </>
            ) : isLogin && !isRegisterVerification ? (
              <>
                <div>
                  <img className={cx('logo')} src={logo} />
                  <img className={cx('logotype', 'ft-mt-4')} src={logotype} />
                </div>
                <div className={cx('has-text-centered')}>
                  <div className={cx('is-size-5', 'ft-mt-4')}>Where Athletes and Fans Connect</div>
                </div>
                <div className={cx('has-text-centered')}>
                  <div className={cx('is-size-6', 'ft-mt-6')}>Sign in below to continue</div>
                </div>
                <form onSubmit={onSubmit}>
                  <div className={cx('ft-mt-4')}>
                    <Input label="Username" name="username" fieldClassName={cx('special')} />
                  </div>
                  <div className={cx('ft-mt-4')}>
                    <Input
                      label="Password"
                      type={!showPassword ? 'password' : 'text'}
                      name="password"
                      fieldClassName={cx('special')}
                    />
                    <div className={cx('is-size-7', 'show-password')} onClick={() => setIsShowPassword(!showPassword)}>
                      {!showPassword ? 'Show' : 'Hide'}
                    </div>
                  </div>
                  <div className={cx('ft-mt-6', 'flexbox')}>
                    <Button type="submit" className={cx('sign-in')}>
                      Sign In
                    </Button>
                    <Anchor
                      className={cx('ft-ml-auto')}
                      type="foreground"
                      onClick={() => {
                        setIsPasswordReset(true);
                        setIsLogin(false);
                      }}>
                      Forgot Password
                    </Anchor>
                  </div>
                </form>
                {auth.error && (
                  <div className={cx('has-text-danger', 'has-text-centered', 'is-size-7', 'ft-mt-6')}>
                    {auth.error.includes('suspended') ? (
                      <>
                        Your account has been suspended until {''}
                        <strong>{moment(auth.errorPayload?.suspensionEndDate).format('MM/DD/YYYY')}</strong>
                      </>
                    ) : (
                      auth.error
                    )}
                  </div>
                )}
                {auth.isPasswordTokenSent && (
                  <div className={cx('has-text-centered', 'is-size-7', 'ft-mt-6')}>
                    A reset password email has been sent. If you do not see it, please check your Spam folder.
                  </div>
                )}
                {auth.isPasswordResetVerified && (
                  <div className={cx('has-text-centered', 'is-size-7', 'ft-mt-6')}>
                    Password reset successful! You may now use your new password.
                  </div>
                )}
                <div className={cx('ft-mt-6', 'has-text-centered')}>
                  <Anchor type="foreground" onClick={() => setIsLogin(false)}>
                    Don't have an account? Sign up here.
                  </Anchor>
                </div>
                <div className={cx('download-app')}>
                  <a href="https://apps.apple.com/us/app/id1591158670" target="_blank">
                    <img src={appStore} />
                  </a>
                </div>

                <img className={cx('beta')} src={beta} />
              </>
            ) : isPasswordReset ? (
              <>
                <div>
                  <div className={cx('is-size-6')}>Forgot Password?</div>
                </div>
                <form onSubmit={onPasswordReset}>
                  <div>
                    <div className={cx('mt-5', 'mb-2')}>Enter the username you use to log into HeyFans:</div>
                    <Input
                      label="(An email will be sent to the address we have on file.)"
                      name="username"
                      fieldClassName={cx('special mt-4')}
                    />
                  </div>
                  <div className={cx('ft-mt-4', 'flexbox')}>
                    <Button type="submit" className={cx('sign-in')}>
                      Submit
                    </Button>
                  </div>
                </form>
                <div className={cx('ft-mt-6', 'has-text-centered')}>
                  <Anchor
                    type="foreground"
                    onClick={() => {
                      setIsPasswordReset(false);
                      setIsLogin(true);
                    }}>
                    Back to Sign In
                  </Anchor>
                </div>
              </>
            ) : isRegisterSuccess ? (
              <>
                <div>
                  <img className={cx('logo')} src={logo} />
                </div>
                <div className={cx('has-text-centered')}>
                  <div className={cx('title', 'has-text-weight-bold', 'is-size-2', 'ft-mt-6')}>
                    Thank you
                    <br />
                    for joining HeyFans!
                  </div>
                </div>
                <div className={cx('has-text-centered', 'ft-mt-6')}>
                  <Button onClick={cacheLogin}>Continue</Button>
                </div>
              </>
            ) : isRegisterVerification ? (
              <Verification />
            ) : (
              <>
                <div>
                  <img className={cx('logo')} src={logo} />
                </div>
                <div className={cx('has-text-centered')}>
                  <div className={cx('title', 'has-text-weight-semibold', 'is-size-5', 'ft-mt-4')}>Create Account</div>
                </div>
                <form onSubmit={register}>
                  <div className={cx('ft-mt-6', 'contactNo', 'flexbox')}>
                    <Select
                      className={cx('country', 'w-25')}
                      label="Country"
                      name="country"
                      layer={1}
                      options={countries}
                    />
                    <Input
                      className={cx('phoneNo', 'ft-ml-2')}
                      label="Phone number"
                      type="number"
                      name="phoneNumber"
                      fieldClassName={cx('special')}
                      required
                    />
                  </div>
                  {auth.errorPayload && auth.errorPayload.errorCode === 'DuplicatePhoneNumber' ? (
                    <label className={cx('has-text-danger')}>
                      <small>{auth.errorPayload.message}</small>
                    </label>
                  ) : (
                    ''
                  )}

                  <div className={cx('ft-mt-2')}>
                    <Input label="Username" name="username" fieldClassName={cx('special')} required />
                    {auth.errorPayload && auth.errorPayload.errorCode === 'DuplicateUsername' ? (
                      <label className={cx('has-text-danger')}>
                        <small>{auth.errorPayload.message}</small>
                      </label>
                    ) : (
                      ''
                    )}
                  </div>

                  <div className={cx('ft-mt-2')}>
                    <Input label="Display name" name="displayName" fieldClassName={cx('special')} required />
                  </div>

                  <div className={cx('ft-mt-2')}>
                    <Input label="Email address" name="emailAddress" fieldClassName={cx('special')} required />
                    {auth.errorPayload && auth.errorPayload.errorCode === 'DuplicateEmailAddress' ? (
                      <label className={cx('has-text-danger')}>
                        <small>{auth.errorPayload.message}</small>
                      </label>
                    ) : (
                      ''
                    )}
                  </div>

                  <div className={cx('ft-mt-2')}>
                    <Input label="Password" type="password" name="password" fieldClassName={cx('special')} required />
                    {auth.errorPayload && auth.errorPayload.errorCode === 'WeakPassword' ? (
                      <label className={cx('has-text-danger')}>
                        <small>{auth.errorPayload.message}</small>
                      </label>
                    ) : (
                      ''
                    )}
                  </div>

                  <div className={cx('ft-mt-2')}>
                    <Input label="Referral code (optional)" name="referralCode" fieldClassName={cx('special')} />
                  </div>

                  <div className={cx('ft-mt-2', 'flexbox')}>
                    <input
                      className={cx('termsOfUse')}
                      type="checkbox"
                      name="termsOfUse"
                      required
                      onInvalid={(e) =>
                        (e.target as HTMLInputElement).setCustomValidity(
                          "You must agree to the HeyFan's Terms of Use to continue."
                        )
                      }
                    />
                    <span className={cx('ml-2', 'is-size-7')}>
                      By creating an account, you agree to the HeyFan's <br />{' '}
                      <span
                        className={cx('link')}
                        onClick={() => {
                          history.push('/legal/terms');
                        }}>
                        Terms of Use
                      </span>
                    </span>
                  </div>

                  <div className={cx('ft-mt-6', 'flexbox')}>
                    <Button type="submit" className={cx('sign-in')}>
                      Sign Up
                    </Button>
                  </div>
                </form>
                <div className={cx('ft-mt-6', 'has-text-centered')}>
                  <Anchor type="foreground" onClick={() => setIsLogin(true)}>
                    Already have an account? Sign in instead.
                  </Anchor>
                </div>
              </>
            )}
          </div>

          <div className={cx('footer')}>
            <ul>
              <li
                onClick={() => {
                  history.push('/legal/terms');
                }}>
                Terms
              </li>
              <li
                onClick={() => {
                  history.push('/legal/privacy');
                }}>
                Privacy
              </li>
              {/* <li
                onClick={() => {
                  history.push('/legal/copyright');
                }}>
                Copyright
              </li>
              <li
                onClick={() => {
                  history.push('/legal/cookies');
                }}>
                Cookies
              </li> */}
              <li
                onClick={() => {
                  history.push('/company/contact');
                }}>
                Company
              </li>
              <li
                onClick={() => {
                  history.push('/company/how-do-i');
                }}>
                How Do I?
              </li>
              <li
                onClick={() => {
                  history.push('/company/faq');
                }}>
                FAQs
              </li>
              <li
                onClick={() => {
                  history.push('/apply');
                }}>
                Apply
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
