import { httpClient } from 'http/httpClient';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ActiveLiveEvent, LiveEvent } from 'types';
import { PendingAction, RejectedAction } from 'state/store';
import { cloneDeep } from 'lodash';
import { ErrorPayload } from 'types/errorPayload';

export const getLiveEvents = createAsyncThunk('liveEvent/getLiveEvents', async () => {
  const response = await httpClient.get(`/liveevents/search`, {
    params: {
      afterDateInclusive: new Date().toISOString(),
      hasTicketForCallingUser: false,
      status: 'Pending',
      isPublish: true
    }
  });
  return response.data;
});

export const getUserLiveEvents = createAsyncThunk('liveEvent/getUserLiveEvents', async () => {
  const response = await httpClient.get(`/liveevents/search`, {
    params: { afterDateInclusive: new Date().toISOString(), hasTicketForCallingUser: true, status: 'Pending' }
  });
  return response.data;
});

export const getMerchantLiveEvents = createAsyncThunk(
  'liveEvent/getMerchantLiveEvents',
  async (merchantGuid: string) => {
    const response = await httpClient.get(`/liveevents/search`, {
      params: { afterDateInclusive: new Date().toISOString(), merchantGUID: merchantGuid, status: 'Pending' }
    });
    return response.data;
  }
);

export const getMerchantPastLiveEvents = createAsyncThunk(
  'liveEvent/getMerchantPastLiveEvents',
  async (merchantGuid: string) => {
    const response = await httpClient.get(`/liveevents/search`, {
      params: { merchantGUID: merchantGuid, status: 'Completed' }
    });
    return response.data;
  }
);

export const getOngoingLiveEvents = createAsyncThunk('liveEvent/getOngoingLiveEvents', async () => {
  const response = await httpClient.get(`/liveevents/search`, {
    params: { liveStreamStatus: 'Active' }
  });
  return response.data;
});

export const getUserPastEvents = createAsyncThunk('liveEvent/getUserPastEvents', async () => {
  const response = await httpClient.get(`/liveevents/search`, {
    params: {
      beforeDateInclusive: new Date().toISOString(),
      liveStreamStatus: 'Completed',
      hasTicketForCallingUser: true
    }
  });
  return response.data;
});

export const purchaseEventTicket = createAsyncThunk(
  'liveEvent/purchaseEventTicket',
  async (
    data: { event: LiveEvent; userGuid: string | undefined; liveEvents: LiveEvent[] | undefined },
    { rejectWithValue }
  ) => {
    try {
      let params: { type?: string; paymentProfileGUID: string };
      if ((data.event.amount as number) > 0) {
        params = {
          type: 'Token',
          paymentProfileGUID: data.userGuid as string
        };
      } else {
        params = {
          paymentProfileGUID: data.userGuid as string
        };
      }

      const response = await httpClient.post(`/liveevents/${data.event?.guid}/liveeventtickets/purchase`, params);
      const eventData = cloneDeep(data.event);
      eventData.hasTicketForCallingUser = true;
      eventData.liveEventTicketGUID = undefined;
      return { response: response.data, data: eventData };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getLiveEvent = createAsyncThunk('liveEvent/getLiveEvent', async (liveEvents: LiveEvent) => {
  const response = await httpClient.get(`/liveevents/${liveEvents.guid}/livestream`);
  return response.data;
});

export const getDiscoverLiveEvents = createAsyncThunk('liveEvent/getDiscoverLiveEvents', async () => {
  const response = await httpClient.get(`/liveevents/search`, {
    params: { afterDateInclusive: new Date().toISOString(), status: 'Pending' }
  });
  return response.data;
});

export const cancelTicket = createAsyncThunk(
  'liveEvent/cancelTicket',
  async (data: { liveEvents: LiveEvent; bookedLiveEvents: LiveEvent[] | undefined }) => {
    const response = await httpClient.post(`/liveeventtickets/${data.liveEvents.liveEventTicketGUID}/cancel`, {});
    const liveEvents = cloneDeep(data.bookedLiveEvents);
    const event = cloneDeep(data.liveEvents);
    const index = liveEvents?.findIndex((e) => e.guid === event.guid);
    event.hasTicketForCallingUser = false;
    event.liveEventTicketDatePurchased = undefined;
    if (liveEvents && index) {
      liveEvents.splice(index, 1);
    }

    return { response: response.data, liveEvents, data: event };
  }
);

export const patchPastLiveEvents = createAsyncThunk('liveEvent/patchPastLiveEvents', (liveEvents: LiveEvent[]) => {
  return liveEvents;
});

export const patchLiveEvents = createAsyncThunk('liveEvent/patchLiveEvents', (liveEvents: LiveEvent[]) => {
  return liveEvents;
});

interface LiveEventState {
  status: string;
  error?: string;
  liveEvents?: LiveEvent[];
  ongoingLiveEvents?: LiveEvent[];
  bookedLiveEvents?: LiveEvent[];
  bookedPastEvents?: LiveEvent[];
  merchantEvents?: LiveEvent[];
  merchantPastEvents?: LiveEvent[];
  activeLiveEvent?: ActiveLiveEvent;
  eventUnlock?: string;
  isUnlockSuccess?: boolean;
  liveEvent?: LiveEvent;
  errorPayload?: ErrorPayload;
}

const initialState: LiveEventState = {
  status: 'idle'
};

const liveEventSlice = createSlice({
  name: 'liveEvent',
  initialState,
  reducers: {
    resetActiveLiveEvent: (state) => {
      state.activeLiveEvent = undefined;
    },
    endActiveLiveEvent: (state) => {
      if (state.activeLiveEvent) {
        const activeLiveEvent = cloneDeep(state.activeLiveEvent);
        activeLiveEvent.status = 'Finished';
        state.activeLiveEvent = activeLiveEvent;
      }
    },
    resetLiveEvent: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLiveEvents.fulfilled, (state, action) => {
        state.status = 'success';
        state.error = undefined;
        state.liveEvents = action.payload;
      })
      .addCase(getMerchantLiveEvents.fulfilled, (state, action) => {
        state.status = 'success';
        state.merchantEvents = action.payload;
      })
      .addCase(getMerchantPastLiveEvents.fulfilled, (state, action) => {
        state.status = 'success';
        state.merchantPastEvents = action.payload;
      })
      .addCase(getOngoingLiveEvents.fulfilled, (state, action) => {
        state.status = 'success';
        state.error = undefined;
        state.ongoingLiveEvents = action.payload;
      })
      .addCase(getUserLiveEvents.fulfilled, (state, action) => {
        state.status = 'success';
        state.error = undefined;
        state.bookedLiveEvents = action.payload;
      })
      .addCase(getUserPastEvents.fulfilled, (state, action) => {
        state.status = 'success';
        state.error = undefined;
        state.bookedPastEvents = action.payload;
      })
      .addCase(purchaseEventTicket.fulfilled, (state, action) => {
        const bookedLiveEvents = cloneDeep(state.bookedLiveEvents);
        const ongoingLiveEvent = cloneDeep(state.ongoingLiveEvents);
        const liveEvents = cloneDeep(state.liveEvents);
        const isAlreadyBooked = bookedLiveEvents?.find((event) => event.guid === action.payload.data.guid);

        if (isAlreadyBooked === undefined || !isAlreadyBooked) {
          const checkOngoing = ongoingLiveEvent?.findIndex((event) => event.guid === action.payload.data.guid);
          if (!checkOngoing === undefined && checkOngoing) {
            ongoingLiveEvent?.splice(checkOngoing, 1);
            state.ongoingLiveEvents = ongoingLiveEvent;
          }
          state.bookedLiveEvents?.push(action.payload.data);
        }

        const isInLiveEvent = liveEvents?.find((event) => event.guid === action.payload.data.guid);
        if (isInLiveEvent !== undefined || isInLiveEvent) {
          const checkLiveEvent = liveEvents?.findIndex((event) => event.guid === action.payload.data.guid);
          if (checkLiveEvent !== undefined && checkLiveEvent >= 0) {
            liveEvents?.splice(checkLiveEvent, 1);
            state.liveEvents = liveEvents;
          }
        }
        state.status = 'success';
        state.error = undefined;
        state.isUnlockSuccess = true;
        state.eventUnlock = undefined;
      })
      .addCase(purchaseEventTicket.pending, (state, action) => {
        state.status = 'loading';
        state.liveEvent = action.meta.arg.event;
        state.eventUnlock = action.meta.arg.event.guid;
      })
      .addCase(purchaseEventTicket.rejected, (state, action) => {
        state.eventUnlock = undefined;
        state.status = 'rejected';
        const errorPayload = action.payload as ErrorPayload;
        state.errorPayload = errorPayload;
        const message = errorPayload.message ? errorPayload.message : '';
        state.error = message;
      })
      .addCase(getLiveEvent.fulfilled, (state, action) => {
        state.status = 'success';
        state.error = undefined;
        state.activeLiveEvent = action.payload;
      })
      .addCase(getDiscoverLiveEvents.fulfilled, (state, action) => {
        const liveEvents = cloneDeep(action.payload);
        if (liveEvents && liveEvents.length > 0) {
          state.bookedLiveEvents = liveEvents.filter((l: LiveEvent) => l.hasTicketForCallingUser === true);
          state.liveEvents = liveEvents.filter((l: LiveEvent) => l.hasTicketForCallingUser === false);
          state.ongoingLiveEvents = liveEvents.filter((l: LiveEvent) => l.liveStreamStatus === 'Active');
        }
        state.status = 'success';
        state.error = undefined;
      })
      .addCase(cancelTicket.fulfilled, (state, action) => {
        state.status = 'success';
        state.error = undefined;
        const bookedLiveEvents = cloneDeep(action.payload.liveEvents);
        const isAlreadyBooked = bookedLiveEvents?.find((event) => event.guid === action.payload.data.guid);

        if (isAlreadyBooked !== undefined || isAlreadyBooked) {
          const index = bookedLiveEvents?.findIndex((event) => event.guid === action.payload.data.guid);
          if (index !== undefined && index >= 0) {
            bookedLiveEvents?.splice(index, 1);
            state.bookedLiveEvents = bookedLiveEvents;
          }
        }
        const liveEvents = cloneDeep(state.liveEvents);
        liveEvents?.push(action.payload.data);
        state.liveEvents = liveEvents;
      })
      .addCase(patchPastLiveEvents.fulfilled, (state, action) => {
        state.status = 'success';
        state.merchantPastEvents = action.payload;
      })
      .addCase(patchLiveEvents.fulfilled, (state, action) => {
        state.status = 'success';
        state.merchantEvents = action.payload;
      })
      .addMatcher(
        (action): action is PendingAction => action.type.endsWith('/pending'),
        (state) => {
          state.status = 'loading';
        }
      )
      .addMatcher(
        (action): action is RejectedAction => action.type.endsWith('/rejected'),
        (state, action) => {
          state.status = 'rejected';
          if (action.payload) {
            const errorPayload = action.payload as ErrorPayload;
            const message = errorPayload.message ? errorPayload.message : '';
            state.error = message;
            state.errorPayload = action.payload as ErrorPayload;
          }
        }
      );
  }
});
export const { resetActiveLiveEvent, endActiveLiveEvent, resetLiveEvent } = liveEventSlice.actions;

export default liveEventSlice.reducer;
