import React from 'react';
import classnames from 'classnames/bind';

import styles from './Ticket.module.scss';

const cn = classnames.bind(styles);

interface TicketProps {
  artist?: string;
  backgroundUrl?: string;
  name?: string;
  passType?: string;
  primaryColor?: string;
  secondaryColor?: string;
  size?: 'sm' | 'xs' | 'xxs';
  date?: string;
}

const Ticket = (props: TicketProps) => {
  return (
    <>
      {props.passType === 'FullTicket' ? (
        <div className={cn('ticket', 'full', props.size)}>
          <div className={cn('wrapper')}>
            <img className={cn('artwork')} src={props.backgroundUrl} />
            <div className={cn('stub')}>
              <div className={cn('details')} style={{ color: props.primaryColor }}>
                <div className={cn('name')}>
                  <span>{props.name}</span>
                </div>
                <div className={cn('artist')}>{props.artist}</div>
                <div className={cn('has-text-weight-semibold', 'ft-mt-4', 'date')}>{props.date}</div>
              </div>
            </div>
            <div className={cn('punch')} />
          </div>
        </div>
      ) : (
        <div className={cn('ticket', 'half', props.size)}>
          <div className={cn('wrapper')}>
            <img className={cn('artwork-half')} src={props.backgroundUrl} />
            <div className={cn('stub')}>
              <div className={cn('background')}>
                <svg xmlns="http://www.w3.org/2000/svg" fill={props.secondaryColor} viewBox="0 0 40 25">
                  <rect className="a" y="1" width="40" height="24" />
                  <polygon className="a" points="1 1 2 1 1.5 0.5 1 0 0.5 0.5 0 1 1 1" />
                  <polygon className="a" points="3 1 4 1 3.5 0.5 3 0 2.5 0.5 2 1 3 1" />
                  <polygon className="a" points="5 1 6 1 5.5 0.5 5 0 4.5 0.5 4 1 5 1" />
                  <polygon className="a" points="7 1 8 1 7.5 0.5 7 0 6.5 0.5 6 1 7 1" />
                  <polygon className="a" points="9 1 10 1 9.5 0.5 9 0 8.5 0.5 8 1 9 1" />
                  <polygon className="a" points="11 1 12 1 11.5 0.5 11 0 10.5 0.5 10 1 11 1" />
                  <polygon className="a" points="13 1 14 1 13.5 0.5 13 0 12.5 0.5 12 1 13 1" />
                  <polygon className="a" points="15 1 16 1 15.5 0.5 15 0 14.5 0.5 14 1 15 1" />
                  <polygon className="a" points="17 1 18 1 17.5 0.5 17 0 16.5 0.5 16 1 17 1" />
                  <polygon className="a" points="19 1 20 1 19.5 0.5 19 0 18.5 0.5 18 1 19 1" />
                  <polygon className="a" points="21 1 22 1 21.5 0.5 21 0 20.5 0.5 20 1 21 1" />
                  <polygon className="a" points="23 1 24 1 23.5 0.5 23 0 22.5 0.5 22 1 23 1" />
                  <polygon className="a" points="25 1 26 1 25.5 0.5 25 0 24.5 0.5 24 1 25 1" />
                  <polygon className="a" points="27 1 28 1 27.5 0.5 27 0 26.5 0.5 26 1 27 1" />
                  <polygon className="a" points="29 1 30 1 29.5 0.5 29 0 28.5 0.5 28 1 29 1" />
                  <polygon className="a" points="31 1 32 1 31.5 0.5 31 0 30.5 0.5 30 1 31 1" />
                  <polygon className="a" points="33 1 34 1 33.5 0.5 33 0 32.5 0.5 32 1 33 1" />
                  <polygon className="a" points="35 1 36 1 35.5 0.5 35 0 34.5 0.5 34 1 35 1" />
                  <polygon className="a" points="37 1 38 1 37.5 0.5 37 0 36.5 0.5 36 1 37 1" />
                  <polygon className="a" points="39 1 40 1 39.5 0.5 39 0 38.5 0.5 38 1 39 1" />
                </svg>
              </div>
              <div className={cn('details')} style={{ color: props.primaryColor }}>
                <div className={cn('name')}>
                  <span>{props.name}</span>
                </div>
                <div className={cn('artist')}>{props.artist}</div>
                <div className={cn('has-text-weight-semibold', 'ft-mt-4', 'date')}>{props.date}</div>
              </div>
            </div>
            <div className={cn('punch')} />
            <div className={cn('border')} style={{ borderColor: props.primaryColor }} />
          </div>
        </div>
      )}
    </>
  );
};

export default Ticket;
