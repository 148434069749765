import React from 'react';
import classnames from 'classnames/bind';

import styles from './Cookies.module.scss';

const cn = classnames.bind(styles);

const Cookies = () => {
  return (
    <div className={cn('legal')}>
      <div className={cn('header')}>Cookies Policy</div>
      <div className={cn('is-size-7')}>
        <p>Last Updated August 20, 2020</p>

        <p className={cn('subheader')}>INFORMATION ABOUT OUR USE OF COOKIES AND TRACKING TECHNOLOGIES</p>

        <p>
          Our Site uses cookies and other similar technologies (as described below) to distinguish you from other users
          of our Site. This helps us to provide you with a good experience when you browse our Site, allows us to
          improve our Site and provide you with tailored content and advertising.
        </p>

        <p>
          A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your
          computer if you agree. Cookies contain information that is transferred to your computer’s hard drive.
        </p>

        <p>
          You can find more information about the types of cookies we use and the purposes for which we use them in the
          table below:
        </p>

        <table>
          <tbody>
            <tr>
              <td>Type of Cookie</td>
              <td>Purpose of Cookie</td>
            </tr>
            <tr>
              <td>Strictly Necessary</td>
              <td>
                These cookies are essential in order to enable you to browse our Site and use its features. The
                information collected by these cookies relate to the operation of our Site, for example website
                scripting language and security tokens to maintain secure areas of our Site.
              </td>
            </tr>
            <tr>
              <td>Performance / Analytics</td>
              <td>
                These cookies collect anonymous information about how you use our Site, for example which pages you
                visit most often, whether you receive any error messages, and how you arrived at our Site. Information
                collected by these cookies is used only to improve your use of our Site and never to identify you. These
                cookies are sometimes placed by third-party providers of web traffic analysis services, such as Google
                Analytics.
              </td>
            </tr>
            <tr>
              <td>Functionality</td>
              <td>
                These cookies remember choices you make, for example, the country you visit our Site from, your language
                and any changes you have made to text size or other parts of web pages that you can customize, in order
                to improve your experience of our Site and to make your visits more tailored and enjoyable. The
                information these cookies collect may be anonymized and cannot be used to track your browsing activity
                on other websites.
              </td>
            </tr>
            <tr>
              <td>Targeting or Advertising</td>
              <td>
                These cookies collect information about your browsing habits and inferred interests in order to make
                advertising more relevant to you. They are also used to limit the number of times you see an advert, as
                well as to measure the effectiveness of an advertising campaign. These cookies are usually placed by
                third-party advertising networks. They remember the other websites that you visit and this information
                is shared with third-party organizations, for example, advertisers.
              </td>
            </tr>
            <tr>
              <td>Social Media</td>
              <td>
                These cookies allow you to share what you’ve been doing on our Site with social media organizations such
                as Facebook and Twitter. These cookies are not within our control. Please refer to the privacy policies
                of these organizations for how their cookies work.
              </td>
            </tr>
          </tbody>
        </table>

        <p>
          Please note that third parties (including, for example, advertising networks and providers of external
          services like web traffic analysis services) may also use cookies, over which we have no control. These
          cookies are likely to be analytical/performance cookies or targeting cookies.
        </p>

        <p>We may use cookies:</p>

        <p>
          (i) to collect anonymized data on users behavior on the site. We may also capture what site a user has come
          from and what site they go to and also some demographic data (e.g. the user’s location) and we may use that
          data to analyze user behavior across multiple sites;
        </p>

        <p>
          (ii) to categorize pools of users (on an anonymized basis) based on demographic and/or behavioral data and use
          this to, (1) target our advertising to cookie-ed users on third party websites and HeyFans Companies’
          websites, and (2) use that data to allow targeted third-party advertising to categorized cookie-ed users
          either in respect of advertising placement HeyFans has bought/placed and targeted to users on behalf of third
          parties or in respect of advertising bought/placed by third parties and targeted to cookie pools HeyFans has
          “sold” to these third parties.
        </p>

        <p>
          Some of our Sites may use Google AdSense to publish advertisements, including information about your
          demographics and interests. When you view or click on an advertisement a cookie will be set to help better
          provide advertisements that may be of interest to you on this and other websites. You may opt-out of the use
          of this cookie by visiting Google’s Advertising and Privacy page:{' '}
          <a target="_blank" href="https://www.google.com/privacy_ads.html">
            https://www.google.com/privacy_ads.html
          </a>
          .
        </p>

        <p>
          Most internet browsers are automatically set up to accept cookies. However, if you want to refuse or delete
          any cookies (or similar technologies), please refer to the help and support area on your internet browser for
          instructions on how to block or delete cookies. Please note you may not be able to take advantage of all the
          features of our Site, including certain personalized features if you delete or disable cookies.
        </p>

        <p>
          For more information on managing cookies, please go to{' '}
          <a target="_blank" href="https://www.allaboutcookies.org">
            www.allaboutcookies.org
          </a>
          , or visit{' '}
          <a target="_blank" href="https://www.youronlinechoices.eu">
            www.youronlinechoices.eu
          </a>{' '}
          which has further information about behavioral advertising and online privacy.
        </p>

        <p className={cn('subheader')}>HOW TO CONTACT HEYFANS</p>

        <p>If you have questions about this policy, you can contact us as described below:</p>

        <p>
          Email: <a href="mailto:legal@heyfans.com">legal@heyfans.com</a>
        </p>

        <p>
          Mail:
          <br />
          HeyFans
          <br />
          ATTN: Privacy Operations
          <br />
          400 Spectrum Center Dr.,
          <br />
          Suite 2180
          <br />
          Irvine, CA 92618
        </p>
      </div>
    </div>
  );
};

export default Cookies;
