import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import moment from 'moment';

import { VideoCallRequest } from 'types';
import Button from 'components/Button';
import ContextMenu from 'components/ContextMenu';
import Icon from 'components/Icon';
import Label from 'components/Label';

import { getFanVideoCallRequests, getVideoCallRequests } from 'state/reducers/requestReducer';
import { joinCall } from 'state/reducers/sessionReducer';
import { RootState } from 'state/store';
import defaultIcon from 'assets/images/default-avatar-1.png';

import { useHistory } from 'react-router-dom';
import { openAdmin } from 'services/miscellaneousService';
import BlankPage from 'components/BlankPage';
import { resetViewUser } from 'state/reducers/userReducer';

import styles from './VideoCall.module.scss';

const cn = classnames.bind(styles);

const labels = [
  { name: 'Pending', color: 'yellow' },
  { name: 'InProgress', color: 'teal' },
  { name: 'Completed', color: 'green' },
  { name: 'Rejected', color: 'red' },
  { name: 'Missed', color: 'peach' }
];

const VideoCall = () => {
  const { request, user } = useSelector((state: RootState) => ({ request: state.request, user: state.user }));
  const dispatch = useDispatch();

  const history = useHistory();
  const viewProfile = (data: VideoCallRequest, type?: string) => {
    const username = type === 'fan' ? data.userUsername : data.merchantUsername;
    dispatch(resetViewUser());

    if (!data.isUserMerchant && type === 'fan') {
      history.push(`/profile/${username}`);
    } else {
      history.push(`/${username}`);
    }
  };

  const viewMerchant = (data: VideoCallRequest) => {
    dispatch(resetViewUser());
    history.push(`/${data.merchantUsername}`);
  };

  const [tab, setTab] = useState<string | undefined>(undefined);

  const onJoinCall = (request: VideoCallRequest) => {
    dispatch(joinCall(request));
  };

  const checkSched = (dateScheduled: Date | undefined) => {
    if (dateScheduled !== undefined) {
      const now = new Date().getTime();
      const ds = new Date(new Date(dateScheduled).getTime() + 10 * 60000).getTime();
      return ds > now;
    }
    return false;
  };

  useEffect(() => {
    if (user.user) {
      if (user.user.merchantGUID) {
        setTab('fan');
        dispatch(getFanVideoCallRequests({ merchantGUID: user.user?.merchantGUID }));
      } else {
        setTab(undefined);
      }
      dispatch(getVideoCallRequests({ userGUID: user.user?.guid }));
    }
  }, [user.user?.guid]);

  const hasRequest =
    (request.videoCallRequests && request.videoCallRequests.length > 0) ||
    (request.fanVideoCallRequests && request.fanVideoCallRequests.length > 0 && user.user?.merchantGUID);
  return (
    <div className={cn(!hasRequest ? 'container' : '')}>
      <div className={cn('header')}>Video Calls</div>
      {user.user?.merchantGUID && (
        <div className={cn('menu')}>
          <div className={cn('flexbox', 'tab')}>
            <div className={cn('tab-item', { active: tab === 'fan' })} onClick={() => setTab('fan')}>
              Fan Requests
              {tab === 'fan' && <div className={cn('border')} />}
            </div>
            <div className={cn('tab-item', { active: tab === undefined })} onClick={() => setTab(undefined)}>
              Your Requests
              {tab === undefined && <div className={cn('border')} />}
            </div>
          </div>
        </div>
      )}
      {hasRequest ? (
        <div className={cn('table-wrapper', 'ft-mt-4')}>
          <table className={cn('table')}>
            <thead>
              <tr>
                <th>User</th>
                <th className={cn('w-50')}>Description</th>
                <th>Updated</th>
                <th>Status</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {tab === undefined &&
                request.videoCallRequests?.map((request) => (
                  <tr key={request.guid}>
                    <td className={cn('flexbox', 'user')} onClick={() => viewMerchant(request)}>
                      <img
                        className={cn('avatar')}
                        src={request.merchantUserNormalizedProfileImageUrl || defaultIcon}
                      />
                      <div className={cn('ft-ml-2')}>{request.merchantName}</div>
                    </td>
                    <td>{request.description}</td>
                    <td>{moment(request.dateScheduled).format('MM/DD/YYYY')}</td>
                    <td>
                      <Label
                        name={
                          request.status === 'InProgress' && !checkSched(request.dateScheduled)
                            ? 'Completed'
                            : request.status || ''
                        }
                        color={
                          request.status === 'InProgress' && !checkSched(request.dateScheduled)
                            ? 'green'
                            : labels.find((x) => x.name === request.status)?.color
                        }
                      />
                    </td>
                    <td>
                      <div>
                        {request.status === 'InProgress' && checkSched(request.dateScheduled) && (
                          <ContextMenu
                            interface={
                              <Button shape="circle" color="white" hasIcon>
                                <Icon name="ellipsis" size="sm" />
                              </Button>
                            }>
                            <ul>
                              <li onClick={() => onJoinCall(request)}>Join call</li>
                            </ul>
                          </ContextMenu>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              {tab === 'fan' &&
                request.fanVideoCallRequests?.map((request) => (
                  <tr key={request.guid}>
                    <td className={cn('flexbox', 'user')} onClick={() => viewProfile(request, 'fan')}>
                      <img className={cn('avatar')} src={request.userProfileImageUrl || defaultIcon} />
                      <div className={cn('ft-ml-2')}>{request.userDisplayName}</div>
                    </td>
                    <td>{request.description}</td>
                    <td>{moment(request.dateScheduled).format('MM/DD/YYYY')}</td>
                    <td>
                      <Label
                        name={
                          request.status === 'InProgress' && !checkSched(request.dateScheduled)
                            ? 'Completed'
                            : request.status || ''
                        }
                        color={
                          request.status === 'InProgress' && !checkSched(request.dateScheduled)
                            ? 'green'
                            : labels.find((x) => x.name === request.status)?.color
                        }
                      />
                    </td>
                    <td>
                      <div>
                        <ContextMenu
                          interface={
                            <Button shape="circle" color="white" hasIcon>
                              <Icon name="ellipsis" size="sm" />
                            </Button>
                          }>
                          <ul>
                            {user.user?.merchantGUID && <li onClick={() => openAdmin()}>Manage</li>}
                            {request.status === 'Pending' && checkSched(request.dateScheduled) && (
                              <li onClick={() => onJoinCall(request)}>Start call</li>
                            )}
                            {request.status === 'InProgress' && checkSched(request.dateScheduled) && (
                              <li onClick={() => onJoinCall(request)}>Join call</li>
                            )}
                          </ul>
                        </ContextMenu>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <BlankPage text="You have no scheduled video calls." tabText="Discover Athletes" tabLink="/discover" />
      )}
    </div>
  );
};

export default VideoCall;
