import React from 'react';
import classnames from 'classnames/bind';

import styles from 'routes/Company/Contact/Contact.module.scss';

const cn = classnames.bind(styles);

const Contact = () => {
  return (
    <div className={cn('legal')}>
      <div className={cn('has-text-weight-bold', 'has-text-primary', 'is-size-6')}>Contact</div>
      <div className={cn('is-size-7')}>
        <p>
          At HeyFans we love to get feedback on our service and application. We especially love getting ideas on how we
          can improve and provide a better user experience - for fans and our athletes. If you'd like to reach out,
          please send us an email below.
        </p>
      </div>

      <div className={cn('legal', 'is-size-7')}>
        <p className={cn('subheader')}>Product or Service Comments</p>
        <p>Use this email if you spot bugs, have suggestions or thoughts on what we can do better.</p>
        <p>
          <a href="mailto:support@heyfans.com">support@heyfans.com</a>
        </p>

        <p className={cn('subheader')}>Media</p>
        <p>Reach out if you are from the media and would like to chat about HeyFans.</p>
        <p>
          <a href="mailto:media@heyfans.com">media@heyfans.com</a>
        </p>

        <p className={cn('subheader')}>Legal</p>
        <p> We want to hear if you suspect or have concerns over privacy, copyright, or offensive content.</p>
        <p>
          <a href="mailto:legal@heyfans.com">legal@heyfans.com</a>
        </p>

        <p className={cn('subheader')}>Last Resort</p>
        <p>If all else fails, contact me directly.</p>
        <p>
          Dan Baxter
          <br />
          Chief Fan Officer
          <br />
          <a href="mailto:max@heyfans.com">dan@heyfans.com</a>
        </p>
        <p>
          Office Address:
          <br />
          120 Vantis Dr.,
          <br />
          Suite 300
          <br />
          Aliso Viejo, CA 92656
        </p>
      </div>
    </div>
  );
};

export default Contact;
