import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import classnames from 'classnames/bind';

import { preferencesRouteConfig } from 'routes';
import Button from 'components/Button';
import Icon from 'components/Icon';
import RouteFromPath from 'components/Route/RouteFromPath';
import { useSelector } from 'react-redux';
import { RootState } from 'state/store';

import styles from './Preferences.module.scss';

const cn = classnames.bind(styles);

const Preferences = () => {
  const [isWide, setIsWide] = useState(false);
  const [isShowSidebar, setIsShowSidebar] = useState(true);
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const user = useSelector((state: RootState) => state.user);
  // const pathname = window.location.pathname;

  const goTo = (page: string) => {
    if (page !== '') {
      history.push(`/preferences${page}`);
    } else {
      history.push('/preferences');
    }
  };

  useEffect(() => {
    location.pathname.includes('video-calls') ||
    location.pathname.includes('video-messages') ||
    location.pathname.includes('legal')
      ? setIsWide(true)
      : setIsWide(false);
  }, [location.pathname]);

  return (
    <React.Fragment>
      <Button
        className={cn('sidebar-button', { active: isShowSidebar })}
        onClick={() => setIsShowSidebar(!isShowSidebar)}>
        <Icon name="menu" />
      </Button>
      <div className={cn('container')}>
        <div className={cn('sidebar', { hide: !isShowSidebar })}>
          <ul className={cn('menu')}>
            <li className={cn('menu-item', { active: location.pathname === '/preferences' })} onClick={() => goTo('')}>
              Profile
            </li>
            <li
              className={cn('menu-item', { active: location.pathname === '/preferences/subscriptions' })}
              onClick={() => goTo('/subscriptions')}>
              Subscriptions
            </li>
            <li
              className={cn('menu-item', {
                active: location.pathname === '/preferences/wallet' || location.pathname === '/preferences/transaction'
              })}
              onClick={() => goTo('/wallet')}>
              Wallet
            </li>
            <li
              className={cn('menu-item', { active: location.pathname === '/preferences/events' })}
              onClick={() => goTo('/events')}>
              Events
            </li>
            <li
              className={cn('menu-item', { active: location.pathname === '/preferences/follower' })}
              onClick={() => goTo('/follower')}>
              Followers
            </li>
            <li
              className={cn('menu-item', { active: location.pathname === '/preferences/following' })}
              onClick={() => goTo('/following')}>
              Following
            </li>
            <li
              className={cn('menu-item', { active: location.pathname === '/preferences/block' })}
              onClick={() => goTo('/block')}>
              Blocked Users
            </li>
            <li
              className={cn('menu-item', { active: location.pathname === '/preferences/video-calls' })}
              onClick={() => goTo('/video-calls')}>
              Video Calls
            </li>
            <li
              className={cn('menu-item', { active: location.pathname === '/preferences/video-messages' })}
              onClick={() => goTo('/video-messages')}>
              Video Messages
            </li>
            {/*user.user?.merchantGUID && (
              <li
                className={cn('menu-item', { active: location.pathname === '/preferences/referrals' })}
                onClick={() => goTo('/referrals')}>
                Referrals
              </li>
            )*/}
            <li
              className={cn('menu-item', { active: location.pathname === '/preferences/about' })}
              onClick={() => goTo('/about')}>
              About HeyFans
            </li>
            <li
              className={cn('menu-item', { active: location.pathname.includes('/preferences/legal') })}
              onClick={() => goTo('/legal/terms')}>
              Legal
            </li>
            {!user.user?.merchantGUID && (
              <li
                className={cn('menu-item', { active: location.pathname.includes('/preferences/apply') })}
                onClick={() => goTo('/apply')}>
                Apply
              </li>
            )}
          </ul>
          <div className={cn('hide-sidebar')} onClick={() => setIsShowSidebar(false)}>
            <div className={cn('arrow', 'flexbox')}>
              <Icon name="arrow-left" size="xs" />
            </div>
            <div className={cn('ft-ml-2')}>Hide</div>
          </div>
        </div>
        {!isShowSidebar && <div className={cn('sidebar-open')} onClick={() => setIsShowSidebar(true)} />}
        <div className={cn('main', { hide: !isShowSidebar })}>
          <div className={cn('content', { wide: isWide })}>
            {preferencesRouteConfig.map((route) => (
              <RouteFromPath key={`route${route.path}`} base={match.url} {...route} />
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Preferences;
