import { MutableRefObject, useEffect } from 'react';

export const useOutsideAlerter = (ref: MutableRefObject<HTMLElement | null>, fn: () => unknown) => {
  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (ref.current && !ref.current.contains(event?.target as Node)) {
        fn();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};
