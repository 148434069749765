import axios, { AxiosInstance } from 'axios';
import { logout } from 'state/reducers/authReducer';
import { store } from 'state/store';

const APPLICATION_JSON = 'application/json';
const AUTH_HEADER = 'authorization';
const CONTENT_TYPE = 'content-type';
const UNAUTHORIZED = 401;

const getAuthHeader = () => {
  const state = store.getState();
  const token = state.session.authToken;
  return { [AUTH_HEADER]: token };
};

const applyDefaultInterceptors = (client: AxiosInstance) => {
  client.interceptors.request.use((config) => {
    const authHeader = getAuthHeader();
    const { headers } = config;

    config.headers = {
      ...headers,
      ...authHeader
    };

    return config;
  });

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === UNAUTHORIZED) {
        store.dispatch(logout());
        window.location.replace('/login');
      }

      return Promise.reject(error);
    }
  );
};

const configureHttpClient = (client: AxiosInstance) => {
  applyDefaultInterceptors(client);
  return client;
};

const initializeHttpClient = (baseUrl?: string) => {
  const client = axios.create({
    baseURL: baseUrl,
    headers: {
      Accept: APPLICATION_JSON,
      [CONTENT_TYPE]: APPLICATION_JSON
    }
  });
  return configureHttpClient(client);
};

export const httpClient = initializeHttpClient(process.env.REACT_APP_BASE_API_URL);
