import React, { ChangeEvent, FocusEvent, KeyboardEvent, MutableRefObject, ReactNode } from 'react';
import classnames from 'classnames/bind';

import styles from './Input.module.scss';

const cx = classnames.bind(styles);

interface InputProps {
  label?: string;
  sublabel?: string;
  type?: string;
  name?: string;
  id?: string;
  className?: string;
  fieldClassName?: string;
  value?: string;
  defaultValue?: string | number | undefined;
  maxLength?: number;
  pattern?: string;
  placeholder?: string;
  required?: boolean;
  layer?: number;
  iconLeft?: ReactNode | string;
  iconRight?: ReactNode | string;
  iconRightSecondary?: ReactNode | string;
  onFocus?: () => unknown;
  onBlur?: (event?: FocusEvent) => unknown;
  onChange?: (event?: ChangeEvent) => unknown;
  onKeyUp?: (event: KeyboardEvent) => unknown;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
  readOnly?: boolean;
  autoComplete?: string;
  disabled?: boolean;
}

const Input = (props: InputProps) => {
  return (
    <div className={cx('control', props.className, props.layer !== undefined ? `layer-${props.layer}` : '')}>
      {props.label && <label className={cx('label')}>{props.label}</label>}
      <div
        className={cx('flexbox', 'field', props.fieldClassName, {
          'has-icon': props.iconLeft || props.iconRight,
          'is-readonly': props.readOnly
        })}>
        {props.iconLeft && <div className={cx('icon', 'icon-left')}>{props.iconLeft}</div>}
        {props.type !== 'textarea' ? (
          <input
            autoCorrect="off"
            className={cx('input')}
            id={props.id}
            maxLength={props.maxLength}
            name={props.name}
            pattern={props.pattern}
            placeholder={props.placeholder}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            onChange={props.onChange}
            onKeyUp={props.onKeyUp}
            readOnly={props.readOnly ? true : false}
            ref={props.inputRef}
            required={props.required}
            type={props.type ? props.type : 'text'}
            autoComplete={props.autoComplete ? props.autoComplete : 'on'}
            disabled={props.disabled}
            defaultValue={props.value || props.defaultValue}
          />
        ) : (
          <textarea rows={4} name={props.name} defaultValue={props.defaultValue} />
        )}
        {props.iconRightSecondary && <div className={cx('icon', 'icon-right')}>{props.iconRightSecondary}</div>}
        {props.iconRight && <div className={cx('icon', 'icon-right')}>{props.iconRight}</div>}
      </div>
      {props.sublabel && <div className={cx('sublabel')}>{props.sublabel}</div>}
    </div>
  );
};

export default Input;
