import React, { MouseEventHandler, ReactNode } from 'react';
import classnames from 'classnames/bind';

import styles from './Modal.module.scss';

const cn = classnames.bind(styles);

interface ModalProps {
  children?: ReactNode;
  onClose?: MouseEventHandler<HTMLDivElement>;
  width?: string;
  customClass?: string;
}

const Modal = (props: ModalProps) => {
  return (
    <div className={cn('modal', props.customClass)}>
      <div className={cn('modal-background')} onClick={props.onClose} />
      <div className={cn('mobile-instruction')}>Tap to close</div>
      <div className={cn('modal-content', props.customClass)} style={{ width: props.width }}>
        {props.children}
      </div>
    </div>
  );
};

export default Modal;
