import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import {
  fetchCreator,
  fetchCreatorActivity,
  resetFeed,
  resetCreator,
  unfollowUser,
  followUser,
  getDashboard,
  fetchAllCreators,
  fetchMerchantPromos
} from 'state/reducers/creatorReducer';
import { getMerchantLiveEvents, getMerchantPastLiveEvents, resetLiveEvent } from 'state/reducers/liveEventReducer';
import { getMerchantMerchandise } from 'state/reducers/merchandiseReducer';
import { resetPosts } from 'state/reducers/postReducer';
import { toggleSnackbarOpen } from 'state/reducers/snackbarReducer';
import {
  resetPeriodEndDate,
  resetSubscriptionsByCreator,
  searchSubscriptionsByMerchantGuid
} from 'state/reducers/subscriptionReducer';
import {
  blockUser,
  fetchMerchantFollowing,
  fetchSubscriptions,
  resetViewUser,
  searchBlockedUsers,
  searchBlockers,
  unsubscribe
} from 'state/reducers/userReducer';
import { RootState } from 'state/store';

import { ContentType, PostType } from 'enums';
import { Merchant, SubscriptionPlan, User } from 'types';
import { creatorRouteConfig } from 'routes';
import RouteFromPath from 'components/Route/RouteFromPath';
import Button from 'components/Button';
import ContextMenu from 'components/ContextMenu';
import Icon from 'components/Icon';
import Anchor from 'components/Anchor';
import Modal from 'components/Modal';
import CancelSubscriptionModal from 'components/Modal/CancelSubscriptionModal';
import RequestModal from 'components/Modal/RequestModal';
import SubscriptionModal from 'components/Modal/SubscriptionModal';
import Skeleton from 'components/Skeleton';

import myFeed from 'assets/images/my-feed.png';
import defaultAvatar from 'assets/images/default-avatar-1.png';
import { getBadges } from 'state/reducers/badgeReducer';
import { Badge } from 'types/badge';

import { getLiveStreams } from 'state/reducers/liveStreamReducer';
import { playContent } from 'state/reducers/sessionReducer';
import moment from 'moment';
import { getVideoCallsAvailability, reset } from 'state/reducers/requestReducer';
import BlockUserModal from 'components/Modal/BlockUserModal';
import BlankPage from 'components/BlankPage';

import Promo from 'components/Promo';
import styles from './CreatorMain.module.scss';

const cn = classnames.bind(styles);

type GenericStringObject = { [key: string]: string };

const CreatorMain = () => {
  const { user, creator, liveEvent, merchandise, subscription, badge, liveStream, request } = useSelector(
    (state: RootState) => ({
      user: state.user,
      creator: state.creator,
      liveEvent: state.liveEvent,
      merchandise: state.merchandise,
      subscription: state.subscription,
      badge: state.badge,
      liveStream: state.liveStream,
      request: state.request
    })
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const { creatorId } = useParams<GenericStringObject>();

  const [isAboutOpen, setAboutOpen] = useState(false);
  const [isRequestModal, setIsRequestModal] = useState(false);
  const [isSubscriptionModal, setIsSubscriptionModal] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [showSubscribeButton, setShowSubscribeButton] = useState(false);
  const [showUnsubscribeButton, setShowUnsubscribeButton] = useState(false);
  const [cancelCreator, setCancelCreator] = useState<SubscriptionPlan | undefined>(undefined);
  const [viewImage, setViewImage] = useState(false);
  const [imageURL, setImageURL] = useState('');
  const [isBlockUser, setIsBlockUser] = useState<boolean>(false);
  const [isUserHidden, setIsUserHidden] = useState<boolean>(false);
  const [blockedBy, setBlockedBy] = useState<string[]>([]);
  const [availableDateTime, setAvailableDateTime] = useState<Date[]>();

  const goToPage = (page: string) => {
    switch (page) {
      case 'activity':
        history.push(`${match.url}`);
        break;
      case 'streams':
        history.push(`${match.url}/streams`);
        break;
      case 'music':
        history.push(`${match.url}/music`);
        break;
      case 'videos':
        history.push(`${match.url}/videos`);
        break;
      case 'gallery':
        history.push(`${match.url}/gallery`);
        break;
      case 'events':
        history.push(`${match.url}/events`);
        break;
      case 'merchandise':
        history.push(`${match.url}/merchandise`);
        break;
      case 'livestream':
        history.push(`${match.url}/livestream`);
        break;
    }
  };

  const onToggleFollow = () => {
    dispatch(followUser(creator.creator as Merchant));
  };

  const onToggleUnfollow = () => {
    dispatch(unfollowUser(creator.creator as Merchant));
  };

  const openLink = (url: string | undefined) => {
    if (url === undefined) return false;

    const win = window.open(url, '_blank');
    if (win != null) {
      win.focus();
    }
  };

  const cancelSubscription = (subscription: SubscriptionPlan) => {
    dispatch(unsubscribe(subscription));
    dispatch(fetchCreatorActivity(creator.creator?.guid as string));
    dispatch(
      toggleSnackbarOpen({
        message: `You have unsubscribed to ${subscription?.merchantUserDisplayName}.`,
        type: 'info',
        timeout: 4000
      })
    );

    setCancelCreator(undefined);
  };

  const confirmSubscription = (value: boolean) => {
    if (value) {
      setShowSubscribeButton(false);
      setIsSubscribed(true);
    } else {
      setShowSubscribeButton(true);
      setIsSubscribed(false);
    }
  };

  const confirmCancellation = (value: boolean) => {
    if (value) {
      cancelSubscription(cancelCreator as SubscriptionPlan);
      setIsSubscribed(false);
    } else {
      setCancelCreator(undefined);
    }
  };

  const getSubscriptionPlan = () => {
    if (subscription.subscriptionsByCreator) {
      dispatch(fetchSubscriptions());

      subscription.subscriptionsByCreator?.map((plan: SubscriptionPlan) => {
        const subscriptionPlan = user.subscriptions?.find(
          (x) => x.subscriptionPlanGUID === plan.guid && x.status === 'Active'
        );
        if (subscriptionPlan !== undefined) {
          setCancelCreator(subscriptionPlan);
        }
      });
    }
  };

  const checkLiveSticky = () => {
    const live = liveStream.liveStreams?.find(
      (x) => x.merchantGUID === creator.creator?.guid && x.liveStreamStatus === 'Active'
    );
    if (live) {
      return (
        <div
          className={cn('sticky', 'ft-mt-4')}
          onClick={() => dispatch(playContent({ contentType: ContentType.Post, post: live }))}>
          <img className={cn('avatar')} src={live.normalizedProfileImageUrl} />
          <div className={cn('ft-p-2')}>
            <div className={cn('has-text-danger', 'is-size-7')}>Click to watch {live.merchantName}</div>
            <div className={cn('has-text-weight-semibold', 'is-size-6')}>
              {moment(live.dateCreated).format('MM/DD/YYYY')} live stream
            </div>
            <div className={cn('views', 'ft-mt-2')}>{live?.activeViewerCount} viewers</div>
          </div>
        </div>
      );
    }
  };

  const openImage = (imageURL: string) => {
    setImageURL(imageURL);
    setViewImage(true);
  };

  const goToFan = (fan: User) => {
    dispatch(resetViewUser());

    if (fan.merchantUserGUID) {
      history.push(`/${fan?.username}`);
    } else {
      history.push(`/profile/${fan?.username}`);
    }
  };

  const goToSubscriptions = () => {
    history.push(`/preferences/subscriptions`);
  };

  const confirmBlocking = (value: boolean) => {
    if (value) {
      dispatch(
        blockUser({
          userGUID: user.user?.guid,
          blockedUserGUID: creator.creator?.userGUID,
          blockedUserUsername: creator.creator?.userUsername,
          blockedUserDisplayName: creator.creator?.userDisplayName,
          blockedUserMerchantGUID: creator.creator?.guid,
          blockedUserProfileImageUrl: creator.creator?.normalizedProfileImageUrl,
          blockedUserNormalizedProfileImageUrl: creator.creator?.normalizedProfileImageUrl
        })
      );
      dispatch(
        toggleSnackbarOpen({
          message: `${creator.creator?.name || creator.creator?.userDisplayName} blocked.`,
          type: 'info',
          timeout: 4000
        })
      );
    }

    setIsBlockUser(false);
  };

  const closeRequestModal = (value: boolean) => {
    if (value) {
      setIsRequestModal(false);
    }
  };

  useEffect(() => {
    if (creator.creator && subscription.subscriptionsByCreator) {
      if (new URLSearchParams(location.search).get('plan_redirect')) {
        setIsSubscriptionModal(true);
      }
    }
  }, [creator.creator, subscription.subscriptionsByCreator]);

  useEffect(() => {
    if (creator.creator?.guid && creator.creator?.userUsername === creatorId) {
      dispatch(getVideoCallsAvailability({ merchantGUID: creator.creator?.guid, type: 'Available' }));
      dispatch(getVideoCallsAvailability({ merchantGUID: creator.creator?.guid, type: 'MeetAndGreet' }));
      dispatch(getDashboard(creator.creator?.guid));
      dispatch(getMerchantLiveEvents(creator.creator?.guid));
      dispatch(getMerchantPastLiveEvents(creator.creator?.guid));
      dispatch(getMerchantMerchandise(creator.creator?.guid));
      dispatch(fetchCreatorActivity(creator.creator.guid));
      dispatch(fetchSubscriptions());
      dispatch(searchSubscriptionsByMerchantGuid(creator.creator.guid));
      dispatch(getBadges(creator.creator.guid));
      dispatch(getLiveStreams());
      dispatch(fetchMerchantPromos(creator.creator?.guid));
    }
  }, [creator.creator?.guid]);

  useEffect(() => {
    if (user.subscriptions) {
      if (user.subscriptions.find((x) => x.merchantGUID === creator.creator?.guid && x.status === 'Active')) {
        setIsSubscribed(true);
        setShowUnsubscribeButton(true);
      } else if (
        user.subscriptions.find(
          (x) =>
            x.merchantGUID === creator.creator?.guid &&
            x.status === 'Cancelled' &&
            new Date(x.periodEndDate as Date).getTime() >= new Date().getTime()
        )
      ) {
        setIsSubscribed(true);
        setShowUnsubscribeButton(false);
      } else {
        setIsSubscribed(false);
        setShowUnsubscribeButton(false);

        if ((subscription.subscriptionsByCreator?.length as number) > 0) {
          setShowSubscribeButton(true);
        } else {
          setShowSubscribeButton(false);
        }
      }
    }
  }, [user.subscriptions, subscription.subscriptionsByCreator]);

  useEffect(() => {
    dispatch(reset());
    dispatch(resetPosts());
    dispatch(resetCreator());
    dispatch(resetPeriodEndDate());
    dispatch(resetSubscriptionsByCreator());
    setShowSubscribeButton(false);
    setIsSubscribed(false);
    setShowUnsubscribeButton(false);
    setIsUserHidden(false);
    dispatch(fetchAllCreators());
    dispatch(fetchCreator(creatorId));
    dispatch(resetFeed());
  }, [creatorId]);

  useEffect(() => {
    if (user.user?.guid) {
      dispatch(fetchMerchantFollowing(user.user?.guid));
      dispatch(searchBlockedUsers(user.user?.guid));
      dispatch(searchBlockers(user.user?.guid));
    }
  }, [user.user]);

  useEffect(() => {
    if (creator.creators) {
      if (creator.creators.findIndex((x) => x.userUsername === creatorId) < 0) {
        setIsUserHidden(true);
      }
    }
  }, [creator.creators]);

  useEffect(() => {
    if (user.blockers && user.merchantFollowing) {
      const merchantBlockers: string[] = [];
      user.merchantFollowing?.forEach((following) => {
        if (user.blockers?.find((x) => x.userGUID === following.followedUserGUID)) {
          merchantBlockers.push(following.followedUserGUID as string);
        }
      });
      setBlockedBy(merchantBlockers);
    }
  }, [user.blockers, user.merchantFollowing]);

  useEffect(() => {
    return () => {
      dispatch(resetPosts());
      dispatch(resetCreator());
      dispatch(resetLiveEvent());
    };
  }, []);

  useEffect(() => {
    const availability: Date[] = [];
    request.callAvailability?.forEach((date) => {
      let tempDateTime = moment(date.startDate);
      do {
        const find = request.reserveSlots?.find(
          (slot) => moment(slot.startDate).toISOString() === tempDateTime.toISOString()
        );
        if (tempDateTime.isAfter(moment()) && find === undefined) {
          availability.push(tempDateTime.toDate());
        }
        tempDateTime = tempDateTime.add(15, 'minutes');
      } while (tempDateTime.isBefore(moment(date.endDate)));
    });

    setAvailableDateTime(availability);
  }, [request.callAvailability, request.reserveSlots]);

  return (
    <div>
      <div className={cn('sidebar')}>
        <div className={cn('creator')} onClick={() => history.push('/')}>
          <div className={cn('avatar')}>
            <img src={myFeed} />
          </div>
          <div className={cn('name')}>My Feed</div>
        </div>
        <div>
          <div className={cn('section', 'is-size-8')}>
            <span>FOLLOWING</span>
          </div>
          {
            <>
              {user.merchantFollowing ? (
                <>
                  {user.merchantFollowing
                    ?.filter((x) => !blockedBy.includes(x.followedUserGUID as string) && x.isFollowedUserMerchantActive)
                    .map((following) => {
                      const isLive = !!liveStream.liveStreams?.find(
                        (x) => x.merchantGUID === following.followedUserMerchantGUID && x.liveStreamStatus === 'Active'
                      );
                      return (
                        <div
                          key={following.guid}
                          className={cn('creator', { highlight: creatorId === following.followedUserUsername })}
                          onClick={() => {
                            history.push(`/${following?.followedUserUsername}${isLive ? '/streams' : ''}`);
                          }}>
                          <div className={cn('avatar', { live: isLive })}>
                            <img src={following.followedUserNormalizedProfileImageUrl || defaultAvatar} />
                          </div>
                          <div className={cn('name')}>
                            {following.followedUserDisplayName || `@${following.followedUserUsername}`}
                            {isLive && <div className={cn('has-text-danger', 'is-size-8')}>LIVE</div>}
                          </div>
                        </div>
                      );
                    })}
                </>
              ) : (
                <div className={cn('sidebar-skeleton')}>
                  <div className={cn('flexbox', 'ft-py-1')}>
                    <Skeleton type="circ" radius={16} />
                    <Skeleton className={cn('desktop', 'ft-ml-2')} type="rect" width="160px" height="16px" />
                  </div>
                  <div className={cn('flexbox', 'ft-py-1')}>
                    <Skeleton type="circ" radius={16} />
                    <Skeleton className={cn('desktop', 'ft-ml-2')} type="rect" width="160px" height="16px" />
                  </div>
                  <div className={cn('flexbox', 'ft-py-1')}>
                    <Skeleton type="circ" radius={16} />
                    <Skeleton className={cn('desktop', 'ft-ml-2')} type="rect" width="160px" height="16px" />
                  </div>
                </div>
              )}
            </>
          }
        </div>
      </div>
      <div className={cn('full-height')}>
        {user.blockedUsers?.find((user) => user.blockedUserUsername === creatorId) ? (
          <div className={cn('container')}>
            <div className={cn('profile')}>
              <div className={cn('fl-right')}>
                <BlankPage
                  className={cn('is-size-5')}
                  text={'You have blocked ' + creatorId}
                  tabText="You can unblock the user here"
                  tabLink="/preferences/block"></BlankPage>
              </div>
            </div>
            <div className={cn('club')}></div>
          </div>
        ) : user.blockers?.find((blocker) => blocker.userGUID === creator.creator?.userGUID) ? (
          <div className={cn('container')}>
            <div className={cn('profile')}>
              <div className={cn('fl-right')}>
                <BlankPage className={cn('is-size-5')} text={'You have been blocked by ' + creatorId}></BlankPage>
              </div>
            </div>
            <div className={cn('club')}></div>
          </div>
        ) : creator.creator ? (
          <div className={cn('container')}>
            <div className={cn('profile')}>
              <div className={cn('profile-float')}>
                <div className={cn('tile', 'header')}>
                  <div
                    className={cn('banner')}
                    onClick={() => openImage(creator.creator?.normalizedBackgroundImageUrl as string)}>
                    {creator.creator?.normalizedBackgroundImageUrl && (
                      <img src={creator.creator?.normalizedBackgroundImageUrl || ''} />
                    )}
                  </div>
                  <div className={cn('creator-info')}>
                    <div className={cn('flexbox')}>
                      <div
                        className={cn('avatar-creator')}
                        onClick={() => openImage(creator.creator?.normalizedProfileImageUrl as string)}>
                        <img src={creator.creator?.normalizedProfileImageUrl || defaultAvatar} />
                      </div>
                      <div className={cn('creator-details', 'ft-ml-4')}>
                        <div className={cn('flexbox', 'name')}>
                          <div>{creator.creator?.name || creator.creator?.userDisplayName}</div>
                          {/* <div className={cn('category')}>MUSIC</div> */}
                        </div>
                        <div className={cn('sub')}>{creator.creator?.followerCount} fans</div>
                      </div>
                      {isSubscribed &&
                        badge.badges?.find(
                          (badge) => badge.type === 'LoyaltyBadge' && badge.membershipDuration === 'OneMonth'
                        ) && (
                          <div className={cn('right-badge', 'badge')}>
                            <img
                              src={
                                badge.badges?.find(
                                  (badge) => badge.type === 'LoyaltyBadge' && badge.membershipDuration === 'OneMonth'
                                )?.badgeImageUrl
                              }
                            />
                          </div>
                        )}
                      {isSubscribed && (
                        <div className={cn('right', 'ft-mr-4')}>
                          <Button color="clear" className={cn('subscribed')} onClick={goToSubscriptions}>
                            SUBSCRIBED
                          </Button>
                        </div>
                      )}
                    </div>
                    <div className={cn('ft-mt-2')}>
                      <div className={cn('about', { open: isAboutOpen })}>{creator.creator?.description}</div>
                      {creator.creator?.description && creator.creator?.description?.length >= 240 && (
                        <Anchor size="sm" onClick={() => setAboutOpen(!isAboutOpen)}>
                          show {isAboutOpen ? 'less' : 'more'}
                        </Anchor>
                      )}
                    </div>
                    <div className={cn('flexbox', 'ft-mt-2')}>
                      {creator.creator.facebookUrl && (
                        <Button
                          hasIcon
                          color="clear"
                          className={cn('ft-mr-2')}
                          onClick={() => openLink(creator.creator ? creator.creator.facebookUrl : undefined)}>
                          <Icon name="facebook" size="sm" />
                        </Button>
                      )}

                      {creator.creator.instagramUrl && (
                        <Button
                          hasIcon
                          color="clear"
                          className={cn('ft-mr-2')}
                          onClick={() => openLink(creator.creator ? creator.creator.instagramUrl : undefined)}>
                          <Icon name="instagram" size="sm" />
                        </Button>
                      )}

                      {creator.creator.tikTokUrl && (
                        <Button
                          hasIcon
                          color="clear"
                          className={cn('ft-mr-2')}
                          onClick={() => openLink(creator.creator ? creator.creator.tikTokUrl : undefined)}>
                          <Icon name="tiktok" size="sm" />
                        </Button>
                      )}

                      {creator.creator.twitterUrl && (
                        <Button
                          hasIcon
                          color="clear"
                          className={cn('ft-mr-2')}
                          onClick={() => openLink(creator.creator ? creator.creator.twitterUrl : undefined)}>
                          <Icon name="twitter" size="sm" />
                        </Button>
                      )}

                      {creator.creator.youtubeUrl && (
                        <Button
                          hasIcon
                          color="clear"
                          className={cn('ft-mr-2')}
                          onClick={() => openLink(creator.creator ? creator.creator.youtubeUrl : undefined)}>
                          <Icon name="youtube" size="sm" />
                        </Button>
                      )}

                      {creator.creator.merchantUrl && (
                        <Button
                          hasIcon
                          color="clear"
                          className={cn('ft-mr-2')}
                          onClick={() => openLink(creator.creator ? creator.creator.merchantUrl : undefined)}>
                          <Icon name="link" size="sm" />
                        </Button>
                      )}
                    </div>
                    <div className={cn('flexbox', 'ft-mt-4')}>
                      {showSubscribeButton && user.user?.guid !== creator.creator?.userGUID && (
                        <div className={cn('ft-mr-4')}>
                          <Button
                            color="primary"
                            onClick={() => {
                              setIsSubscriptionModal(true);
                            }}>
                            Subscribe
                          </Button>
                        </div>
                      )}
                      {!creator.creator?.isFollowRequestedByCallingUser &&
                        !creator.creator?.isFollowedByCallingUser &&
                        user.user?.guid !== creator.creator?.userGUID && (
                          <div className="ft-mr-4">
                            <Button color="secondary" onClick={onToggleFollow}>
                              Follow
                            </Button>
                          </div>
                        )}
                      {((creator.creator?.isFollowRequestedByCallingUser && creator.creator.userAutoAcceptFollowers) ||
                        creator.creator?.isFollowedByCallingUser) &&
                        user.user?.guid !== creator.creator?.userGUID && (
                          <div className={'ft-mr-4'}>
                            <Button color="secondary" onClick={onToggleUnfollow}>
                              Unfollow
                            </Button>
                          </div>
                        )}
                      {creator.creator?.isFollowRequestedByCallingUser &&
                        !creator.creator.userAutoAcceptFollowers &&
                        user.user?.guid !== creator.creator?.userGUID && (
                          <div className={cn('ft-mr-4')}>
                            {<Button onClick={onToggleUnfollow}>Cancel Follow Request</Button>}
                          </div>
                        )}
                      {user.user?.guid !== creator.creator?.userGUID &&
                        ((creator.creator.acceptMeetAndGreetRequests && (availableDateTime?.length as number) > 0) ||
                          creator.creator?.acceptVideoMessageRequests) && (
                          <div className={cn('ft-mr-4')}>
                            <Button color="secondary" onClick={() => setIsRequestModal(true)}>
                              Request
                            </Button>
                          </div>
                        )}
                      {user.user?.guid !== creator.creator?.userGUID && (
                        <>
                          <div className={cn('more')}>
                            <ContextMenu
                              interface={
                                <Button shape="circle" color="white" hasIcon>
                                  <Icon name="ellipsis" size="sm" />
                                </Button>
                              }>
                              <ul>
                                <li className={cn('has-text-danger')} onClick={() => setIsBlockUser(true)}>
                                  Block
                                </li>
                                {showUnsubscribeButton && (
                                  <li className={cn('has-text-danger')} onClick={() => getSubscriptionPlan()}>
                                    Unsubscribe
                                  </li>
                                )}
                              </ul>
                            </ContextMenu>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {creator.promos ? (
                  <div className={cn('promo-wrapper', 'is-flex', 'ft-mt-4')}>
                    {creator.promos?.map((p) => (
                      <div className={cn('post')}>
                        <Promo post={p} />
                      </div>
                    ))}
                  </div>
                ) : (
                  ''
                )}
                {checkLiveSticky()}

                <div className={cn('menu-wrapper')}>
                  <div className={cn('menu')}>
                    <div className={cn('flexbox', 'tab')}>
                      <div
                        className={cn('tab-item', { active: location.pathname === `/${creatorId}` })}
                        onClick={() => goToPage('activity')}>
                        Activity
                        {location.pathname === `/${creatorId}` && <div className={cn('border')} />}
                      </div>
                      {merchandise.merchandise && merchandise.merchandise.length > 0 && (
                        <div
                          className={cn('tab-item', { active: location.pathname === `/${creatorId}/merchandise` })}
                          onClick={() => goToPage('merchandise')}>
                          Merchandise
                          {location.pathname === `/${creatorId}/merchandise` && <div className={cn('border')} />}
                        </div>
                      )}
                      {((creator.feed?.filter((x) => x.postType === PostType.LiveStream).length || 0) > 0 ||
                        (liveStream.liveStreams?.filter((x) => x.merchantGUID === creator.creator?.guid).length || 0) >
                          0) && (
                        <div
                          className={cn('tab-item', { active: location.pathname === `/${creatorId}/streams` })}
                          onClick={() => goToPage('streams')}>
                          Streams
                          {location.pathname === `/${creatorId}/streams` && <div className={cn('border')} />}
                        </div>
                      )}
                      {(creator.feed?.filter((x) => x.postType === (PostType.PostGroup || PostType.Audio)).length ||
                        0) > 0 && (
                        <div
                          className={cn('tab-item', { active: location.pathname === `/${creatorId}/music` })}
                          onClick={() => goToPage('music')}>
                          Music
                          {location.pathname === `/${creatorId}/music` && <div className={cn('border')} />}
                        </div>
                      )}
                      {(creator.feed?.filter((x) => x.postType === PostType.Video).length || 0) > 0 && (
                        <div
                          className={cn('tab-item', { active: location.pathname === `/${creatorId}/videos` })}
                          onClick={() => goToPage('videos')}>
                          Videos
                          {location.pathname === `/${creatorId}/videos` && <div className={cn('border')} />}
                        </div>
                      )}
                      {(creator.feed?.filter((x) => x.postType === PostType.Image).length || 0) > 0 && (
                        <div
                          className={cn('tab-item', { active: location.pathname === `/${creatorId}/gallery` })}
                          onClick={() => goToPage('gallery')}>
                          Gallery
                          {location.pathname === `/${creatorId}/gallery` && <div className={cn('border')} />}
                        </div>
                      )}
                      {((liveEvent.merchantEvents && liveEvent.merchantEvents.length > 0) ||
                        (liveEvent.merchantPastEvents && liveEvent.merchantPastEvents.length > 0)) && (
                        <div
                          className={cn('tab-item', { active: location.pathname === `/${creatorId}/events` })}
                          onClick={() => goToPage('events')}>
                          Events
                          {location.pathname === `/${creatorId}/events` && <div className={cn('border')} />}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {creatorRouteConfig.map((route) => (
                  <RouteFromPath key={`route${route.path}`} base={match.url} {...route} />
                ))}
              </div>
            </div>
            <div className={cn('club')}>
              <div className={cn('tile', 'top-fans')}>
                <div className={cn('header')}>Top Fans</div>
                <div className={cn('scroll-y')}>
                  {creator.topFans?.map((fan, index) => (
                    <div className={cn('flexbox', 'ft-mt-2')} key={index}>
                      <div className={cn('avatar', 'cursor')} onClick={() => goToFan(fan)}>
                        <img src={fan.profileImageUrl || defaultAvatar} />
                      </div>
                      <div className={cn('ft-ml-2', 'cursor')} onClick={() => goToFan(fan)}>
                        {fan.displayName}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/*subscription.subscriptionsByCreator && (
                <>
                  {(isSubscribed || checkOngoing()) && (
                    <div className={cn('tile', 'post-subscription')}>
                      You are currently subscribed to {creator.creator.userDisplayName}. <br />
                      Subscriptions ends on{' '}
                      {moment(
                        subscription.periodEndDate ||
                          user.subscriptions?.find(
                            (x) => x.merchantGUID === creator.creator?.guid && x.status === 'Active'
                          )?.periodEndDate ||
                          user.subscriptions?.find(
                            (x) =>
                              x.merchantGUID === creator.creator?.guid &&
                              x.status === 'Cancelled' &&
                              new Date(x.periodEndDate as Date).getTime() >= new Date().getTime()
                          )?.periodEndDate
                      ).format('MM/DD/YYYY')}
                      .
                    </div>
                  )}
                </>
              )*/}
            </div>
          </div>
        ) : creator.creators && isUserHidden ? (
          <div className={cn('container')}>
            <div className={cn('profile')}>
              <div className={cn('fl-right')}>
                <BlankPage className={cn('is-size-5')} text={'No profile found for ' + creatorId}></BlankPage>
              </div>
            </div>
            <div className={cn('club')}></div>
          </div>
        ) : (
          <div className={cn('container')}>
            <div className={cn('profile')}>
              <div>
                <Skeleton type="rect" width="640px" height="332px" />
              </div>
              <div className={cn('ft-mt-4')}>
                <Skeleton type="rect" width="640px" height="48px" />
              </div>
              <div className={cn('ft-mt-4')}>
                <Skeleton type="rect" width="640px" height="600px" />
              </div>
            </div>
            <div className={cn('club')}>
              <div>
                <Skeleton type="rect" width="280px" height="254px" />
              </div>
            </div>
          </div>
        )}

        {isSubscriptionModal && (
          <Modal onClose={() => setIsSubscriptionModal(false)}>
            <SubscriptionModal
              creator={creator.creator as Merchant}
              plans={subscription.subscriptionsByCreator as SubscriptionPlan[]}
              badges={badge.badges as Badge[]}
              user={user.user as User}
              onSelect={confirmSubscription}
            />
          </Modal>
        )}

        {isRequestModal && (
          <Modal onClose={() => setIsRequestModal(false)}>
            <RequestModal creator={creator.creator} availableDates={availableDateTime} onSelect={closeRequestModal} />
          </Modal>
        )}

        {cancelCreator && (
          <Modal onClose={() => setCancelCreator(undefined)}>
            <CancelSubscriptionModal subscriptionPlan={cancelCreator} onSelect={confirmCancellation} />
          </Modal>
        )}

        {imageURL && viewImage && (
          <div className={cn('player')} onClick={() => setViewImage(false)}>
            <div className={cn('layout')}>
              <div className={cn('theater')}>
                <img className={cn('media', 'image')} src={imageURL} />
              </div>
            </div>
          </div>
        )}

        {isBlockUser && (
          <Modal onClose={() => setIsBlockUser(false)}>
            <BlockUserModal
              onSelect={confirmBlocking}
              blockedUsername={(creator.creator?.name as string) || (creator.creator?.userDisplayName as string)}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default CreatorMain;
