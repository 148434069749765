import React, { useState } from 'react';
import classnames from 'classnames/bind';

import { Post } from 'types';
import Modal from 'components/Modal';
import Zoom from 'components/Zoom';

import styles from './ItemCard.module.scss';
import 'react-medium-image-zoom/dist/styles.css';

interface ItemCardProps {
  item: Post;
}

const cx = classnames.bind(styles);

const ItemCard = (props: ItemCardProps) => {
  const [isShowModal, setIsShowModal] = useState(false);

  return (
    <>
      <div className={cx('collection-card')}>
        <img className={cx('thumbnail')} src={props.item.contentUrl} onClick={() => setIsShowModal(true)} />
      </div>
      {isShowModal && (
        <Modal customClass="zoom-container" onClose={() => setIsShowModal(false)}>
          <div className={cx('close-modal')} onClick={() => setIsShowModal(false)}>
            <i className="riptide-close" />
          </div>
          <div className={cx('zoom')}>
            {(props.item.thumbnailImageUrl || props.item.contentUrl) && (
              <Zoom src={props.item.contentUrl || props.item.thumbnailImageUrl || ''} />
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default ItemCard;
