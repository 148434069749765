import React from 'react';
import classnames from 'classnames/bind';

import styles from './Label.module.scss';

const cn = classnames.bind(styles);

interface LabelProps {
  name: string;
  color?: string;
}

const Label = (props: LabelProps) => {
  return <div className={cn('champ', props.color)}>{props.name}</div>;
};

export default Label;
