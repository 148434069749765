import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import moment from 'moment';

import { ContentType, PostType, ResponseStatus } from 'enums';
import { Feed, Post } from 'types';
import { fetchPostsByMerchatByType } from 'state/reducers/postReducer';
import { playContent } from 'state/reducers/sessionReducer';
import { createView } from 'state/reducers/viewReducer';
import { RootState } from 'state/store';

import Icon from 'components/Icon';
import Modal from 'components/Modal';
import UnlockModal from 'components/Modal/UnlockModal';

import styles from './Gallery.module.scss';

const cn = classnames.bind(styles);

const Gallery = () => {
  const { creator, post, user } = useSelector((state: RootState) => ({
    creator: state.creator,
    post: state.post,
    user: state.user
  }));
  const [unlockPost, setUnlockPost] = useState<Feed | undefined>(undefined);
  const dispatch = useDispatch();

  const [gallery, setGallery] = useState<Post[]>();

  const onViewContent = (post: Post) => {
    dispatch(playContent({ contentType: ContentType.Post, post }));
    const view = {
      merchantGUID: post?.merchantGUID,
      postGUID: post?.guid,
      dateViewed: moment().format('MM/DD/YY hh:mm:ss')
    };

    dispatch(createView(view));
  };

  useEffect(() => {
    dispatch(
      fetchPostsByMerchatByType({
        creatorGuid: creator.creator?.guid as string,
        postType: PostType.Image
      })
    );
  }, [creator.creator?.guid]);

  useEffect(() => {
    if (post.status === ResponseStatus.Success && post.postType === PostType.Image) {
      setGallery(post.posts);
    }
  }, [post]);

  useEffect(() => {
    const updatedUnlockPost = post.posts?.find((p) => p.guid === unlockPost?.guid);
    if (updatedUnlockPost !== undefined && updatedUnlockPost) {
      setUnlockPost(undefined);
      setGallery(post.posts);
    }
  }, [post.posts]);

  return (
    <div className={cn('gallery-wrapper')}>
      <div className={cn('gallery')}>
        <div className={cn('grid')}>
          {gallery?.map((galleryPost) => (
            <div className={cn('image-wrapper')} key={galleryPost.guid}>
              <div
                className={cn('thumbnail')}
                onClick={() => (galleryPost.isLockedForCallingUser === false ? onViewContent(galleryPost) : null)}>
                <img
                  className={cn({
                    blur: galleryPost.isLockedForCallingUser && user.user?.guid !== creator.creator?.userGUID
                  })}
                  src={galleryPost.contentUrl}
                />
              </div>
              {galleryPost.isLockedForCallingUser && user.user?.guid !== creator.creator?.userGUID && (
                <div
                  className={cn('frosted')}
                  onClick={() => {
                    setUnlockPost(galleryPost);
                  }}>
                  <Icon name="lock" />
                </div>
              )}
              <div className={cn('ft-mt-1')}>
                <div className={cn('name')}>{galleryPost.name || galleryPost.description}</div>
                <div className={cn('views')}>{galleryPost.viewCount} views</div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {unlockPost && (
        <Modal onClose={() => setUnlockPost(undefined)}>
          <UnlockModal feed={unlockPost} />
        </Modal>
      )}
    </div>
  );
};

export default Gallery;
