import React from 'react';
import classnames from 'classnames/bind';

import styles from './About.module.scss';

const cn = classnames.bind(styles);

const About = () => {
  return (
    <div>
      <div className={cn('is-size-5', 'has-text-weight-semibold')}>About HeyFans</div>
      <div className={cn('ft-mt-4')}>
        <p>
          HeyFans is about giving fans access to popular athletes across all sports and leagues. Follow athletes that
          you like, watch their content, enjoy their live streams and reach out to them for a personalized video message
          or call. You can even support your favorite athletes by tipping them with FanBucks or subscribing to their
          channel.
        </p>
        <p>
          We hope you enjoy HeyFans and congratulations on joining one of the fastest growing fan engagement platforms
          in the world.
        </p>
        <p>
          Dan Baxter
          <br />
          Chief Fan Officer
          <br />
          <a href="mailto:dan@heyfans.com">dan@heyfans.com</a>
        </p>
      </div>
    </div>
  );
};

export default About;
