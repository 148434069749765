/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';

declare global {
  interface Document {
    msExitFullscreen?(): void;
    mozCancelFullScreen?(): void;
    webkitExitFullscreen?(): void;
  }
  interface Element {
    msRequestFullscreen?(): void;
    mozRequestFullScreen?(): void;
    webkitRequestFullscreen?(): void;
    webkitEnterFullscreen?(): void;
  }
}

const useVideoPlayer = (videoElement: any) => {
  const [playerState, setPlayerState] = useState({
    isPlaying: false,
    progress: 0,
    speed: 1,
    isMuted: false,
    isFullscreen: false,
    volume: 100,
    prevVolume: 100,
    currentTime: 0,
    duration: 0
  });

  const togglePlay = () => {
    setPlayerState({
      ...playerState,
      isPlaying: !playerState.isPlaying
    });
  };

  useEffect(() => {
    if (videoElement.current !== null) {
      playerState.isPlaying ? videoElement.current.play() : videoElement.current.pause();
    }
  }, [playerState.isPlaying, videoElement]);

  const handleOnTimeUpdate = () => {
    if (videoElement.current !== null) {
      const currentTime = videoElement.current.currentTime;
      const duration = videoElement.current.duration;
      const progress = (currentTime / duration) * 100;
      setPlayerState({
        ...playerState,
        progress,
        currentTime,
        duration
      });
    }
  };

  const handleVideoProgress = (event: any) => {
    const manualChange = Number(event.target.value);
    if (videoElement.current !== null) {
      videoElement.current.currentTime = (videoElement.current.duration / 100) * manualChange;
      setPlayerState({
        ...playerState,
        progress: manualChange
      });
    }
  };

  const handleVideoSpeed = (event: any) => {
    const speed = Number(event.target.value);
    if (videoElement.current !== null) {
      videoElement.current.playbackRate = speed;
      setPlayerState({
        ...playerState,
        speed
      });
    }
  };

  const toggleMute = () => {
    if (videoElement.current !== null) {
      setPlayerState({
        ...playerState,
        isMuted: !playerState.isMuted,
        prevVolume: playerState.volume
      });
    }
  };

  useEffect(() => {
    if (videoElement.current !== null) {
      playerState.isMuted ? (videoElement.current.muted = true) : (videoElement.current.muted = false);
    }
  }, [playerState.isMuted, videoElement]);

  const toggleFullscreen = () => {
    setPlayerState({
      ...playerState,
      isFullscreen: !playerState.isFullscreen
    });
  };

  const closeFullscreen = () => {
    setPlayerState({
      ...playerState,
      isFullscreen: false
    });
  };

  useEffect(() => {
    // const theater: HTMLElement | null = document.getElementById('video-only');
    setPlayerState({
      ...playerState,
      isFullscreen: playerState.isFullscreen
    });
  }, [playerState.isFullscreen]);

  const handleVolume = (event: any) => {
    const volume = Number(event?.target.value);
    if (volume <= 0) {
      playerState.isMuted = true;
    } else {
      playerState.isMuted = false;
    }
    videoElement.current.volume = volume * 0.01;
    setPlayerState({
      ...playerState,
      volume: volume
    });
  };

  return {
    playerState,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleVideoSpeed,
    toggleMute,
    toggleFullscreen,
    handleVolume,
    closeFullscreen
  };
};

export default useVideoPlayer;
