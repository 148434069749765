import React from 'react';

import './Faq.module.scss';

const Faq = () => {
  return (
    <div>
      <div className="has-text-weight-bold has-text-primary is-size-6">FAQs</div>
      <div className="has-text-weight-bold is-size-6 has-text-primary ft-mt-2">General Info</div>
      <div className="ft-mt-4">
        <div className="legal">
          <span className="subheader">What Is HeyFans?</span>
        </div>
      </div>
      <div className="ft-mt-2 response is-size-7">
        HeyFans is a place where athletes can connect directly with their fans - unfiltered and uncensored. Find out
        what really happened on the field or ask questions directly. You can request a video call to talk one-on-one
        with your favorite athlete, or have them record a personalized video message just for you or someone you care
        about. You can even subscribe to an athlete's channel and get exclusive access to content, club rooms and behind
        the scenes videos.
      </div>
      <div className="ft-mt-4">
        <div className="legal">
          <span className="subheader">Can Anyone Post Content On HeyFans?</span>
        </div>
      </div>
      <div className="ft-mt-2 response">
        Only verified athletes are able to post content on HeyFans. If you are an athlete and would like to be featured
        on HeyFans, please complete our application{' '}
        <a href="/apply" className="has-text-green">
          here
        </a>
        .
      </div>
      <div className="ft-mt-4">
        <div className="legal">
          <span className="subheader">Is HeyFans Free?</span>
        </div>
      </div>
      <div className="ft-mt-2 response">
        It is free to join HeyFans as a fan. Some content and events promoted by an athlete may have a fee, typically
        charged in FanBucks.
      </div>
      <div className="has-text-weight-bold is-size-5 has-text-primary ft-mt-6">About FanBucks</div>
      <div className="ft-mt-4">
        <div className="legal">
          <span className="subheader">What Are FanBucks?</span>
        </div>
      </div>
      <div className="ft-mt-2 response">
        FanBucks are the digitial currency used on the HeyFans platform. FanBucks need to be purchased from HeyFans and
        can then be used to tip, pay and reward athletes for their work, events, merch and more.
      </div>
      <div className="ft-mt-4">
        <div className="legal">
          <span className="subheader">Are FanBucks Refundable?</span>
        </div>
      </div>
      <div className="ft-mt-2 response">
        FanBucks are not refundable. Once they are purchased they are stored in your account's wallet and can then be
        used to tip or pay for content and athlete access.
      </div>
      <div className="ft-mt-4">
        <div className="legal">
          <span className="subheader">Do FanBucks Expire?</span>
        </div>
      </div>
      <div className="ft-mt-2 ft-mb-4 response">FanBucks do not expire.</div>
    </div>
  );
};

export default Faq;
