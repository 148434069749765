import { createSlice } from '@reduxjs/toolkit';

interface SnackbarState {
  toggle: boolean;
  timeout: number;
  type?: string;
  message?: string;
  url?: string;
}

const initialState: SnackbarState = {
  toggle: false,
  message: '',
  type: 'info',
  timeout: 4000,
  url: ''
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    toggleSnackbarOpen: (state, action) => {
      state.toggle = true;
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.url = action.payload.url;
      state.timeout = action.payload.timeout || initialState.timeout;
      return state;
    },
    toggleSnackbarClose: (state) => {
      state.toggle = false;
      state.message = '';
      state.type = 'info';
      state.url = '';
      return state;
    }
  }
});

export const { toggleSnackbarOpen, toggleSnackbarClose } = snackbarSlice.actions;
export default snackbarSlice.reducer;
