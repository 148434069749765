/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import { RootState } from 'state/store';
import Twilio from 'twilio-video';

import { endCall, generateVideoMeetingToken } from 'state/reducers/sessionReducer';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { toggleSnackbarOpen } from 'state/reducers/snackbarReducer';
import interact from 'interactjs';
import defaultIcon from 'assets/images/default-avatar-1.png';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import styles from './TwilioVideoCall.module.scss';

const cn = classnames.bind(styles);

const TwilioVideoCall = () => {
  const { session, user } = useSelector((state: RootState) => ({ session: state.session, user: state.user }));
  const [room, setRoom] = useState<Twilio.Room | undefined>();

  const [isEnableVideo, setIsEnableVideo] = useState<boolean>(true);
  const [isEnableAudio, setIsEnableAudio] = useState<boolean>(true);
  const [participantVideoEnable, setParticipantVideoEnable] = useState<boolean>(false);
  const [participantAudioEnable, setParticipantAudioEnable] = useState<boolean>(false);
  const [isParticipantConnected, setIsParticipantConnected] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(0);
  const [countdown, setCountdown] = useState<number | string | undefined>(undefined);
  const [isTimerStart, setIsTimerStart] = useState<boolean>(true);
  const [timeLapse, setTimeLapse] = useState<string>('');
  const [inGracePeriod, setInGracePeriod] = useState<boolean>(true);
  const [dateDiff, setDateDiff] = useState<number>(0);
  const [startTime, setStarTime] = useState<Date | undefined>(undefined);

  const dispatch = useDispatch();

  const connect = async () => {
    if (session.videoMeetingToken?.token) {
      const options = {
        audio: true,
        video: true,
        name: session.videoCall?.videoMeetingExternalMeetingId
      };

      await Twilio.connect(session.videoMeetingToken.token, options)
        .then((room: Twilio.Room) => {
          addLocalVideo(room.localParticipant);
          room.participants.forEach(participantConnected);
          room.on('participantConnected', (participant) => {
            participantConnected(participant);
          });

          room.on('participantDisconnected', (participant) => {
            // setParticipantAudioEnable(false);
            setParticipantVideoEnable(false);
            setIsParticipantConnected(false);
            setIsParticipantConnected(false);
            participantDisconnected(participant);
          });

          room.on('disconnected', () => {
            room.participants.forEach((participant) => {
              // setParticipantAudioEnable(false);
              setParticipantVideoEnable(false);
              setIsParticipantConnected(false);
              setIsParticipantConnected(false);
              participantDisconnected(participant);
            });
          });
          setRoom(room);
        })
        .catch((error) => {
          hangUp();
          dispatch(
            toggleSnackbarOpen({
              message: error.message,
              type: 'danger',
              timeout: 3000
            })
          );
        });
    }
  };

  const participantTimerCheck = () => {
    const data = cloneDeep(session.videoCall);
    if (data) {
      const currentDate = new Date();
      setIsTimerStart(true);
      if (data.dateScheduled) {
        const dateScheduled = new Date(data.dateScheduled);
        if (currentDate.valueOf() < dateScheduled.valueOf()) {
          data.dateCallStarted = currentDate;
          setStarTime(currentDate);
        } else if (currentDate.valueOf() < dateScheduled.valueOf() + 60000) {
          setInGracePeriod(true);
          setStarTime(currentDate);
          if (session.videoCall?.durationInMinutes) {
            setDateDiff(session.videoCall?.durationInMinutes * 60);
          }
        } else if (currentDate.valueOf() > dateScheduled.valueOf() + 60000) {
          setStarTime(dateScheduled);
        }
      }
    }
  };

  const participantConnected = (participant: Twilio.RemoteParticipant) => {
    participantTimerCheck();

    setIsParticipantConnected(true);
    const participantDiv = document.createElement('div');
    participantDiv.setAttribute('id', participant.sid);
    participantDiv.setAttribute('class', 'participant');

    const tracksDiv = document.createElement('div');
    tracksDiv.setAttribute('id', `tracks-${participant.sid}`);
    tracksDiv.setAttribute('class', cn('track'));

    participantDiv.appendChild(tracksDiv);

    participant.on('trackSubscribed', (track: Twilio.RemoteAudioTrack | Twilio.RemoteVideoTrack) => {
      setIsTimerStart(true);
      setIsParticipantConnected(true);

      track.on('enabled', (t) => {
        if (t.kind === 'audio') {
          setParticipantAudioEnable(true);
        }
        if (t.kind === 'video') {
          setParticipantVideoEnable(true);
        }
      });
      track.on('disabled', (t) => {
        if (t.kind === 'audio') {
          setParticipantAudioEnable(false);
        }
        if (t.kind === 'video') {
          setParticipantVideoEnable(false);
        }
      });

      trackSubscribed(tracksDiv, track);

      const status = track.isEnabled === false || track.mediaStreamTrack.enabled === false ? false : true;
      if (track.kind === 'audio') {
        setParticipantAudioEnable(status);
      }
      if (track.kind === 'video') {
        setParticipantVideoEnable(status);
      }
    });

    participant.on('trackUnsubscribed', (track: Twilio.RemoteAudioTrack | Twilio.RemoteVideoTrack) => {
      trackUnsubscribed(track);
      const status = track.isEnabled === false || track.mediaStreamTrack.enabled === false ? false : true;
      if (track.kind === 'audio') {
        setParticipantAudioEnable(status);
      }
      if (track.kind === 'video') {
        setParticipantVideoEnable(status);
      }
    });

    participant.tracks.forEach((publication) => {
      if (publication.track && publication.isSubscribed) {
        const t = publication.track as any;
        tracksDiv.appendChild(t.attach());
        trackSubscribed(tracksDiv, publication.track);
      }
    });

    const x = document.getElementById('meet');
    if (x !== null) {
      x.appendChild(participantDiv);
    }
  };

  const trackSubscribed = (div: HTMLElement, track: any) => {
    div.appendChild(track.attach());
  };

  const trackUnsubscribed = (track: any) => {
    track.detach().forEach((element: HTMLElement) => element.remove());
  };

  const participantDisconnected = (participant: Twilio.Participant) => {
    const element = window.document.getElementById(`${participant.sid}`);
    if (element) {
      element.remove();
    }
  };

  const addLocalVideo = (localParticipant: Twilio.LocalParticipant) => {
    const video = document.getElementById('local');
    if (video) {
      video.innerHTML = '';
    }

    const name = document.createElement('div');
    name.setAttribute('class', cn('name'));
    name.innerHTML = user.user?.displayName || '';
    video?.appendChild(name);
    localParticipant.videoTracks.forEach((track: any) => video?.appendChild(track.track.attach()));

    // const record = document.createElement('div');
    // record.setAttribute('class', cn('recording-local'));
    // const recordStatus = document.createElement('span');
    // recordStatus.setAttribute('class', cn('record-status', room?.isRecording ? 'on' : 'off'));
    // const recordText = document.createElement('span');
    // recordText.setAttribute('class', cn('ft-ml-1'));
    // recordText.innerHTML = room?.isRecording ? 'Recording' : 'Offline';
    // record.appendChild(recordStatus);
    // record.appendChild(recordText);
    // video?.appendChild(record);
  };

  const videoControl = (state: boolean) => {
    if (room) {
      if (state) {
        room.localParticipant.videoTracks.forEach((publication: Twilio.LocalVideoTrackPublication) => {
          publication.track.restart();
          publication.track.enable();
          setIsEnableVideo(true);
        });
      }
      if (!state) {
        room.localParticipant.videoTracks.forEach((publication: Twilio.LocalVideoTrackPublication) => {
          publication.track.disable();
          publication.track.stop();
          setIsEnableVideo(false);
        });
      }
    }
  };

  const audioControl = (state: boolean) => {
    if (room) {
      if (state) {
        room.localParticipant.audioTracks.forEach((publication: Twilio.LocalAudioTrackPublication) => {
          publication.track.enable();
          publication.track.restart();
          setIsEnableAudio(false);
        });
        setIsEnableAudio(true);
      }
      if (!state) {
        room.localParticipant.audioTracks.forEach((publication: Twilio.LocalAudioTrackPublication) => {
          publication.track.disable();
          publication.track.stop();
          setIsEnableAudio(false);
        });
      }
    }
  };

  const hangUp = () => {
    // setParticipantAudioEnable(false);
    setParticipantVideoEnable(false);
    audioControl(false);
    videoControl(false);
    if (room) {
      room?.localParticipant.videoTracks.forEach((video: Twilio.LocalVideoTrackPublication) => {
        video.unpublish();
      });
      room?.localParticipant.audioTracks.forEach((audio: Twilio.LocalAudioTrackPublication) => {
        audio.unpublish();
      });

      room?.disconnect();
    }
    setTimeout(() => {
      dispatch(endCall());
    }, 1500);
  };

  const dateCompare = (start: Date, end: Date) => {
    return moment.duration(moment(start).diff(moment(end.toISOString())));
  };

  const position = { x: 0, y: 0 };
  interact('#local')
    .resizable({
      edges: { top: true, left: true, bottom: true, right: true },
      listeners: {
        move: function (event) {
          let { x, y } = event.target.dataset;

          if (event.deltaRect.right) {
            x = (parseFloat(x) || 0) - event.deltaRect.right;
          }
          if (event.deltaRect.left) {
            // x = (parseFloat(x) || 0) + (event.deltaRect.left);
          }
          if (event.deltaRect.top) {
            y = (parseFloat(y) || 0) + event.deltaRect.top;
          }
          Object.assign(event.target.style, {
            width: `${event.rect.width}px`,
            height: `${event.rect.height}px`,
            right: `${x}px`,
            top: `${y}px`
          });

          Object.assign(event.target.dataset, { x, y });
        }
      },
      modifiers: [
        interact.modifiers.restrictEdges({
          outer: 'parent'
        }),

        interact.modifiers.restrictSize({
          min: { width: 200, height: 160 },
          max: { width: 4 * 200, height: 4 * 160 }
        })
      ],

      inertia: true
    })
    .draggable({
      listeners: {
        move(event) {
          position.x += event.dx;
          position.y += event.dy;

          event.target.style.transform = `translate(${position.x}px, ${position.y}px)`;
        }
      },
      inertia: true,
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: 'parent',
          endOnly: true
        })
      ]
    });

  useEffect(() => {
    if (session.videoCall?.videoMeetingGUID) {
      dispatch(generateVideoMeetingToken(session.videoCall?.videoMeetingGUID));
    }
    if (session.videoCall === undefined) {
      hangUp();
    }
  }, [session.videoCall]);

  useEffect(() => {
    if (session.videoCall?.videoMeetingGUID === undefined) {
      hangUp();
    } else {
      connect();
    }
  }, [session.videoMeetingToken]);

  useEffect(() => {
    if (isTimerStart && session.videoCall?.dateScheduled) {
      if (inGracePeriod) {
        setInGracePeriod(false);
      }

      // setDateDiff((dateCompare(session.videoCall?.dateScheduled as Date, new Date()).asMinutes() +
      // (session.videoCall.durationInMinutes as number)) *
      // 60);
      setDateDiff(
        (dateCompare(startTime as Date, new Date()).asMinutes() + (session.videoCall.durationInMinutes as number)) * 60
      );
      const callDurationInMoments = moment.duration(Math.abs(dateDiff), 'seconds').add(1, 'seconds');
      const convertedTimeLapse = moment.utc(callDurationInMoments.asMilliseconds()).format('mm:ss');

      const momentCountdown = moment.duration(
        Math.abs(dateCompare(session.videoCall?.dateScheduled as Date, new Date()).asSeconds()),
        'seconds'
      );

      setCountdown(moment.utc(momentCountdown.asMilliseconds()).format('mm:ss'));
      if (Math.floor(dateDiff) >= 0 && Math.floor(dateDiff) < 180) {
        setTimeLapse(convertedTimeLapse);
        setCountdown(undefined);
      } else {
        setTimeLapse('');
      }
      setTimeout(() => {
        setTimer(timer + 1);
      }, 1000);
    }
  }, [timer, isTimerStart]);

  try {
    return (
      <>
        <div className={cn('frame')}>
          <div className={cn('room')}>
            <div
              id="meet"
              className={cn(isParticipantConnected === false || participantVideoEnable === false ? 'hide' : '')}></div>
            {(isParticipantConnected === false || participantVideoEnable === false) && (
              <>
                <div id="merchantImg" className={cn('merchantImg')}>
                  <img src={session.videoCall?.merchantUserNormalizedProfileImageUrl || defaultIcon} />
                  <span className={cn('ft-mb-4', 'ft-mt-4')}>{session.videoCall?.merchantName}</span>
                </div>
              </>
            )}
            <>
              <div className={cn('recording-participant')}>
                <span className={cn('record-status', isParticipantConnected ? 'on' : 'off')}></span>
                <span className={cn('ft-ml-1')}>{isParticipantConnected ? 'Recording' : 'Offline'}</span>
              </div>

              <div className={cn('countdown-timer')}>
                {countdown && <span className={cn('is-size-5')}>{`Call limit will begin in ${countdown}`}</span>}
              </div>
            </>

            <div id="local" className={cn('local', !isEnableVideo && 'hide')}></div>
          </div>
          {room?.state === 'connected' && (
            <>
              <div className={cn('status')}>
                <span className={cn('merchant', 'ft-mr-2')}>{session.videoCall?.merchantName}</span>
                <div className={cn('mic-status')}>
                  {participantAudioEnable ? <Icon name="mic-on" /> : <Icon name="mic-off" />}
                </div>
              </div>
              <div className={cn('controls')}>
                <Button
                  className={cn('control-icon', 'ml-2', 'mr-2')}
                  shape="circle"
                  hasIcon
                  onClick={() => videoControl(!isEnableVideo)}
                  color={isEnableVideo ? 'primary' : 'danger'}
                  type="button">
                  {isEnableVideo ? <Icon name="camera-on" /> : <Icon name="camera-off" />}
                </Button>
                <Button
                  className={cn('control-icon', 'ml-2', 'mr-2')}
                  shape="circle"
                  hasIcon
                  onClick={() => audioControl(!isEnableAudio)}
                  color={isEnableAudio ? 'primary' : 'danger'}
                  type="button">
                  {isEnableAudio ? <Icon name="mic-on" /> : <Icon name="mic-off" />}
                </Button>
                <Button
                  className={cn('control-icon', 'ml-2', 'mr-2')}
                  shape="circle"
                  hasIcon
                  onClick={hangUp}
                  color="danger"
                  type="button">
                  <Icon name="call-off" />
                </Button>
              </div>
              <div className={cn('time-lapse-container')}>
                <span className={cn('time-lapse', 'ft-ml-2', 'is-size-4')}>{timeLapse}</span>
              </div>
            </>
          )}
        </div>
      </>
    );
  } catch (error) {
    dispatch(endCall());
    return null;
  }
};

export default TwilioVideoCall;
