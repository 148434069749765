import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames/bind';
import moment from 'moment';

import { resetRequest, videoCallRequest, videoMessageRequest } from 'state/reducers/requestReducer';
import { RootState } from 'state/store';

import { Merchant, Option, VideoCallRequest, VideoMessageRequest } from 'types';
import { formatNumber, serializeFormData } from 'utils';
import Button from 'components/Button';

import DatePicker from 'components/DatePicker';
import Icon from 'components/Icon';
import Input from 'components/Input';
import Select from 'components/Select';
import { setMerchantDirectMessage, toggleMessages, toggleNotifications } from 'state/reducers/sessionReducer';

import { toggleSnackbarOpen } from 'state/reducers/snackbarReducer';
import ReactSwitch from 'react-switch';
import styles from './RequestModal.module.scss';

const cn = classnames.bind(styles);

interface PaymentModalProps {
  creator?: Merchant;
  availableDates?: Date[];
  onSelect: (value: boolean) => void;
}

const RequestModal = (props: PaymentModalProps) => {
  const { creator, request, snackbar, session } = useSelector((state: RootState) => ({
    creator: state.creator,
    request: state.request,
    snackbar: state.snackbar,
    session: state.session
  }));
  const [availableTimeslot, setAvailableTimeslot] = useState<Option[]>();
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [requestSelected, setRequestSelected] = useState<number | undefined>(undefined);
  const [requestSuccess, setRequestSuccess] = useState<number | undefined>(undefined);
  const [isRequestSent, setIsRequestSent] = useState<boolean>(false);
  const [toggleSwitchPermission, setToggleSwitchPermission] = useState<boolean>(false);
  const videoMessageRef = useRef(null);
  const videoCallRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const sendRequest = (event: FormEvent, type: number) => {
    event.preventDefault();
    setIsRequestSent(true);
    switch (type) {
      case 1: {
        const request: VideoMessageRequest = serializeFormData(
          new FormData(videoMessageRef.current || new HTMLFormElement())
        );
        request.merchantGUID = creator.creator?.guid;
        request.durationInSeconds = 30;
        request.isPublic = toggleSwitchPermission;
        dispatch(videoMessageRequest(request));
        break;
      }
      case 2: {
        const request: VideoCallRequest = serializeFormData(
          new FormData(videoCallRef.current || new HTMLFormElement())
        );

        request.dateScheduled = new Date(request.selectedTime || '');
        request.merchantGUID = creator.creator?.guid;
        request.durationInMinutes =
          creator.creator?.meetAndGreetDurationInMinutes === 3 ? creator.creator?.meetAndGreetDurationInMinutes : 3;
        dispatch(videoCallRequest(request));
        break;
      }
    }
  };

  const initializeVideoCallRequest = () => {
    setRequestSelected(2);
    //dispatch(getVideoCallsAvailability({ merchantGUID: creator.creator?.guid, type: 'Available' }));
    ///dispatch(getVideoCallsAvailability({ merchantGUID: creator.creator?.guid, type: 'MeetAndGreet' }));
  };

  const selectDate = (date: Date) => {
    setSelectedDate(date);
  };

  const openMessages = () => {
    props.onSelect(true);
    dispatch(
      setMerchantDirectMessage({
        userGUID: props.creator?.userGUID,
        username: props.creator?.userUsername,
        normalizedProfileImageUrl: props.creator?.normalizedProfileImageUrl,
        merchantGUID: props.creator?.guid,
        unreadMessageCount: 0,
        isMerchantAcceptDirectMessages: props.creator?.acceptDirectMessages,
        merchantDirectMessagePaymentAmount: props.creator?.directMessagePaymentAmount
      })
    );

    if (session.isNotificationsOpen) {
      dispatch(toggleNotifications());
      setTimeout(() => {
        dispatch(toggleMessages());
      }, 500);
    } else if (!session.isMessagesOpen) {
      dispatch(toggleMessages());
    }
  };

  const handleChange = () => {
    setToggleSwitchPermission(!toggleSwitchPermission);
  };

  useEffect(() => {
    //dispatch(reset());
    setRequestSuccess(undefined);
    setIsRequestSent(false);
  }, []);

  useEffect(() => {
    if (requestSelected) {
      if (request.isVideoMessageRequestSuccess) {
        setRequestSelected(undefined);
        setRequestSuccess(1);
        setToggleSwitchPermission(false);
      }
      if (request.isVideoCallRequestSuccess) {
        setRequestSelected(undefined);
        setRequestSuccess(2);
      }

      if (request.isVideoMessageRequestSuccess === false && request.status === 'rejected') {
        dispatch(
          toggleSnackbarOpen({
            message: `Low account balance. Please purchase additional FanBucks for this request.`,
            type: 'danger',
            timeout: 3000
          })
        );
      }

      if (request.isVideoCallRequestSuccess === false && request.status === 'rejected') {
        if (request.error) {
          dispatch(
            toggleSnackbarOpen({
              message: request.error,
              type: 'danger',
              timeout: 3000
            })
          );
        } else {
          dispatch(
            toggleSnackbarOpen({
              message: `Low account balance. Please purchase additional FanBucks for this request.`,
              type: 'danger',
              timeout: 3000
            })
          );
        }
      }
    }
  }, [request.isVideoMessageRequestSuccess, request.isVideoCallRequestSuccess]);

  useEffect(() => {
    if (snackbar.toggle === false) {
      dispatch(resetRequest());
    }
  }, [snackbar]);

  /*useEffect(() => {
    const availability: Date[] = [];
    request.callAvailability?.forEach((date) => {
      let tempDateTime = moment(date.startDate);
      do {
        const find = request.reserveSlots?.find(
          (slot) => moment(slot.startDate).toISOString() === tempDateTime.toISOString()
        );
        if (tempDateTime.isAfter(moment()) && find === undefined) {
          availability.push(tempDateTime.toDate());
        }
        tempDateTime = tempDateTime.add(15, 'minutes');
      } while (tempDateTime.isBefore(moment(date.endDate)));
    });

    setAvailableDateTime(availability);
  }, [request.callAvailability, request.reserveSlots]);*/

  useEffect(() => {
    if (selectedDate) {
      setAvailableTimeslot(
        props.availableDates
          ?.filter((x) => moment(x).format('MM/DD/YY') === moment(selectedDate).format('MM/DD/YY'))
          .map((time) => ({
            name: moment(time).format('hh:mm a'),
            value: time.toString()
          }))
      );
    }
  }, [selectedDate]);

  return (
    <div className={cn('content')}>
      {!requestSelected && !requestSuccess ? (
        <>
          <div className={cn('header')}>Make a request to {props.creator?.name}</div>
          <div className={cn('is-size-6', 'ft-mt-4')}>Select a request</div>
          <div className={cn('request-types', 'ft-mt-2')}>
            {props.creator?.acceptVideoMessageRequests ? (
              <div className={cn('type')} onClick={() => setRequestSelected(1)}>
                <div className={cn('is-size-6', 'has-text-weight-semibold')}>Video Message</div>
                <div className={cn('has-text-primary')}>
                  <Icon name="video-message" sizePixel={48} />
                </div>
                <div className={cn('is-size-8')}>Have {props.creator?.name} send you a personal message</div>
              </div>
            ) : (
              ''
            )}
            {props.creator?.acceptMeetAndGreetRequests && (props.availableDates?.length as number) > 0 ? (
              <div className={cn('type', 'ft-ml-4', 'ft-mr-4')} onClick={initializeVideoCallRequest}>
                <div className={cn('is-size-6', 'has-text-weight-semibold')}>Video Call</div>
                <div className={cn('has-text-primary')}>
                  <Icon name="video-call" sizePixel={48} />
                </div>
                <div className={cn('is-size-8')}>Schedule to have a video call with {props.creator?.name}</div>
              </div>
            ) : (
              ''
            )}
            {props.creator?.acceptDirectMessages ? (
              <div className={cn('type')} onClick={() => openMessages()}>
                <div className={cn('is-size-6', 'has-text-weight-semibold')}>Direct Messages</div>
                <div className={cn('has-text-primary')}>
                  <Icon name="messages" sizePixel={48} />
                </div>
                <div className={cn('is-size-8')}>Direct message {props.creator?.name}</div>
              </div>
            ) : (
              ''
            )}
          </div>
        </>
      ) : (
        <>
          {requestSelected === 1 && (
            <div>
              <div className={cn('subheader')}>Request a video message</div>
              <div className={cn('ft-mt-4')}>
                <form ref={videoMessageRef} onSubmit={(e) => sendRequest(e, 1)}>
                  <div className={cn('control-group')}>
                    <Input label="Who is this message for?*" name="recipientName" required />
                    <Input
                      type="textarea"
                      label={'What would you like ' + props.creator?.name + ' to say?'}
                      sublabel="Be as descriptive as possible. For example, it's my birthday and I'd love to get a shout out, or this is for my brother who is your biggest fan."
                      name="description"
                    />
                    <div className={cn('control-group')}>
                      <div className={cn('is-size-7', 'ft-mt-4', 'ft-mb-2')}>
                        Allow {props.creator?.userDisplayName} to post your video message on their Profile page
                      </div>
                      <ReactSwitch
                        className={cn('v-align', 'ft-mb-4')}
                        name="isPublic"
                        onChange={handleChange}
                        checked={toggleSwitchPermission}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        onColor="#5f74f4"
                      />
                    </div>
                    <div className={cn('flexbox')}>
                      <Button color="primary" disabled={isRequestSent} hasIconOnText>
                        Submit request for {formatNumber(creator.creator?.videoMessagePaymentAmount || 0)}
                        <Icon name="fanbucks" className={cn('ft-ml-1', 'ft-mr-1')} />
                        <p className={cn('is-size-8')}>
                          (${((creator.creator?.videoMessagePaymentAmount as number) / 100).toFixed(2)})
                        </p>
                      </Button>
                      <Button
                        type="button"
                        className={cn('ft-ml-4')}
                        color="clear"
                        onClick={() => setRequestSelected(undefined)}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
                <div className={cn('is-size-8', 'ft-mt-4')}>
                  Cancellation Policy: You can cancel at any time before {props.creator?.name} agrees to record your
                  message. Your FanBucks will be automatically refunded to your account. There are no refunds once{' '}
                  {props.creator?.name} has agreed to record your message, even if you decide to cancel.
                </div>
              </div>
            </div>
          )}
          {requestSelected === 2 && (
            <div>
              <div className={cn('subheader')}>Request a video call</div>
              <div className={cn('ft-mt-4')}>
                <form ref={videoCallRef} onSubmit={(e) => sendRequest(e, 2)}>
                  <div className={cn('control-group', 'inline-controls')}>
                    <div className={cn('row')}>
                      <DatePicker
                        className={cn('w-50')}
                        label="Select available date*"
                        availableDates={props.availableDates}
                        onChange={selectDate}
                        required
                      />
                      <Select
                        className={cn('w-50')}
                        name="selectedTime"
                        label="Select time*"
                        options={availableTimeslot}
                        required
                      />
                    </div>
                    <div className={cn('row')}>
                      <Input
                        className={cn('w-100')}
                        label={'Who will ' + props.creator?.name + ' be calling?*'}
                        name="recipientName"
                        required
                      />
                    </div>
                    <div className={cn('row')}>
                      <Input
                        type="textarea"
                        label="What would you like to talk about?"
                        sublabel="Be as descriptive as possible. For example, who were your most important mentors, or how do you mentally get ready for a big game."
                        name="description"
                      />
                    </div>
                    <div className={cn('flexbox')}>
                      <Button color="primary" disabled={isRequestSent} hasIconOnText>
                        Submit request for {formatNumber(creator.creator?.meetAndGreetPaymentAmount || 0)}
                        <Icon name="fanbucks" className={cn('ft-ml-1', 'ft-mr-1')} />
                        <p className={cn('is-size-8')}>
                          (${((creator.creator?.meetAndGreetPaymentAmount as number) / 100).toFixed(2)})
                        </p>
                      </Button>
                      <Button className={cn('ft-ml-4')} color="clear" onClick={() => setRequestSelected(undefined)}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
                <div className={cn('is-size-8', 'ft-mt-4')}>
                  {/* <div>
                    Time Limit: Video calls have a maximum limit of 3 minutes. You will be automatically disconnected
                    when you have reached your time limit.
                  </div> */}
                  <div className={cn('ft-mt-1')}>
                    Cancelation Policy: You can cancel at any time before {props.creator?.name} agrees to accept your
                    video call. Your FanBucks will be automatically refunded to your account. There are no refunds once{' '}
                    {props.creator?.name} has agreed to schedule the video call, even if you decide to cancel.
                  </div>
                  <div className={cn('ft-mt-1')}>
                    <p></p>
                    No Shows: There are no refunds if you do not show to your meeting. If {props.creator?.name} does not
                    show, you will get a full refund.
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {!requestSelected && requestSuccess && (
        <>
          {requestSuccess === 1 && (
            <>
              <div className={cn('has-text-centered', 'is-size-6')}>Request sent</div>
              <div className={cn('ft-mt-4')}>
                You have successfully sent a video message request. Check the status of your request on this page.
              </div>
              <div className={cn('has-text-centered', 'ft-mt-6')}>
                <Button onClick={() => history.push('/preferences/video-messages')}>Check request</Button>
              </div>
            </>
          )}
          {requestSuccess === 2 && (
            <>
              <div className={cn('has-text-centered', 'is-size-6')}>Request sent</div>
              <div className={cn('ft-mt-4')}>
                You have successfully sent a video call request. Check the status of your request on this page.
              </div>
              <div className={cn('has-text-centered', 'ft-mt-6')}>
                <Button onClick={() => history.push('/preferences/video-calls')}>Check request</Button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default RequestModal;
