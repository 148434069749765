import React, { useEffect, useState, useRef, ReactNode } from 'react';
import classnames from 'classnames/bind';

import styles from './Collapse.module.scss';

const cn = classnames.bind(styles);

interface CollapseProps {
  isOpen?: boolean;
  children?: ReactNode;
}

const Collapse = (props: CollapseProps) => {
  const [childHeight, setChildHeight] = useState('0');
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const childHeightCopy = `${contentRef.current?.clientHeight}px`;
    setChildHeight(childHeightCopy);
  });

  return (
    <div
      className={cn('collapse')}
      style={{
        maxHeight: props.isOpen ? childHeight : 0
      }}>
      <div ref={contentRef}>{props.children}</div>
    </div>
  );
};

export default Collapse;
