import React from 'react';
import classnames from 'classnames/bind';
import Anchor from 'components/Anchor';

import avatar5 from 'assets/images/temp/a8134180-208d-44f0-accd-3df12c0f5903.png';

import styles from './Club.module.scss';

const cn = classnames.bind(styles);

const Club = () => {
  return (
    <div className={cn('club')}>
      <div className={cn('card-wrapper')}>
        <div className={cn('card', 'pinned')}>
          <div className={cn('section')}>Pinned</div>
          <div className={cn('flexbox', 'align-top')}>
            <div className={cn('has-text-centered')}>
              <div className={cn('avatar')}>
                <img src={avatar5} />
              </div>
              <div className={cn('role')}>MOD</div>
            </div>
            <div className={cn('ft-ml-2')}>
              <div className={cn('has-text-weight-semibold')}>katy</div>
              <div>
                <Anchor>olivia</Anchor> will be holding a virtual concert on June 6, 2021. Don’t forget to buy your
                tickets.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Club;
