import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classnames from 'classnames/bind';

import { fetchPostById, fetchPostsByMerchatByType } from 'state/reducers/postReducer';
import { initializePurchaseItem } from 'state/reducers/sessionReducer';
import { RootState } from 'state/store';

import { PostType } from 'enums';
import { Post } from 'types';
import { formatNumber } from 'utils';
import Button from 'components/Button';

import ItemCard from './ItemCard';
import styles from './Item.module.scss';

type GenericStringObject = { [key: string]: string };

const cx = classnames.bind(styles);

const Item = () => {
  const { creator, post } = useSelector((state: RootState) => ({
    creator: state.creator,
    post: state.post
  }));
  const { merchId } = useParams<GenericStringObject>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPostById(merchId));
  }, [merchId]);

  const purchase = (item: Post) => {
    dispatch(initializePurchaseItem(item));
  };

  useEffect(() => {
    dispatch(
      fetchPostsByMerchatByType({
        creatorGuid: creator.creator?.guid as string,
        postType: PostType.Image
      })
    );
  }, [creator.creator?.guid]);

  return (
    <>
      {post.post && (
        <div className={cx('container')}>
          <div className={cx('item-wrapper')}>
            <div className={cx('columns')}>
              <div className={cx('column', 'no-stretch')}>
                {/*<img src={post.post?.contentUrl} className={cx('item-preview')} />*/}
                <ItemCard item={post.post} />
              </div>
              <div className={cx('column')}>
                <div className={cx('has-text-weight-bold', 'is-size-2')}>{post.post?.name}</div>
                <div className={cx('flexbox')}>
                  <img src={post.post?.profileImageUrl} className={cx('avatar', 'ft-mr-2')} />
                  <div>{post.post?.merchantName}</div>
                </div>
                <div className={cx('ft-mt-4', 'has-text-weight-bold', 'is-size-5')}>
                  ${formatNumber(post.post?.marketplaceListingUSDAmount || 0)}
                </div>
                <div className={cx('ft-mt-4')}>{post.post?.description}</div>
                <div className={cx('ft-mt-8', 'flexbox')}>
                  <Button
                    color="primary"
                    size="lg"
                    className={cx('purchase-button')}
                    onClick={() => purchase(post.post || {})}>
                    Purchase Item
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Item;
