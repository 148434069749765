import React from 'react';
import classnames from 'classnames/bind';

import Icon from 'components/Icon';

import styles from './Select.module.scss';

const cn = classnames.bind(styles);

interface OptionProps {
  name: string;
  value: string | number;
}

interface SelectProps {
  name?: string;
  className?: string;
  label?: string;
  layer?: number;
  options?: OptionProps[];
  required?: boolean;
  onChange?: (e: unknown) => void;
}

const Select = (props: SelectProps) => {
  const handleOnchange = (e: unknown) => {
    if (props.onChange) props.onChange(e);
  };
  return (
    <div className={cn('control', { 'layer-100': props.layer === 1 }, props.className)}>
      {props.label && <label className={cn('label')}>{props.label}</label>}
      <div className={cn('select')}>
        <select name={props.name} onChange={handleOnchange} required={props.required}>
          {props.options?.map((option) => (
            <option key={option.name} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>
        <Icon name="arrow-down" size="sm" />
      </div>
    </div>
  );
};

export default Select;
