import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import classnames from 'classnames/bind';

import { LiveEvent, RemainingTime } from 'types';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import Ticket from 'components/Ticket';

import {
  cancelTicket,
  getLiveEvent,
  getMerchantLiveEvents,
  patchLiveEvents,
  patchPastLiveEvents,
  purchaseEventTicket,
  resetActiveLiveEvent
} from 'state/reducers/liveEventReducer';
import { openAdmin } from 'services/miscellaneousService';

import { RootState } from 'state/store';

const cn = classnames.bind(styles);

import { playContent } from 'state/reducers/sessionReducer';
import { fetchLiveStreamPostById, fetchPastLiveStreamPostById, resetLiveStream } from 'state/reducers/postReducer';
import { ContentType } from 'enums';
import { clone } from 'lodash';
import { toggleSnackbarOpen } from 'state/reducers/snackbarReducer';

import BlankPage from 'components/BlankPage';
import { formatNumber } from 'utils';
import styles from './CreatorEvent.module.scss';

const CreatorEvent = () => {
  const { creator, liveEvent, user, post } = useSelector((state: RootState) => ({
    creator: state.creator,
    liveEvent: state.liveEvent,
    user: state.user,
    post: state.post
  }));
  const [showCancelTicket, setShowCancelTicket] = useState(false);
  const [eventModal, setEventModal] = useState<LiveEvent | undefined>(undefined);
  const [ticketAction, setTicketAction] = useState<boolean>(false);
  const [tab, setTab] = useState<string | undefined>(undefined);
  const [isPastEvent, setIsPastEvent] = useState<boolean>(false);
  const [purchaseDisabled, setPurchaseDisabled] = useState<boolean>(false);

  const dispatch = useDispatch();

  const viewTicket = (pastEvent: boolean, event?: LiveEvent) => {
    dispatch(resetActiveLiveEvent());
    setEventModal(event);
    setTimeLeft(calculateTimeLeft(event));
    setIsPastEvent(pastEvent);

    if (event?.liveStreamGUID && pastEvent) {
      dispatch(fetchPastLiveStreamPostById(event?.liveStreamGUID));
    }
    if (event?.liveStreamGUID && !pastEvent) {
      dispatch(getLiveEvent(event));
    }
  };

  const purchaseTicket = (event: LiveEvent) => {
    setPurchaseDisabled(true);
    dispatch(purchaseEventTicket({ event, userGuid: user.user?.guid, liveEvents: liveEvent.liveEvents }));
    if (liveEvent.error) {
      dispatch(
        toggleSnackbarOpen({
          message: liveEvent.error,
          type: 'danger',
          timeout: 3000
        })
      );
    } else {
      setTicketAction(true);
    }
    setEventModal(undefined);
  };

  const watchLiveEvent = (event: LiveEvent) => {
    setEventModal(undefined);
    dispatch(playContent({ contentType: ContentType.Post, post: event }));
    dispatch(fetchLiveStreamPostById(event.liveStreamGUID || ''));
  };

  const watchPastLiveEvent = () => {
    dispatch(playContent({ contentType: ContentType.Post, post: post.liveStream }));
  };

  const calculateTimeLeft = (purchaseEvent: LiveEvent | undefined) => {
    let difference = 0;
    if (purchaseEvent) {
      difference = +new Date(purchaseEvent.dateOfEvent as Date) - +new Date();
    }
    let timeLeft: RemainingTime = {
      eventStart: false,
      raw: 0
    };

    if (difference > 0) {
      timeLeft = {
        raw: difference,
        eventStart: false,
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    } else {
      timeLeft.eventStart = true;
      timeLeft.raw = 0;
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(eventModal));

  const confirmCancelTicket = (event: LiveEvent) => {
    dispatch(cancelTicket({ liveEvents: event, bookedLiveEvents: liveEvent.bookedLiveEvents }));
    setEventModal(undefined);
    setShowCancelTicket(false);
    setTicketAction(true);
    // dispatch(getMerchantLiveEvents(creator.creator?.guid || ''));
  };

  useEffect(() => {
    dispatch(getMerchantLiveEvents(creator.creator?.guid || ''));
  }, [creator.creator?.guid]);

  useEffect(() => {
    if (ticketAction === true) {
      dispatch(getMerchantLiveEvents(creator.creator?.guid || ''));
      setTicketAction(false);
      setPurchaseDisabled(false);
    }
  }, [ticketAction]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(eventModal));
    }, 1000);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    if (!liveEvent.eventUnlock && liveEvent.isUnlockSuccess) {
      const checkPastLiveEvents = liveEvent.merchantPastEvents?.find((x) => x.guid === liveEvent.liveEvent?.guid);
      const checkLiveEvents = liveEvent.merchantEvents?.find((x) => x.guid === liveEvent.liveEvent?.guid);

      if (checkLiveEvents) {
        const newEvents = clone(liveEvent.merchantEvents);
        const findLiveEventId = liveEvent.merchantEvents?.findIndex((x) => x.guid === liveEvent.liveEvent?.guid);

        if (findLiveEventId !== undefined && newEvents && checkLiveEvents) {
          const newEvent = clone(checkLiveEvents);
          newEvent.hasTicketForCallingUser = true;
          newEvents[findLiveEventId] = newEvent;
          setEventModal(newEvent);

          const updatedEvents = clone(liveEvent);
          updatedEvents.merchantEvents = newEvents;

          dispatch(patchLiveEvents(newEvents));
        }
      }

      if (checkPastLiveEvents) {
        const newEvents = clone(liveEvent.merchantPastEvents);
        const findLiveEventId = liveEvent.merchantPastEvents?.findIndex((x) => x.guid === liveEvent.liveEvent?.guid);

        if (findLiveEventId !== undefined && newEvents && checkPastLiveEvents) {
          const newEvent = clone(checkPastLiveEvents);
          newEvent.hasTicketForCallingUser = true;
          newEvents[findLiveEventId] = newEvent;
          setEventModal(newEvent);

          const updatedEvents = clone(liveEvent);
          updatedEvents.merchantPastEvents = newEvents;

          dispatch(patchPastLiveEvents(newEvents));
        }
      }
    }
  }, [liveEvent.eventUnlock]);

  useEffect(() => {
    if (eventModal === undefined && post.liveStream) {
      dispatch(resetLiveStream());
    }
  }, [eventModal, post.liveStream]);

  return (
    <>
      <div className={cn('menu')}>
        <div className={cn('flexbox', 'tab')}>
          <div className={cn('tab-item', { active: tab === undefined })} onClick={() => setTab(undefined)}>
            Upcoming
            {tab === undefined && <div className={cn('border')} />}
          </div>
          <div className={cn('tab-item', { active: tab === 'past' })} onClick={() => setTab('past')}>
            Past
            {tab === 'past' && <div className={cn('border')} />}
          </div>
        </div>
      </div>

      {tab === undefined && liveEvent.merchantEvents && liveEvent.merchantEvents.length > 0 ? (
        <div className={cn('container')}>
          <div className={cn('events')}>
            <div className={cn('group-wrapper')}>
              <div className={cn('group')}>
                <div className={cn('event-group')}>
                  {liveEvent.merchantEvents?.map((event) => (
                    <div
                      className={cn('event', 'image-wrapper')}
                      key={event.guid}
                      onClick={() => viewTicket(true, event)}>
                      <Ticket
                        artist={event.merchantName}
                        size="sm"
                        passType={event.ticketType ? event.ticketType : 'FullTicket'}
                        backgroundUrl={event.thumbnailImageUrl}
                        name={event.name}
                        date={moment(event.dateOfEvent).format('MMM DD / hh:mm a')}
                        primaryColor={event.colorHex ? '#' + event.colorHex : event.primaryColor}
                        secondaryColor={event.secondaryColorHex ? '#' + event.secondaryColorHex : event.secondaryColor}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        tab === undefined &&
        liveEvent.merchantEvents &&
        liveEvent.merchantEvents.length === 0 && (
          <BlankPage text={creator.creator?.name + ' does not have any upcoming Live Events.'}></BlankPage>
        )
      )}
      {tab === 'past' && liveEvent.merchantPastEvents && liveEvent.merchantPastEvents.length > 0 ? (
        <div className={cn('container')}>
          <div className={cn('events')}>
            <div className={cn('group-wrapper')}>
              <div className={cn('group')}>
                <div className={cn('event-group')}>
                  {liveEvent.merchantPastEvents?.map((event) => (
                    <div
                      className={cn('event', 'image-wrapper')}
                      key={event.guid}
                      onClick={() => viewTicket(true, event)}>
                      <Ticket
                        artist={event.merchantName}
                        size="sm"
                        passType={event.ticketType ? event.ticketType : 'FullTicket'}
                        backgroundUrl={event.thumbnailImageUrl}
                        name={event.name}
                        date={moment(event.dateOfEvent).format('MMM DD / hh:mm a')}
                        primaryColor={event.colorHex ? '#' + event.colorHex : event.primaryColor}
                        secondaryColor={event.secondaryColorHex ? '#' + event.secondaryColorHex : event.secondaryColor}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        tab === 'past' &&
        liveEvent.merchantPastEvents &&
        liveEvent.merchantPastEvents.length === 0 && (
          <BlankPage text={creator.creator?.name + ' does not have any Past Live Events yet.'}></BlankPage>
        )
      )}
      {eventModal && (
        <Modal width="90rem" onClose={() => setEventModal(undefined)}>
          <div className={cn('event-modal')}>
            {!showCancelTicket ? (
              <>
                <div>
                  <Ticket
                    size="sm"
                    passType={eventModal.ticketType ? eventModal.ticketType : 'FullTicket'}
                    backgroundUrl={eventModal.thumbnailImageUrl}
                    name={eventModal.name}
                    artist={eventModal.merchantName}
                    date={moment(eventModal.dateOfEvent).format('MMM DD / hh:mm a')}
                    primaryColor={eventModal.colorHex ? '#' + eventModal.colorHex : eventModal.primaryColor}
                    secondaryColor={
                      eventModal.secondaryColorHex ? '#' + eventModal.secondaryColorHex : eventModal.secondaryColor
                    }
                  />
                </div>
                <div className={cn('details')}>
                  <div className={cn('title')}>{eventModal.name}</div>
                  <div className={cn('has-text-weight-semibold')}>
                    {moment(eventModal.dateOfEvent).format('MMM DD')} / {moment(eventModal.dateOfEvent).format('h:mm')}-
                    {moment(eventModal.dateOfEvent).add(eventModal.durationInMinutes, 'm').format('h:mm A')}
                  </div>
                  <div className={cn('ft-mt-4')}>{eventModal.description}</div>
                  <div className={cn('purchase')}>
                    {!eventModal.hasTicketForCallingUser ? (
                      <Button
                        hasIconOnText
                        onClick={() => {
                          purchaseTicket(eventModal);
                        }}
                        disabled={purchaseDisabled}>
                        Purchase ticket for ${formatNumber((eventModal.amount as number) / 100)} or{' '}
                        {formatNumber(eventModal.amount as number)}
                        <Icon name="fanbucks" className={'ft-ml-1'} />
                      </Button>
                    ) : isPastEvent && post.liveStream !== undefined && eventModal.hasTicketForCallingUser ? (
                      <Button onClick={() => watchPastLiveEvent()}>Watch</Button>
                    ) : (liveEvent.activeLiveEvent?.playbackUrl ||
                        liveEvent.activeLiveEvent?.status === 'Active' ||
                        eventModal.liveStreamStatus === 'Active') &&
                      !isPastEvent ? (
                      <Button onClick={() => watchLiveEvent(eventModal)}>Watch</Button>
                    ) : eventModal.merchantGUID === user.user?.merchantGUID ? (
                      <Button onClick={() => openAdmin()}>Manage in Admin</Button>
                    ) : eventModal.status === 'Pending' &&
                      eventModal.liveStreamStatus === undefined &&
                      !timeLeft.eventStart ? (
                      <>
                        <div className={cn('flexbox')}>
                          {`Event will start in 
                        ${timeLeft.raw > 86400000 ? `${timeLeft.days} ${timeLeft.days !== 1 ? 'days' : 'day'}` : ''}
                        ${timeLeft.raw > 3600000 ? `${timeLeft.hours} ${timeLeft.hours !== 1 ? 'hours' : 'hour'}` : ''} 
                        ${
                          timeLeft.raw > 60000
                            ? `${timeLeft.minutes} ${timeLeft.minutes !== 1 ? 'minutes' : 'minute'}`
                            : ''
                        } 
                        ${
                          timeLeft.seconds ? `${timeLeft.seconds} ${timeLeft.seconds !== 1 ? 'seconds' : 'second'}` : ''
                        }`}
                        </div>
                        {timeLeft.days && timeLeft.days >= 1 && eventModal.liveEventTicketGUID ? (
                          <div className={cn('flexbox', 'ft-mt-4')}>
                            <Button onClick={() => setShowCancelTicket(true)}>Cancel Ticket</Button>
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                    ) : eventModal.status === 'Pending' && timeLeft.eventStart ? (
                      'Event will start soon.'
                    ) : (
                      ''
                    )}
                  </div>
                  <div className={cn('is-size-8', 'ft-mt-4')}>
                    <div>
                      Cancelation Policy: You may cancel your ticket up to one day before the event and receive a full
                      refund (where applicable). No refunds are given on the day of the event.
                    </div>
                    <div className={cn('ft-mt-1')}>
                      When you purchase this event, you will receive the event pass which you can add on your profile to
                      show other fans.
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div>
                  <div className={cn('flexbox', 'ft-mb-4')}>
                    Are you sure you want to cancel your ticket for this event?
                  </div>
                  <Button color="primary" onClick={() => confirmCancelTicket(eventModal)}>
                    Cancel Ticket
                  </Button>
                  <Button
                    type="button"
                    className={cn('ft-ml-4')}
                    color="clear"
                    onClick={() => setShowCancelTicket(false)}>
                    Cancel
                  </Button>
                </div>
              </>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default CreatorEvent;
