import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useHistory } from 'react-router-dom';
import classnames from 'classnames/bind';

import { playContent, initializePurchaseItem, saveToken } from 'state/reducers/sessionReducer';
import { toggleSnackbarClose } from 'state/reducers/snackbarReducer';
import { toggleToastClose } from 'state/reducers/toastReducer';
import { getCurrentUser, platformRefreshToken } from 'state/reducers/userReducer';
import { RootState } from 'state/store';

import { ContentType, SidebarType } from 'enums';
import { mainRouteConfig } from 'routes';
import { webSocketService } from 'services';
import { Post } from 'types';
import RouteFromPath from 'components/Route/RouteFromPath';
import Messages from 'components/Messages';
import Modal from 'components/Modal';
import PurchaseItemModal from 'components/Modal/PurchaseItemModal';
import NavBar from 'components/NavBar';
import Notifications from 'components/Notifications';
import Player from 'components/Player';
import TwilioVideoCall from 'components/TwilioVideoCall';
import Snackbar from 'components/Snackbar';
import ToastAlert from 'components/ToastAlert';

import styles from './Base.module.scss';

const cn = classnames.bind(styles);
const Base = () => {
  const { session, snackbar, user, toast } = useSelector((state: RootState) => ({
    session: state.session,
    snackbar: state.snackbar,
    user: state.user,
    toast: state.toast
  }));

  const dispatch = useDispatch();
  const [showRightSidebar, setShowRightSidebar] = useState(false);
  const [slideRightSidebar, setSlideRightSidebar] = useState(false);
  const [isNewLiveStream, setIsNewLiveStream] = useState<Post | undefined>(undefined);

  const history = useHistory();

  const toggleRightSidebar = (visible: boolean) => {
    if (visible) {
      setShowRightSidebar(true);
      setTimeout(() => {
        setSlideRightSidebar(true);
      }, 50);
    } else {
      setSlideRightSidebar(false);
      setTimeout(() => {
        setShowRightSidebar(false);
      }, 250);
    }
  };

  const snackBarAction = () => {
    if (snackbar.url && snackbar.url !== '') {
      history.push(snackbar.url);
      dispatch(toggleSnackbarClose());
    }
  };

  const toastAction = () => {
    if (toast.url && toast.url !== '') {
      history.push(toast.url);
      dispatch(toggleToastClose());
    }

    if (isNewLiveStream) {
      dispatch(playContent({ contentType: ContentType.Post, post: isNewLiveStream }));
    }
  };

  const closePurchaseModal = () => {
    dispatch(initializePurchaseItem(undefined));
  };

  useEffect(() => {
    if (new URLSearchParams(location.search).get('s') && !session.authToken) {
      dispatch(saveToken(new URLSearchParams(location.search).get('s')));
      dispatch(platformRefreshToken('HeyFans'));
    } else {
      dispatch(getCurrentUser());
    }

    if (user.authToken) {
      dispatch(saveToken(user.authToken));
    }
    webSocketService.initializeWebSocket();
    // meetAndGreetWebSocketService.initializeMeetAndGreetWebSocket();
  }, [user.authToken]);

  useEffect(() => {
    if (session.isMessagesOpen || session.isNotificationsOpen) {
      toggleRightSidebar(true);
    } else {
      toggleRightSidebar(false);
    }
  }, [session]);

  useEffect(() => {
    setIsNewLiveStream(toast.liveStream);
  }, [toast.liveStream]);

  return (
    <>
      {user.user && (
        <div>
          <div className={cn('sticky')}>
            <NavBar />
          </div>
          <div className={cn('container')}>
            <Switch>
              {mainRouteConfig.map((route) => (
                <RouteFromPath key={`route${route.path}`} base="" {...route} />
              ))}
            </Switch>
          </div>
          {showRightSidebar && (
            <>
              <div className={cn('right-sidebar', { show: slideRightSidebar })}>
                {session.sidebarType === SidebarType.Notification && <Notifications />}
                {session.sidebarType === SidebarType.Messages && <Messages />}
              </div>
            </>
          )}
          {session.isPlayContent && (
            <div className={cn('player')}>
              <Player />
            </div>
          )}

          {session.isVideoCallActive && (
            <div className={cn('player')}>
              <TwilioVideoCall />
            </div>
          )}

          {session.purchasePost && (
            <Modal onClose={closePurchaseModal}>
              <PurchaseItemModal />
            </Modal>
          )}

          <Snackbar onClick={snackBarAction} />
          <div className={cn('toasts', 'flexbox')}>
            <ToastAlert className={cn(toast.liveStream ? 'pointer' : '')} onClick={toastAction} />
          </div>
        </div>
      )}
    </>
  );
};

export default Base;
