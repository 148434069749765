import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/store';
import classnames from 'classnames/bind';

import logoMark from 'assets/images/heyfans-logo.svg';
import logoType from 'assets/images/heyfans-logotype.svg';

import Input from 'components/Input';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { UserMerchantApplication } from 'types';
import { serializeFormData } from 'utils';
import {
  resetIsApplicationSubmitted,
  resetUserErrorPayload,
  submitMerchantApplication
} from 'state/reducers/userReducer';
import { COUNTRIES } from 'consts';
import Select from 'components/Select';
import Modal from 'components/Modal';
import ApplyConfirmationModal from 'components/Modal/ApplyConfirmationModal';
import { resetErrorPayload } from 'state/reducers/authReducer';

import styles from './Apply.module.scss';

const cn = classnames.bind(styles);
const countries = COUNTRIES.map((x) => ({ name: `${x.code} (${x.dialCode})`, value: x.dialCode, code: x.code }));

const Apply = () => {
  const preferencesRoute = useRouteMatch('/preferences');
  const history = useHistory();
  const dispatch = useDispatch();
  const formRef = useRef<HTMLFormElement>(null);
  const user = useSelector((state: RootState) => state.user);
  const [isApplicationSuccess, setIsApplicationSuccess] = useState<boolean>(false);
  const [invalidCode, setInvalidCode] = useState<boolean>(false);

  const submitApplication = (event: FormEvent) => {
    event.preventDefault();
    const application: UserMerchantApplication = serializeFormData(new FormData(formRef.current as HTMLFormElement));
    const phoneNo = `${application.country}${application.phoneNumber}`;
    application.phoneNumber = phoneNo;
    application.userGUID = user.user?.guid;
    dispatch(submitMerchantApplication(application));
  };

  const redirectToHome = () => {
    setIsApplicationSuccess(false);
    history.push('/');
  };

  useEffect(() => {
    if (user.status === 'success' && user.isApplicationSubmitted) {
      formRef.current?.reset();
      setIsApplicationSuccess(true);
      dispatch(resetIsApplicationSubmitted());
    } else if (user.error && !user.isApplicationSubmitted) {
      setInvalidCode(true);
      setTimeout(() => {
        setInvalidCode(false);
        dispatch(resetUserErrorPayload());
      }, 3000);
      dispatch(resetIsApplicationSubmitted());
      dispatch(resetErrorPayload());
    }
  }, [user.isApplicationSubmitted]);

  useEffect(() => {
    setInvalidCode(false);
    dispatch(resetUserErrorPayload());
  }, []);

  return (
    <div className={cn('container', { 'preferences-container': preferencesRoute })}>
      <div className={cn('wrapper')}>
        {!preferencesRoute && (
          <div className={cn('header')}>
            <img className={cn('mark')} src={logoMark} onClick={() => history.push('/login')} />
            <img className={cn('type')} src={logoType} />
          </div>
        )}
        <div className={cn('header-name', preferencesRoute ? 'preferences' : 'login')}>Athlete Application</div>
        <div className={cn('is-size-7', 'ft-mt-4')}>
          Thank you for your interest in HeyFans. We are currently in Beta and only accepting applications from athletes
          with a referral code. If you have one, please continue and complete the form below. One of our account reps
          will reach out to help you get on-boarded.
        </div>
        <div className={cn('ft-mt-4')}>
          <form ref={formRef} onSubmit={submitApplication}>
            <div className={cn('flexbox')}>
              <Select className={cn('w-25')} label="Country" name="country" layer={1} options={countries} />
              <Input className={cn('ft-ml-2', 'phoneNumber')} label="Phone number" type="number" name="phoneNumber" />
            </div>
            <Input label="Name*" name="name" className={cn('ft-mt-4 mb-5')} required />
            <Input label="Email Address*" name="emailAddress" required />
            <Input label="Referred By" name="referralCode" className={cn('ft-mt-4')} />
            <Input label="What Sport Do You Play" name="description" className={cn('ft-mt-4')} />
            <Input
              label="Social Media Links (optional)"
              name="twitterUrl"
              placeholder="Twitter"
              className={cn('ft-mt-4')}
              iconLeft={<Icon name="twitter" size="sm" />}
            />
            <Input
              name="facebookUrl"
              placeholder="Facebook"
              className={cn('ft-mt-2')}
              iconLeft={<Icon name="facebook" size="sm" />}
            />
            <Input
              name="youTubeUrl"
              placeholder="Youtube"
              className={cn('ft-mt-2')}
              iconLeft={<Icon name="youtube" size="sm" />}
            />
            <Input
              name="instagramUrl"
              placeholder="Instagram"
              className={cn('ft-mt-2')}
              iconLeft={<Icon name="instagram" size="sm" />}
            />
            <Input
              name="tikTokUrl"
              placeholder="TikTok"
              sublabel="Link to your social media profiles so we can get a better understanding of who you are."
              className={cn('ft-mt-2')}
              iconLeft={<Icon name="tiktok" size="sm" />}
            />
            <div className={cn('control')}>
              <Button className={cn('ft-mt-4', 'ft-mb-4')}>Submit Application</Button>
            </div>
          </form>
        </div>
      </div>

      {isApplicationSuccess && (
        <Modal>
          <ApplyConfirmationModal onSelect={() => redirectToHome()}></ApplyConfirmationModal>
        </Modal>
      )}

      {invalidCode && (
        <div className={cn('snackbar', 'danger')}>
          <div>{user.errorPayload?.message}</div>
        </div>
      )}
    </div>
  );
};

export default Apply;
