import React from 'react';
import classnames from 'classnames/bind';

import styles from './Privacy.module.scss';

const cn = classnames.bind(styles);

const Privacy = () => {
  return (
    <div className={cn('legal')}>
      <div className={cn('header')}>Privacy Policy</div>
      <div className={cn('is-size-7')}>
        <p>Last Updated August 20, 2021</p>

        <p>
          Welcome to HeyFans, a product and service provided by XGenLabs, LLC, (collectively “we”, “us”, "HeyFans", or
          “XGenLabs”). This privacy policy sets out the basis on which any information (which may include personal data,
          as defined in data protection law) we collect from you, or that you provide to us, through our website, mobile
          application or service (the “Site”) will be processed by us. Please read the following carefully to understand
          our practices regarding your information and how we will treat it.
        </p>

        <p className={cn('subheader')}>INFORMATION WE MAY COLLECT FROM YOU</p>

        <p>
          When you use the Site or contact us by e-mail, social media, SMS, telephone, or other methods, we may collect
          and process the following data about you:
        </p>

        <p>Information you give us, including:</p>
        <ul>
          <li>Forms filled out by you on the Site;</li>
          <li>Content of correspondence with us by phone, e-mail or otherwise;</li>
          <li>Site registration;</li>
          <li>Subscriptions or orders placed on the Site;</li>
          <li>Participation in discussion boards or other social media functions on the Site;</li>
          <li>Entering a competition, promotion or survey;</li>
          <li>If applicable, resume, work history, CV, resume or other job application information;</li>
          <li>Reporting a problem or requesting support for the Site.</li>
        </ul>

        <p>
          The information you give us may include your name, address, e-mail address and phone number, date of birth,
          payment information, personal description, photographs or images or comments.
        </p>

        <p>Information we collect about you.</p>

        <p>With regard to each of your visits to our Site we may automatically collect the following information:</p>
        <ul>
          <li>
            Technical information, including IP address, login information, browser type and version, time zone setting,
            browser plug-in types and versions, operating system and platform;
          </li>
          <li>
            Information about your visit, including the full URL clickstream to, through and from our Site (including
            date and time);
          </li>
          <li>
            Pages you viewed or searched for; page response times, download errors, length of visits to certain pages,
            page interaction information (such as scrolling, clicks, and mouse-overs), and methods used to browse away
            from the page.
          </li>
        </ul>

        <p>
          We may also collect information about your devices (including phones, computers or other devices) where you
          access and visit our Sites. Such information may include unique device identifiers and mobile network
          information (including phone number), IP address, language and time zone, operating system, hardware version,
          device locations (including specific geographic locations, such as through GPS, Bluetooth or WiFi signals).
        </p>

        <p className={cn('subheader')}>INFORMATION WE COLLECT FROM OTHER SOURCES</p>

        <p>
          We also work with third parties (including, for example, business partners, sub-contractors in technical,
          payment and delivery services, advertising networks, analytics providers, social networks such as Facebook and
          Twitter, search information providers, third parties who enable sign on functionality and social network
          integration) and may receive information about you from them.
        </p>

        <p>
          If you choose to use features on this Site to access your online profiles, accounts or services (including
          social media accounts) (“Connected Accounts”), we may collect any information that you permit through your
          settings on those Connected Accounts. For example, if you log in to our site using your Facebook account and
          choose to allow Facebook to share your profile with us, we may collect that information. Information we
          receive from Connected Accounts includes email address, first and last name, location (hometown and country),
          gender, date of birth, likes, posts or other social activity. Please review your Connected Account privacy
          settings to control what information is provided to us through this option.
        </p>

        <p className={cn('subheader')}>HOW YOUR INFORMATION MAY BE USED</p>

        <p>
          When you use the Site, we need some information held about you in order to identify you amongst other users of
          the Site when we may possibly:
        </p>

        <ul>
          <li>pay you;</li>
          <li>report sales and usage to you;</li>
          <li>send out your content to our distribution partners;</li>
          <li>monitor your HeyFans account;</li>
          <li>collect any subscription fees;</li>
          <li>
            process your order and payment in order to confirm your identity and check that the payment details provided
            are valid;
          </li>
          <li>help you sell your content.</li>
        </ul>

        <p>We may also use information held about you in the following ways:</p>

        <ul>
          <li>To help us respond to your queries;</li>
          <li>To monitor how your content is performing for ranking charts;</li>
          <li>
            To provide information about sales and/or streams of your content to third parties and to compile such
            information in charts and for other analytical purposes.
          </li>
          <li>
            To present you with a voluntary survey or questionnaire about our content or possible future services or
            products that asks for non-personal information from you.
          </li>
          <li>
            To provide you, or permit selected third parties to provide you, with information about other goods and
            services we offer that are similar to those that you have already purchased or enquired about or that we
            feel may interest you;
          </li>
          <li>
            To make suggestions and recommendations to you and other users of our Site about goods or services that may
            interest you or them;
          </li>
          <li>
            To provide or permit third parties to provide targeted advertising via websites you visit about goods or
            services that you may find interesting (see more on this in our Cookies Policy);
          </li>
          <li>
            To measure or understand the effectiveness of advertising we serve to you and others, and to deliver
            relevant advertising to you;
          </li>
          <li>To track the progress and number of entries in competitions and promotions.</li>
          <li>
            To allow you to interact with our Site, for example by posting comments or to share information from our
            Site on third-party social media platforms;
          </li>
          <li>
            To carry out our obligations arising from any contracts entered into between you and us and to provide you
            with the information, products and services that you request from us;
          </li>
          <li>To manage your access to the services or notify you about changes to the services;</li>
          <li>To comply with legal and regulatory requirements;</li>
          <li>
            To administer our Site and for internal operations, including troubleshooting, data analysis, testing,
            research, statistical and survey purposes;
          </li>
          <li>
            To improve our Site to ensure that content is presented in the most effective manner for you and for your
            computer;
          </li>
          <li>
            As part of our efforts to keep our Site safe and secure and to prevent or detect fraud or abuses of the
            Site;
          </li>
        </ul>

        <p>
          We may combine information we receive from other sources with information you give to us and information we
          collect about you (including information in relation to your devices). We may use this combined information
          for the purposes set out above.
        </p>

        <p className={cn('subheader')}>DISCLOSURE OF YOUR INFORMATION</p>

        <p>We may share your personal data with any XGenLabs company, and with selected third parties including:</p>

        <ul>
          <li>
            Business partners, suppliers and sub-contractors for the performance of any contract we enter into with them
            or you in order to provide you with a product or service;
          </li>
          <li>
            HeyFans scouts and our distribution partner in order for you to have the opportunity to get help with
            marketing, distributing and promoting your content. Our scouts will also receive a report on the number of
            sales/streams your content generates and other information that we provide them from time to time in order
            for HeyFans and the scouts to be able to offer you the best service possible.
          </li>
          <li>
            Business partners, suppliers and sub-contractors to provide you with information about promotions and
            offers;
          </li>
          <li>
            Advertisers, advertising networks and social networks that require the data to select and serve relevant
            adverts to you and others. We may also use aggregate and other information to help advertisers reach the
            kind of audience they want to target. We may make use of the personal data we have collected from you to
            enable us to comply with our advertisers’ wishes by displaying their advertisement to that target audience;
          </li>
          <li>Analytics and search engine providers that assist us in the improvement and optimization of our Site;</li>
          <li>Third parties when you consent to or request such sharing.</li>
        </ul>

        <p>We may disclose your personal data to third parties:</p>

        <ul>
          <li>
            In the event that we sell or buy any business or assets, in which case we may disclose your personal data to
            the prospective seller or buyer of such business or assets;
          </li>
          <li>
            If the HeyFans Company that controls this Site or substantially all of its assets are acquired by a third
            party, in which case personal data held by it about its customers will be one of the transferred assets;
          </li>
          <li>
            If we are under a duty to disclose or share your personal data in order to comply with any legal obligation,
            or in order to enforce or apply our Terms and Conditions and other agreements; or to protect the rights,
            property, or safety of HeyFans Companies, our customers, or others. This includes exchanging information
            with other companies and organizations for the purposes of fraud protection and credit risk reduction;
          </li>
          <li>To any other third party where lawful.</li>
        </ul>

        <p className={cn('subheader')}>STORAGE AND TRANSFER OF DATA</p>

        <p>
          All the information you provide may be processed and stored, transferred or accessed by entities around the
          world as described in this policy. We will take steps to ensure that your data is treated securely and in
          accordance with this Privacy Policy. We have put in place technical and organizational procedures designed to
          safeguard the information we collected on this Site.
        </p>

        <p>
          Unfortunately, the transmission of information via the internet is not completely secure. Although we will do
          our best to protect your personal data, we cannot guarantee the security of your data transmitted to our Site;
          any transmission is at your own risk.
        </p>

        <p className={cn('subheader')}>MESSAGING AND COMMUNICATING WITHIN OUR SERVICE</p>

        <p>
          Our service provides the unique opportunity and platform for content creators to meet fans as well as talent
          scouts and we are always looking for ways to help you and your creative endeavors. In the event that we build
          functionality and community focused services to facilitate such opportunities such as blogs, news boards,
          instant messaging and other similar services, you should be aware that any information you share is visible to
          other users. Please note that information that you share on one of these forms of communication can be viewed,
          obtained or used by others to contact you. By accessing these features and disclosing personal data, you
          affirmatively consent to the distribution of your personal data. HeyFans is not responsible for the personally
          identifiable information you choose to submit in these forums and you are solely responsible for any content
          that you post there, including, but not limited to, any personal data that you choose to reveal. In using any
          of these services, you agree that you will not use them for any purpose that is unlawful or in violation of
          HeyFans's Terms and Conditions. Please note that HeyFans may, in its sole discretion, monitor postings in
          these forums to assure that users are complying with the HeyFans Terms and Conditions
        </p>

        <p className={cn('subheader')}>CHILDREN</p>

        <p>
          This Site is not directed toward children (as defined by local law) nor does HeyFans knowingly collect
          information from children (as defined by local law) without parental consent except where in compliance with
          applicable laws.
        </p>

        <p className={cn('subheader')}>YOUR RIGHTS</p>

        <p>
          You may be entitled, in accordance with applicable law, to object to or request the restriction of processing
          of your personal data, and to request access to, rectification, erasure, and portability of your own personal
          data by contacting us as described in the “Contact” section. We may require you to prove your identity with
          approved identification if you request this information.
        </p>

        <p>
          You may also be entitled, in accordance with applicable law, to lodge a complaint with a supervisory authority
          if you consider that our processing of your personal data infringes applicable law.
        </p>

        <p>
          You are responsible for keeping your information current and up-to-date. You may update your information by
          contacting us as described in the “Contact” section.
        </p>

        <p>
          We will retain your information for as long as your account is active or as needed to provide you services. We
          will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and
          enforce our agreements.
        </p>

        <p className={cn('subheader')}>YOUR CALIFORNIA PRIVACY RIGHTS</p>

        <p>
          California residents may opt out of having their personally identifiable information shared with third parties
          for those third parties’ direct marketing purposes by contacting us as described in the “Contact” section.
        </p>

        <p>
          Do-Not-Track Signals and Similar Mechanisms. Some web browsers may transmit “do-not-track” signals to the
          websites and other online services with which a user communicates. There is no industry standard that governs
          what, if anything, websites should do when they receive these signals. HeyFans currently does not take action
          in response to these signals.
        </p>

        <p className={cn('subheader')}>LINKS TO THIRD PARTY WEBSITES</p>

        <p>
          Our Site and other communications may, from time to time, contain links to and from the websites of our
          partner networks, advertisers and affiliates. If you follow a link to any of these websites, please note that
          these websites have their own privacy policies and that we do not accept any responsibility or liability for
          these policies.
        </p>

        <p className={cn('subheader')}>CHANGES TO OUR PRIVACY POLICY</p>

        <p>
          Any material changes we may make to our privacy policy in the future will be posted on this page prior to the
          change becoming effective and/or we will notify you through the Site or, where appropriate, by e-mail. Please
          check back frequently to see any updates or changes to our privacy policy.
        </p>

        <p className={cn('subheader')}>USERS OF SITES IN THE EUROPEAN ECONOMIC AREA</p>

        <p>
          This section of this Privacy Policy applies only if you use the Site from a country that is a Member State of
          the European Economic Area, and supplements the information in this Privacy Policy.
        </p>

        <p>
          Legal Basis for Data Processing: We process personal data for the purposes set out in this Privacy Policy, as
          described above. Our legal basis to process personal data includes processing that is: necessary for the
          performance of the contract between you and us (for example, to provide you with the services you request and
          to identify and authenticate you so you may use the Site); necessary to comply with legal requirements (for
          example, to comply with applicable accounting rules and to make mandatory disclosures to law enforcement);
          necessary for our legitimate interests (for example, to manage our relationship with you and to improve the
          Site); and based on consent by our customers (for example, to communicate with you about our products and
          services without affecting the lawfulness of processing based on consent before its withdrawal.
        </p>

        <p>
          We may use automated decision making technologies, including profiling, to support our data processing
          activities. Our automated decision making capabilities include logic that attempts to identify content, music,
          communications, products, or offers that we believe may interest you. By using this logic, it helps us
          personalize your interactions with HeyFans. For you, this means that you may see online advertisements, direct
          marketing communications (if you have subscribed), or other advertising or marketing messages or special
          offers based on your activity on our Sites or interactions with HeyFans Companies or our third party partners.
        </p>

        <p>
          In some instances, you may be required to provide us with personal data for processing as described above, in
          order for us to be able to provide you to use all the features of the Site.
        </p>

        <p>International transfers</p>

        <p>
          Our sharing your personal data in accordance with this Privacy Policy, may involve transferring your data
          outside the European Economic Area (EEA). Whenever we transfer your personal data out of the EEA, we ensure a
          similar degree of protection is afforded to it by ensuring, where required by law, at least one of the
          following safeguards is implemented:
        </p>

        <ul>
          <li>
            transferring personal data to countries that have been deemed to provide an adequate level of protection for
            personal data by the European Commission;
          </li>
          <li>
            using specific contracts approved by the European Commission which give personal data the same protection it
            has in Europe; or
          </li>
          <li>
            transferring personal data to the United States to an entity if they are part of the Privacy Shield which
            requires them to provide similar protection to personal data shared between Europe and the US.
          </li>
        </ul>

        <p>
          You may be entitled, in accordance with applicable law, to request a copy of relevant safeguards by contacting
          HeyFans as described in the “Contact” section.
        </p>

        <p className={cn('subheader')}>HOW TO CONTACT HEYFANS</p>

        <p>If you have questions about this policy, you can contact us as described below:</p>

        <p>
          Email: <a href="mailto:legal@heyfans.com">legal@heyfans.com</a>
        </p>

        <p>
          Mail:
          <br />
          HeyFans
          <br />
          ATTN: Privacy Operations
          <br />
          400 Spectrum Center Dr.,
          <br />
          Suite 2180
          <br />
          Irvine, CA 92618
        </p>
      </div>
    </div>
  );
};

export default Privacy;
