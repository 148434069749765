import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import classnames from 'classnames/bind';

import { companyRouteConfig } from 'routes';
import RouteFromPath from 'components/Route/RouteFromPath';

import logoMark from 'assets/images/heyfans-logo.svg';
import logoType from 'assets/images/heyfans-logotype.svg';

import styles from './Company.module.scss';

const cn = classnames.bind(styles);

const Company = () => {
  const match = useRouteMatch();
  const preferencesRoute = useRouteMatch('/preferences');
  const history = useHistory();

  return (
    <div className={cn('container', { 'preferences-container': preferencesRoute })}>
      <div className={cn('wrapper')}>
        {!preferencesRoute && (
          <div className={cn('header')}>
            <img className={cn('mark')} src={logoMark} onClick={() => history.push('/login')} />
            <img className={cn('type')} src={logoType} />
          </div>
        )}
        <div className={cn('header-name', preferencesRoute ? 'preferences' : 'login')}>Company</div>
        <div className={cn('is-size-7', 'ft-mt-4')}>
          HeyFans brings fans closer to the action with unparalleled access to athletes from across all sports and
          leagues. Follow athletes that you like, watch their content, enjoy their live streams and reach out to them
          for a personalized video message, call or training tips. You can even support your favorite athletes by
          tipping them with FanBucks or subscribing to their channel. <p></p>
          We hope you enjoy HeyFans and congratulations on joining one of the fastest growing fan engagement platforms
          in the world.
          <p>
            Dan Baxter
            <br />
            Chief Fan Officer
          </p>
        </div>

        <div className={cn('flexbox', 'tab', 'ft-mt-4')}>
          <div
            className={cn('tab-item', {
              active: location.pathname === `${preferencesRoute?.url || ''}/company/contact`
            })}
            onClick={() => {
              history.push(`${preferencesRoute?.url || ''}/company/contact`);
            }}>
            Contact
            {location.pathname === `${preferencesRoute?.url || ''}/company/contact` && <div className={cn('border')} />}
          </div>
          <div
            className={cn('tab-item', {
              active: location.pathname === `${preferencesRoute?.url || ''}/company/how-do-i`
            })}
            onClick={() => {
              history.push(`${preferencesRoute?.url || ''}/company/how-do-i`);
            }}>
            How Do I?
            {location.pathname === `${preferencesRoute?.url || ''}/company/how-do-i` && (
              <div className={cn('border')} />
            )}
          </div>
          <div
            className={cn('tab-item', {
              active: location.pathname === `${preferencesRoute?.url || ''}/company/faq`
            })}
            onClick={() => {
              history.push(`${preferencesRoute?.url || ''}/company/faq`);
            }}>
            FAQs
            {location.pathname === `${preferencesRoute?.url || ''}/company/faq` && <div className={cn('border')} />}
          </div>
        </div>
        <div className={cn('ft-mt-8')}>
          {companyRouteConfig.map((route) => (
            <RouteFromPath key={`route${route.path}`} base={match.url} {...route} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Company;
