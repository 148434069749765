import { httpClient } from 'http/httpClient';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { PostType } from 'enums';
import { Post, PurchaseInformation } from 'types';
import { PendingAction, RejectedAction } from 'state/store';

export const getMerchantMerchandise = createAsyncThunk(
  'merchandise/getMerchantMerchandise',
  async (creatorGuid: string) => {
    const response = await httpClient.get('/posts/search', {
      params: { merchantGUID: creatorGuid, postType: PostType.Merchandise, hasActiveMarketplaceListing: true }
    });
    return response.data;
  }
);

export const purchaseMerchandise = createAsyncThunk(
  'merchandise/purchaseMerchandise',
  async (purchaseInformation: PurchaseInformation, { rejectWithValue }) => {
    try {
      const response = await httpClient.patch(
        `/marketplacelistings/${purchaseInformation.listingGuid}/purchase`,
        purchaseInformation
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

interface LiveEventState {
  status: string;
  error?: string;
  merchandise?: Post[];
  isPurchaseComplete?: boolean;
  isPurchaseRequest?: boolean;
}

const initialState: LiveEventState = {
  status: 'idle'
};

const merchandiseSlice = createSlice({
  name: 'merchandise',
  initialState,
  reducers: {
    resetPurchase: (state) => {
      state.isPurchaseComplete = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMerchantMerchandise.fulfilled, (state, action) => {
        state.status = 'success';
        state.merchandise = action.payload;
      })
      .addCase(purchaseMerchandise.pending, (state) => {
        state.isPurchaseComplete = undefined;
        state.isPurchaseRequest = true;
      })
      .addCase(purchaseMerchandise.fulfilled, (state) => {
        state.status = 'success';
        state.isPurchaseRequest = false;
        state.isPurchaseComplete = true;
      })
      .addCase(purchaseMerchandise.rejected, (state) => {
        state.status = 'rejected';
        state.isPurchaseRequest = false;
        state.isPurchaseComplete = false;
      })
      .addMatcher(
        (action): action is PendingAction => action.type.endsWith('/pending'),
        (state) => {
          state.status = 'loading';
        }
      )
      .addMatcher(
        (action): action is RejectedAction => action.type.endsWith('/rejected'),
        (state) => {
          state.status = 'rejected';
        }
      );
  }
});

export const { resetPurchase } = merchandiseSlice.actions;
export default merchandiseSlice.reducer;
