import React, { FormEvent } from 'react';
import classnames from 'classnames/bind';
import Button from 'components/Button';
import Select from 'components/Select';
import Input from 'components/Input';
import { Feed, UserFlaggedContent } from 'types';
import { serializeFormData } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/store';
import { reportPost } from 'state/reducers/postReducer';

import styles from './ReportPostModal.module.scss';

const cn = classnames.bind(styles);

const ReportTypes = [
  {
    name: 'Inappropriate',
    value: 'Inappropriate'
  },
  {
    name: 'Scam',
    value: 'Scam'
  },
  {
    name: 'Dislike',
    value: 'Dislike'
  },
  {
    name: 'Other',
    value: 'Other'
  }
];

interface ClubChannelProps {
  onSelect: (value: boolean) => void;
  post: Feed;
}

const ReportPostModal = (props: ClubChannelProps) => {
  const { user } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  const sendReport = (event: FormEvent) => {
    event.preventDefault();

    const report: UserFlaggedContent = serializeFormData(new FormData(event.target as HTMLFormElement));
    report.flaggedUserGUID = props.post.userGUID;
    report.postGUID = props.post.guid;
    report.userGUID = user?.guid;
    report.merchantGUID = user?.merchantGUID;
    dispatch(reportPost(report));
    props.onSelect(true);
  };

  return (
    <div className={cn('modal-content')}>
      <form onSubmit={(event) => sendReport(event)}>
        <Select name="flaggedContentType" label="Report reason:" options={ReportTypes} required />
        {
          <div className={cn('row', 'ft-mt-4')}>
            <Input type="textarea" label="Description: (optional)" name="description" />
          </div>
        }
        <div className={cn('ft-mt-8')}>
          <Button color="primary" className={cn('ft-mr-4')}>
            Submit
          </Button>
          <Button type="button" color="white" onClick={() => props.onSelect(false)}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ReportPostModal;
