import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import { cloneDeep } from 'lodash';

import { Channel, ChannelMessage, SubscriptionPlan } from 'types';
import {
  deleteChannel,
  fetchSubscriptions,
  fetchUserChannels,
  pinChannel,
  resetChangeUser,
  unpinChannel
} from 'state/reducers/userReducer';
import { RootState } from 'state/store';

import defaultAvatar from 'assets/images/default-avatar-1.png';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Input from 'components/Input';
import Modal from 'components/Modal';
import ClubModal from 'components/Modal/ClubModal';
import Skeleton from 'components/Skeleton';
import { getChannelMessages, sendChannelMessage } from 'state/reducers/channelReducer';
import moment from 'moment';
import BlankPage from 'components/BlankPage';
import { useHistory } from 'react-router-dom';
import { toggleSnackbarOpen } from 'state/reducers/snackbarReducer';
import DeleteChannelModal from 'components/Modal/DeleteChannelModal';
import ContextMenu from 'components/ContextMenu';
import { propertySort } from 'utils';
import { webSocketService } from 'services';

import styles from './Club.module.scss';

const cn = classnames.bind(styles);

const Club = () => {
  const { user, channel } = useSelector((state: RootState) => ({
    user: state.user,
    channel: state.channel
  }));
  const [selectedUser, setSelectedUser] = useState<{ name: string; guid: string } | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [contextMenuId, setContextMenuId] = useState<string | undefined>(undefined);

  const [selectedChannel, setSelectedChannel] = useState<Channel>();
  const [editChannel, setEditChannel] = useState<Channel>();
  const [deletedChannel, setDeletedChannel] = useState<Channel>();
  const [channelsList, setChannelsList] = useState<Channel[]>();
  const [channelMessagesList, setChannelMessagesList] = useState<ChannelMessage[] | undefined>(undefined);
  //const [showPin, setShowPin] = useState('');

  const channelMessagesRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (user.user) {
      dispatch(fetchSubscriptions());
      dispatch(fetchUserChannels());

      /*if (user.user?.merchantGUID) {
        dispatch(getChannels(user.user?.merchantGUID));
      } else {
        dispatch(getChannels(user.user?.guid as string));
      }*/
    }
  }, [user.user]);

  useEffect(() => {
    if (user.user?.merchantGUID) {
      setSelectedUser({ name: user.user.displayName as string, guid: user.user.merchantGUID as string });
      filterUserChannels(user.user.merchantGUID);
    } else {
      if (user.subscriptions && user.subscriptions.filter((plan) => plan.status === 'Active').length > 0) {
        onSwitchCreator(user.subscriptions.filter((plan) => plan.status === 'Active')[0]);
      } /*else {
        setSelectedChannel(undefined);
        dispatch(getChannels(user.user?.guid as string));
      }/*/
    }
  }, [user.subscriptions]);

  useEffect(() => {
    if (user.userChannels && user.userChannels.length > 0 && selectedUser !== undefined) {
      filterUserChannels(selectedUser.guid);
    }
  }, [user.userChannels]);

  useEffect(() => {
    if (selectedChannel && channel.channelMessages?.length) {
      setChannelMessagesList([...channel.channelMessages].reverse());
    } else {
      setChannelMessagesList([]);
    }
  }, [channel.channelMessages]);

  useEffect(() => {
    if (user.changeUser) {
      setSelectedUser({ name: user.user?.displayName as string, guid: user.user?.merchantGUID as string });
      dispatch(resetChangeUser());
    }
  }, [user.changeUser]);

  useEffect(() => {
    if (user.updateChannelStatus == 'success' && user.updatedChannel) {
      if (selectedChannel && selectedChannel?.guid == user.updatedChannel.guid) {
        selectedChannel.name = user.updatedChannel.name;
        selectedChannel.description = user.updatedChannel.description;
        onSelectChannel(selectedChannel);
      } else {
        setSelectedChannel(selectedChannel);
      }
    }
  }, [user.updateChannelStatus, user.updatedChannel]);

  useEffect(() => {
    if (channel && channel.newMessage) {
      setChannelMessagesList([
        ...(channelMessagesList || []),
        {
          ...channel.newMessage
        }
      ]);
    }
  }, [channel.newMessage]);

  useEffect(() => {
    scrollToBottom();
  }, [channelMessagesList]);

  const onSwitchCreator = (plan: SubscriptionPlan) => {
    setSelectedUser({ name: plan.merchantUserDisplayName as string, guid: plan.merchantGUID as string });
    filterUserChannels(plan.merchantGUID as string);
  };

  const onSelectChannel = (selectedChannel: Channel) => {
    if (selectedChannel && selectedChannel.guid !== undefined) {
      webSocketService.initializeChannelMessagingService(selectedChannel.guid as string);
    }

    setChannelMessagesList(undefined);

    if (selectedChannel !== undefined) {
      if (selectedChannel?.accessType !== 'lobby') {
        const channelsCopy = cloneDeep(user.userChannels);
        setSelectedChannel(channelsCopy?.find((x) => x.name === selectedChannel.name) || {});
        dispatch(getChannelMessages(selectedChannel.guid as string));
      }
    }
  };

  const handleAddMessage = (event: KeyboardEvent) => {
    event.stopPropagation();
    let value = (event.target as HTMLInputElement).value;
    value = value.replace(/^\s+/, '').replace(/\s+$/, '');

    if (event.key === 'Enter' && value !== '') {
      const message: ChannelMessage = {
        channelGUID: selectedChannel?.guid,
        userGUID: user.user?.guid,
        message: (event.target as HTMLInputElement).value,
        userUsername: user.user?.username,
        userDisplayName: user.user?.displayName,
        profileImageUrl: user.user?.profileImageUrl
      };

      dispatch(sendChannelMessage(message));
      (event.target as HTMLInputElement).value = '';
    }
  };

  const getOwnChannels = () => {
    setSelectedUser({ name: user.user?.displayName as string, guid: user.user?.merchantGUID as string });
    filterUserChannels(user.user?.merchantGUID as string);
  };

  const goToUserPage = (message: ChannelMessage) => {
    if (message.userMerchantGUID) {
      history.push(`/${message.userUsername}`);
    } else {
      history.push(`/profile/${message.userUsername}`);
    }
  };

  const confirmPinChannel = (channel: Channel) => {
    dispatch(pinChannel(channel.guid as string));
    //setShowPin('');
  };

  const confirmUnpinChannel = (channel: Channel) => {
    dispatch(unpinChannel(channel.guid as string));
    //setShowPin('');
  };

  const updateChannel = (channel: Channel) => {
    setIsModalOpen(true);
    setEditChannel(channel);
  };

  const setupDelete = (channel: Channel) => {
    setDeletedChannel(channel);
    setIsDeleteModal(true);
  };

  const confirmDeletion = (value: boolean) => {
    if (value) {
      dispatch(deleteChannel(deletedChannel?.guid as string));
      dispatch(
        toggleSnackbarOpen({
          message: `Channel ${deletedChannel?.name} deleted.`,
          type: 'info',
          timeout: 4000
        })
      );
    }

    setIsDeleteModal(false);
  };

  const resetClubModal = (showModal: boolean, editedChannel: Channel | undefined) => {
    setEditChannel(editedChannel);
    setIsModalOpen(showModal);
  };

  const filterUserChannels = (merchantGUID: string) => {
    setChannelsList(
      user.userChannels
        ?.filter((channel) => channel.merchantGUID === merchantGUID)
        .sort(propertySort('-isChannelPinnedForCallingUser'))
    );
    onSelectChannel(
      user.userChannels
        ?.filter((channel) => channel.merchantGUID === merchantGUID)
        .sort(propertySort('-isChannelPinnedForCallingUser'))[0] as Channel
    );
  };

  const scrollToBottom = () => {
    if (channelMessagesRef.current) {
      channelMessagesRef.current.scroll({
        top: channelMessagesRef.current.scrollHeight
      });
    }
  };

  return (
    <>
      <div className={cn('container')}>
        <div className={cn('sidebar')}>
          {user.user?.merchantGUID && (
            <div
              className={cn('flexbox', 'creator', { highlight: selectedUser?.name === user.user?.displayName })}
              onClick={getOwnChannels}>
              <div className={cn('avatar')}>
                <img src={user.user?.profileImageUrl || defaultAvatar} />
              </div>
            </div>
          )}

          {user.subscriptions?.map(
            (plan) =>
              plan.status === 'Active' && (
                <div
                  key={plan.guid}
                  className={cn('flexbox', 'creator', {
                    highlight: selectedUser?.name === plan.merchantUserDisplayName
                  })}
                  onClick={() => onSwitchCreator(plan)}>
                  <div className={cn('avatar')}>
                    <img src={plan.merchantUserProfileImageUrl || defaultAvatar} />
                  </div>
                </div>
              )
          )}
        </div>
        <div className={cn('channel-sidebar')}>
          <div className={cn('merchant')}>{selectedUser?.name}'s Club</div>
          <div className={cn('wrapper')}>
            <div className={cn('list')}>
              <div className={cn('is-size-8', 'ft-ml-2')}>CHANNELS</div>
              <div className={cn('channels')}>
                {channelsList?.map((ch) => (
                  <div
                    key={`${ch.guid}`}
                    className={cn('channel-row', {
                      selected: ch.name === selectedChannel?.name
                    })}
                    onClick={() => onSelectChannel(ch)}>
                    <div className={cn('channel')}>
                      {ch.isChannelPinnedForCallingUser ? (
                        <Icon name="pin" size="sm" />
                      ) : ch.accessType === 'Private' ? (
                        <Icon name="lock" size="sm" />
                      ) : (
                        '#'
                      )}
                      {' ' + ch.name}
                    </div>
                    {
                      <div
                        className={cn('menu', {
                          sticky: ch.guid === contextMenuId
                        })}>
                        <ContextMenu
                          alignment="channel"
                          interface={
                            <Button className={cn('clear', 'ellipsis')} onClick={() => setContextMenuId(ch.guid)}>
                              <Icon name="ellipsis" size="sm" />
                            </Button>
                          }
                          onClose={() => setContextMenuId(undefined)}>
                          <ul>
                            {ch.isChannelPinnedForCallingUser ? (
                              <li className={cn('pin')} onClick={() => confirmUnpinChannel(ch)}>
                                <Icon name="pin" size="sm" /> Unpin
                              </li>
                            ) : (
                              <li className={cn('pin')} onClick={() => confirmPinChannel(ch)}>
                                <Icon name="pin" size="sm" /> Pin
                              </li>
                            )}
                            {user.user?.merchantGUID === ch.merchantGUID && (
                              <>
                                <li className={cn('edit')} onClick={() => updateChannel(ch)}>
                                  <Icon name="edit" size="sm" /> Edit
                                </li>
                                <li className={cn('delete')} onClick={() => setupDelete(ch)}>
                                  <Icon name="trash" size="sm" /> Delete
                                </li>
                              </>
                            )}
                          </ul>
                        </ContextMenu>
                      </div>
                    }
                  </div>
                ))}
              </div>
              {/*<div className={cn('is-size-8')}>LOBBIES</div>
              <div className={cn('channels')}>
                {mockLobbies.map((lb, index) => (
                  <div
                    key={`${lb.id}-${index}`}
                    className={cn('channel', {
                      selected: lb.id === selectedChannel?.id
                    })}
                    onClick={() => onSelectChannel(lb)}>
                    <Icon name="lightning" size="sm" /> {lb.name}
                  </div>
                ))}
              </div>*/}
            </div>
            {user.user?.merchantGUID && selectedChannel?.merchantGUID === user.user?.merchantGUID && (
              <div className={cn('action')}>
                <Button onClick={() => resetClubModal(true, undefined)}>Create Room</Button>
              </div>
            )}
          </div>
        </div>
        <div className={cn('content')}>
          {(channelsList?.length as number) > 0 && selectedChannel && selectedChannel?.accessType !== 'lobby' && (
            <>
              <div className={cn('channel-body')}>
                {channelMessagesList ? (
                  <div className={cn('celestial')} ref={channelMessagesRef}>
                    <div className={cn('channel-name')}>
                      <div className={cn('has-text-weight-bold')}>
                        {selectedChannel.accessType === 'Private' ? <Icon name="lock" size="lg" /> : '#'}
                        {' ' + selectedChannel.name}
                      </div>
                      <div className={cn('is-size-7', 'has-text-muted', 'ft-mt-1')}>{selectedChannel.description}</div>
                    </div>
                    <div className={cn('channel-conversations')}>
                      {(channelMessagesList?.length as number) > 0 ? (
                        <div className={cn('messages-box')}>
                          {channelMessagesList?.map((message, index) => (
                            <div className={cn('message-row')} key={index}>
                              <img
                                className={cn('message-icon')}
                                src={message.profileImageUrl || defaultAvatar}
                                onClick={() => goToUserPage(message)}
                              />
                              <div className={cn('ft-ml-2')}>
                                <div className={cn('ft-mt-1')}>
                                  <span className={cn('message-user')} onClick={() => goToUserPage(message)}>
                                    {message.userDisplayName}
                                  </span>
                                  <span className={cn('message-content')}>{message.message}</span>
                                </div>
                                <span className={cn('message-timestamp')}>{moment(message.dateCreated).fromNow()}</span>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <BlankPage text="Be the first one to leave a message." />
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    <div className={cn('flexbox')}>
                      <Skeleton type="circ" radius={12} />
                      <Skeleton className={cn('desktop', 'ft-ml-2')} type="rect" width="160px" height="12px" />
                    </div>
                    <div className={cn('flexbox', 'ft-mt-2')}>
                      <Skeleton type="circ" radius={12} />
                      <Skeleton className={cn('desktop', 'ft-ml-2')} type="rect" width="240px" height="12px" />
                    </div>
                    <div className={cn('flexbox', 'ft-mt-2')}>
                      <Skeleton type="circ" radius={12} />
                      <Skeleton className={cn('desktop', 'ft-ml-2')} type="rect" width="200px" height="12px" />
                    </div>
                  </>
                )}
              </div>
              <div className={cn('chat')}>
                <Input placeholder="Add your message" name="message" onKeyUp={(event) => handleAddMessage(event)} />
              </div>
            </>
          )}

          {selectedChannel && selectedChannel?.accessType === 'lobby' && (
            <>
              <div className={cn('channel-body')}>
                <div className={cn('channel-name')}>
                  <Icon name="lightning" size="lg" />
                  {` ${selectedChannel?.name}`}
                </div>
                <div className={cn('channel-conversations')}>
                  <div className={cn('users')}>
                    {selectedChannel.participants?.map((user) => (
                      <div className={cn('user')} key={user.username}>
                        <div className={cn('avatar', { speaking: user.isSpeaking })}>
                          <img src={user.normalizedProfileImageUrl || defaultAvatar} />
                        </div>
                        <div className={cn('ft-mt-2')}>
                          <div>{user.username}</div>
                          <div className={cn('participant-type')}>{user.participantType}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className={cn('chat', 'request')}>
                <div>
                  <Button>Request to be a speaker</Button>
                </div>
              </div>
            </>
          )}

          {!user.user?.merchantGUID && user.subscriptions?.filter((plan) => plan.status === 'Active').length === 0 && (
            <>
              <BlankPage
                text="Subscribe to an athlete to access their private channel."
                tabText="Discover Athletes"
                tabLink="/discover"
              />
            </>
          )}

          {selectedUser && channelsList?.length === 0 && (
            <>
              <BlankPage text={selectedUser.name + ' has no channels yet.'} />
            </>
          )}
        </div>
      </div>
      {isModalOpen && (
        <Modal onClose={() => setIsModalOpen(false)}>
          <ClubModal channel={editChannel} onClose={() => setIsModalOpen(false)} />
        </Modal>
      )}
      {isDeleteModal && (
        <Modal onClose={() => setIsDeleteModal(false)}>
          <DeleteChannelModal onSelect={confirmDeletion} />
        </Modal>
      )}
    </>
  );
};

export default Club;
