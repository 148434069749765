import React from 'react';
import classnames from 'classnames/bind';
import Button from 'components/Button';

import styles from './DeleteChannelModal.module.scss';

const cn = classnames.bind(styles);

interface ClubChannelProps {
  onSelect: (value: boolean) => void;
}

const DeleteChannelModal = (props: ClubChannelProps) => {
  return (
    <div className={cn('modal-content')}>
      This channel already has subscribers. Deleting this will mean that all comments from all subscribers will be lost.
      Are you sure you want to delete this channel?
      <div className={cn('ft-mt-8')}>
        <Button color="danger" className={cn('ft-mr-4')} onClick={() => props.onSelect(true)}>
          Delete
        </Button>
        <Button color="white" onClick={() => props.onSelect(false)}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default DeleteChannelModal;
