import React, { FormEvent, useEffect } from 'react';
import classnames from 'classnames/bind';

import Button from 'components/Button';
import { RootState } from 'state/store';
import { useDispatch, useSelector } from 'react-redux';
import { resendPhoneVerification, verifyByPhoneNumber } from 'state/reducers/authReducer';
import styles from './Verification.module.scss';

const cn = classnames.bind(styles);
const Verification = () => {
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  let inputElements: HTMLInputElement[];

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    const code = [...document.getElementsByTagName('input')]
      .filter(({ name }) => name)
      .map(({ value }) => value)
      .join('');

    if (auth.guid) {
      dispatch(verifyByPhoneNumber({ guid: auth.guid, code }));
    }
  };

  const resend = () => {
    if (auth.guid) {
      dispatch(resendPhoneVerification(auth.guid));
    }
  };

  useEffect(() => {
    inputElements = [...document.querySelectorAll('input[name="code"]')] as HTMLInputElement[];
    inputElements.forEach((ele: HTMLElement, index: number) => {
      ele.addEventListener('keydown', (e: KeyboardEvent) => {
        const element = e.target as HTMLInputElement;
        if (e.key === 'Backspace' && element.value === '') inputElements[Math.max(0, index - 1)].focus();
      });
      ele.addEventListener('input', (el) => {
        const element = el.target as HTMLInputElement;
        const [first, ...rest] = element.value;
        element.value = first ?? '';
        const lastInputBox = index === inputElements.length - 1;
        const insertedContent = first !== undefined;
        if (insertedContent && !lastInputBox) {
          inputElements[index + 1].focus();
          inputElements[index + 1].value = rest.join('');
          inputElements[index + 1].dispatchEvent(new Event('input'));
        }
      });
    });
  });

  return (
    <div className={cn('verify')}>
      <form onSubmit={onSubmit}>
        Enter the verification that was sent to your mobile phone.
        <div className={cn('flexbox', 'ft-mt-4')}>
          <input className={cn('code')} type="number" name="code" required />
          <input className={cn('code')} type="number" name="code" required />
          <input className={cn('code')} type="number" name="code" required />
          <input className={cn('code')} type="number" name="code" required />
          <input className={cn('code')} type="number" name="code" required />
          <input className={cn('code')} type="number" name="code" required />
        </div>
        {auth.error && <div className={cn('ft-mt-4', 'has-text-danger')}>{auth.error}</div>}
        <div className={cn('row', 'text-center', 'ft-mt-4')}>
          <Button type="submit" color="primary">
            Proceed
          </Button>
        </div>
        <div className={cn('row', 'text-center', 'ft-mt-4')}>
          <Button type="button" color="clear-primary" onClick={() => resend()}>
            Resend Verification Code
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Verification;
