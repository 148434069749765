import React from 'react';

import './HowDoI.module.scss';

const HowDoI = () => {
  return (
    <div className="legal is-size-7">
      <div className="has-text-weight-bold has-text-primary is-size-6">How Do I?</div>
      <div className="columns ft-mt-2">
        <div className="column">
          <div className="has-text-weight-bold is-size-6 has-text-primary">For Fans</div>
          <div className="ft-mt-4">
            <div className="show">
              <span className="subheader">Buy FanBucks</span>
            </div>
          </div>
          <div className="ft-mt-2 response">
            Go to your profile page and select the Wallet menu. Here you can purchase FanBucks, see FanBuck transactions
            and manage your credit card.
          </div>
          <div className="ft-mt-4">
            <div className="show">
              <span className="subheader">Tip</span>
            </div>
          </div>
          <div className="ft-mt-2 response">
            Look for the FanBucks icon on the comments bar for any post or live stream.
          </div>
          <div className="ft-mt-4">
            <div className="show">
              <span className="subheader">Change My Profile</span>
            </div>
          </div>
          <div className="ft-mt-2 response">
            Click on the profile icon to view and edit your profile image and details.
          </div>
          <div className="ft-mt-4">
            <div className="show">
              <span className="subheader">Get a Refund</span>
            </div>
          </div>
          <div className="ft-mt-2 response">
            Please send an email to <a href="mailto:support@heyfans.com">support@heyfans.com</a> and explain the amount
            of the refund and reason.
          </div>
          <div className="ft-mt-4">
            <div className="show">
              <span className="subheader">Close My Account</span>
            </div>
          </div>
          <div className="ft-mt-2 response">
            Go to your profile page and click Cancel Account at the bottom of this page.
          </div>
          <div className="has-text-weight-bold is-size-6 has-text-primary ft-mt-6">Legal and Privacy</div>
          <div className="ft-mt-4">
            <div className="show">
              <span className="subheader">Report offensive content</span>
            </div>
          </div>
          <div className="ft-mt-2 response">
            Please send an email to <a href="mailto:support@heyfans.com">support@heyfans.com</a> and identify the
            offensive post. (Best option is to copy the offending URL address from your browser search bar and paste
            into your email.)
          </div>
        </div>
        <div className="column">
          <div className="has-text-weight-bold is-size-6 has-text-primary">For Athletes</div>
          <div className="ft-mt-4">
            <div className="show">
              <span className="subheader">Apply</span>
            </div>
          </div>
          <div className="ft-mt-2 response">
            Visit the{' '}
            <a href="/apply" className="has-text-green">
              Apply
            </a>{' '}
            page and complete the online form. Someone from the HeyFans team will reach out to you.
          </div>
          <div className="ft-mt-4">
            <div className="show">
              <span className="subheader">Redeem FanBucks</span>
            </div>
          </div>
          <div className="ft-mt-2 response">
            Go to your Admin portal and click on Wallet. Under the FanBucks section click the Cash Out button to request
            your earings via Venmo or Paypal.
          </div>
          <div className="ft-mt-4">
            <div className="show">
              <span className="subheader">Close My Account</span>
            </div>
          </div>
          <div className="ft-mt-2 response">
            Log into your Admin portal and click the Profile tab. At the bottom of this page click Cancel Account.
          </div>
          <div className="ft-mt-4">
            <div className="show">
              <span className="subheader">Offer Fan Rewards</span>
            </div>
          </div>
          <div className="ft-mt-2 response">
            Log into your Admin portal and click on the Fan tab. Locate the Fan you want to reward and then click on the
            three dots that appear at the end of the row.
          </div>
          <div className="ft-mt-4">
            <div className="show">
              <span className="subheader">View My Dashboard</span>
            </div>
          </div>
          <div className="ft-mt-2 response">
            Log into your Admin portal and click on the Dashboard tab, or tap the My Stats menu option on the mobile
            app.
          </div>
          <div className="has-text-weight-bold is-size-6 has-text-primary ft-mt-6">Account</div>
          <div className="ft-mt-4">
            <div className="show">
              <span className="subheader">Update My Banking Information</span>
            </div>
          </div>
          <div className="ft-mt-2 response">
            Log into your Admin portal and click on the Banking tab. Here you can view and edit existing bank account
            information as well as add a new bank account.
          </div>
          <div className="ft-mt-4">
            <div className="show">
              <span className="subheader">View My Tax Forms</span>
            </div>
          </div>
          <div className="ft-mt-2 ft-mb-4 response">
            Log into your Admin portal and click on the Banking tab. Look for the Tax tab. This will show all the
            documents you need for filing taxes.
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowDoI;
