import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { RootState } from 'state/store';
import { Post } from 'types';
import { fetchPostsByMerchatByType } from 'state/reducers/postReducer';
import { ContentType, PostType, ResponseStatus } from 'enums';
import { playContent } from 'state/reducers/sessionReducer';

import { cloneDeep } from 'lodash';
import BlankPage from 'components/BlankPage';
import defaultAvatar from 'assets/images/default-avatar-1.png';

import styles from './Livestream.module.scss';

const cn = classnames.bind(styles);

const Livestream = () => {
  const { creator, post, liveStream, user } = useSelector((state: RootState) => ({
    creator: state.creator,
    post: state.post,
    liveStream: state.liveStream,
    user: state.user
  }));
  const dispatch = useDispatch();

  const [creatorLivestream, setCreatorLivestream] = useState<Post[]>();

  const onViewContent = (post: Post) => {
    dispatch(playContent({ contentType: ContentType.Post, post }));
  };

  useEffect(() => {
    dispatch(
      fetchPostsByMerchatByType({
        creatorGuid: creator.creator?.guid as string,
        postType: PostType.LiveStream,
        isPublished: true
      })
    );
  }, [creator.creator?.guid]);

  useEffect(() => {
    if (post.status === ResponseStatus.Success && post.postType === PostType.LiveStream) {
      setCreatorLivestream(post.posts?.filter((x) => x.contentUrl));
    }
  }, [post]);

  useEffect(() => {
    const clonedPost = cloneDeep(post.posts);
    const check = liveStream.liveStreams?.filter((x) => x.merchantGUID === creator.creator?.guid && x.contentUrl);
    if (check && check?.length > 0) {
      check.map((liveStream) => {
        const checkPost = post.posts?.filter((post) => post.guid === liveStream.guid);
        if (checkPost === undefined || checkPost.length === 0) {
          clonedPost?.push(liveStream);
          setCreatorLivestream(clonedPost);
        }
      });
    } else {
      setCreatorLivestream(post.posts?.filter((x) => x.contentUrl));
    }
  }, [liveStream.liveStreams]);

  return (
    <div className={cn('wrapper')}>
      {creatorLivestream && creatorLivestream?.length > 0 ? (
        <div className={cn('livestreams')}>
          <div className={cn('grid')}>
            {creatorLivestream?.map(
              (post) =>
                post.liveStreamGUID && (
                  <div className={cn('image-wrapper')} key={post.guid}>
                    <div className={cn('thumbnail')} onClick={() => onViewContent(post)}>
                      <img
                        className={cn({
                          blur: post.isLockedForCallingUser && user.user?.guid !== creator.creator?.userGUID
                        })}
                        src={post.thumbnailImageUrl || post.normalizedProfileImageUrl || defaultAvatar}
                      />
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      ) : (
        <div className={cn('ft-pt-4', 'ft-pb-4')}>
          <BlankPage
            text={(creator.creator?.name || creator.creator?.userDisplayName) + ' has yet to post any live streams.'}
            removeTopMargin
          />
        </div>
      )}
    </div>
  );
};

export default Livestream;
