import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { Post, PostGroup } from 'types';
import { ContentType, PostGroupType, PostType } from 'enums';
import { fetchPostGroup, fetchPostsFromPostGroup } from 'state/reducers/postReducer';
import { playContent } from 'state/reducers/sessionReducer';
import { RootState } from 'state/store';

import Skeleton from 'components/Skeleton';

import Modal from 'components/Modal';
import UnlockModal from 'components/Modal/UnlockModal';
import Icon from 'components/Icon';
import BlankPage from 'components/BlankPage';
import { toggleSnackbarOpen } from 'state/reducers/snackbarReducer';
import styles from './Music.module.scss';

const cn = classnames.bind(styles);

const Music = () => {
  const { creator, post, user } = useSelector((state: RootState) => ({
    creator: state.creator,
    post: state.post,
    user: state.user
  }));
  const dispatch = useDispatch();

  const onViewContent = (postGroup: PostGroup) => {
    setPostGroup(postGroup);
    dispatch(fetchPostsFromPostGroup(postGroup));
  };
  const onViewPost = (post?: Post) => {
    if (post?.isLockedForCallingUser && post?.userGUID !== user.user?.guid) {
      dispatch(
        toggleSnackbarOpen({
          message: 'You must puchase this content to view it.'
        })
      );
    } else {
      dispatch(playContent({ contentType: ContentType.Post, post }));
    }
  };
  const [unlockPost, setUnlockPost] = useState<PostGroup | undefined>(undefined);
  const [tab, setTab] = useState<string | undefined>(undefined);
  const [singles, setSingles] = useState<Post[] | undefined>(undefined);
  const [postGroup, setPostGroup] = useState<PostGroup | undefined>(undefined);

  useEffect(() => {
    if (creator.creator?.userGUID) {
      dispatch(
        fetchPostGroup({
          creatorGuid: creator.creator?.userGUID as string,
          postGroupType: PostGroupType.MusicAlbum
        })
      );
    }
  }, [creator.creator?.userGUID]);

  useEffect(() => {
    if (creator.feed && creator.feed.length > 0) {
      setSingles(creator.feed.filter((x) => x.postType === PostType.Audio));
    }
  }, [creator.feed]);

  useEffect(() => {
    if (post.postGroup !== undefined && postGroup && post.postGroup.guid === postGroup.guid) {
      setPostGroup(post.postGroup);
      dispatch(playContent({ contentType: ContentType.PostGroup, postGroup }));
    }
  }, [post.postGroup]);

  return (
    <>
      <div className={cn('menu')}>
        <div className={cn('flexbox', 'tab')}>
          <div className={cn('tab-item', { active: tab === undefined })} onClick={() => setTab(undefined)}>
            Albums
            {tab === undefined && <div className={cn('border')} />}
          </div>
          <div className={cn('tab-item', { active: tab === 'singles' })} onClick={() => setTab('singles')}>
            Singles
            {tab === 'singles' && <div className={cn('border')} />}
          </div>
        </div>
      </div>
      <div className={cn('music')}>
        {tab === undefined && post.postGroups && (
          <div className={cn('album-group')}>
            {post.postGroups ? (
              post.postGroups?.map((group) => {
                if (group.groupCount && group.groupCount < 1) return null;
                return (
                  <div className={cn('album', 'image-wrapper')} key={group.guid}>
                    <div className={cn('thumbnail')} onClick={() => onViewContent(group)}>
                      <img
                        className={cn({
                          blur: group.isLockedForCallingUser && user.user?.guid !== creator.creator?.userGUID
                        })}
                        src={
                          group.normalizedThumbnailImageUrl ||
                          group.thumbnailImageUrl ||
                          creator.creator?.normalizedProfileImageUrl
                        }
                      />
                    </div>
                    {group.isLockedForCallingUser && user.user?.guid !== creator.creator?.userGUID && (
                      <div className={cn('frosted')} onClick={() => setUnlockPost(group)}>
                        <Icon name="lock" />
                      </div>
                    )}
                    <div className={cn('ft-mt-1')}>
                      <div className={cn('name')}>{group.name}</div>
                    </div>
                  </div>
                );
              })
            ) : (
              <>
                <div className={cn('ft-mt-4')}>
                  <Skeleton type="rect" width="640px" height="600px" />
                </div>
              </>
            )}
          </div>
        )}

        {tab === 'singles' && singles && (
          <>
            {singles ? (
              singles?.map((single) => {
                if (!singles.length) return null;
                return (
                  <div className={cn('singles')} key={single.guid}>
                    <div
                      className={cn('album-wrapper')}
                      onClick={() => (single.isLockedForCallingUser ? setUnlockPost(single) : onViewPost(single))}>
                      <div className={cn('album-details')}>
                        <div className={cn('play-button', 'album-art', 'ft-mr-4')}>
                          <img
                            src={single?.thumbnailImageUrl || single.normalizedProfileImageUrl}
                            className={cn({
                              blur: single.isLockedForCallingUser && single?.userGUID !== user.user?.guid
                            })}
                          />
                          <div
                            className={cn(
                              single.isLockedForCallingUser && single?.userGUID !== user.user?.guid
                                ? 'locked'
                                : 'hovered'
                            )}>
                            <Icon
                              name={
                                single.isLockedForCallingUser && single?.userGUID !== user.user?.guid ? 'lock' : 'play'
                              }
                            />
                          </div>
                        </div>

                        <div>
                          <div className={cn('has-text-muted', 'is-size-8')}>
                            {single?.postType === PostType.Audio ? 'SINGLE' : 'ALBUM'}
                          </div>
                          <div className={cn('has-text-weight-bold', 'is-size-5')}>{single?.name}</div>
                          <div className={cn('is-size-7', 'ft-mt-4')}>
                            Released on {moment(single?.dateCreated).format('MM/DD/YYYY')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <>
                <div className={cn('ft-mt-4')}>
                  <BlankPage text={creator.creator?.name + ' does not have any Singles.'}></BlankPage>
                </div>
              </>
            )}
          </>
        )}

        {unlockPost && (
          <Modal onClose={() => setUnlockPost(undefined)}>
            <UnlockModal feed={unlockPost} />
          </Modal>
        )}
      </div>
    </>
  );
};

export default Music;
