import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import {
  setUser,
  updateUser,
  setPendingUpdate,
  updateUserProfileImage,
  resetUserUpdate,
  setMerchant,
  resetUserErrorPayload
} from 'state/reducers/userReducer';
import { toggleSnackbarOpen } from 'state/reducers/snackbarReducer';
import { RootState } from 'state/store';

import { User, UserResetPassword } from 'types';
import { serializeFormData } from 'utils';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Input from 'components/Input';

import defaultIcon from 'assets/images/default-avatar-1.png';
import ReactSwitch from 'react-switch';
import {
  resetMerchantErrorPayload,
  resetMerchantUpdate,
  setPendingMerchantUpdate,
  updateMerchant
} from 'state/reducers/creatorReducer';
import { changePassword, resetErrorPayload, resetPasswordChange } from 'state/reducers/authReducer';

import styles from './Account.module.scss';

const cn = classnames.bind(styles);

const Account = () => {
  const { user, creator, auth } = useSelector((state: RootState) => ({
    user: state.user,
    creator: state.creator,
    auth: state.auth
  }));
  const [tabValue, setTabValue] = useState('account');
  const profileImageFile = useRef<File | undefined>();
  const [previewUrl, setPreviewUrl] = useState<string | undefined>(undefined);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [toggleSwitch, setToggleSwitch] = useState<boolean>(user.user?.autoAcceptFollowers as boolean);
  const dispatch = useDispatch();

  const onUpdateUser = (event: FormEvent) => {
    event.preventDefault();
    const updatedUser: User = serializeFormData(new FormData(event.target as HTMLFormElement));

    if (updatedUser.displayName === '') {
      dispatch(
        toggleSnackbarOpen({
          message: `Display name is required.`,
          type: 'danger',
          timeout: 4000
        })
      );
      return false;
    }

    if (updatedUser.username === '') {
      dispatch(
        toggleSnackbarOpen({
          message: `Username is required.`,
          type: 'danger',
          timeout: 4000
        })
      );
      return false;
    }

    if (updatedUser.emailAddress === '') {
      dispatch(
        toggleSnackbarOpen({
          message: `Email address is required.`,
          type: 'danger',
          timeout: 4000
        })
      );
      return false;
    }

    if (updatedUser.phoneNumber === '') {
      dispatch(
        toggleSnackbarOpen({
          message: `Phone number is required.`,
          type: 'danger',
          timeout: 4000
        })
      );
      return false;
    }

    if (user.user?.merchantGUID) {
      updatedUser.merchantGUID = user.user?.merchantGUID;
      updatedUser.guid = user.user?.guid;

      dispatch(setMerchant(updatedUser));
      dispatch(setPendingMerchantUpdate());
      dispatch(updateMerchant(updatedUser));
      dispatch(updateUser(updatedUser));
    } else {
      updatedUser.guid = user.user?.guid;
      updatedUser.autoAcceptFollowers = toggleSwitch;
      dispatch(setUser(updatedUser));
      dispatch(setPendingUpdate());
      dispatch(updateUser(updatedUser));
    }
  };

  const onUpdatePassword = (event: FormEvent) => {
    event.preventDefault();
    const updatedPassword: UserResetPassword = serializeFormData(new FormData(event.target as HTMLFormElement));
    if (
      updatedPassword.oldPassword === '' ||
      updatedPassword.newPassword === '' ||
      updatedPassword.confirmPassword === ''
    ) {
      dispatch(
        toggleSnackbarOpen({
          message: `Please answer all fields.`,
          type: 'danger',
          timeout: 4000
        })
      );
    } else if (updatedPassword.newPassword !== updatedPassword.confirmPassword) {
      dispatch(
        toggleSnackbarOpen({
          message: `Passwords do not match.`,
          type: 'danger',
          timeout: 4000
        })
      );
    } else {
      dispatch(changePassword(updatedPassword));
    }
  };

  const previewProfileImage = (fileInput: ChangeEvent) => {
    profileImageFile.current = ((fileInput.target as HTMLInputElement).files as FileList)[0];
    const mimeType = profileImageFile.current.type;
    if (mimeType?.match(/image\/*/) == null) {
      profileImageFile.current = undefined;
      return;
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(profileImageFile.current);
      reader.onload = () => {
        setPreviewUrl(reader.result?.toString());
      };
    }
  };

  const handleChange = () => {
    setToggleSwitch(!toggleSwitch);
  };

  useEffect(() => {
    if (
      (user.isUserUpdated && user.updateStatus === 'success') ||
      (creator.isUserUpdated && creator.updateStatus === 'success')
    ) {
      dispatch(
        toggleSnackbarOpen({
          message: 'Profile updated.',
          type: 'info'
        })
      );

      if (user.user?.merchantGUID) {
        dispatch(resetMerchantUpdate());
      } else {
        dispatch(resetUserUpdate());
      }

      if (profileImageFile.current) {
        const formData = new FormData();
        formData.append('file', profileImageFile.current as Blob);
        dispatch(updateUserProfileImage({ userGuid: user.user?.guid as string, file: formData }));
      }
    } else {
      if (
        (!user.isUserUpdated && user.updateStatus === 'rejected') ||
        (!creator.isUserUpdated && creator.updateStatus === 'rejected')
      ) {
        dispatch(
          toggleSnackbarOpen({
            message: user.errorPayload?.message || creator.errorPayload?.message,
            type: 'danger',
            timeout: 4000
          })
        );

        if (user.user?.merchantGUID) {
          dispatch(resetMerchantUpdate());
          dispatch(resetMerchantErrorPayload());
        } else {
          dispatch(resetUserUpdate());
          dispatch(resetUserErrorPayload());
        }
      }
    }
  }, [user.isUserUpdated, user.updateStatus, creator.isUserUpdated, creator.updateStatus]);

  useEffect(() => {
    if (auth.isPasswordChanged) {
      dispatch(
        toggleSnackbarOpen({
          message: `Password successfully changed.`,
          type: 'info',
          timeout: 4000
        })
      );

      dispatch(resetPasswordChange());
      dispatch(resetErrorPayload());
    } else {
      if (auth.status === 'rejected' && auth.error !== undefined) {
        dispatch(
          toggleSnackbarOpen({
            message: `Incorrect current password.`,
            type: 'danger',
            timeout: 4000
          })
        );

        dispatch(resetPasswordChange());
        dispatch(resetErrorPayload());
      }
    }
  }, [auth.isPasswordChanged, auth.status, auth.error]);

  return (
    <div>
      <div className={cn('is-size-5', 'has-text-weight-semibold')}>Profile</div>
      <div className={cn('ft-mt-4')}>
        <ul className={cn('tab')}>
          <li className={cn('tab-item', { active: tabValue === 'account' })} onClick={() => setTabValue('account')}>
            Account
            {tabValue === 'account' && <div className={cn('border')} />}
          </li>
          <li className={cn('tab-item', { active: tabValue === 'security' })} onClick={() => setTabValue('security')}>
            Security
            {tabValue === 'security' && <div className={cn('border')} />}
          </li>
        </ul>
      </div>
      <div className={cn('content', 'ft-mt-4')}>
        {tabValue === 'account' && (
          <>
            <form onSubmit={onUpdateUser}>
              <div className={cn('main', 'control-group')}>
                <Input label="Display name*" name="displayName" defaultValue={user.user?.displayName} />
                <Input label="Username*" name="username" defaultValue={user.user?.username} />
                <Input label="Email address*" name="emailAddress" defaultValue={user.user?.emailAddress?.toString()} />
                <Input
                  label="Phone number*"
                  name="phoneNumber"
                  defaultValue={user.user?.phoneNumber?.toString()}
                  readOnly
                  disabled
                />
                {user.user?.merchantGUID ? (
                  <Input
                    type="textarea"
                    label="About me"
                    name="description"
                    defaultValue={user.user?.merchantDescription}
                  />
                ) : (
                  <Input type="textarea" label="About me" name="description" defaultValue={user.user?.description} />
                )}
                {!user.user?.merchantGUID && (
                  <>
                    <label className={cn('label')}>Auto-accept followers?</label>
                    <ReactSwitch
                      className={cn('v-align', 'ft-ml-4')}
                      name="autoAcceptFollowers"
                      onChange={handleChange}
                      checked={toggleSwitch}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      onColor="#5f74f4"
                    />
                  </>
                )}
                <div className={cn('flexbox', 'ft-mt-4')}>
                  <div>
                    <Button>Save changes</Button>
                  </div>
                </div>
              </div>
              <div className={cn('side')}>
                <label className={cn('label')}>Profile picture</label>
                <div className={cn('profile-picture')}>
                  <img
                    className={cn('avatar')}
                    src={previewUrl || user.user?.normalizedProfileImageUrl || defaultIcon}
                  />
                  <Button
                    type="button"
                    shape={'circle'}
                    className={cn('more')}
                    size="sm"
                    hasIcon
                    onClick={() => fileInputRef.current?.click()}>
                    <Icon name="edit" size="sm" />
                  </Button>
                  <input type="file" className={cn('hidden')} onChange={previewProfileImage} ref={fileInputRef} />
                </div>
              </div>
            </form>
          </>
        )}
        {tabValue === 'security' && (
          <>
            <form onSubmit={onUpdatePassword}>
              <div className={cn('main')}>
                <Input
                  className={cn('control')}
                  type="password"
                  label="Current password*"
                  name="oldPassword"
                  required
                />
                <Input className={cn('control')} type="password" label="New password*" name="newPassword" required />
                <Input
                  className={cn('control')}
                  type="password"
                  label="Confirm new password*"
                  name="confirmPassword"
                  required
                />
                <div className={cn('flexbox')}>
                  <div>
                    <Button>Save changes</Button>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default Account;
