import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames/bind';

import styles from './Sound.module.scss';

const cn = classnames.bind(styles);

export enum SoundStatus {
  Pause,
  Play,
  Stop
}

export interface SoundProps {
  url?: string;
  playStatus?: SoundStatus.Play | SoundStatus.Pause | SoundStatus.Stop;
  onPlaying?: (param: number) => void;
  onEnded?: () => void;
  playFromPosition?: number;
}

let audio: HTMLAudioElement;

const Sound = (props: SoundProps) => {
  const [intervalId, setIntervalId] = useState(0);
  const position = useRef(0);
  const audioRef = useRef<HTMLAudioElement>(null);

  const startInterval = () => {
    const id = setInterval(
      (() => {
        const time = Math.trunc(audio.currentTime);
        props.onPlaying?.(time);
        position.current = time;
      }) as TimerHandler,
      1000
    );
    setIntervalId(id);
  };

  useEffect(() => {
    audio = audioRef.current as HTMLAudioElement;
    audio.src = props.url as string;
    audio.play();
  }, [props.url]);

  useEffect(() => {
    if (audio) {
      if (props.playStatus === SoundStatus.Play) {
        audio.play();
        startInterval();
      } else if (props.playStatus === SoundStatus.Pause) {
        audio.pause();
        clearInterval(intervalId);
      } else if (props.playStatus === SoundStatus.Stop) {
        audio.pause();
        audio.currentTime = 0;
        clearInterval(intervalId);
      }
    }
  }, [props.playStatus]);

  useEffect(() => {
    if (props.playFromPosition !== undefined && props.playFromPosition !== position.current) {
      audio.currentTime = props.playFromPosition;
      position.current = props.playFromPosition;
    }
  }, [props.playFromPosition]);

  return (
    <div className={cn('hidden')}>
      <audio src={props.url} ref={audioRef} onEnded={props.onEnded} />
    </div>
  );
};

export default Sound;
