export enum PostType {
  Audio = 'Audio',
  Image = 'Image',
  Video = 'Video',
  LiveStream = 'LiveStream',
  PostGroup = 'PostGroup',
  LiveEvent = 'LiveEvent',
  Merchandise = 'Merchandise'
}

export enum PostGroupType {
  MusicAlbum = 'MusicAlbum'
}

export enum FlaggedContentType {
  Other = 'Other',
  Inappropriate = 'Inappropriate',
  Scam = 'Scam',
  Dislike = 'Dislike'
}
