import React from 'react';
import classnames from 'classnames/bind';

import styles from './Terms.module.scss';

const cn = classnames.bind(styles);

const Terms = () => {
  return (
    <div className={cn('legal')}>
      <div className={cn('header')}>Terms of Use</div>
      <div className={cn('is-size-7')}>
        <p>Last Updated: September 10, 2021</p>

        <p>
          This Terms of Use Agreement (“Terms”) governs your use of the website, mobile application or service offered
          by XGenLabs Llc., doing business as HeyFans (“we”, “us”, or HeyFans), including our website (heyfans.com),
          mobile application (“App”), and services we provide through them (collectively, the website, App, and services
          referred to as our “Services”). “You” refers to you as a user of the Site.
        </p>

        <p>
          <b>
            PLEASE READ THESE TERMS CAREFULLY. By using our Service or otherwise indicating your acceptance (for
            example, by agreeing when creating or logging into your account, clicking “I Agree,” etc.), you represent
            and warrant that you have read, understand, and agree to be bound by these Terms. If you do not agree, do
            not access, or use our Site.
          </b>
        </p>
        <p>
          <b>
            ARBITRATION NOTICE: The Section Conflict Resolution of these Terms contains provisions governing how claims
            that you and HeyFans have against each other are resolved, including any claims that arose or were asserted
            prior to the “Last Updated” date at the top of these Terms. It contains an arbitration agreement that will,
            with limited exceptions, require disputes between you and HeyFans to be submitted to binding and final
            arbitration. Unless you opt out of the arbitration agreement: (1) you may pursue claims and seek relief
            against us only on an individual basis, not as a plaintiff or class member in any class or representative
            action or proceeding; and (2) you waive your right to seek relief in a court of law and to have a jury trial
            on your claims.
          </b>
        </p>

        <p className={cn('subheader')}>THE HEYFANS SERVICE</p>

        <p>We agree to provide you with the HeyFans Service that may consist of:</p>

        <ul>
          <li>The ability to view content and leave comments and likes for other people to see.</li>
          <li>
            The ability to live stream and post your own content, in the form of images, video and audio to share with
            others on the HeyFans platform.
          </li>
          <li>
            The ability to discover, earn or transfer user tokens and/or virtual gifts in exchange for products and
            services offered by people and businesses on the HeyFans platform.
          </li>
          <li>The ability to follow people and/or be followed by request.</li>
        </ul>

        <p className={cn('subheader')}>THE DATA POLICY</p>

        <p>
          Providing our Service requires collecting and using your information. The Privacy Policy explains how we
          collect and use this information. You must agree to our Terms and Privacy Policy to use HeyFans.
        </p>

        <p className={cn('subheader')}>YOUR RESPONSIBILITIES</p>

        <p>
          In order to use the HeyFans platform, we require that you make the following commitments to ensure a safe and
          respectful environment for people to enjoy:
        </p>

        <ul>
          <li>You must be at least 13 years old.</li>
          <li>You must not be a convicted sex offender.</li>
          <li>You must not post objectionable material on HeyFans.</li>
          <li>You cannot impersonate others or provide inaccurate information.</li>
          <li>You can't do anything unlawful, misleading, or fraudulent or for an illegal or unauthorized purpose.</li>
          <li>You can't violate (or help or encourage others to violate) these Terms or our policies.</li>
          <li>You can't do anything to interfere with or impair the intended operation of the Service.</li>
          <li>You can't attempt to create accounts or access or collect information in unauthorized ways.</li>
          <li>
            You can't attempt to buy, sell, or transfer any aspect of your account (including your username) or solicit,
            collect, or use login credentials or badges of other users.
          </li>
          <li>
            You can't post private or confidential information or do anything that violates someone else's rights,
            including intellectual property.
          </li>
          <li>
            You can’t reverse engineer, reverse compile, de-compile or attempt to discover the source code of the
            software.
          </li>
          <li>
            You can’t use, rent, lend, copy, modify or otherwise create and publish sites and applications that contain
            the intellectual property rights of HeyFans or user content uploaded or streamed on the platform.
          </li>
        </ul>

        <p className={cn('subheader')}>PERMISSIONS YOU GIVE TO US</p>

        <p>As part of our agreement, you also give us permissions that we need to provide the Service.</p>

        <ul>
          <li>
            <b>We do not claim ownership of your content, but you grant us a license to use it.</b>
            <br />
            Nothing is changing about your rights in your content. We do not claim ownership of your content that you
            post on or through the Service. Instead, when you share, post, or upload content that is covered by
            intellectual property rights (like music, photos or videos) on or in connection with our Service, you hereby
            grant to us a non-exclusive, royalty-free, transferable, sub-licensable, worldwide license to host, use,
            distribute, modify, run, copy, publicly perform or display, translate, and create derivative works of your
            content (consistent with your privacy and application settings). You can end this license anytime by
            deleting your content or account. However, content will continue to appear if you shared it with others and
            they have not deleted it.
          </li>
          <li>
            <b>
              Permission to use your username, profile picture, and information about your relationships and actions.
            </b>
            <br />
            You give us permission to show your username, profile picture, and information about your actions (such as
            likes) or relationships (such as follows) next to or in connection with accounts, ads, offers, and other
            content that you follow or engage with - without any compensation to you.
          </li>
        </ul>

        <p className={cn('subheader')}>ADDITIONAL RIGHTS WE RETAIN</p>

        <ul>
          <li>
            If you select a username or similar identifier for your account, we may change it if we believe it is
            appropriate or necessary (for example, if it infringes someone's intellectual property or impersonates
            another user or is considered objectionable).
          </li>
          <li>
            If you use content covered by intellectual property rights that we have and make available in our Service
            (for example, images, designs, videos, or sounds we provide that you add to content you create or share), we
            retain all rights to our content (but not yours).
          </li>
          <li>
            You must not modify, create derivative works of, decompile, or otherwise attempt to extract source code from
            us.
          </li>
          <li>We can modify the Services provided at any time without prior notice to you at our sole discretion.</li>
        </ul>

        <p className={cn('subheader')}>CONTENT REMOVAL AND DISABLING OR TERMINATING YOUR ACCOUNT</p>

        <ul>
          <li>
            We can remove any content or information you share on the Service if we believe that it violates these Terms
            of Use, or we are permitted or required to do so by law. If the content is in violation, it will be removed
            within 24 hours from the time of the initial report.
          </li>
          <li>
            We can refuse to provide or stop providing all or part of the Service to you (including terminating or
            disabling your account) immediately to protect our community or services, or if you create risk or legal
            exposure for us, violate these Terms of Use or our policies, if you repeatedly infringe other people's
            intellectual property rights, or where we are permitted or required to do so by law.
          </li>
          <li>
            Content you delete may persist for a limited period of time in backup copies and will still be visible where
            others have shared it.
          </li>
        </ul>

        <p className={cn('subheader')}>USER AND COMPANY RIGHTS</p>

        <ul>
          <li>This agreement does not give rights to any third parties.</li>
          <li>You cannot transfer your rights or obligations under this agreement without our consent.</li>
          <li>
            Our rights and obligations can be assigned to others. For example, this could occur if our ownership changes
            (as in a merger, acquisition, or sale of assets) or by law.
          </li>
        </ul>

        <p className={cn('subheader')}>USER AND COMPANY RESPONSIBILITIES</p>

        <ul>
          <li>
            Our Service is provided "as is," and we can't guarantee it will be safe and secure or will work perfectly
            all the time. TO THE EXTENT PERMITTED BY LAW, WE ALSO DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED,
            INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
            NON-INFRINGEMENT.
          </li>
          <li>
            We also don’t control what people and others do or say, and we aren’t responsible for their (or your)
            actions or conduct (whether online or offline) or content (including unlawful or objectionable content). We
            also aren’t responsible for services and features offered by other people or companies, even if you access
            them through our Site.
          </li>
          <li>
            Our responsibility for anything that happens on the Site (also called "liability") is limited as much as the
            law will allow. If there is an issue with our Site, we can't know what all the possible impacts might be.
            You agree that we won't be responsible ("liable") for any lost profits, revenues, information, or data, or
            consequential, special, indirect, exemplary, punitive, or incidental damages arising out of or related to
            these Terms, even if we know they are possible. This includes when we delete your content, information, or
            account. Our aggregate liability arising out of or relating to these Terms will not exceed $100.
          </li>
          <li>
            You agree to defend (at our request), indemnify and hold us harmless from and against any claims,
            liabilities, damages, losses, and expenses, including without limitation, reasonable attorney's fees and
            costs, arising out of or in any way connected with these Terms or your use of the Site. You will cooperate
            as required by us in the defense of any claim. We reserve the right to assume the exclusive defense and
            control of any matter subject to indemnification by you, and you will not in any event settle any claim
            without our prior written consent.
          </li>
        </ul>

        <p className={cn('subheader')}>CONFLICT RESOLUTION</p>

        <ul>
          <li>
            Except as provided below, you and we agree that any cause of action, legal claim, or dispute between you and
            us arising out of or related to these Terms or HeyFans ("claim(s)") must be resolved by arbitration on an
            individual basis. Class actions and class arbitrations are not permitted; you and we may bring a claim only
            on your own behalf and cannot seek relief that would affect other HeyFans users. If there is a final
            judicial determination that any particular claim (or a request for particular relief) cannot be arbitrated
            in accordance with this provision's limitations, then only that claim (or only that request for relief) may
            be brought in court. All other claims (or requests for relief) remain subject to this provision.
          </li>
          <li>
            <p className={cn('ft-mt-0')}>
              Instead of using arbitration, you or we can bring claims in your local "small claims" court, if the rules
              of that court will allow it. If you don't bring your claims in small claims court (or if you or we appeal
              a small claims court judgment to a court of general jurisdiction), then the claims must be resolved by
              binding, individual arbitration. The American Arbitration Association will administer all arbitrations
              under its Consumer Arbitration Rules. You and we expressly waive a trial by jury.
            </p>
            <p>
              The following claims don't have to be arbitrated and may be brought in court: disputes related to
              intellectual property (like copyrights and trademarks), violations of our Platform Policy, or efforts to
              interfere with the Site or engage with the Site in unauthorized ways (for example, automated ways). In
              addition, issues relating to the scope and enforceability of the arbitration provision are for a court to
              decide.
            </p>
            <p>This arbitration provision is governed by the Federal Arbitration Act.</p>
            <div>
              You can opt out of this provision within 30 days of the date that you agreed to these Terms. To opt out,
              you must send your name, residence address, username, email address or phone number you use for your
              HeyFans account, and a clear statement that you want to opt out of this arbitration agreement, and you
              must email them to: support@heyfans.com.
            </div>
          </li>
          <li>
            Before you commence arbitration of a claim, you must provide us with a written Notice of Dispute that
            includes your name, residence address, username, email address or phone number you use for your HeyFans
            account, a detailed description of the dispute, and the relief you seek. Any Notice of Dispute you send to
            us should be emailed to: support@heyfans.com. Before we commence arbitration, we will send you a Notice of
            Dispute to the email address you use with your HeyFans account, or other appropriate means. If we are unable
            to resolve a dispute within thirty (30) days after the Notice of Dispute is received, you or we may commence
            arbitration.
          </li>
          <li>
            We will pay all arbitration filing fees, administration and hearing costs, and arbitrator fees for any
            arbitration we bring or if your claims seek less than $75,000 and you timely provided us with a Notice of
            Dispute. For all other claims, the costs and fees of arbitration shall be allocated in accordance with the
            arbitration provider's rules, including rules regarding frivolous or improper claims.
          </li>
          <li>
            For any claim that is not arbitrated or resolved in small claims court, you agree that it will be resolved
            exclusively in the U.S. District Court for the Southern District of California or a state court located in
            Orange County. You also agree to submit to the personal jurisdiction of either of these courts for the
            purpose of litigating any such claim.
          </li>
          <li>
            The laws of the State of California, to the extent not preempted by or inconsistent with federal law, will
            govern these Terms and any claim, without regard to conflict of law provisions.
          </li>
        </ul>

        <p className={cn('subheader')}>UPDATING THESE TERMS</p>

        <p>
          We may change our Site and policies, and we may need to make changes to these Terms so that they accurately
          reflect our Site and policies. Unless otherwise required by law, we will notify you (for example, through our
          Site) before we make changes to these Terms and give you an opportunity to review them before they go into
          effect. Then, if you continue to use the Site, you will be bound by the updated Terms. If you do not want to
          agree to these or any updated Terms, you should delete your account and discontinue use of this Site.
        </p>

        <p className={cn('subheader')}>VIRTUAL CURRENCY</p>

        <p>
          By using the Site, you are able to purchase FanBucks, the Site's virtual currency. Unless otherwise specified,
          FanBucks can only be used in the Site and hold no purchasing power in scenarios other than the Site. FanBucks
          can be used to tip, pay or gift to users of the platform for product, services or rewards.
        </p>

        <ul>
          <li>
            We do not encourage minors to use FanBucks. If you are a minor, you should ask your guardian(s) to operate
            or operate with the expressed consent of your guardian(s).
          </li>
          <li>
            HeyFans is not responsible for the loss of any FanBucks caused by the following reasons:
            <ul>
              <li>Your account was locked, banned or frozen based on any infringement of these Terms.</li>
              <li>That you lost your password or account access.</li>
              <li>That you disclosed your account access to others.</li>
              <li>Intentional, gross negligence or violation of laws and regulations of other users.</li>
              <li>Any other reason that was caused by yourself.</li>
            </ul>
          </li>
          <li>
            HeyFans reserves the right to deduct FanBucks from your account under the following circumstances:
            <ul>
              <li>If your purchase of FanBucks is determined by the bank, payment channel, or court as invalid;</li>
              <li>
                If a chargeback or refund initiated by an end user of this Site is permitted subject to decision of a
                bank, payment gateway, court, arbitration tribunal, or us;
              </li>
              <li>
                Any purchase is considered illegal or from improper gains, and we are required by the court and other
                institutions to refund;
              </li>
            </ul>
          </li>
        </ul>

        <p>
          <b>
            Specific price information, exchange rate, and usage policy will be determined by HeyFans at our sole
            discretion. Unless otherwise provided hereof, FanBucks may not be used for any other purposes, including the
            ability to pay for transactions outside of the Site or trading on third-party platforms. If you violate the
            foregoing requirement, HeyFans is not liable for any losses incurred by you or a third party.
          </b>
        </p>

        <p>
          <b>
            UNLESS OTHERWISE REGULATD BY LAWS, OR WHERE SPECIFICALY STATED WITHIN THE SITE, ALL PURCHASES OF FANBUCKs
            MADE THROUGH THE SITE ARE FINAL, NON-REFUNDABLE, NON-TRANSFERRABLE UNDER ANY CIRCUMSTANCES.
          </b>
        </p>

        <p className={cn('subheader')}>HOW TO CONTACT HEYFANS</p>

        <p>If you have questions about these Terms, you can contact us as described below:</p>

        <p>
          Email: <a href="mailto:legal@heyfans.com">legal@heyfans.com</a>
        </p>

        <p>
          Mail:
          <br />
          HeyFans
          <br />
          ATTN: Privacy Operations
          <br />
          400 Spectrum Center Dr.,
          <br />
          Suite 2180
          <br />
          Irvine, CA 92618
        </p>
      </div>
    </div>
  );
};

export default Terms;
