import { httpClient } from 'http/httpClient';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { View } from 'types/view';
import { PendingAction, RejectedAction } from 'state/store';

export const createView = createAsyncThunk('view/createView', async (views: View) => {
  const response = await httpClient.post(`/views`, views);
  return response.data;
});

interface ViewState {
  status: string;
  error?: string;
  view?: View;
}

const initialState: ViewState = {
  status: 'idle'
};

const viewSlice = createSlice({
  name: 'view',
  initialState,
  reducers: {
    reset: (state) => {
      state = initialState;
      return state;
    },
    resetViews: (state) => {
      state.view = undefined;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createView.fulfilled, (state, action) => {
        state.status = 'success';
        state.view = action.payload;
      })
      .addMatcher(
        (action): action is PendingAction => action.type.endsWith('/pending'),
        (state) => {
          state.status = 'loading';
        }
      )
      .addMatcher(
        (action): action is RejectedAction => action.type.endsWith('/rejected'),
        (state, action) => {
          state.error = 'rejected';
          state.error = action.error.message;
        }
      );
  }
});

export const { reset, resetViews } = viewSlice.actions;
export default viewSlice.reducer;
