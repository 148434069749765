import { httpClient } from 'http/httpClient';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { cloneDeep } from 'lodash';

import { PostType } from 'enums';
import { LiveStream, Post, PostComment } from 'types';
import { PendingAction, RejectedAction } from 'state/store';
import { Poll, PollOptionResults, PollOptions, PollResult } from 'types/poll';
import { clone, cloneDeep } from 'lodash';

export const fetchLiveStream = createAsyncThunk('post/fetchLiveStreamPost', async (streamGuid: string) => {
  const response = await httpClient.get(`/posts/${streamGuid}/livestream`);
  return response.data;
});

export const addChat = createAsyncThunk('liveStream/addChat', async (comment: PostComment) => {
  const response = await httpClient.post(`/livestreams/${comment.liveStreamGUID}/comments`, comment);
  return response.data;
});

export const getLiveStreams = createAsyncThunk('liveStream/getLiveStreams', async () => {
  const response = await httpClient.get('/posts/search', {
    params: {
      postType: PostType.LiveStream,
      liveStreamStatus: 'Active',
      isPublished: true
      // topN: 6
    }
  });
  return response.data;
});

export const fetchPoll = createAsyncThunk('liveStream/fetchPoll', async (streamGuid: string) => {
  if (streamGuid === null || streamGuid === '') return false;
  const response = await httpClient.get(`/livestreams/${streamGuid}/polls`);
  return response.data.find((p: Poll) => p.isActive === true);
});

export const answerPoll = createAsyncThunk('liveStream/answerPoll', async (option: PollOptions) => {
  const response = await httpClient.post(`/polls/${option.pollGUID}/polloptions/${option.guid}/vote`);
  return { response: response.data, option };
});

export const getPollResult = createAsyncThunk('liveStream/getPollResult', async (pollGuid: string) => {
  if (pollGuid === '') {
    throw new Error('Invalid request');
  } else {
    const response = await httpClient.get(`/polls/${pollGuid}`);
    return response.data;
  }
});
interface LiveStreamState {
  status: string;
  error?: string;
  live?: LiveStream;
  activeViewCount?: number;
  comments?: PostComment[];
  newComment?: PostComment;
  liveStreams?: Post[];
  poll?: Poll;
  togglePoll?: boolean;
  pollResult?: PollResult;
  getResult?: boolean;
}

const initialState: LiveStreamState = {
  status: 'idle',
  activeViewCount: 0,
  comments: [],
  poll: undefined
};

const liveStreamSlice = createSlice({
  name: 'liveStream',
  initialState,
  reducers: {
    setNewComment: (state, action) => {
      // const oldComments = cloneDeep(state.comments || []);
      // oldComments.push(action.payload);
      // state.comments = oldComments;
      state.newComment = action.payload;
    },
    resetComment: (state) => {
      state.newComment = undefined;
    },
    resetComments: (state) => {
      state.comments = [];
    },
    initializeViewCount: (state, action) => {
      state.activeViewCount = action.payload;
    },
    updateViewCount: (state, action) => {
      state.activeViewCount += action.payload.viewerCountChange;
    },
    setNewPoll: (state, action) => {
      state.togglePoll = true;
      state.pollResult = undefined;
      state.poll = action.payload;
      state.error = undefined;
    },
    togglePoll: (state, action) => {
      state.togglePoll = action.payload;
    },
    updatePollCount: (state, action) => {
      const pollResult = cloneDeep(state.pollResult);
      const vote = action.payload;
      const index = pollResult?.pollOptions.findIndex((o: PollOptionResults) => o.guid === vote.pollOptionGUID);

      if (pollResult && index && index !== undefined) {
        pollResult.voteAmount += vote.voteCountChange;
        pollResult.pollOptions[index].voteCount = vote.voteCountChange;
      }
      state.pollResult = pollResult;
    },
    resetPoll: (state) => {
      state.poll = undefined;
      state.pollResult = undefined;
      state.togglePoll = undefined;
    },
    removeEndedLiveStreamFromLiveStream: (state, action) => {
      if (state.live) {
        const guid = action.payload;
        const liveStreams = cloneDeep(state.liveStreams);
        const index = liveStreams?.findIndex((live) => live.guid === guid);

        if (index && index !== undefined) {
          liveStreams?.splice(index, 1);
          state.liveStreams = liveStreams;
        }
      }
    },
    endLive: (state) => {
      if (state.live) {
        const live = clone(state.live);
        live.status = 'Finished';
        state.live = live;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLiveStream.fulfilled, (state, action) => {
        state.status = 'success';
        state.live = action.payload;
        state.activeViewCount = action.payload.activeViewerCount;
      })
      .addCase(addChat.fulfilled, (state) => {
        state.status = 'success';
      })
      .addCase(getLiveStreams.fulfilled, (state, action) => {
        state.status = 'success';
        state.liveStreams = action.payload;
      })
      .addCase(fetchPoll.fulfilled, (state, action) => {
        state.status = 'success';
        state.togglePoll = true;
        state.poll = action.payload;
      })
      .addCase(answerPoll.fulfilled, (state, action) => {
        state.status = 'success';
        const poll = cloneDeep(state.poll);
        const updatedOption = cloneDeep(action.payload.option);
        if (poll) {
          const findOptionId = poll?.pollOptions.findIndex((poll) => poll.guid === action.payload.option.guid);
          updatedOption.isVotedForByCallingUser = true;
          poll.pollOptions[findOptionId] = updatedOption;
        }
        state.poll = poll;
      })
      .addCase(getPollResult.fulfilled, (state, action) => {
        state.status = 'success';
        const result = cloneDeep(action.payload);
        if (result.pollOptions.find((option: PollOptionResults) => option.isVotedForByCallingUser === true)) {
          let voteAmount = 0;
          action.payload.pollOptions.map((res: PollOptionResults) => {
            voteAmount += res.voteCount;
          });
          result.voteAmount = voteAmount;
          state.pollResult = result;
        } else {
          state.pollResult = action.payload;
        }
      })
      .addMatcher(
        (action): action is PendingAction => action.type.endsWith('/pending'),
        (state) => {
          state.error = undefined;
          state.status = 'loading';
        }
      )
      .addMatcher(
        (action): action is RejectedAction => action.type.endsWith('/rejected'),
        (state, action) => {
          state.error = 'rejected';
          state.error = action.error.message;
        }
      );
  }
});

export const {
  setNewComment,
  resetComment,
  resetComments,
  initializeViewCount,
  updateViewCount,
  setNewPoll,
  togglePoll,
  resetPoll,
  updatePollCount,
  endLive,
  removeEndedLiveStreamFromLiveStream
} = liveStreamSlice.actions;
export default liveStreamSlice.reducer;
