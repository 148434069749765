import { httpClient } from 'http/httpClient';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Feed, Merchant, Post, User } from 'types';
import { PendingAction, RejectedAction } from 'state/store';
import { clone, cloneDeep } from 'lodash';
import { ErrorPayload } from 'types/errorPayload';

export const fetchCreator = createAsyncThunk('creator/fetchCreator', async (username: string) => {
  const response = await httpClient.get('/merchants/search', { params: { userUsername: username } });
  return response.data;
});

export const fetchAllCreators = createAsyncThunk('creator/fetchAllCreators', async () => {
  const response = await httpClient.get('/merchants', {});
  return response.data;
});

export const fetchCreatorActivity = createAsyncThunk('post/fetchCreatorActivity', async (merchantGuid: string) => {
  const response = await httpClient.get(`/merchants/${merchantGuid}/activity`);
  return response.data;
});

export const patchCreatorActivity = createAsyncThunk('post/patchCreatorActivity', async (feed: Feed[] | undefined) => {
  return feed;
});

export const searchCreators = createAsyncThunk('creator/searchCreators', async (query: string) => {
  const response = await httpClient.get('/merchants/search', { params: { searchTerm: query } });
  return response.data;
});

export const getNewCreators = createAsyncThunk('creator/getNewCreators', async () => {
  const response = await httpClient.get('/merchants/search', { params: { topN: 60 } });
  return response.data;
});

export const getFeaturedCreators = createAsyncThunk('creator/getFeaturedCreators', async () => {
  const response = await httpClient.get('/merchants/search');
  return response.data;
});

export const followUser = createAsyncThunk('user/followUser', async (request: Merchant) => {
  const response = await httpClient.post(`/users/${request?.userGUID}/follow`);
  const creator = clone(request);

  if (creator.userAutoAcceptFollowers) {
    creator.isFollowedByCallingUser = true;
  } else {
    creator.isFollowRequestedByCallingUser = true;
  }
  return { response, creator };
});

export const unfollowUser = createAsyncThunk('user/unfollowUser', async (request: Merchant) => {
  const response = await httpClient.post(`/users/${request?.userGUID}/unfollow`);
  const creator = clone(request);
  creator.isFollowRequestedByCallingUser = false;
  creator.isFollowedByCallingUser = false;
  return { response, creator };
});

export const getDashboard = createAsyncThunk('creator/getDashboard', async (merchantGuid?: string) => {
  const response = await httpClient.get(`/merchants/${merchantGuid}/dashboard`, {
    params: { tokenProfileKey: 'kudo' }
  });
  return response.data;
});

export const updateMerchant = createAsyncThunk('creator/updateMerchant', async (request: User, { rejectWithValue }) => {
  try {
    const response = await httpClient.patch(`/merchants/${request.merchantGUID}`, request);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchMerchantPromos = createAsyncThunk('creator/fetchMerchantPromos', async (guid: string) => {
  const response = await httpClient.get('/posts/search', {
    params: {
      merchantGUID: guid,
      postType: 'Video',
      isPromotion: 'true',
      isPublished: 'true',
      platform: 'heyfans'
    }
  });
  return response.data;
});

interface CreatorState {
  status: string;
  error?: string;
  feed?: Feed[];
  promos?: Post[];
  creator?: Merchant;
  creators?: Merchant[];
  newCreators?: Merchant[];
  featuredCreators?: Merchant[];
  topFans?: User[];
  updateStatus?: string;
  isUserUpdated?: boolean;
  errorPayload?: ErrorPayload;
  feedStatus?: string;
}

const initialState: CreatorState = {
  status: 'idle',
  creator: undefined
};

const creatorSlice = createSlice({
  name: 'creator',
  initialState,
  reducers: {
    resetCreator: (state) => {
      state = initialState;
      return state;
    },
    resetFeed: (state) => {
      state.feed = undefined;
      state.feedStatus = undefined;
    },
    removeEndedLiveStreamFromCreator: (state, action) => {
      if (state.feed) {
        const guid = action.payload;
        const feed = cloneDeep(state.feed);
        const index = feed?.findIndex((feed) => feed.guid === guid);

        if (index !== undefined) {
          feed?.splice(index, 1);
          state.feed = feed;
        }
      }
    },
    setPendingMerchantUpdate: (state) => {
      state.updateStatus = 'pending';
    },
    resetMerchantUpdate: (state) => {
      state.isUserUpdated = false;
      state.updateStatus = undefined;
    },
    resetMerchantErrorPayload: (state) => {
      state.error = undefined;
      state.errorPayload = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCreator.fulfilled, (state, action) => {
        state.status = 'success';
        state.creator = action.payload[0];
      })
      .addCase(fetchAllCreators.fulfilled, (state, action) => {
        state.status = 'success';
        state.creators = action.payload;
      })
      .addCase(fetchCreatorActivity.fulfilled, (state, action) => {
        state.status = 'success';
        state.feed = action.payload;
        state.feedStatus = 'fulfilled';
      })
      .addCase(patchCreatorActivity.fulfilled, (state, action) => {
        state.status = 'success';
        state.feed = action.payload;
      })
      .addCase(searchCreators.fulfilled, (state, action) => {
        state.status = 'success';
        state.creators = action.payload;
      })
      .addCase(getNewCreators.fulfilled, (state, action) => {
        state.status = 'success';
        state.newCreators = action.payload;
      })
      .addCase(getFeaturedCreators.fulfilled, (state, action) => {
        state.status = 'success';
        if (action.payload.length) {
          state.featuredCreators = action.payload;
        }
      })
      .addCase(followUser.fulfilled, (state, action) => {
        state.status = 'success';
        state.creator = action.payload.creator;
      })
      .addCase(unfollowUser.fulfilled, (state, action) => {
        state.status = 'success';
        state.creator = action.payload.creator;
      })
      .addCase(getDashboard.fulfilled, (state, action) => {
        state.status = 'success';
        state.topFans = action.payload.topFans.slice(0, 5);
      })
      .addCase(updateMerchant.fulfilled, (state) => {
        state.status = 'success';
        state.isUserUpdated = true;
        state.updateStatus = 'success';
      })
      .addCase(fetchMerchantPromos.fulfilled, (state, action) => {
        state.status = 'success';
        state.promos = action.payload;
      })
      .addCase(updateMerchant.rejected, (state, action) => {
        state.status = 'rejected';
        state.isUserUpdated = false;
        state.errorPayload = action.payload as ErrorPayload;
      })
      .addCase(fetchCreatorActivity.rejected, (state) => {
        state.feedStatus = 'error';
      })
      .addMatcher(
        (action): action is PendingAction => action.type.endsWith('/pending'),
        (state) => {
          state.status = 'loading';
        }
      )
      .addMatcher(
        (action): action is RejectedAction => action.type.endsWith('/rejected'),
        (state, action) => {
          state.status = 'rejected';
          if (action.payload) {
            const errorPayload = action.payload as ErrorPayload;
            const message = errorPayload.message ? errorPayload.message : '';
            state.error = message;
            state.errorPayload = action.payload as ErrorPayload;
          }
        }
      );
  }
});

export const {
  resetCreator,
  resetFeed,
  removeEndedLiveStreamFromCreator,
  setPendingMerchantUpdate,
  resetMerchantUpdate,
  resetMerchantErrorPayload
} = creatorSlice.actions;
export default creatorSlice.reducer;
