import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames/bind';

import { ContentType } from 'enums';
import { getFeaturedCreators } from 'state/reducers/creatorReducer';
import { getLiveStreams } from 'state/reducers/liveStreamReducer';
import { playContent } from 'state/reducers/sessionReducer';

import { RootState } from 'state/store';

import Anchor from 'components/Anchor';

import defaultAvatar from 'assets/images/default-avatar-1.png';
import { Merchant, Post } from 'types';

import Skeleton from 'components/Skeleton';
// import { fetchLiveStreams } from 'state/reducers/postReducer';
import styles from './Creators.module.scss';

const cn = classnames.bind(styles);

const Creators = () => {
  const { creator, liveStream } = useSelector((state: RootState) => ({
    creator: state.creator,
    liveStream: state.liveStream
  }));

  const [featuredCreatorList, setFeaturedCreatorList] = useState<Merchant[]>();
  const [liveStreamsList, setLiveStreamsList] = useState<Post[]>();
  const history = useHistory();
  const dispatch = useDispatch();

  const watchLiveStream = (guid?: string) => {
    const live = liveStreamsList?.find((x) => x.guid === guid);

    // dispatch(fetchLiveStreams());
    // dispatch(getLiveStreams());

    dispatch(playContent({ contentType: ContentType.Post, post: live }));
  };

  useEffect(() => {
    dispatch(getLiveStreams());
    dispatch(getFeaturedCreators());
  }, []);

  useEffect(() => {
    if (creator.featuredCreators) {
      setFeaturedCreatorList(
        creator.featuredCreators.filter(
          (merchant, index) => index === creator.featuredCreators?.findIndex((x) => x.guid === merchant.guid)
        )
      );
    }
  }, [creator.featuredCreators]);

  useEffect(() => {
    if (liveStream.liveStreams && liveStream.liveStreams.length > 0) {
      setLiveStreamsList(liveStream.liveStreams.filter((x) => x.liveStreamStatus === 'Active'));
    }
  }, [liveStream.liveStreams]);

  return (
    <div className={cn('container')}>
      <div className={cn('content')}>
        <div className={cn('main')}>
          {liveStreamsList && liveStreamsList.length > 0 && (
            <div className={cn('ft-my-8')}>
              <div className={cn('section')}>Streaming Now</div>
              <div className={cn('fluid-wrapper')}>
                <div className={cn('flexbox', 'fluid')}>
                  {liveStreamsList?.map((stream) => (
                    <div className={cn('tile-sm')} key={stream.guid}>
                      <div className={cn('image-wrapper')}>
                        <img
                          className={cn('avatar')}
                          src={stream.normalizedProfileImageUrl}
                          onClick={() => watchLiveStream(stream.guid)}
                        />
                      </div>
                      <div className={cn('wrap-text')}>
                        <Anchor className={cn('link')}>{stream.merchantName}</Anchor>
                        <div className={cn('sub')}>{stream.activeViewerCount} viewers</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {featuredCreatorList ? (
            <div className={cn('ft-mt-8')}>
              {/* <div className={cn('section')}>All</div> */}
              <div className={cn('fluid-wrapper')}>
                <div className={cn('flexbox', 'fluid')}>
                  {featuredCreatorList?.map((creator: Merchant) => (
                    <div
                      className={cn('creator', 'tile-sm')}
                      key={creator.guid}
                      onClick={() => history.push(`/${creator.userUsername}`)}>
                      <div className={cn('image-wrapper')}>
                        <img className={cn('avatar')} src={creator.normalizedProfileImageUrl || defaultAvatar} />
                      </div>
                      <div className={cn('wrap-text')}>
                        <Anchor>{creator.name || creator.userUsername}</Anchor>
                        <div className={cn('sub')}>{creator.followerCount} fans</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className={cn('ft-mt-8')} style={{ visibility: 'visible' }}>
                <div className={cn('section')}>
                  <Skeleton type="rect" width="100px" height="20px" />
                </div>
                <div className={cn('fluid-wrapper')}>
                  <div className={cn('feed-skeleton', 'flexbox', 'fluid')}>
                    <div className={cn('creator', 'tile-sm', 'skeleton-wrapper')}>
                      <Skeleton className={cn('avatar')} type="rect" width="96px" height="96px" />
                      <Skeleton className={cn('skeleton-name', 'mt-4')} type="rect" width="96px" height="17px" />
                      <Skeleton className={cn('skeleton-follower', 'mt-2')} type="rect" width="30px" height="10px" />
                    </div>
                    <div className={cn('creator', 'tile-sm', 'skeleton-wrapper')}>
                      <Skeleton className={cn('avatar')} type="rect" width="96px" height="96px" />
                      <Skeleton className={cn('skeleton-name', 'mt-4')} type="rect" width="96px" height="17px" />
                      <Skeleton className={cn('skeleton-follower', 'mt-2')} type="rect" width="30px" height="10px" />
                    </div>
                    <div className={cn('creator', 'tile-sm', 'skeleton-wrapper')}>
                      <Skeleton className={cn('avatar')} type="rect" width="96px" height="96px" />
                      <Skeleton className={cn('skeleton-name', 'mt-4')} type="rect" width="96px" height="17px" />
                      <Skeleton className={cn('skeleton-follower', 'mt-2')} type="rect" width="30px" height="10px" />
                    </div>
                    <div className={cn('creator', 'tile-sm', 'skeleton-wrapper')}>
                      <Skeleton className={cn('avatar')} type="rect" width="96px" height="96px" />
                      <Skeleton className={cn('skeleton-name', 'mt-4')} type="rect" width="96px" height="17px" />
                      <Skeleton className={cn('skeleton-follower', 'mt-2')} type="rect" width="30px" height="10px" />
                    </div>
                    <div className={cn('creator', 'tile-sm', 'skeleton-wrapper')}>
                      <Skeleton className={cn('avatar')} type="rect" width="96px" height="96px" />
                      <Skeleton className={cn('skeleton-name', 'mt-4')} type="rect" width="96px" height="17px" />
                      <Skeleton className={cn('skeleton-follower', 'mt-2')} type="rect" width="30px" height="10px" />
                    </div>
                    <div className={cn('creator', 'tile-sm', 'skeleton-wrapper')}>
                      <Skeleton className={cn('avatar')} type="rect" width="96px" height="96px" />
                      <Skeleton className={cn('skeleton-name', 'mt-4')} type="rect" width="96px" height="17px" />
                      <Skeleton className={cn('skeleton-follower', 'mt-2')} type="rect" width="30px" height="10px" />
                    </div>
                    <div className={cn('creator', 'tile-sm', 'skeleton-wrapper')}>
                      <Skeleton className={cn('avatar')} type="rect" width="96px" height="96px" />
                      <Skeleton className={cn('skeleton-name', 'mt-4')} type="rect" width="96px" height="17px" />
                      <Skeleton className={cn('skeleton-follower', 'mt-2')} type="rect" width="30px" height="10px" />
                    </div>
                    <div className={cn('creator', 'tile-sm', 'skeleton-wrapper')}>
                      <Skeleton className={cn('avatar')} type="rect" width="96px" height="96px" />
                      <Skeleton className={cn('skeleton-name', 'mt-4')} type="rect" width="96px" height="17px" />
                      <Skeleton className={cn('skeleton-follower', 'mt-2')} type="rect" width="30px" height="10px" />
                    </div>
                    <div className={cn('creator', 'tile-sm', 'skeleton-wrapper')}>
                      <Skeleton className={cn('avatar')} type="rect" width="96px" height="96px" />
                      <Skeleton className={cn('skeleton-name', 'mt-4')} type="rect" width="96px" height="17px" />
                      <Skeleton className={cn('skeleton-follower', 'mt-2')} type="rect" width="30px" height="10px" />
                    </div>
                    <div className={cn('creator', 'tile-sm', 'skeleton-wrapper')}>
                      <Skeleton className={cn('avatar')} type="rect" width="96px" height="96px" />
                      <Skeleton className={cn('skeleton-name', 'mt-4')} type="rect" width="96px" height="17px" />
                      <Skeleton className={cn('skeleton-follower', 'mt-2')} type="rect" width="30px" height="10px" />
                    </div>
                    <div className={cn('creator', 'tile-sm', 'skeleton-wrapper')}>
                      <Skeleton className={cn('avatar')} type="rect" width="96px" height="96px" />
                      <Skeleton className={cn('skeleton-name', 'mt-4')} type="rect" width="96px" height="17px" />
                      <Skeleton className={cn('skeleton-follower', 'mt-2')} type="rect" width="30px" height="10px" />
                    </div>
                    <div className={cn('creator', 'tile-sm', 'skeleton-wrapper')}>
                      <Skeleton className={cn('avatar')} type="rect" width="96px" height="96px" />
                      <Skeleton className={cn('skeleton-name', 'mt-4')} type="rect" width="96px" height="17px" />
                      <Skeleton className={cn('skeleton-follower', 'mt-2')} type="rect" width="30px" height="10px" />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Creators;
