import React from 'react';
import classnames from 'classnames/bind';

import { useHistory } from 'react-router-dom';
import styles from './BlankPage.module.scss';

const cn = classnames.bind(styles);

interface AnchorProps {
  text: string | null;
  tabText?: string;
  tabLink?: string;
  className?: string;
  onClick?: () => void;
  removeTopMargin?: boolean;
}

const BlankPage = (props: AnchorProps) => {
  const history = useHistory();
  const goToTabLink = () => {
    if (props.tabLink) {
      history.push(props.tabLink);
    }
  };

  return (
    <>
      <div className={cn(props.removeTopMargin ? '' : 'ft-my-4', 'text-center', props.className)}>{props.text}</div>
      {props.tabText && props.tabLink ? (
        <div className={cn('ft-mt-8', 'text-center', 'tabLink')} onClick={goToTabLink}>
          {props.tabText}
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default BlankPage;
