import { httpClient } from 'http/httpClient';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { SubscriptionPlan, UserPaymentProfile } from 'types';
import { PendingAction, RejectedAction } from 'state/store';

export const searchSubscriptionsByMerchantGuid = createAsyncThunk(
  'subscription/searchSubscriptionsByMerchantGuid',
  async (merchantGuid: string) => {
    const response = await httpClient.get('/subscriptionplans/search', { params: { merchantGUID: merchantGuid } });
    return response.data;
  }
);

export const subscribe = createAsyncThunk(
  'subscription/subscribe',
  async (request: {
    subscriptionPlanGuid: string;
    paymentProfileGUID?: string;
    tokenProfileKey: string;
    creditCard?: UserPaymentProfile;
    amount: number;
    saveCreditCard?: boolean;
  }) => {
    const response = await httpClient.post(`/subscriptionplans/${request.subscriptionPlanGuid}/subscribe`, {
      paymentProfileGUID: request.paymentProfileGUID,
      tokenProfileKey: request.tokenProfileKey,
      creditCard: request.creditCard,
      amount: request.amount,
      saveCreditCard: request.saveCreditCard
    });
    return response.data;
  }
);

export const fetchSubscribers = createAsyncThunk(
  'subscription/fetchSubscribers',
  async (subscriptionPlanGUID: string) => {
    const response = await httpClient.get(`/subscriptionplans/${subscriptionPlanGUID}/subscriptions`);
    return response.data;
  }
);

interface SubscriptionState {
  status: string;
  error?: string;
  subscriptionsByCreator?: SubscriptionPlan[];
  subscribers?: SubscriptionPlan[];
  periodEndDate?: Date;
}

const initialState: SubscriptionState = {
  status: 'idle'
};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    resetPeriodEndDate: (state) => {
      state.periodEndDate = undefined;
    },
    resetSubscriptionsByCreator: (state) => {
      state.subscriptionsByCreator = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchSubscriptionsByMerchantGuid.fulfilled, (state, action) => {
        state.status = 'success';
        state.subscriptionsByCreator = action.payload;
      })
      .addCase(subscribe.fulfilled, (state, action) => {
        state.status = 'success';
        state.periodEndDate = action.payload.periodEndDate;
      })
      .addCase(fetchSubscribers.fulfilled, (state, action) => {
        state.status = 'success';
        state.subscribers = action.payload;
      })
      .addMatcher(
        (action): action is PendingAction => action.type.endsWith('/pending'),
        (state) => {
          state.status = 'loading';
        }
      )
      .addMatcher(
        (action): action is RejectedAction => action.type.endsWith('/rejected'),
        (state, action) => {
          state.error = 'rejected';
          state.error = action.error.message;
        }
      );
  }
});

export const { resetPeriodEndDate, resetSubscriptionsByCreator } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
