import React, { useRef } from 'react';
import classnames from 'classnames/bind';

import { Post } from 'types';

import { useDispatch } from 'react-redux';
import { resetPostComment } from 'state/reducers/postReducer';
import { playContent } from 'state/reducers/sessionReducer';
import { ContentType } from 'enums';
import styles from './Promo.module.scss';

const cn = classnames.bind(styles);
interface PromoProps {
  post: Post;
  onClick?: () => void;
}

const Promo = (props: PromoProps) => {
  const promoVideo = useRef<HTMLVideoElement>(null);

  const dispatch = useDispatch();

  const onViewPost = (post?: Post) => {
    dispatch(resetPostComment());

    dispatch(playContent({ contentType: ContentType.Post, post }));
  };

  return (
    <>
      <div
        className={cn('promo-container', 'is-flex', 'is-flex-direction-column')}
        onMouseEnter={() => {
          if (promoVideo.current) {
            const video = promoVideo.current as HTMLVideoElement;
            video.play();
          }
        }}
        onMouseLeave={() => {
          if (promoVideo.current) {
            const video = promoVideo.current as HTMLVideoElement;
            video.pause();
          }
        }}>
        <div className={cn('video-container')} onClick={() => onViewPost(props.post)}>
          <video ref={promoVideo} className={cn('video-promo')} src={props.post.contentUrl} loop></video>
          <div className={cn('info-container')}>
            <span className={cn('post-name')}>{props.post.name}</span>
            <span className={cn('description')}> {props.post.description} </span>
          </div>

          <div className={cn('play-button')}>
            <i className={cn('tf tf-play')}></i>
          </div>
        </div>
      </div>
    </>
  );
};

export default Promo;
