import React, { ChangeEvent, KeyboardEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clone, cloneDeep } from 'lodash';
import classnames from 'classnames/bind';
import moment from 'moment';
import { v4 as uuid4 } from 'uuid';

import { patchCreatorActivity } from 'state/reducers/creatorReducer';
import {
  fetchComments,
  likeComment,
  likePost,
  patchPosts,
  postComment,
  resetDeletedComment,
  resetPostComment,
  setPostComments,
  unlikeComment,
  unlikePost,
  unlockPost
} from 'state/reducers/postReducer';
import { playContent, updateContent } from 'state/reducers/sessionReducer';
import { toggleSnackbarOpen } from 'state/reducers/snackbarReducer';
import { fetchTokens } from 'state/reducers/userReducer';
import { createView } from 'state/reducers/viewReducer';
import { RootState } from 'state/store';

import { ContentType, PostType } from 'enums';
import { Feed, Post, PostComment, PostGroup, TokenBalance } from 'types';
import Button from 'components/Button';
import ContextMenu from 'components/ContextMenu';
import Icon from 'components/Icon';
import Comment from 'components/Comment';
import Skeleton from 'components/Skeleton';
import Anchor from 'components/Anchor';
import ReportPostModal from 'components/Modal/ReportPostModal';
import Input from 'components/Input';
import Modal from 'components/Modal';

import defaultAvatar from 'assets/images/default-avatar-1.png';

import styles from './PostFeed.module.scss';

const cn = classnames.bind(styles);

interface PostFeedProps {
  type: 'feed' | 'activity';
  feed?: Feed;
  isPromoted?: boolean;
}

const convertMoment = (date = moment().toDate(), isPromoted = false) => {
  if (isPromoted) {
    return 'PROMOTED';
  } else {
    return moment(date).fromNow();
  }
};

const PostFeed = (props: PostFeedProps) => {
  const session = useSelector((state: RootState) => state.session);

  const [isPaymentConfirmationModal, setIsPaymentConfirmationModal] = useState(false);
  const [isInsufficientBalanceModal, setIsInsufficientBalanceModal] = useState(false);
  const [isReportPost, setIsReportPost] = useState(false);

  const [isPaymentSubmit, setIsPaymentSubmit] = useState(false);

  const [postContent, setPostContent] = useState<Feed | undefined>();
  const [openCommentBox, setOpenCommentBox] = useState<string | undefined>();
  const [isTrubucksPanelOpen, setIsTrubucksPanelOpen] = useState(false);
  const [tipJar, setTipJar] = useState<number | undefined>();
  const [truBucks, setTruBucks] = useState<TokenBalance | undefined>();
  const hasBalance = (truBucks?.balance || 0) > 10;
  const [reactionType, setReactionType] = useState<string | undefined>(props.feed?.reactionTypeForCallingUser);
  const [countLikes, setCountLikes] = useState<number | undefined>(props.feed?.reactionCount);
  const [comments, setComments] = useState<PostComment[] | undefined>(undefined);
  const [inputComment, setInputComment] = useState('');
  const [countComments, setCountComments] = useState<number | undefined>(props.feed?.commentCount);

  const [selectedPost, setSelectedPost] = useState<Feed>();
  //const [pauseLike, setPauseLike] = useState<boolean>(false);

  const postState = useSelector((state: RootState) => state.post);
  const creator = useSelector((state: RootState) => state.creator);
  const user = useSelector((state: RootState) => state.user);

  const dispatch = useDispatch();
  const history = useHistory();
  const inputRef = useRef<HTMLInputElement>(null);

  const [parentComment, setParentComment] = useState<PostComment | undefined>();

  const onViewPost = (post?: Post) => {
    dispatch(resetPostComment());
    if (post?.isLockedForCallingUser && post?.userGUID !== user.user?.guid) {
      dispatch(
        toggleSnackbarOpen({
          message: 'To view this content, click the Unlock button.'
        })
      );
    } else {
      dispatch(playContent({ contentType: ContentType.Post, post }));
    }
  };

  const onViewPostGroup = (postGroup?: PostGroup) => {
    dispatch(resetPostComment());
    if (postGroup?.isLockedForCallingUser) {
      dispatch(
        toggleSnackbarOpen({
          message: 'To view this content, click the Unlock button.'
        })
      );
    } else {
      dispatch(playContent({ contentType: ContentType.PostGroup, postGroup }));
    }
  };

  const incrementViews = (post?: Post) => {
    const view = {
      merchantGUID: post?.merchantGUID,
      postGUID: post?.guid,
      dateViewed: moment().format('MM/DD/YY hh:mm:ss')
    };

    dispatch(createView(view));
  };

  const showPaymentConfirmation = (event: MouseEvent, content?: Feed) => {
    event.stopPropagation();
    setPostContent(content);
    setIsPaymentSubmit(false);
    setIsPaymentConfirmationModal(true);
  };

  const unlockContent = (event: MouseEvent, content?: Feed) => {
    event.stopPropagation();
    setIsPaymentSubmit(true);
    dispatch(unlockPost(content as Post));
  };

  const confirmReport = (value: boolean) => {
    if (value) {
      dispatch(
        toggleSnackbarOpen({
          message: 'Report submitted.',
          type: 'info',
          timeout: 4000
        })
      );
    }

    setIsReportPost(false);
  };

  const togglePostLike = (post: Feed) => {
    const updatedPost = { ...post };
    /*if (!pauseLike) {
      setPauseLike(true);*/
    if (post.reactionTypeForCallingUser) {
      setReactionType(undefined);
      setCountLikes((countLikes as number) - 1);
      dispatch(unlikePost(post.guid as string));
      (updatedPost.reactionCount as number) -= 1;
      updatedPost.reactionTypeForCallingUser = undefined;
    } else {
      setReactionType('like');
      setCountLikes((countLikes as number) + 1);
      dispatch(likePost(post.guid as string));
      (updatedPost.reactionCount as number) += 1;
      updatedPost.reactionTypeForCallingUser = 'like';
    }

    setSelectedPost(updatedPost);
    dispatch(updateContent(updatedPost));

    if (updatedPost.reactionTypeForCallingUser) {
      dispatch(
        toggleSnackbarOpen({
          message: 'Post liked.',
          type: 'info',
          timeout: 2000
        })
      );
    } else {
      dispatch(
        toggleSnackbarOpen({
          message: 'Post unliked.',
          type: 'info',
          timeout: 2000
        })
      );
    }

    /*setTimeout(() => {
      setPauseLike(false);
    }, 5000);
  } else {
    return;
  }*/
  };

  const handleChange = (event?: ChangeEvent) => {
    setInputComment((event?.target as HTMLInputElement).value);
  };

  const handleComment = (isClicked: boolean, event?: KeyboardEvent) => {
    /*if (postState.comments === undefined) {
      dispatch(fetchComments(props.feed?.guid as string));
    }*/

    const tempComments = comments;

    if (isClicked || event?.key === 'Enter') {
      let comment: PostComment = {
        guid: uuid4(),
        userGUID: user.user?.guid,
        postGUID: props.feed?.guid,
        message: isClicked ? inputComment : (event?.target as HTMLInputElement).value,
        dateCreated: moment().toDate(),
        normalizedProfileImageUrl: user.user?.normalizedProfileImageUrl,
        userDisplayName: user.user?.displayName,
        reactionCount: 0
      };

      if (parentComment) {
        comment.parentCommentGUID = parentComment.guid;
      }

      if (tipJar) {
        comment = {
          ...comment,
          ...{
            tipTokenProfileKey: 'kudo',
            tipAmount: tipJar
          }
        };
      }

      (inputRef.current as HTMLInputElement).value = '';
      setInputComment('');
      if (!isClicked) {
        (event?.target as HTMLInputElement).value = '';
      }

      toggleOpenTipJar(false);
      setCountComments((countComments as number) + 1);

      if ((tipJar as number) > (truBucks?.balance as number)) {
        dispatch(
          toggleSnackbarOpen({
            message: 'Insufficient balance for transfer.',
            type: 'danger',
            timeout: 4000
          })
        );
      } else {
        dispatch(postComment(comment));

        tempComments?.push(comment);
        setComments(tempComments);
      }
      setParentComment(undefined);
    }
  };

  const toggleOpenTipJar = (isOpen?: boolean) => {
    const value = isOpen !== undefined ? isOpen : !isTrubucksPanelOpen;
    setIsTrubucksPanelOpen(value);
    dispatch(fetchTokens());
    if (!value) {
      setTimeout(() => {
        setTipJar(undefined);
      }, 500);
    }
  };

  const tipMerchant = (tip: number) => {
    if (tip !== tipJar && hasBalance) {
      setTipJar(tip);
    } else {
      setTipJar(undefined);
    }
  };

  const renderTipPanel = () => {
    return (
      <div className={cn('trubucks-panel')}>
        <div className={cn('header')}>
          <div>Tip FanBucks</div>
          <Button
            size="sm"
            shape="circle"
            color="clear"
            className={cn('close')}
            onClick={() => toggleOpenTipJar(false)}
            hasIcon>
            <Icon name="close" size="xs" />
          </Button>
        </div>
        <div className={cn('ft-mt-2')}>
          <ul className={cn('predefined')}>
            <li>
              <Button
                color={tipJar === 100 ? 'primary' : 'secondary'}
                className={cn('cost')}
                onClick={() => tipMerchant(100)}>
                100 <Icon name="fanbucks" size="sm" />
              </Button>
            </li>
            <li>
              <Button
                color={tipJar === 200 ? 'primary' : 'secondary'}
                className={cn('cost')}
                onClick={() => tipMerchant(200)}>
                200 <Icon name="fanbucks" size="sm" />
              </Button>
            </li>
            <li>
              <Button
                color={tipJar === 500 ? 'primary' : 'secondary'}
                className={cn('cost')}
                onClick={() => tipMerchant(500)}>
                500 <Icon name="fanbucks" size="sm" />
              </Button>
            </li>
            <li>
              <Button
                color={tipJar === 1000 ? 'primary' : 'secondary'}
                className={cn('cost')}
                onClick={() => tipMerchant(1000)}>
                1000 <Icon name="fanbucks" size="sm" />
              </Button>
            </li>
            <li>
              <Button
                color={tipJar === 2000 ? 'primary' : 'secondary'}
                className={cn('cost')}
                onClick={() => tipMerchant(2000)}>
                2000 <Icon name="fanbucks" size="sm" />
              </Button>
            </li>
            <li>
              <Button
                color={tipJar === 5000 ? 'primary' : 'secondary'}
                className={cn('cost')}
                onClick={() => tipMerchant(5000)}>
                5000 <Icon name="fanbucks" size="sm" />
              </Button>
            </li>
          </ul>
        </div>
        <div className={cn('confirmation')}>
          {tipJar && hasBalance ? (
            <div className={cn('has-text-centered')}>
              <div className={cn('is-size-8')}>You are about to tip</div>
              <div className={cn('has-text-weight-semibold', 'is-size-2', 'ft-mb-2')}>
                {tipJar} <Icon name="fanbucks" size="lg" />
              </div>
              <div className={cn('is-size-8')}>Your tip will be sent with your message</div>
            </div>
          ) : (
            <div>
              {hasBalance
                ? 'Select an amount above to tip'
                : 'Insufficient balance. Please add FanBucks to your wallet.'}
            </div>
          )}
        </div>
      </div>
    );
  };

  const fetchPostComments = (post: Feed) => {
    setComments(undefined);
    if (openCommentBox === post.guid) {
      setOpenCommentBox(undefined);
    } else {
      setOpenCommentBox(post.guid);
      setCountComments(post.commentCount);
      dispatch(fetchComments(post.guid as string));
    }
  };

  const toggleCommentLike = (comment: PostComment) => {
    const newComments = cloneDeep(comments);
    const index = comments?.findIndex((x: PostComment) => x.guid === comment.guid);
    if (comments && index !== undefined) {
      const updatedComment = newComments?.[index];

      if (!updatedComment) {
        return;
      }

      if (comment.reactionTypeForCallingUser) {
        dispatch(unlikeComment(comment.guid as string));
        updatedComment.reactionTypeForCallingUser = undefined;
        (updatedComment.reactionCount as number) -= 1;
      } else {
        dispatch(likeComment(comment.guid as string));
        updatedComment.reactionTypeForCallingUser = 'like';
        (updatedComment.reactionCount as number) += 1;
      }

      setComments(newComments);
    }
  };

  const openFeedType = () => {
    if (props.feed?.postType === PostType.PostGroup) {
      onViewPostGroup(props.feed);
    } else if (props.feed?.postType === PostType.Merchandise) {
      history.push(`/merch/${props.feed.guid}`);
    } else {
      onViewPost(props.feed);
    }
  };

  useEffect(() => {
    if (postState.status === 'rejected' && props.feed?.guid === postContent?.guid) {
      setIsPaymentSubmit(false);
      setIsPaymentConfirmationModal(false);
      if (!isInsufficientBalanceModal) {
        dispatch(
          toggleSnackbarOpen({
            message: postState.error
          })
        );
        setIsInsufficientBalanceModal(true);
      }
    }
  }, [postState.status]);

  useEffect(() => {
    if (!postState.postUnlock && postState.isUnlockSuccess) {
      const checkPosts = postState.posts?.find((p) => p.guid === postState.post?.guid);
      const checkCreator = creator.feed?.find((p) => p.guid === postState.post?.guid);

      if (checkPosts) {
        const newPosts = clone(postState.posts);
        const findPostId = postState.posts?.findIndex((p) => p.guid === postState.post?.guid);

        if (findPostId !== undefined && newPosts && checkPosts) {
          const newPost = clone(checkPosts);
          newPost.isLockedForCallingUser = false;
          newPosts[findPostId] = newPost;

          const updatedPosts = clone(postState);
          updatedPosts.posts = newPosts;

          dispatch(patchPosts(newPosts));
        }
      }

      if (checkCreator) {
        const findCreatorPostId = creator.feed?.findIndex((p) => p.guid === postState.post?.guid);
        const newFeed = clone(creator.feed);

        if (findCreatorPostId !== undefined && newFeed && checkCreator) {
          const newPost = clone(checkCreator);
          newPost.isLockedForCallingUser = false;

          newFeed[findCreatorPostId] = newPost;

          const updatedPosts = clone(postState);
          updatedPosts.posts = newFeed;
          dispatch(patchCreatorActivity(newFeed));
        }
      }
      setIsPaymentSubmit(false);
      setIsPaymentConfirmationModal(false);

      props.feed?.postType === PostType.PostGroup
        ? dispatch(playContent({ contentType: ContentType.PostGroup, postGroup: postState.postGroup }))
        : dispatch(playContent({ contentType: ContentType.Post, post: postState.post }));
    }
  }, [postState.postUnlock]);

  useEffect(() => {
    if (user.balance) {
      setTruBucks(user.balance.find((x) => x.tetheredMerchantGUID === undefined));
    }
  }, [user.balance]);

  useEffect(() => {
    if (postState.comments) {
      setComments([...postState.comments].reverse());
    } else if (comments?.length) {
      dispatch(setPostComments(comments.reverse()));
    }
  }, [postState.comments]);

  useEffect(() => {
    if (postState.isCommentDeleted) {
      setCountComments((countComments as number) - 1);
      dispatch(resetDeletedComment());
    }
  }, [postState.isCommentDeleted]);

  useEffect(() => {
    if (selectedPost && selectedPost.guid === session.post?.guid) {
      //setPauseLike(false);
      setSelectedPost(undefined);
    }
  }, [postState.posts]);

  return (
    <div className={cn('post', { promoted: props.isPromoted })}>
      <div className={cn('header')}>
        {props.type === 'feed' && (
          <>
            <div className={cn('flexbox')}>
              <img
                className={cn('avatar')}
                src={props.feed?.normalizedProfileImageUrl}
                onClick={() => history.push(`/${props.feed?.userUsername}`)}
              />
              <div>
                <div
                  className={cn('creator')}
                  onClick={() => {
                    history.push(`/${props.feed?.userUsername}`);
                  }}>
                  {props.feed?.merchantName || props.feed?.userDisplayName}
                </div>
                <div className={cn('moment', { promoted: props.isPromoted })}>
                  {props.feed?.postType} ● {convertMoment(props.feed?.dateCreated, props.isPromoted)}
                </div>
              </div>
            </div>
            {props.feed?.postType === PostType.LiveStream && (
              <div className={cn('has-text-weight-bold', 'ft-mt-2', 'is-size-5')}>
                Live stream on {moment(props.feed?.dateCreated).format('MM/DD/YYYY')}
              </div>
            )}
            {[PostType.PostGroup, PostType.Audio].indexOf(props.feed?.postType as PostType) > -1 && (
              <div className={cn('has-text-weight-bold', 'is-size-5', 'ft-mt-2')}>{props.feed?.name}</div>
            )}
          </>
        )}
        {props.type === 'activity' && (
          <div className={cn('flexbox')}>
            <div>
              <div className={cn('moment', { promoted: props.isPromoted })}>
                {props.feed?.postType} ● {convertMoment(props.feed?.dateCreated, props.isPromoted)}
              </div>
              {[PostType.PostGroup, PostType.Audio].indexOf(props.feed?.postType as PostType) === -1 && (
                <div className={cn('has-text-weight-bold', 'is-size-5')}>{props.feed?.name}</div>
              )}
              {props.feed?.postType === PostType.LiveStream && (
                <div className={cn('has-text-weight-bold', 'is-size-5')}>
                  Live stream on {moment(props.feed?.dateCreated).format('MM/DD/YYYY')}
                </div>
              )}
            </div>
          </div>
        )}
        <div
          className={cn('more', {
            'position-fix':
              (props.feed?.postType === PostType.Image ||
                props.feed?.postType === PostType.Audio ||
                props.feed?.postType === PostType.PostGroup) &&
              props.type === 'activity'
          })}>
          <ContextMenu
            interface={
              <Button shape="circle" color="clear" hasIcon>
                <Icon name="ellipsis" size="sm" />
              </Button>
            }>
            <ul>
              <li onClick={() => setIsReportPost(true)}>Report post</li>
            </ul>
          </ContextMenu>
        </div>
      </div>
      <div className={cn('body')} onClick={() => openFeedType()}>
        {props.feed?.postType === PostType.LiveStream && (
          <div className={cn('play-button')}>
            <img src={props.feed?.thumbnailImageUrl || props.feed?.normalizedProfileImageUrl || defaultAvatar} />
            <div className={cn('hovered')}>
              <Icon name="play" size="xl" />
            </div>
          </div>
        )}
        {(props.feed?.postType === PostType.PostGroup || props.feed?.postType === PostType.Audio) && (
          <div className={cn('album-wrapper')}>
            <div className={cn('album-details')}>
              {props.feed?.thumbnailImageUrl && (
                <div className={cn('play-button', 'album-art', 'ft-mr-4')}>
                  <img
                    src={props.feed?.thumbnailImageUrl}
                    className={cn({
                      blur: props.feed.isLockedForCallingUser && props.feed?.userGUID !== user.user?.guid
                    })}
                  />
                  <div className={cn('hovered')}>
                    <Icon name="play" size="xl" />
                  </div>
                </div>
              )}
              <div>
                <div className={cn('has-text-muted', 'is-size-8')}>
                  {props.feed?.postType === PostType.Audio ? 'SINGLE' : 'ALBUM'}
                </div>
                <div className={cn('has-text-weight-bold', 'is-size-5')}>{props.feed?.name}</div>
                <div className={cn('is-size-7', 'ft-mt-4')}>
                  Released on {moment(props.feed?.dateCreated).format('MM/DD/YYYY')}
                </div>
                {props.feed.isLockedForCallingUser && props.feed?.userGUID !== user.user?.guid && (
                  <div className={cn('ft-mt-6')}>
                    <Button
                      hasIconOnText
                      onClick={(event) => showPaymentConfirmation(event, props.feed)}
                      disabled={props.feed.guid === postState.postUnlock}>
                      Unlock for {props.feed.unlockAmount} <Icon name="fanbucks" className={cn('ft-ml-1', 'ft-mr-1')} />
                      <p className={cn('is-size-8')}>(${props.feed.usdUnlockAmount?.toFixed(2)})</p>
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {props.feed?.postType === PostType.Video &&
          (props.feed.isLockedForCallingUser ? (
            <div className={cn('image-wrapper')}>
              <img
                className={cn({ blur: props.feed.isLockedForCallingUser && props.feed?.userGUID !== user.user?.guid })}
                src={props.feed?.featuredImageUrl || props.feed.thumbnailImageUrl}
                onClick={() => incrementViews(props.feed)}
              />
              {props.feed.isLockedForCallingUser && props.feed?.userGUID !== user.user?.guid && (
                <div className={cn('frosted')}>
                  <Button
                    hasIconOnText
                    onClick={(event) => showPaymentConfirmation(event, props.feed)}
                    disabled={props.feed.guid === postState.postUnlock}>
                    Unlock for {props.feed.unlockAmount} <Icon name="fanbucks" className={cn('ft-ml-1', 'ft-mr-1')} />
                    <p className={cn('is-size-8')}>(${props.feed.usdUnlockAmount?.toFixed(2)})</p>
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <div className={cn('play-button')}>
              <img src={props.feed?.featuredImageUrl || props.feed.thumbnailImageUrl} />
              <div className={cn('hovered')}>
                <Icon name="play" size="xl" />
              </div>
            </div>
          ))}
        {props.feed?.postType === PostType.Image && (
          <div className={cn('image-wrapper')}>
            <img
              className={cn({ blur: props.feed.isLockedForCallingUser && props.feed?.userGUID !== user.user?.guid })}
              src={props.feed?.thumbnailImageUrl}
              onClick={() => incrementViews(props.feed)}
            />
            {props.feed.isLockedForCallingUser && props.feed?.userGUID !== user.user?.guid && (
              <div className={cn('frosted')}>
                <Button
                  hasIconOnText
                  onClick={(event) => showPaymentConfirmation(event, props.feed)}
                  disabled={props.feed.guid === postState.postUnlock}>
                  Unlock for {props.feed.unlockAmount} <Icon name="fanbucks" className={cn('ft-ml-1', 'ft-mr-1')} />
                  <p className={cn('is-size-8')}>(${props.feed.usdUnlockAmount?.toFixed(2)})</p>
                </Button>
              </div>
            )}
          </div>
        )}
        {props.feed?.postType === PostType.Merchandise && (
          <div className={cn('image-wrapper')}>
            <img src={props.feed?.contentUrl} />
          </div>
        )}
      </div>
      <div className={cn('footer')}>
        {!props.isPromoted && (
          <div
            className={cn('flexbox', 'engagement')}
            /*onClick={() =>
              props.feed?.postType === PostType.PostGroup ? onViewPostGroup(props.feed) : onViewPost(props.feed)
            }*/
          >
            <div
              className={cn('flexbox', 'ft-mr-4', { highlight: reactionType })}
              onClick={() => togglePostLike(props.feed as Feed)}>
              <span className={cn('icon')}>
                <Icon name="heart" size="sm" />
              </span>
              <span className={cn('count')}>{countLikes}</span>
            </div>
            <div className={cn('flexbox')} onClick={() => fetchPostComments(props.feed as Post)}>
              <span className={cn('icon')}>
                <Icon name="messages" size="sm" />
              </span>
              <span className={cn('count')}>
                {props.feed?.guid === openCommentBox ? countComments : props.feed?.commentCount}
              </span>
            </div>
          </div>
        )}
        {props.type === 'feed' && props.feed?.name && (
          <div className={cn('ft-mt-1', 'has-text-weight-bold')}>{props.feed?.name}</div>
        )}
        {props.feed?.description && <div className={cn('ft-mt-1')}>{props.feed?.description}</div>}
        {openCommentBox !== undefined && (
          <>
            <div className={cn('ft-mt-2')}>
              {comments ? (
                <>
                  {comments.length === 0 ? (
                    <div className={cn('ft-py-2', 'has-text-muted')}>Be the first to leave a comment.</div>
                  ) : (
                    comments[0].postGUID === openCommentBox && (
                      <>
                        {comments && comments.length > 3 && (
                          <div className={cn('ft-mb-2')}>
                            <Anchor
                              className={cn('is-size-7')}
                              onClick={() =>
                                props.feed?.postType === PostType.PostGroup
                                  ? onViewPostGroup(props.feed)
                                  : onViewPost(props.feed)
                              }>
                              View all comments
                            </Anchor>
                          </div>
                        )}
                        {comments.slice(comments.length >= 3 ? comments.length - 3 : 0).map((comment) => (
                          <Comment
                            key={comment.guid}
                            comment={comment}
                            type={comment.tipAmount ? 'tip' : 'comment'}
                            className={cn('ft-mb-2')}
                            onToggleCommentLike={() => toggleCommentLike(comment)}
                            onReply={() => setParentComment(comment)}
                          />
                        ))}
                      </>
                    )
                  )}
                </>
              ) : (
                <>
                  <div className={cn('flexbox')}>
                    <Skeleton type="circ" radius={12} />
                    <Skeleton className={cn('desktop', 'ft-ml-2')} type="rect" width="160px" height="12px" />
                  </div>
                  <div className={cn('flexbox', 'ft-mt-2')}>
                    <Skeleton type="circ" radius={12} />
                    <Skeleton className={cn('desktop', 'ft-ml-2')} type="rect" width="240px" height="12px" />
                  </div>
                </>
              )}

              {parentComment && <div className={cn('replying')}>Replying to {parentComment?.userUsername}...</div>}
              {openCommentBox !== undefined &&
                comments &&
                (comments.length === 0 || comments[0].postGUID === openCommentBox) && (
                  <Input
                    inputRef={inputRef}
                    name="inputComment"
                    className={cn('ft-mt-2')}
                    fieldClassName={cn('comment')}
                    iconLeft={
                      <img
                        className={cn('avatar', 'comment-avatar')}
                        src={user.user?.normalizedProfileImageUrl || defaultAvatar}
                      />
                    }
                    iconRight={
                      <>
                        <div className={cn('flexbox')}>
                          {props.feed?.merchantGUID !== user.user?.merchantGUID && (
                            <div onClick={() => toggleOpenTipJar()}>
                              <Icon
                                name="fanbucks"
                                className={cn('trubucks', 'ft-mr-2', { open: isTrubucksPanelOpen })}
                              />
                            </div>
                          )}
                          <Button
                            color="primary"
                            size="sm"
                            onClick={() => handleComment(true)}
                            disabled={inputComment === ''}>
                            Send
                          </Button>
                        </div>
                      </>
                    }
                    placeholder="Add your comment"
                    autoComplete="off"
                    onKeyUp={(event) => handleComment(false, event)}
                    onChange={handleChange}
                    type="text"
                  />
                )}
              {isTrubucksPanelOpen && renderTipPanel()}
            </div>
          </>
        )}
      </div>

      {isInsufficientBalanceModal && (
        <Modal onClose={() => setIsInsufficientBalanceModal(false)}>
          <div className={cn('payment-confirmation-content', 'has-text-centered')}>
            <div className="is-size-6">
              {postState.errorPayload?.message}.<br />
              <Anchor type="primary" onClick={() => history.push('preferences/wallet')}>
                Click here to purchase FanBucks.
              </Anchor>
            </div>
            {/* <div className={cn('flexbox', 'ft-mt-6', 'payment-buttons')}>
              <Button
                hasIconOnText
                type="button"
                onClick={(event) => unlockContent(event, postContent)}
                disabled={isPaymentSubmit}>
                Send {postContent?.unlockAmount}
                <Icon name="fanbucks" className={'ft-ml-1'} />
              </Button>
              <Button
                type="button"
                className={cn('ft-ml-4')}
                color="clear"
                onClick={() => setIsPaymentConfirmationModal(false)}>
                Cancel
              </Button>
            </div> */}
          </div>
        </Modal>
      )}

      {isPaymentConfirmationModal && (
        <Modal onClose={() => setIsPaymentConfirmationModal(false)}>
          <div className={cn('payment-confirmation-content', 'has-text-centered')}>
            <div className="is-size-6">Unlock this content with FanBucks.</div>
            <div className={cn('flexbox', 'ft-mt-6', 'payment-buttons')}>
              <Button
                hasIconOnText
                type="button"
                onClick={(event) => unlockContent(event, postContent)}
                disabled={isPaymentSubmit}>
                Send {postContent?.unlockAmount}
                <Icon name="fanbucks" className={cn('ft-ml-1', 'ft-mr-1')} />
                <p className={cn('is-size-8')}>(${postContent?.usdUnlockAmount?.toFixed(2)})</p>
              </Button>
              <Button
                type="button"
                className={cn('ft-ml-4 ml-6')}
                color="clear"
                onClick={() => setIsPaymentConfirmationModal(false)}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {isReportPost && (
        <Modal onClose={() => setIsReportPost(false)}>
          <ReportPostModal onSelect={confirmReport} post={props.feed as Feed} />
        </Modal>
      )}
    </div>
  );
};

export default PostFeed;
