import { httpClient } from 'http/httpClient';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { PaymentProfile, TokenBundle } from 'types';
import { PendingAction, RejectedAction } from 'state/store';

export const fetchTokenBundles = createAsyncThunk('payment/fetchTokenBundles', async () => {
  const response = await httpClient.get('/tokenpurchasebatches');
  return response.data;
});

export const purchaseTokens = createAsyncThunk('payment/purchaseTokens', async (payload: PaymentProfile) => {
  const response = await httpClient.post('/tokenpurchase', payload);
  return response.data;
});

interface PaymentState {
  status: string;
  error?: string;
  tokens?: TokenBundle[];
  isPurchaseSuccess?: boolean;
  transactionResponse?: string;
}

const initialState: PaymentState = {
  status: 'idle'
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    resetPurchaseStatus: (state) => {
      state.isPurchaseSuccess = undefined;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTokenBundles.fulfilled, (state, action) => {
        state.status = 'success';
        state.tokens = action.payload;
      })
      .addCase(purchaseTokens.fulfilled, (state, action) => {
        state.status = 'success';
        state.isPurchaseSuccess = true;
        state.transactionResponse = action.payload;
      })
      .addCase(purchaseTokens.rejected, (state, action) => {
        state.status = 'rejected';
        state.isPurchaseSuccess = false;
        state.transactionResponse = action.error.message;
      })
      .addMatcher(
        (action): action is PendingAction => action.type.endsWith('/pending'),
        (state) => {
          state.status = 'loading';
          // state.isPurchaseSuccess = undefined;
        }
      )
      .addMatcher(
        (action): action is RejectedAction => action.type.endsWith('/rejected'),
        (state, action) => {
          state.error = 'rejected';
          state.error = action.error.message;
        }
      );
  }
});
export const { resetPurchaseStatus } = paymentSlice.actions;
export default paymentSlice.reducer;
