import { httpClient } from 'http/httpClient';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PendingAction, RejectedAction } from 'state/store';
import { Channel, ChannelMessage, User } from 'types';

export const getChannels = createAsyncThunk('channel/getChannels', async (merchantGuid: string) => {
  const response = await httpClient.get(`/channels/search`, {
    params: { merchantGuid: merchantGuid }
  });
  return response.data;
});

export const sendChannelMessage = createAsyncThunk('channel/sendChannelMessage', async (message: ChannelMessage) => {
  const response = await httpClient.post(`/channels/${message.channelGUID}/messages`, message);
  return { message, data: response.data };
});

export const getChannelMessages = createAsyncThunk('channel/getChannelMessages', async (channelGUID: string) => {
  const response = await httpClient.get(`/channels/${channelGUID}/messages`);
  return response.data;
});

export const addUsersToChannel = createAsyncThunk(
  'channel/addUsersToChannel',
  async (request: { channelGUID: string; userGUIDs: string[] }) => {
    const response = await httpClient.post(`/channels/${request.channelGUID}/users`, {
      userGUIDs: request.userGUIDs
    });
    return { request, data: response.data };
  }
);

export const removeUserFromChannel = createAsyncThunk(
  'channel/removeUserFromChannel',
  async (request: { channelGUID: string; userGUID: string }) => {
    const response = await httpClient.delete(`/channels/${request.channelGUID}/users/${request.userGUID}`);
    return response.data;
  }
);

export const getMembersPerChannel = createAsyncThunk('channel/getMembersPerChannel', async (channelGUID: string) => {
  const response = await httpClient.get(`/channels/${channelGUID}/users`);
  return response.data;
});

interface ChannelState {
  status: string;
  error?: string;
  channel?: Channel;
  channels?: Channel[];
  channelMessages?: ChannelMessage[];
  newMessage?: ChannelMessage;
  channelMembers?: User[];
}

const initialState: ChannelState = {
  status: 'idle'
};

const channelSlice = createSlice({
  name: 'channel',
  initialState,
  reducers: {
    setNewMessage: (state, action) => {
      state.newMessage = action.payload;
    },
    resetChannelMembers: (state) => {
      state.channelMembers = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChannels.fulfilled, (state, action) => {
        state.status = 'success';
        state.channels = action.payload;
      })
      .addCase(getChannelMessages.fulfilled, (state, action) => {
        state.status = 'success';
        state.channelMessages = action.payload;
      })
      .addCase(sendChannelMessage.fulfilled, (state) => {
        state.status = 'success';
        /*const channelMessages = cloneDeep(state.channelMessages);
        const updatedMessage = action.payload.message;
        updatedMessage.guid = action.payload.data.guid;
        channelMessages?.unshift(updatedMessage);
        state.channelMessages = channelMessages;*/
      })
      .addCase(getMembersPerChannel.fulfilled, (state, action) => {
        state.status = 'success';
        state.channelMembers = action.payload;
      })
      .addMatcher(
        (action): action is PendingAction => action.type.endsWith('/pending'),
        (state) => {
          state.status = 'loading';
        }
      )
      .addMatcher(
        (action): action is RejectedAction => action.type.endsWith('/rejected'),
        (state, action) => {
          state.error = 'rejected';
          state.error = action.error.message;
        }
      );
  }
});

export const { setNewMessage, resetChannelMembers } = channelSlice.actions;
export default channelSlice.reducer;
