import React from 'react';
import classnames from 'classnames/bind';
import Button from 'components/Button';

import styles from './ApplyConfirmationModal.module.scss';

const cn = classnames.bind(styles);

interface ClubChannelProps {
  onSelect: (value: boolean) => void;
}

const ApplyConfirmationModal = (props: ClubChannelProps) => {
  return (
    <div className={cn('modal-content')}>
      Thank you for applying to be an athlete on HeyFans! An account rep will reach out to you shortly.
      <div className={cn('ft-mt-4')}>
        <Button color="primary" onClick={() => props.onSelect(true)}>
          OK
        </Button>
      </div>
    </div>
  );
};

export default ApplyConfirmationModal;
