import React, { MouseEventHandler } from 'react';

import classnames from 'classnames/bind';
import moment from 'moment';

import { PostComment } from 'types';
import { generateNumberFromUsername } from 'utils';
import Icon from 'components/Icon';

import defaultAvatar from 'assets/images/default-avatar-1.png';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/store';
import { deleteComment } from 'state/reducers/postReducer';
import { toggleSnackbarOpen } from 'state/reducers/snackbarReducer';
import { Badge } from 'types/badge';
import { useHistory } from 'react-router-dom';
import { stopContent } from 'state/reducers/sessionReducer';
import styles from './Comment.module.scss';

const cn = classnames.bind(styles);

const chatColors = [
  '#8b69f0',
  '#be6bd8',
  '#ef8440',
  '#d02e5a',
  '#00b488',
  '#a6d668',
  '#e58961',
  '#e3440a',
  '#03bfcf',
  '#43e0c4',
  '#eeaf0e'
];

interface CommentProps {
  comment: PostComment;
  type: 'comment' | 'chat' | 'tip';
  parentComment?: PostComment;
  highlightComment?: string;
  onToggleCommentLike?: MouseEventHandler;
  onReply?: MouseEventHandler;
  onHighlight?: MouseEventHandler;
  className?: string;
  badge?: Badge;
}

const Comment = (props: CommentProps) => {
  const { user } = useSelector((state: RootState) => ({
    user: state.user
  }));
  const dispatch = useDispatch();
  const history = useHistory();

  const deletePostComment = (comment: PostComment) => {
    if (comment.guid) {
      dispatch(deleteComment(comment.guid));
      dispatch(
        toggleSnackbarOpen({
          message: 'Your comment has been deleted.',
          type: 'info',
          timeout: 4000
        })
      );
    }
  };

  const goToUserProfile = (comment: PostComment) => {
    dispatch(stopContent());
    if (comment.merchantGUID) {
      history.push(`/${comment.userUsername}`);
    } else {
      history.push(`/profile/${comment.userUsername}`);
    }
  };

  return {
    comment: (
      <div
        className={cn('comment', 'flexbox', 'flexbox-start', props.className, {
          highlight: props.comment.guid === props.highlightComment
        })}>
        <img className={cn('avatar')} src={props.comment.normalizedProfileImageUrl || defaultAvatar} />
        <div className={cn('ft-ml-2')}>
          <div className={cn('flexbox')}>
            <span className={cn('link')} onClick={() => goToUserProfile(props.comment)}>
              {props.comment.userDisplayName}
            </span>
            <img className={cn('badge', 'ft-ml-2')} src={props.badge?.badgeImageUrl} />
          </div>
          {props.parentComment && (
            <div className={cn('is-size-8')}>
              Replying to{' '}
              <span className={cn('link')} onClick={props.onHighlight}>
                {props.parentComment?.userDisplayName}
              </span>
            </div>
          )}
          <div>{props.comment.message}</div>
          <div className={cn('flexbox', 'controls', 'ft-mt-1')}>
            <div
              className={cn({ 'has-text-primary': props.comment.reactionTypeForCallingUser })}
              onClick={props.onToggleCommentLike}>
              {props.comment?.reactionCount} Likes
            </div>
            <div
              className={cn(user.user?.guid === props.comment.userGUID ? 'ft-ml-4' : 'ft-mx-4')}
              onClick={props.onReply}>
              Reply
            </div>
            {user.user?.guid === props.comment.userGUID ? (
              <div className={cn('ft-mx-4', 'delete')} onClick={() => deletePostComment(props.comment)}>
                Delete
              </div>
            ) : (
              ''
            )}
            <div className={cn('moment')}>{moment(props.comment.dateCreated).fromNow()}</div>
          </div>
        </div>
      </div>
    ),
    chat: (
      <div className={cn('chat')}>
        <img className={cn('avatar')} src={props.comment.normalizedProfileImageUrl || defaultAvatar} />
        <span
          className={cn('ft-ml-2', 'sender')}
          style={{ color: chatColors[generateNumberFromUsername(props.comment.userDisplayName || '', 11)] }}>
          {props.comment.userDisplayName}
        </span>
        <span className={cn('ft-ml-2', 'message')}>{props.comment.message}</span>
      </div>
    ),
    tip: (
      <div className={cn('tip')}>
        <div className={cn('detail')}>
          <div className={cn('header', 'ft-mb-1')}>
            <img className={cn('avatar')} src={props.comment.normalizedProfileImageUrl || defaultAvatar} />
            <span>
              {props.comment.userDisplayName} tipped {props.comment.tipAmount}
            </span>
            <Icon name="fanbucks" size="sm" className={cn('ft-mx-1')} />
            <span>FanBucks</span>
          </div>
          <div>{props.comment.message}</div>
        </div>
      </div>
    )
  }[props.type];
};

export default Comment;
