import React, { ReactNode } from 'react';
import classnames from 'classnames/bind';

import styles from './ContextMenuList.module.scss';

const cn = classnames.bind(styles);

interface ContextMenuProps {
  children?: ReactNode;
  alignment?: 'mobile' | 'channel';
}

const ContextMenuList = (props: ContextMenuProps) => {
  return (
    <>
      <div className={cn('context-menu', props.alignment)}>
        <div className={cn('arrow')} />
        <div className={cn('submenu')}>{props.children}</div>
      </div>
    </>
  );
};

export default ContextMenuList;
