import React, { MouseEventHandler, ReactNode } from 'react';
import classnames from 'classnames/bind';

import styles from './Button.module.scss';

const cn = classnames.bind(styles);

interface ButtonProps {
  children: string | ReactNode;
  color?: 'primary' | 'secondary' | 'white' | 'clear' | 'disabled' | 'clear-primary' | 'danger';
  className?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  size?: 'sm' | 'md' | 'lg';
  shape?: 'circle' | 'rect';
  hasIcon?: boolean;
  disabled?: boolean;
  hasIconOnText?: boolean;
  onClick?: MouseEventHandler;
}

const Button = (props: ButtonProps) => {
  return (
    <button
      type={props.type}
      className={cn('button', props.size, props.shape, props.color, props.className, {
        icon: props.hasIcon,
        'icon-text': props.hasIconOnText
      })}
      disabled={props.disabled}
      onClick={props.onClick}>
      {props.children}
    </button>
  );
};

export default Button;
