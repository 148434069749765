import { httpClient } from 'http/httpClient';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ContentType, SidebarType } from 'enums';
import { Post, PostGroup, UserMessages, VideoCallRequest, VideoMeetings, VideoMessageRequest } from 'types';
import { PendingAction, RejectedAction } from 'state/store';

export const getPostsFromPostGroup = createAsyncThunk('post/appendPostsToPostGroup', async (request: string) => {
  const response = await httpClient.get('/posts/search', {
    params: { postGroupGUID: request }
  });
  return response.data;
});

export const generateVideoMeetingToken = createAsyncThunk('session/videoMeetingToken', async (guid: string) => {
  const response = await httpClient.post(`videomeetings/${guid}/token`, {});
  return response.data;
});

export interface SessionState {
  status: string;
  error?: string;
  authToken?: string;
  isMessagesOpen?: boolean;
  isNotificationsOpen?: boolean;
  isPlayContent?: boolean;
  isVideoCallActive?: boolean;
  purchasePost?: Post;
  sidebarType?: SidebarType;
  contentType?: ContentType;
  post?: Post;
  postGroup?: PostGroup;
  postsInPostGroup?: Post[];
  videoCall?: VideoCallRequest;
  videoMessage?: VideoMessageRequest;
  videoMeetingToken?: VideoMeetings;
  directMessageMerchant?: UserMessages;
}

const initialState: SessionState = {
  status: 'idle'
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    saveToken: (state, action) => {
      state.authToken = action.payload;
    },
    clearToken: () => initialState,
    playContent: (state, action) => {
      state.isPlayContent = true;
      state.contentType = action.payload.contentType;
      switch (action.payload.contentType) {
        case ContentType.Post:
          state.post = action.payload.post;
          break;
        case ContentType.PostGroup:
          state.postGroup = action.payload.postGroup;
          break;
        case ContentType.VideoMessage:
          state.videoMessage = action.payload.videoMessage;
          break;
      }
    },
    stopContent: (state) => {
      state.isPlayContent = false;
      state.post = undefined;
      state.postGroup = undefined;
      state.postsInPostGroup = undefined;
      state.videoMessage = undefined;
    },
    updateContent: (state, action) => {
      state.post = action.payload;
    },
    toggleMessages: (state) => {
      state.sidebarType = SidebarType.Messages;
      state.isMessagesOpen = !state.isMessagesOpen;
    },
    toggleNotifications: (state) => {
      state.sidebarType = SidebarType.Notification;
      state.isNotificationsOpen = !state.isNotificationsOpen;
    },
    closeRightSidebar: (state) => {
      state.isMessagesOpen = false;
      state.isNotificationsOpen = false;
    },
    joinCall: (state, action) => {
      state.isVideoCallActive = true;
      state.videoCall = action.payload;
    },
    triggerTwilioEndCall: (state) => {
      state.videoCall = undefined;
    },
    endCall: (state) => {
      state.isVideoCallActive = false;
      state.videoCall = undefined;
    },
    setMerchantDirectMessage: (state, action) => {
      state.directMessageMerchant = action.payload;
    },
    resetMerchantDirectMessage: (state) => {
      state.directMessageMerchant = undefined;
    },
    initializePurchaseItem: (state, action) => {
      state.purchasePost = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPostsFromPostGroup.fulfilled, (state, action) => {
        state.status = 'success';
        state.postsInPostGroup = action.payload;
      })
      .addCase(generateVideoMeetingToken.fulfilled, (state, action) => {
        state.status = 'success';
        state.videoMeetingToken = action.payload;
      })
      .addMatcher(
        (action): action is PendingAction => action.type.endsWith('/pending'),
        (state) => {
          state.status = 'loading';
        }
      )
      .addMatcher(
        (action): action is RejectedAction => action.type.endsWith('/rejected'),
        (state, action) => {
          state.error = 'rejected';
          state.error = action.error.message;
        }
      );
  }
});

export const {
  saveToken,
  clearToken,
  playContent,
  stopContent,
  updateContent,
  toggleMessages,
  toggleNotifications,
  closeRightSidebar,
  joinCall,
  endCall,
  triggerTwilioEndCall,
  setMerchantDirectMessage,
  resetMerchantDirectMessage,
  initializePurchaseItem
} = sessionSlice.actions;
export default sessionSlice.reducer;
