import React, { MouseEventHandler, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { RootState } from 'state/store';

import Icon from 'components/Icon';
import { toggleToastClose, toggleToastOpen } from 'state/reducers/toastReducer';
import Button from 'components/Button';
import { joinCall } from 'state/reducers/sessionReducer';
import { callResponse } from 'state/reducers/requestReducer';
import styles from './ToastAlert.module.scss';

const cn = classnames.bind(styles);
interface ToastProps {
  onClose?: MouseEventHandler<HTMLDivElement>;
  width?: string;
  onClick?: MouseEventHandler;
  className?: string;
}

const ToastAlert = (props: ToastProps) => {
  const dispatch = useDispatch();
  const { toast } = useSelector((state: RootState) => ({
    toast: state.toast
  }));

  function handleTimeout() {
    setTimeout(() => {
      dispatch(toggleToastClose());
    }, toast.timeout);
  }

  const onJoinCall = () => {
    if (toast.data?.guid) {
      dispatch(callResponse({ guid: toast.data?.guid, isAnswer: true }));
    }
    dispatch(joinCall(toast.data));
    setTimeout(() => {
      dispatch(toggleToastClose());
    }, 3000);
  };

  const hangUpCall = () => {
    if (toast.data?.guid) {
      dispatch(callResponse({ guid: toast.data?.guid, isAnswer: false }));
    }
    dispatch(toggleToastClose());
    dispatch(
      toggleToastOpen({
        message: 'You can join the call in Preferences.',
        timeout: 10000,
        messageType: 'RejectedCall',
        url: '/preferences/video-calls'
      })
    );
  };

  useEffect(() => {
    if (toast.toggle && toast.messageType !== 'MerchantMeetAndGreetRequestCallStarted') {
      handleTimeout();
    }
  }, [toast.toggle]);
  return (
    <>
      {toast.toggle && (
        <div
          className={cn(
            'toast',
            'ft-mb-2',
            toast.messageType === 'MerchantMeetAndGreetRequestCallStarted' && 'video-call',
            props.className
          )}
          onClick={props.onClick}>
          {toast.messageType !== 'MerchantMeetAndGreetRequestCallStarted' && (
            <Button
              className={cn('close-toast')}
              type="button"
              color="clear"
              hasIcon
              onClick={() => dispatch(toggleToastClose())}>
              <Icon name="close" size="xs" />
            </Button>
          )}
          <div className={cn('ft-mt-2', 'flex', 'content')}>
            <div>{toast.message}</div>
            {toast.messageType === 'MerchantMeetAndGreetRequestCallStarted' && toast.data !== undefined && (
              <div className={cn('call-buttons')}>
                <Button hasIconOnText className={cn('call', 'answer')} onClick={() => onJoinCall()}>
                  <Icon name="call-on" />
                  Answer
                </Button>
                <Button hasIconOnText className={cn('call', 'hang-up')} onClick={() => hangUpCall()}>
                  <Icon name="call-off" />
                  Reject
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ToastAlert;
