import React from 'react';
import classnames from 'classnames/bind';

import styles from './Skeleton.module.scss';

const cn = classnames.bind(styles);

interface SkeletonProps {
  type: 'rect' | 'circ';
  width?: string;
  height?: string;
  radius?: number;
  className?: string;
}

const Skeleton = (props: SkeletonProps) => {
  const { width = 0, height = 0, radius = 0 } = props;
  return (
    <>
      {props.type === 'rect' && <div className={cn('skeleton', props.className)} style={{ width, height }} />}
      {props.type === 'circ' && (
        <div
          className={cn('skeleton', 'circle', props.className)}
          style={{ width: `${radius * 2}px`, height: `${radius * 2}px` }}
        />
      )}
    </>
  );
};

export default Skeleton;
